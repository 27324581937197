<ng-container [ngSwitch]="viewState">
	<ng-container *ngSwitchCase="CalendarViewState.SCHEDULE">
		<app-form-field
			*ngIf="locations?.length"
			type="select"
			[disabled]="locations?.length === 1"
			[options]="locations"
			[value]="selectedLocation"
			(valueChange)="selectLocation($event)"
		>
			<div matPrefix>Location:</div>
		</app-form-field>

		<app-form-field type="select" [withSearch]="true" [options]="tzones" [value]="tz" (valueChange)="changeTimezone($event)">
			<div matPrefix>Timezone:</div>
		</app-form-field>

		<ng-container *ngFor="let calendar of _filteredContacts">
			<div class="name">{{ calendar.first_name }} {{ calendar.last_name }}</div>
			<ng-container *ngIf="calendar.duration$ | async as durations">
				<div *ngIf="durations.length > 1" class="duration">
					<b>How long do you need?</b>
					<mat-button-toggle-group [value]="duration" (change)="duration = $event.value">
						<mat-button-toggle *ngFor="let d of durations" [value]="d.value">
							{{ d.label }}
						</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</ng-container>
			<app-skeleton [show]="showCalendar" [height]="260" [width]="412">
				<div *appLazyContent>
					<app-calendar
						[forceMobile]="forceMobile"
						[timezone]="tz"
						[duration]="duration"
						[existingAppointment]="(existingAppointments$ | async)[calendar.id]"
						[calendarId]="calendar.calendar_id"
						(cellClick)="onTimeSelect($event, calendar.calendar_id)"
						(deleteAppointment)="_deleteAppointment($event, calendar.calendar_id)"
						(updateAppointment)="_updateAppointment($event, calendar.calendar_id)"
					></app-calendar>
				</div>
			</app-skeleton>
		</ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="CalendarViewState.CONFIRM">
		<mat-form-field appearance="outline" class="area" (click)="viewState = CalendarViewState.SCHEDULE">
			<mat-label>Date & Time</mat-label>
			<input matInput [value]="bookingTimeJS | date: 'EEEE, MMMM d \'at\' h:mm a'" required />
			<span matSuffix>Edit</span>
		</mat-form-field>
		<button mat-raised-button color="primary" class="confirm" [disabled]="bookingInProgress" (click)="onAppointmentConfirm()">
			Confirm
		</button>
	</ng-container>
</ng-container>
