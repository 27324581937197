import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BreadcrumbModule } from '@/components/breadcrumb/breadcrumb.module';
import { CalendarModule } from '@/components/calendar/calendar.module';
import { CompareBarModule } from '@/components/compare-bar/compare-bar.module';
import { ConciergeFtuPopupModule } from '@/components/concierge-ftu-popup/concierge-ftu-popup.module';
import { ConciergeModule } from '@/components/concierge/concierge.module';
import { FormFieldModule } from '@/components/form-field/form-field.module';
import { HeartModule } from '@/components/heart/heart.module';
import { IvfFiltersModule } from '@/components/ivf-filters/ivf-filters.module';
import { LoaderModule } from '@/components/loader/loader.module';
import { LocationEntryModule } from '@/components/location-entry/location-entry.module';
import { MobileChatModule } from '@/components/mobile-chat/mobile-chat.module';
import { PasswordChecksModule } from '@/components/password-checks/password-checks.module';
import { RatingModule } from '@/components/rating/rating.module';
import { ScrollerModule } from '@/components/scroller/scroller.module';
import { InsuranceModule } from '@/components/select/insurance/insurance.module';
import { SuccessRatesChartModule } from '@/components/success-rates-chart/success-rates-chart.module';
import { VerificationCodeModule } from '@/components/verification-code/verification-code.module';
import { VideoModule } from '@/components/video/video.module';
import { MaterialModule } from '@/material.module';
import { IVF_ACCREDITATIONS, IVF_MEMBERSHIPS, IVF_SERVICES, NIvfFilters } from '@/models/ivf.model';
import { PipesModule } from '@/pipes/pipes.module';
import { IvfCardModule } from '@/screens/ivf/ivf-card/ivf-card.module';
import { NumericGroupModule } from '@/screens/ivf/numeric-group/numeric-group.module';
import { RequestInterceptor } from '@/services/auth.interceptor';
import { ContactAgencyService } from '@/services/contact-agency.service';
import { IvfJourneyComponent } from './ivf-journey/ivf-journey.component';
import { IvfProfileEditComponent } from './ivf-profile-edit.component';
import { IvfProfileComponent } from './ivf-profile/ivf-profile.component';
import { IvfProgramComponent } from './ivf-program/ivf-program.component';
import { IvfRoutingModule } from './ivf-routing.module';
import { IvfComponent } from './ivf.component';

@NgModule({
	declarations: [IvfComponent, IvfJourneyComponent, IvfProfileComponent, IvfProfileEditComponent, IvfProgramComponent],
	imports: [
		CommonModule,
		IvfRoutingModule,
		IvfCardModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		FormFieldModule,
		PasswordChecksModule,
		VerificationCodeModule,
		LoaderModule,
		InsuranceModule,
		IvfFiltersModule,
		CompareBarModule,
		ScrollerModule,
		NumericGroupModule,
		VideoModule,
		CalendarModule,
		LocationEntryModule,
		ConciergeModule,
		ConciergeFtuPopupModule,
		HeartModule,
		RatingModule,
		SuccessRatesChartModule,
		MobileChatModule,
		BreadcrumbModule,
	],
	exports: [IvfProfileComponent, IvfProfileEditComponent],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true,
		},
		ContactAgencyService,
		{
			provide: IVF_SERVICES,
			useValue: Object.freeze([
				{ value: NIvfFilters.IvfService.IntrauterineInsemination, label: 'Intrauterine Insemination', icon: '' },
				{ value: NIvfFilters.IvfService.EggDonation, label: 'Egg Donation', icon: 'assets/ivf/egg_donation.svg' },
				{ value: NIvfFilters.IvfService.SpermDonation, label: 'Sperm Donation', icon: '' },
				{ value: NIvfFilters.IvfService.EmbryoDonation, label: 'Embryo Donation', icon: '' },
				{ value: NIvfFilters.IvfService.Surrogacy, label: 'Surrogacy', icon: 'assets/ivf/surrogacy.svg' },
				{ value: NIvfFilters.IvfService.EggFreezing, label: 'Egg Freezing', icon: 'assets/ivf/egg_freezing.svg' },
				{ value: NIvfFilters.IvfService.EmbryoFreezing, label: 'Embryo Freezing', icon: '' },
				{ value: NIvfFilters.IvfService.Accupuncture, label: 'Accupuncture', icon: '' },
				{ value: NIvfFilters.IvfService.MultipleEmbryoTransfer, label: 'Multiple Embryo Transfer', icon: '' },
				{ value: NIvfFilters.IvfService.GeneticTesting, label: 'Genetic Testing', icon: '' },
				{ value: NIvfFilters.IvfService.EmbryoGenderSelection, label: 'Embryo Gender Selection', icon: '' },
				{ value: NIvfFilters.IvfService.FemaleFertilityTreatments, label: 'Female Fertility Treatments', icon: '' },
				{ value: NIvfFilters.IvfService.MaleFertilityTreatments, label: 'Male Fertility Treatments', icon: '' },
				{ value: NIvfFilters.IvfService.ReciprocalIvf, label: 'Reciprocal IVF', icon: 'assets/ivf/reciprocal_ivf.svg' },
				{ value: NIvfFilters.IvfService.MentalHealth, label: 'Mental Health Counseling', icon: '' },
				{ value: NIvfFilters.IvfService.HivPatients, label: 'HIV Patients', icon: '' },
				{
					value: NIvfFilters.IvfService.AllowingEDSGC,
					label: 'Allowing Egg Donor + Sperm Donor + Gestational Carrier Cycle',
					icon: '',
				},
				{ value: NIvfFilters.IvfService.HaveBipocPhysicians, label: 'We have BIPOC physicians', icon: '' },
			]),
		},
		{
			provide: IVF_ACCREDITATIONS,
			useValue: Object.freeze([
				{ value: 'College of American Pathologists', label: 'College of American Pathologists', icon: 'assets/ivf/cap.svg' },
				{ value: 'The Joint Commission', label: 'The Joint Commission', icon: 'assets/ivf/joint.png' },
			]),
		},
		{
			provide: IVF_MEMBERSHIPS,
			useValue: Object.freeze([
				{ value: 'sart', label: 'Society for Assisted Reproductive Technology', icon: 'assets/ivf/memberships/sart.png' },
				{ value: 'asrm', label: 'American Society for Reproductive Medicine', icon: 'assets/ivf/memberships/asrm.jpg' },
				{ value: 'cdc', label: 'Center for Disease Control and Prevention', icon: 'assets/ivf/memberships/cdc.jpg' },
			]),
		},
	],
})
export class IvfModule {}
