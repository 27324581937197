<ng-container [ngSwitch]="event$ | async">
	<ng-container *ngSwitchCase="'cancel'">
		<div class="logo">
			<img src="assets/logo-solo.png" />
			<div>GoStork</div>
		</div>

		<div class="event">
			Your appointment has been cancelled. <br />
			Thank you for confirming.
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'cancel-multi'">
		<div class="logo">
			<img src="assets/logo-solo.png" />
			<div>GoStork</div>
		</div>

		<div class="event">
			Your appointments have all been canceled. <br />
			Thank you for confirming.
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'confirm'">
		<div class="logo">
			<img src="assets/logo-solo.png" />
			<div>GoStork</div>
		</div>

		<div class="event">
			<b>Thank you!</b> <br />
			Your appointment has been confirmed.
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'reschedule'">
		<ng-container *ngIf="{c: contacts$ | async, l: locations$ | async} as o">
			<ng-container *ngIf="o.c && o.l">
				<app-calendar-list
					[contacts]="o.c"
					[locations]="o.l"
					[eggDonorId]="eggDonorId$ | async"
					[dealId]="dealId$ | async"
					(updateAppointment)="onUpdateAppointment($event)"
				></app-calendar-list>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
