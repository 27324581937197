export const aboutTitles = [
	{value: 'year_founded', label: 'Year Founded'},
	{value: 'number_of_babies_born', label: 'Number of Babies Born'},
	{placeholder: 'Months', value: 'matching_timeframe', label: 'Surrogate Matching Time (avg.)'},
	{placeholder: 'Months', value: 'length_of_journey', label: 'Length of Journey (avg.)'},
	{value: 'family_load', label: 'Number of Families per Coordinator'},
];

export const servicesTitles = [
	// carrier_matching: 'Carrier Screening',
	// carrier_screening: 'Carrier Matching',
	{value: 'case_management_and_education', label: 'Case<br>Management'},
	{value: 'counseling_and_support', label: 'Counseling<br>& Support'},
	{value: 'medical_billing_management', label: 'Billing Management'},
	{value: 'escrow_management', label: 'Escrow Management'},
	{value: 'in_house_attorney', label: 'In-House<br>Attorney'},
	{value: 'establishing_parental_rights', label: 'Establishing Parental Rights'},
	{value: 'fertility_loans', label: 'Fertility Loans'},
];

export const ascreeningOrder = [
	'criminal_background_check',
	'social_worker_pre_screened_carriers',
	'psychological_pre_screened_carriers',
	'home_visits_pre_screening',
	'medical_records_review',
	'medically_pre_screened_carriers',
	'financial_review',
	'insurance_review',
];

export const aboutTitlesObj = {};
for (const item of aboutTitles) aboutTitlesObj[item.value] = item.label;

export const profileReadyState = [
	{label: 'Logo', value: 'img'},
	{label: 'Cover Video', value: 'video'},
	{label: 'About', value: 'about'},
	{label: 'What Makes Us Unique', value: 'what_makes_us_unique'},
	{label: 'Surrogacy Services', value: 'available_surrogacy_services'},
	{label: 'Your Team', value: 'team'},
	{label: 'Calendar', value: 'calendar'},
	{label: 'Costs', value: 'costs'},
];

export const memberships = [
	'Society for Assisted Reproductive Technology',
	'American Society for Reproductive Medicine',
	'Centers for Disease Control and Prevention',
];

export enum ProviderType {
	EGG_AGENCY = 'egg_agency',
	SURROGATES_AGENCY = 'surrogates',
	SURROGACY_AGENCY = 'surrogacy',
	ADOPTION_AGENCY = 'adoption_agency',
	IVF_CLINIC = 'ivf_clinic',
	LAW_FIRM = 'law_firm',
	EGG_BANK = 'egg_bank',
	SPERM_BANK = 'sperm_agency',
}

export const providerNames: Record<ProviderType, string> = {
	[ProviderType.EGG_AGENCY]: 'Egg Donors Agency',
	[ProviderType.SURROGACY_AGENCY]: 'Surrogacy Agency',
	[ProviderType.ADOPTION_AGENCY]: '',
	[ProviderType.IVF_CLINIC]: '',
	[ProviderType.LAW_FIRM]: '',
	[ProviderType.EGG_BANK]: '',
	[ProviderType.SPERM_BANK]: '',
	[ProviderType.SURROGATES_AGENCY]: ''
};

export const surrogacyServicesSelectors = {
	internal: [
		{s: 'agency_compensation', n: 'Agency Compensation', withComment: true, inline: true},
		{
			s: 'carrier',
			n: 'Carrier Fees',
			sectionComment:
				'Your carrier’s compensation depends on the carrier’s location and experience. Select whether you prefer a carrier from a non-preferred or preferred state and whether you prefer a first time or experienced carrier to see how it will affect your cost.',
		},
		{s: 'screening', n: 'Screening Costs'},
		{
			s: 'legal',
			n: 'Legal Fees',
			c: 'Intended Parents are expected to hire an attorney to negotiate and draft the contract between the egg donor and themselves. Many agencies might offer in-house attorneys or may refer to outside attorneys whom they work with. Further, intended parents are also expected to pay for the attorney representing the egg donor.',
		},
		{s: 'clinical_workers_fees', n: 'Psychological Support for Carrier', inline: true, withComment: true},
		{
			s: 'insurance',
			n: 'Insurance',
			sectionComment:
				'The carrier’s insurance cost depends on whether the carrier has her own surrogacy-friendly insurance or whether you’ll have to purchase health insurance for her. If you choose an insured carrier, you will be responsible for reimbursing her for deductibles, co-pays and co-insurance. However, selecting this option may delay the matching process with the carrier.',
		},
		{s: 'miscellaneous', n: 'Miscellaneous'},
		{
			s: 'contingency',
			n: "Carrier's Potential Contingency Costs",
			sectionComment: 'The following costs are collected by the agency in the event any of the below occurs.',
		},
		{s: 'policies', n: 'Policies', noTotal: true},
		{s: 'discount', n: 'Promotions & Discounts', inline: true},
	],
	carrier: [
		{s: 'carrier_compensation', n: 'Carrier’s Compensation', withComment: true},
		{
			s: 'travel_expenses_medical_screening',
			n: 'Travel Expenses for Medical Screening for Carrier',
			c: 'Includes child care, hotel, rental car, lost wages',
			withComment: true,
			disabled: true,
		},
		{
			s: 'travel_expenses_embryo_transfer',
			n: 'Travel expenses for embryo transfer for Carrier',
			c: 'Includes child care, hotel, rental car, lost wages',
			withComment: true,
			disabled: true,
		},
		{s: 'transfer_payment', n: 'Embryo transfer payment', c: 'Per transfer', withComment: true},
		{s: 'add_monthly_expenses', n: 'Monthly Expense Allowance', withComment: true},
		{s: 'maternity_clothing', n: 'Maternity clothing allowance', withComment: true},
		{s: 'housekeeping', n: 'Housekeeping Allowance', withComment: true},
		{s: 'local_monitoring', n: 'Local Monitoring for Carrier', withComment: true, disabled: true},
		{s: 'lost_wages', n: 'Lost Wages', withComment: true, disabled: true},
	],
	screening: [
		{s: 'psychological_screening', n: 'Psychological Screening of Carrier and Spouse', withComment: true},
		{s: 'criminal_history', n: 'Criminal history inquiry for Carrier and Spouse', withComment: true},
	],
	legal: [
		{s: 'parent_attorney_fees', n: "Intended Parent Attorney for Drafting Carrier's Agreement", withComment: true},
		{s: 'carriers_attorney_legal_fees', n: "Carrier's Attorney", withComment: true},
		{s: 'establish_parentage_attorney_fee', n: 'Establish Parentage Attorney', withComment: true},
	],
	insurance: [
		{
			s: 'carrier_health',
			n: "Carrier's Health Insurance",
		},
		{s: 'insurance_verification', n: "Insurance Verification of Carrier's Private Insurance Policy", withComment: true},
		{s: 'carrier_life', n: "Carrier's Life Insurance Policy", withComment: true},
	],
	miscellaneous: [
		{s: 'escrow_account_service_fee', n: 'Escrow Account Service', withComment: true},
		{s: 'international_intendeed_parents_fee', n: 'International Intended Parents Fee', withComment: true},
	],
	policies: [
		{s: 'rematch_policy', n: 'Rematch Policy with a Carrier', withComment: true},
		{s: 'journey_policy', n: 'Sibling Journey Policy', withComment: true},
		{
			s: 'refund_policy',
			n: 'Refund Policy',
			c: 'There are several scenarios in which intended parents may want to request a refund. Such a request depends on the egg donor’s agency’s policy. In some instances, agencies may offer a full refund, a partial refund, no refund whatsoever, and some others may have a rematch policy instead.',
			withComment: true,
		},
		{
			s: 'unused_funds_returned_policy',
			n: 'Return Unused Funds At The End of The Journey',
			c: 'This policy sets the time period after which the agency will return  any unused funds to the intended parents. This time period varies by agency.',
			withComment: true,
		},
		{s: 'financial_options_offered_by_agency', n: 'Financial Options Offered by Agency'},
	],
	contingency: [
		{s: 'cesarean_section', n: 'Cesarean Section', withComment: true, disabled: true},
		{
			s: 'invasive_procedures',
			n: 'Invasive Procedures (Amniocentesis, Chorionic Villus Sampling, Cerclage)',
			withComment: true,
			disabled: true,
		},
		{s: 'pregnency_termination', n: 'Pregnancy Termination or Selective Reduction', withComment: true, disabled: true},
		{s: 'lost_of_repr', n: 'Lost of Reproductive Organs (Hysterectomy and Loss of Tubes/Ovaries)', withComment: true, disabled: true},
		{s: 'multiples_fetus', n: 'Multiples Fetus Fees (if carrier carrying twins or triplets)', withComment: true, disabled: true},
	],
	promotions: [{s: 'discount', n: 'Discount', withComment: true}],
};
export const surrogacyServicesSelectorsEdit = {
	...surrogacyServicesSelectors,
	carrier: [
		{s: 'carrier_compensation_prefered_first', n: 'Carrier’s Compensation from California (First Time Carrier)', withComment: true},
		{s: 'carrier_compensation_prefered_exp', n: 'Carrier’s Compensation from California (Experienced Carrier)', withComment: true},
		{s: 'carrier_compensation_nonprefered_first', n: 'Carrier’s Compensation from other states (First Time Carrier)', withComment: true},
		{s: 'carrier_compensation_nonprefered_exp', n: 'Carrier’s Compensation from other states (Experienced Carrier)', withComment: true},
		...surrogacyServicesSelectors.carrier.slice(1),
	],
	insurance: [
		{
			s: 'insured_carrier',
			n: "Carrier's Co-Pay, Deductible and Co-Insurance (only paid if carrier has surrogacy friendly health insurance)",
			withComment: true,
			disabled: true,
		},
		{
			s: 'aca_insurance',
			n: "Carrier's Health Insurance (through ACA) + Premiums + Medical Bills",
			withComment: true,
			disabled: true,
		},
		{
			s: 'art_insurance',
			n: "Carrier's Health Insurance (through ART RisK)",
			withComment: true,
			disabled: true,
		},
		{
			s: 'ifi_insurance',
			n: "Carrier's Health Insurance (International Fertility Insurance)",
			withComment: true,
			disabled: true,
		},
		...surrogacyServicesSelectors.insurance.slice(1),
	],
};

export const eggAgencyServicesSelectors = {
	frozen_eggs: [
		{s: 'egg_lot_cost', n: 'Egg Lot Cost', inline: true },
		{s: 'eggs_in_lot', n: '# of Eggs in lot', inline: true }
	],
	internal: [
		{s: 'agency_compensation', n: 'Agency Compensation', withComment: true, inline: true},
		{s: 'donor', n: 'Egg Donor Fees'},
		{
			s: 'screening',
			n: 'Egg Donor Screening',
			c: 'Egg donors undergo various screenings before starting the process. Such screenings may include psychological and genetic consults.',
		},
		{
			s: 'legal',
			n: 'Legal Fees',
			c: 'Intended Parents are expected to hire an attorney to negotiate and draft the contract between the egg donor and themselves. Many agencies might offer in-house attorneys or may refer to outside attorneys whom they work with. Further, intended parents are also expected to pay for the attorney representing the egg donor.',
		},
		{s: 'miscellaneous', n: 'Miscellaneous'},
		{s: 'policies', n: 'Policies', noTotal: true},
		{s: 'discount', n: 'Promotions & Discounts', inline: true},
	],
	donor: [
		{s: 'egg_donor_compensation', n: 'Egg Donor Compensation', withComment: true},
		{
			s: 'travel_expenses',
			n: 'Travel Expenses for Medical Screening & Egg Retrieval',
			c: 'As an intended parent, you are responsible for the egg donor’s travel expenses when the egg donor is traveling to the Fertility clinic for her medical screening, and for the egg retrieval procedure. Travel expenses may include airfare, car transportation, hotel accommodation and per diem allowance. Prices vary depending on the Fertility clinic and the egg donor’s location.',
			withComment: true,
			disabled: true,
		},
		{
			s: 'compensation_in_case_of_cycle_cancelation',
			n: 'Compensation in Case of Cycle Cancellation',
			// eslint-disable-next-line max-len
			c: 'In the case of cycle cancellation due to unforeseen circumstances, the egg donor is expected to be compensated for that time period.',
			withComment: true,
		},
		{
			s: 'local_medical_monitoring',
			n: 'Local Medical Monitoring',
			c: 'After the egg donor undergoes her medical screening and she is cleared, and before the egg retrieval, the egg donor will be under local medical monitoring where she is located. As an intended parent, you are responsible for these costs.',
			withComment: true,
			disabled: true,
		},
	],
	screening: [
		{s: 'psychological_screening', n: 'Psychological Screening', withComment: true},
		{s: 'genetic_consult', n: 'Genetic Consult', withComment: true},
	],
	legal: [
		{
			s: 'parent_attorney_legal_fees',
			n: "Intended parents Attorney legal fees for drafting & negotiating of the egg donor's agreement",
			withComment: true,
		},
		{s: 'egg_donor_attorney_legal_fees', n: "Egg Donor's Attorney Fees", withComment: true},
	],
	miscellaneous: [
		{
			s: 'complication_insurance',
			n: 'Complications Insurance',
			c: 'Intended parents are required to purchase complications insurance. The egg donor agency might connect you with insurance providers or purchase the policy on your behalf.',
			withComment: true,
		},
		{
			s: 'escrow_account_service_fee',
			n: 'Escrow Account Service Fee',
			c: 'A surrogacy escrow account is an arrangement set up to hold and disperse funds during the surrogacy process.',
			withComment: true,
		},
		{s: 'contingencies_fees', n: 'Contingencies Fees', withComment: true},
	],
	policies: [
		{
			s: 'refund_policy',
			n: 'Agency Fee Refund Policy',
			c: 'There are several scenarios in which intended parents may want to request a refund. Such a request depends on the egg donor’s agency’s policy. In some instances, agencies may offer a full refund, a partial refund, no refund whatsoever, and some others may have a rematch policy instead.',
			withComment: true,
		},
		{
			s: 'unused_funds_returned_policy',
			n: 'Unused Funds Returned Policy',
			c: 'This policy sets the time period after which the agency will return  any unused funds to the intended parents. This time period varies by agency.',
			withComment: true,
		},
		{
			s: 'financial_options_offered_by_agency',
			n: 'Financial Options Offered by Agency',
			c: 'Egg donor agencies may offer financial assistance to intended parents through third parties.',
		},
	],
	promotions: [{s: 'discount', n: 'Discount', withComment: true}],
};

export const ivfServicesSelectors = {
	internal: [
		{s: 'autologous___ivf_one', n: 'Autologous + IVF', inline: true, minmax: true},
		{s: 'donor_egg___ivf_one', n: 'Donor Egg + IVF', inline: true, minmax: true},
		{s: 'autologous___ivf___gc_one', n: 'Autologous + IVF + GC', inline: true, minmax: true},
		{s: 'donor_egg___ivf___gc_one', n: 'Donor Egg + IVF + GC', inline: true, minmax: true},
		{s: 'recipocal_ivf_one', n: 'Reciprocal IVF', inline: true, minmax: true},
		{s: 'egg_freezing_one', n: 'Egg Freezing', inline: true, minmax: true},
	],
};

export const ivfCostServices = [
	{label: 'Ultrasound', sublabel: null},
	{label: 'Hormone Panel Testing', sublabel: null},
	{label: 'Medications', sublabel: null},
	{label: 'Local Monitoring', sublabel: null},
	{label: 'Operating Room Fee', sublabel: null},
	{label: 'Anesthesia', sublabel: null},
	{label: 'Egg Retrieval', sublabel: null},
	{label: 'FET', sublabel: '(Frozen Embryo Transfer) / (Fresh Embryo Transfer)'},
	{label: 'Consultation Call', sublabel: null},
	{label: 'ICSI - Selecting best sperm', sublabel: null},
	{label: 'Sperm Cryopreservation & Storage Fees', sublabel: null},
	{label: 'Assisted Hatching', sublabel: null},
	{label: 'Embryo Cryopreservation Fees', sublabel: null},
	{label: 'Genetic Testing Biopsy Fees', sublabel: null},
	{label: 'In House Genetic Testing (PGT-A)', sublabel: null},
	{label: 'Outsource Genetic Testing (PGT-D)', sublabel: null},
	{label: 'Gender Selection', sublabel: null},
	{label: 'Frozen Oocyte Thaw (FOT) Fees', sublabel: null},
	{label: 'Medical Management Fees', sublabel: null},
	{label: 'Cryopreservation', sublabel: null},
];
