import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
	selector: 'app-heart',
	templateUrl: './heart.component.html',
	styleUrls: ['./heart.component.scss']
})
export class HeartComponent {
	@HostBinding('attr.active')
	@Input()
	value = false;
	@Output()
	valueChange = new EventEmitter<boolean>();

	@HostListener('click')
	onClick() {
		this.valueChange.emit(!this.value);
	}

	constructor() { }
}
