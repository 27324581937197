import {UtilsService} from "@/services/utils.service";

const DEFAULT_MEETING_DURATION = 60;

export const parseConfigs = (utilsService: UtilsService, configs: {duration_of_meetings: number[]}[]) => {
    const durations = [];
    for (const config of configs) {
        durations.push(
            config.duration_of_meetings
                .map((item) => ({
                    label: utilsService.durationToString(item),
                    value: item,
                }))
                .sort((a, b) => b.value - a.value),
        );
    }

    return {
        durations,
        defaultDuration: durations.length ? durations[0][0].value : DEFAULT_MEETING_DURATION
    }
}
