<div class="menu">
    <button mat-button *ngFor="let step of steps" class="menu__step" [class.active]="currentStep === step.value" (click)="goToCheckout(step)">
		<div class="wrapper">
			<label>{{ step.label }}</label>
			<mat-icon>arrow_forward_ios</mat-icon>
		</div>
	</button>
</div>

<ng-template #accountRef>
    <form class="card" (ngSubmit)="onSubmit()">
        <div class="col name">
            <app-form-field type="text" label="First Name" [(value)]="fields.first_name" [validation]="validators.first_name" [errors]="errors.first_name" autocomplete="given-name"></app-form-field>
            <app-form-field type="text" label="Last Name" [(value)]="fields.last_name" [validation]="validators.last_name" [errors]="errors.last_name" autocomplete="family-name"></app-form-field>
        </div>
        <div class="col">
            <app-form-field class="stretch" type="date" label="Birth Date" [(value)]="fields.birthday" [validation]="validators.birthday" [errors]="errors.birthday"></app-form-field>
        </div>
        <div class="col">
            <app-form-field class="stretch" type="text" id="email" label="Email" [(value)]="fields.email" [validation]="validators.email" [errors]="errors.email" autocomplete="email"></app-form-field>
        </div>
        <div class="col">
            <app-form-field class="stretch" type="phone" label="Phone Number" [(value)]="fields.phone" [validation]="validators.phone" [errors]="errors.phone"></app-form-field>
        </div>
        <div class="col">
            <app-form-field class="stretch" type="select" label="Sexual Orientation" [(value)]="fields.identify" [height]="selectOptions.identify.length * 48" [options]="selectOptions.identify"></app-form-field>
        </div>

        <div class="col">
            <app-form-field class="stretch" type="select" label="Relationship Status" [(value)]="fields.relation" [height]="selectOptions.relation.length * 48" [options]="selectOptions.relation"></app-form-field>
        </div>

        <div class="row">
            <app-form-field type="address" label="Address" [(value)]="locationString" (details)="onAddressDetails($event)"></app-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Fertility Clinic</mat-label>
                <input matInput name="ivf_clinic" autocomplete="off" [(ngModel)]="fields.ivf_clinic" (ngModelChange)="ivfClinicValue$.next($event)" [matAutocomplete]="autocomplete" />
                <mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete">
                    <mat-option *ngFor="let option of clinics$ | async" [value]="option.item">
                        {{ option.item }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <ng-container *ngIf="showParentProfile">
            <span class="header">Partner’s Information</span>
            <div class="col">
                <app-form-field type="text" label="Partner’s First Name" [(value)]="fields.partner_first_name" [validation]="validators.first_name" [errors]="errors.first_name">
                </app-form-field>
                <app-form-field type="text" label="Partner’s Last Name" [(value)]="fields.partner_last_name" [validation]="validators.last_name" [errors]="errors.last_name">
                </app-form-field>
            </div>
            <app-form-field type="number" label="Partner’s Age" [(value)]="fields.partner_age" [errors]="errors.partner_age"> </app-form-field>
        </ng-container>

        <ng-container *ngIf="annexe?.ivf_clinic">
            <span class="header">IVF Information</span>
            <app-form-field class="location" type="address" label="Location" [autofill]="true" [value]="fullAddress" (coordinates)="onMapCoordinates($event)" (details)="onMapDetails($event)"></app-form-field>
        </ng-container>

        <div class="submit">
            <button mat-raised-button type="submit" color="primary" [disabled]="loading || !this.fieldsAreValid">
				{{ loading ? '&#8203;' : 'Submit' }}
			</button>
            <mat-spinner [ngStyle]="{display: loading ? 'block' : 'none'}" class="white" diameter="24"></mat-spinner>
        </div>
    </form>
</ng-template>

<ng-template #passwordRef>
    <form class="card" (ngSubmit)="onSubmit()">
        <div class="col">
            <app-form-field class="stretch" type="password" label="Password" [(value)]="fields.password" [validation]="validators.password" [errors]="errors.password" autocomplete="new-password"></app-form-field>
        </div>
        <div class="col">
            <app-form-field class="stretch" type="password" label="Confirm Password" [(value)]="fields.confirm_password" [validation]="validators.confirm_password" [errors]="errors.confirm_password" autocomplete="new-password"></app-form-field>
        </div>
        <div class="submit">
            <button mat-raised-button type="submit" color="primary" [disabled]="loading || !this.fieldsAreValid">
				{{ loading ? '&#8203;' : 'Submit' }}
			</button>
            <mat-spinner [ngStyle]="{display: loading ? 'block' : 'none'}" class="white" diameter="24"></mat-spinner>
        </div>
    </form>
</ng-template>



<ng-template #subscriptionRef>
    <form class="card" (ngSubmit)="(null)">
        <app-checkout></app-checkout>
    </form>
</ng-template>


<ng-template #calendarRef>
    <form class="card" (ngSubmit)="saveCalendarConfig()">
        <ng-container *ngIf="calendarConfig?.calendar_id; else calenadrConnect">
            <div class="calendarSettings">
                <div class="calendar__label">Calendar Links</div>
                <div class="calendar__links">
                    <div class="calendar__link">
                        <b>All Calendars: </b>
                        <a [href]="calendarLinks.group">{{ calendarLinks.group }}</a>
                        <button mat-icon-button type="button" (click)="copyToClipboard(calendarLinks.group)">
							<mat-icon> content_copy </mat-icon>
						</button>
                    </div>
                    <div class="calendar__link">
                        <b>Only My Calendar: </b>
                        <a [href]="calendarLinks.self">{{ calendarLinks.self }}</a>
                        <button mat-icon-button type="button" (click)="copyToClipboard(calendarLinks.self)">
							<mat-icon> content_copy </mat-icon>
						</button>
                    </div>
                </div>

                <div class="calendar__label">Time Zone</div>
                <app-form-field type="select" [withSearch]="true" [(value)]="calendarConfig.time_zone" [options]="tzones"></app-form-field>

                <div class="calendar__label">Availability</div>
                <div *ngFor="let row of calendarConfig.availability; let i = index" class="calendar__availability">
                    <button *ngIf="i !== 0" mat-icon-button type="button" class="remove-form-group" (click)="removeAvailableRow(i)">✕</button>

                    <app-form-field type="select" label="Days" [multiple]="true" [options]="availableDays" [(value)]="row.days"></app-form-field>

                    <app-form-field type="select" label="From" [options]="availableTimeValues" [(value)]="row.start"></app-form-field>

                    <app-form-field type="select" label="To" [options]="availableTimeValues" [(value)]="row.end"></app-form-field>
                </div>
                <button mat-stroked-button type="button" color="primary" class="add-form-group" (click)="addAvailableRow()">+ Add</button>

                <p class="calendar__label">Duration Of Meetings</p>
                <div *ngFor="let row of calendarConfig.duration_of_meetings; let i = index" class="calendar__duration">
                    <button *ngIf="i !== 0" mat-icon-button type="button" class="remove-form-group" (click)="removeDurationRow(i)">✕</button>
                    <mat-form-field class="editing__number" appearance="outline">
                        <mat-label>Minutes</mat-label>
                        <input matInput type="number" inputmode="numeric" name="duration_of_meetings{{ i }}" [(ngModel)]="calendarConfig.duration_of_meetings[i]" />
                    </mat-form-field>
                </div>
                <button mat-stroked-button type="button" color="primary" class="add-form-group" (click)="addDurationRow()">+ Add</button>

                <p class="calendar__label">Minimum amount of time before a meeting can be booked</p>
                <div class="calendar__before-meeting">
                    <app-form-field type="select" [options]="amountBeforeMeeting" [(value)]="calendarConfig.amount_before_meeting"></app-form-field>
                </div>

                <p class="calendar__label">Configuration</p>
                <div class="calendar__configuration">
                    <app-form-field type="text" label="Subject Of Meeting Invitation" [(value)]="calendarConfig.subject_of_meeting_invitation"></app-form-field>
                    <app-form-field type="text" label="Location Of Meeting" [(value)]="calendarConfig.location_of_meeting"></app-form-field>
                    <app-form-field type="area" label="Meeting Invitation Description" [(value)]="calendarConfig.meeting_invitation_description" rows="5"></app-form-field>
                </div>
            </div>

            <div class="submit" [class.loading]="loading">
                <button mat-flat-button type="button" color="warn" [disabled]="loading" (click)="detachCalednar()">Disconnect Calendar</button>
                <button mat-flat-button type="submit" color="primary" [disabled]="loading">
					{{ loading ? '&#8203;' : 'Submit' }}
				</button>
                <mat-spinner class="white" diameter="24"></mat-spinner>
            </div>
        </ng-container>

        <ng-template #calenadrConnect>
            <button mat-flat-button type="button" color="primary" (click)="openCalendarUrl('google')">Google Calendar</button>
            <button mat-flat-button type="button" color="primary" (click)="openCalendarUrl('outlook')">Outlook</button>
        </ng-template>
    </form>
</ng-template>

<ng-template #usersRef>
    <form class="card" (ngSubmit)="(null)">
        <app-admin></app-admin>
    </form>
</ng-template>

<ng-container *ngTemplateOutlet="currentStep"></ng-container>

<div class="spec-bg">
    <img src="assets/profile_background.svg" alt="" />
</div>