import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BreadcrumbModule } from '@/components/breadcrumb/breadcrumb.module';
import { CalendarModule } from '../../components/calendar/calendar.module';

import { FormFieldModule } from '../../components/form-field/form-field.module';
import { InsvgModule } from '../../components/insvg/insvg.module';
import { RatingModule } from '../../components/rating/rating.module';

import { MaterialModule } from '../../material.module';
import { PipesModule } from '../../pipes/pipes.module';

import { ChatComponent } from './chat.component';

@NgModule({
	declarations: [ChatComponent],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		CalendarModule,
		RatingModule,
		InsvgModule,
		PipesModule,
		FormFieldModule,
		BreadcrumbModule,
	],
})
export class ChatModule {}
