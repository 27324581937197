import {
	AfterViewInit,
	Component,
	OnDestroy,
	Inject,
	OnInit,
	TemplateRef,
	ViewChild,
	Optional,
	Input
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {AnalyticsService} from '@/services/analytics.service';
import {AuthStoreService} from '@/services/auth.service';
import {CacheService} from '@/services/cache.service';
import {UtilsService} from '@/services/utils.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {loadStripe, Stripe, StripeElements, StripePaymentElementOptions} from '@stripe/stripe-js';
import {CheckoutService} from '@/services/checkout.service';
import {nanoid} from '@/helpers/nanoid';
import {DatePipe} from '@angular/common';
import {CheckoutSources} from "@/consts/config";

const stripe = loadStripe(environment.stripePk);
const datepipe: DatePipe = new DatePipe('en-US');

interface CheckoutDialogData {
	success: Boolean;
	name: string;
	source?: CheckoutSources;
}

const SourceToText = {
	[CheckoutSources.EggDonor]: 'Egg Donor Search',
	[CheckoutSources.TopHeader]: 'Top Header',
	[CheckoutSources.Subscription]: 'Subscription'
}
@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
	@ViewChild('subscriptionRef', {static: true})
	subscriptionRef: TemplateRef<void>;

	@ViewChild('successRef', {static: true})
	successRef: TemplateRef<void>;
	@ViewChild('errorRef', {static: true})
	errorRef: TemplateRef<void>;
	@ViewChild('cancelRef', {static: true})
	cancelRef: TemplateRef<void>;
	@ViewChild('successCancelRef', {static: true})
	successCancelRef: TemplateRef<void>;

	stripe_customer_id: string;
	stripe_subscription_id: string;
	stripe_subscription_status: string;
	stripe_cancel_at_period_end: Boolean;
	stripe_canceled_at: Date;
	stripe_current_period_end: Date;
	stripe_current_period_end_fomatted: string;
	stripe_subscription_can_subscribe: Boolean = false;

	loading = false;

	currentStep: TemplateRef<void>;
	stripeElements: StripeElements;
	items = [{id: environment.stripePriceId}];
	stripeResult: Stripe;
	clientSecret;
	success_redirect_uri;

	steps = [];

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public data: CheckoutDialogData,
		private store: AuthStoreService,
		private snackBar: MatSnackBar,
		private analytics: AnalyticsService,
		private cache: CacheService,
		private utils: UtilsService,
		private http: HttpClient,
		public router: Router,
		private checkoutService: CheckoutService,
	) {
		this.analytics.setDimension('Checkout', 'checkout');
	}

	clickNotNow(navigate: boolean) {
		this.trackEvent('CTA - Not Now');
		if (navigate) {
			this.router.navigateByUrl('/account');
		}
	}

	ngOnDestroy() {}

	ngOnInit() {
		this.http.get('/api/redirect').subscribe((success_redirect_uri) => {
			this.success_redirect_uri = success_redirect_uri['success_redirect_uri'];
		});

		this.store.getUser().subscribe((user) => {
			this.stripe_customer_id = user.stripe_customer_id;
			this.stripe_subscription_id = user.stripe_subscription_id;
			this.stripe_cancel_at_period_end = user.stripe_cancel_at_period_end;
			this.stripe_canceled_at = user.stripe_canceled_at;
			this.stripe_current_period_end = user.stripe_current_period_end;
			this.stripe_subscription_status = user.stripe_subscription_status;
			this.stripe_current_period_end_fomatted = datepipe.transform(this.stripe_current_period_end, 'MM/dd/YY');
			this.stripe_subscription_can_subscribe = user.stripe_subscription_can_subscribe;

			if (this.stripe_subscription_can_subscribe && this.stripe_subscription_status !== 'active') {
				try {
					this.initialize();
				} catch (e) {}
				this.currentStep = this.subscriptionRef;
			} else if (this.stripe_subscription_status === 'active') {
				this.currentStep = this.cancelRef;
			}
		});

		this.steps = [{label: 'Premium membership', value: this.subscriptionRef}];
	}

	onInterested() {
		this.currentStep = this.successRef;
	}

	close() {
		this.router.navigate(['/egg-donor']).then(() => {
			window.location.reload();
		});
	}

	showSucessRef() {
		this.currentStep = this.successRef;
	}

	trackEvent(label: string) {
		try {
			const eventAction = SourceToText[this.data?.source || CheckoutSources.Subscription];
			this.analytics.emit('User Area', eventAction, label);
		} catch (ex) {
			// incase of failure to track event, we don't want to fail other flows
		}
	}

	initialize() {
		const priceId = environment.stripePriceId;
		this.clientSecret = null;
		this.http
			.post('/api/payment', {priceId})
			.pipe(
				switchMap((subscription) => {
					this.clientSecret = subscription['latest_invoice']['payment_intent']['client_secret'];
					return subscription['latest_invoice']['payment_intent']['client_secret'];
				}),
			)
			.subscribe((result) => {
				// If `redirectToCheckout` fails due to a browser or network
				// error, you should display the localized error message to your
				// customer using `error.message`.
				//clientSecret = result

				const options = {
					clientSecret: this.clientSecret,
				};

				const paymentElementOptions: StripePaymentElementOptions = {
					wallets: {applePay: 'never', googlePay: 'never'},
				};

				stripe.then((stripeResultSubscribe: Stripe) => {
					if (this.stripeElements === undefined || this.stripeElements === null) {
						this.stripeResult = stripeResultSubscribe;
						this.stripeElements = this.stripeResult.elements(options);
						const paymentElement = this.stripeElements.create('payment', paymentElementOptions);
						try {
							paymentElement.mount('#payment-element');
							const paymentRequest = this.stripeResult.paymentRequest({
								country: 'US',
								currency: 'usd',
								total: {
									label: 'GoStork Premium Membership',
									amount: environment.stripeAmount,
								},
								requestPayerName: true,
								requestPayerEmail: true,
							});

							const prButton = this.stripeElements.create('paymentRequestButton', {paymentRequest: paymentRequest});

							// Check the availability of the Payment Request API first.
							paymentRequest.canMakePayment().then(function (result) {
								const paymentElement = document.querySelector('#payment-request-button');
								if (!paymentElement) {
									console.warn('No payment element - aborting');
									return;
								}
								if (result) {
									prButton.mount('#payment-request-button');
								} else {
									document.getElementById('payment-request-button').style.display = 'none';
									document.getElementById('payment-message').style.display = 'none';
								}
							});

							// is this even used??
							let newThis = this;
							paymentRequest.on('paymentmethod', async (ev) => {
								// Confirm the PaymentIntent without handling potential next actions (yet).
								await stripeResultSubscribe
									.confirmCardPayment(this.clientSecret, {payment_method: ev.paymentMethod.id, setup_future_usage: "off_session" }, {handleActions: false})
									.then(function (confirmResult) {
										if (confirmResult.error) {
											// Report to the browser that the payment failed, prompting it to
											// re-show the payment interface, or show an error message and close
											// the payment interface.
											ev.complete('fail');
											newThis.currentStep = newThis.errorRef;
										} else {
											// Report to the browser that the confirmation was successful, prompting
											// it to close the browser payment method collection interface.
											ev.complete('success');
											// Check if the PaymentIntent requires any actions and if so let Stripe.js
											// handle the flow. If using an API version older than "2019-02-11"
											// instead check for: `paymentIntent.status === "requires_source_action"`.
											if (confirmResult.paymentIntent.status === 'requires_action') {
												// Let Stripe.js handle the rest of the payment flow.
												this.stripeResultSubscribe.confirmCardPayment(this.clientSecret, { payment_method: ev.paymentMethod.id, setup_future_usage: "off_session" }).then(function (result) {
													if (result.error) {
														const messageContainer = document.querySelector('#error-message');
														messageContainer.textContent = result.error.message;
													} else {
														//ok
													}
												});
											} else {
												//ok
												newThis.currentStep = newThis.successRef;
											}
										}
									});
							});

							document.querySelector('#payment-form').addEventListener('submit', async (event) => {
								event.preventDefault();
								this.trackEvent('CTA - Subscribe');

								const btn = document.querySelector('#submit') as HTMLButtonElement;
								btn.disabled = true;

								const {paymentIntent, error} = await stripeResultSubscribe.confirmPayment({
									//`Elements` instance that was used to create the Payment Element
									elements: this.stripeElements,
									confirmParams: {
										return_url: this.success_redirect_uri,
										save_payment_method: true
									},
									redirect: 'if_required',
								});

								if (paymentIntent && paymentIntent.status === 'succeeded') {
									this.store.getUser().subscribe((user) => {
										this.stripe_customer_id = user.stripe_customer_id;
										this.stripe_subscription_id = user.stripe_subscription_id;
										this.stripe_cancel_at_period_end = user.stripe_cancel_at_period_end;
										this.stripe_canceled_at = user.stripe_canceled_at;
										this.stripe_current_period_end = user.stripe_current_period_end;
										this.stripe_subscription_status = user.stripe_subscription_status;
										if (this.stripe_subscription_status !== 'active') {
											try {
												this.initialize();
											} catch (e) {}
										}
									});

									this.analytics.push({
										token: nanoid(12),
										category: 'Subscription',
										action: 'Subscription',
										label: 'Subscription',
									});
									this.currentStep = this.successRef;
								}

								if (error) {
									// This point will only be reached if there is an immediate error when
									// confirming the payment. Show error to your customer (for example, payment
									// details incomplete)
									const messageContainer = document.querySelector('#error-message');
									messageContainer.textContent = error.message;
									btn.disabled = false;
								} else {
									// Your customer will be redirected to your `return_url`. For some payment
									// methods like iDEAL, your customer will be redirected to an intermediate
									// site first to authorize the payment, then redirected to the `return_url`.
								}
								//this.checkStatus()
								//btn.disabled = false
							});
						} catch (e) {}
					}
				});
			});
	}

	cancelSubscription() {
		this.http.post('/api/cancel-subscription', {}).subscribe((subscription) => {
			//console.log("si")
			//this.router.navigate(['/account']);
			this.currentStep = this.successCancelRef;
			// If `redirectToCheckout` fails due to a browser or network
			// error, you should display the localized error message to your
			// customer using `error.message`.
		});
	}

	// Fetches the payment intent status after payment submission
	async checkStatus() {
		if (!this.clientSecret) {
			return;
		}

		const {paymentIntent} = await this.stripeResult.retrievePaymentIntent(this.clientSecret);

		switch (paymentIntent.status) {
			case 'succeeded':
				this.showMessage('Payment succeeded!');
				break;
			case 'processing':
				this.showMessage('Your payment is processing.');
				break;
			case 'requires_payment_method':
				this.showMessage('Your payment was not successful, please try again.');
				break;
			default:
				this.showMessage('Something went wrong.');
				break;
		}
	}

	showMessage(messageText) {
		const messageContainer = document.querySelector('#payment-message');

		messageContainer.classList.remove('hidden');
		messageContainer.textContent = messageText;

		setTimeout(function () {
			messageContainer.classList.add('hidden');
			messageText.textContent = '';
		}, 4000);
	}

	// Show a spinner on payment submission
	async setLoading(isLoading) {
		if (isLoading) {
			// Disable the button and show a spinner
			//document.querySelector('#submit').disabled = true;
			document.querySelector('#spinner').classList.remove('hidden');
			document.querySelector('#button-text').classList.add('hidden');
		} else {
			//document.querySelector('#submit').disabled = false;
			document.querySelector('#spinner').classList.add('hidden');
			document.querySelector('#button-text').classList.remove('hidden');
		}
	}
}
