import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({ providedIn: 'root' })
export class AppDateAdapter extends NativeDateAdapter {
	parse(value: any): Date | null {
		if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
			const str = value.split('/');
			const year = Number(str[2]);
			const date = Number(str[1].length === 1 ? '0' + str[1] : str[1]);
			const month = Number(str[0].length === 1 ? '0' + str[0] : str[0]) - 1;
			if (!str[2] || !str[1]) {
				return null;
			} else if (str[1].length < 1 || str[2].length !== 4) {
				return null;
			} else if (
				(month > 11) ||
				(month === 1 && date > 29) ||
				([0, 2, 4, 6, 7, 9, 11].includes(month) && date > 31) ||
				([1, 3, 5, 8, 10].includes(month) && date > 30)
			)
				return null;
			return new Date(year, month, date);
		}
		return null;
	}
	format(date: Date, displayFormat: any): string {
		if (displayFormat === 'input') {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return this._to2digit(month) + '/' + this._to2digit(day) + '/' + year;
		} else {
			return date.toDateString();
		}
	}

	private _to2digit(n: number) {
		return ('00' + n).slice(-2);
	}
}


export const APP_DATE_FORMATS = {
	parse: {
		dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
	},
	display: {
		dateInput: 'input',
		monthYearLabel: 'inputMonth',
		dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'long' },
	}
};
