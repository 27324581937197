<mat-tab-group *ngIf="withTabs; else withoutTabsRef" [disablePagination]="true">
    <mat-tab label="Search Doctors & Clinics">
        <div class="container">
            <ng-container *ngTemplateOutlet="name"></ng-container>
        </div>
    </mat-tab>

    <mat-tab label="Location">
        <div class="container">
            <ng-container *ngTemplateOutlet="location"></ng-container>
        </div>
    </mat-tab>

    <mat-tab label="Sorted by:">
        <div class="container">
            <ng-container *ngTemplateOutlet="sort"></ng-container>
        </div>
    </mat-tab>
</mat-tab-group>

<!--
	Removing this. Eran doesn't need it
	<button mat-button *ngIf="withTabs" color="primary" class="viewAll">View All Filters</button>
-->

<ng-template #name>
    <mat-form-field appearance="outline" class="name">
        <mat-label>Search Doctors & Clinics</mat-label>
        <input matInput placeholder="Enter Clinic or Doctor Name" [ngModel]="filters.search_by" (ngModelChange)="onUpdate('search_by', $event)" />
        <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
</ng-template>

<ng-template #location>
    <app-form-field class="location" type="address" label="Location" [autofill]="true" [value]="filters.fullAddress" (coordinates)="onMapCoordinates($event)" (details)="onMapDetails($event)"></app-form-field>
</ng-template>

<ng-template #sort>
    <mat-form-field appearance="outline" class="sorting">
        <mat-label>Sorted by:</mat-label>

        <mat-select [value]="sortValue" (valueChange)="onSortChange($event)">
            <mat-option *ngFor="let option of sortByOptions" [value]="option">
                {{ option.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>

<ng-template #withoutTabsRef>
    <ng-container *ngTemplateOutlet="name"></ng-container>
    <ng-container *ngTemplateOutlet="location"></ng-container>
    <ng-container *ngTemplateOutlet="sort"></ng-container>
</ng-template>