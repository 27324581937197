import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MaterialModule} from '@/material.module';

import {LoaderModule} from '../loader/loader.module';

import {ScrollerComponent, ScrollerItemDirective} from './scroller.component';
import {TakeMaxSpaceDirective} from './scroller.directive';

@NgModule({
	declarations: [ScrollerItemDirective, ScrollerComponent, TakeMaxSpaceDirective],
	imports: [CommonModule, MaterialModule, ScrollingModule, LoaderModule],
	exports: [ScrollerItemDirective, ScrollerComponent],
})
export class ScrollerModule {}
