import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-insvg',
	templateUrl: './insvg.component.html',
	styleUrls: ['./insvg.component.scss']
})
export class InsvgComponent implements OnInit, OnChanges {
	@Input()
	link: string;
	@Input()
	name: string;

	@Input()
	style: string;

	svg = '';

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.matIconRegistry.addSvgIcon(this.name, this.domSanitizer.bypassSecurityTrustResourceUrl(this.link));
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.link?.currentValue)
			this.matIconRegistry.addSvgIcon(this.name, this.domSanitizer.bypassSecurityTrustResourceUrl(changes.link.currentValue));
	}
}
