import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TimezoneDateModule } from '@/pipes/timezoneDate.module';
import { MaterialModule } from '../../material.module';
import { FormFieldModule } from '../form-field/form-field.module';
import { InsvgModule } from '../insvg/insvg.module';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { CalendarComponent } from './calendar.component';
import { TrueLengthPipe } from './trueLength.pipe';

@NgModule({
	imports: [CommonModule, MaterialModule, InsvgModule, SkeletonModule, FormFieldModule, TimezoneDateModule],
	declarations: [CalendarComponent, CalendarHeaderComponent, TrueLengthPipe, CalendarListComponent],
	exports: [CalendarComponent, CalendarHeaderComponent, TrueLengthPipe, CalendarListComponent],
})
export class CalendarModule {}
