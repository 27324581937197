import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormFieldModule} from '@/components/form-field/form-field.module';
import {MaterialModule} from '@/material.module';
import {TimezoneDateModule} from '@/pipes/timezoneDate.module';
import {CalendarModule} from '../calendar/calendar.module';
import {ConciergeComponent} from './concierge.component';

@NgModule({
	declarations: [ConciergeComponent],
	imports: [CommonModule, CalendarModule, MaterialModule, FormFieldModule, TimezoneDateModule],
	exports: [ConciergeComponent],
})
export class ConciergeModule {}
