import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
	selector: 'app-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements AfterViewInit {
	@ViewChild('videoRef')
	videoRef: ElementRef<HTMLVideoElement>;
	@ViewChild('iframeRef')
	iframeRef: ElementRef<HTMLIFrameElement>;

	initiated = false;

	@Input()
	public get video(): string {
		return this._video;
	}
	public set video(value: string) {
		this._video = value;
		const match = this.video?.match(/(?:youtube.*\?v=|youtu\.be\/)(\w+)/);
		if (match) {
			this.youtubeVideoLink = this.sanitiser.bypassSecurityTrustResourceUrl(
				`https://www.youtube.com/embed/${match[1]}?origin=${window.location.protocol}://${window.location.host}`,
			);
		} else {
			this.youtubeVideoLink = null;
		}

		if (this.initiated) {
			setTimeout(() => this.updateHeight());
		}
	}
	private _video: string;

	isVideoPlaying = false;

	youtubeVideoLink: SafeResourceUrl = null;

	constructor(private sanitiser: DomSanitizer) {}

	ngAfterViewInit() {
		this.initiated = true;
		this.updateHeight();
	}

	updateHeight() {
		if (this.iframeRef) {
			this.iframeRef.nativeElement.height = this.youtubeVideoLink ? `${(this.iframeRef.nativeElement.clientWidth / 16) * 9}px` : null;
		}
	}

	openVideo() {
		this.isVideoPlaying = true;
		this.videoRef.nativeElement.play();
	}
}
