import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {LocationEntryComponent} from './location-entry.component';

@NgModule({
	declarations: [LocationEntryComponent],
	imports: [CommonModule],
	exports: [LocationEntryComponent],
})
export class LocationEntryModule {}
