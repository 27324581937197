<!-- todo: add IVF filters -->
<header [class.questionnaire]="!(loginStatus$ | async) && (page$ | async | includes: 'questionnaire')">
    <mat-toolbar>
        <mat-toolbar-row>
            <svg class="ham" [class.active]="showNavigationOnMobile" viewBox="0 0 100 100" width="50" (click)="toggleNavigation()">
				<path
					class="line top"
					d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
				/>
				<path class="line middle" d="m 30,50 h 40" />
				<path
					class="line bottom"
					d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
				/>
			</svg>
            <span *ngIf="stripe_subscription_can_subscribe && !showSubscriptionButtonValue || (isSmallScreen$ | async) === false">
                <ng-container *ngTemplateOutlet="logoLink"></ng-container>
            </span>
            <span *ngIf="stripe_subscription_can_subscribe && showSubscriptionButtonValue && stripe_subscription_status === 'active' && (isSmallScreen$ | async) === true">
                <ng-container *ngTemplateOutlet="logoLink"></ng-container>
            </span>
            <span *ngIf="!stripe_subscription_can_subscribe && showSubscriptionButtonValue && stripe_subscription_status !== 'active' && (isSmallScreen$ | async) === true">
                <ng-container *ngTemplateOutlet="logoLink"></ng-container>
            </span>
            <div *ngIf="(page$ | async) === '/ivf' && (isSmallScreen$ | async) === false; else modulesRef" class="filters">
                <app-ivf-filters></app-ivf-filters>
            </div>

            <ng-template #modulesRef>
                <div class="modules" [class.admin]="(userType$ | async) === 'admin'" [class.opened]="showNavigationOnMobile" [ngSwitch]="userType$ | async">
                    <ng-container *ngSwitchCase="'provider'">
                        <div><a routerLink="/egg-donor">Egg Donors</a></div>
                        <ng-container *ngIf="authState$ | async as authState">
                            <ng-container *ngIf="authState.company_type | includes: 'surrogacy'">
                                <div><a routerLink="/surrogacy/agency">Surrogacy Profile</a></div>
                                <div><a routerLink="/surrogates">Surrogates</a></div>
                            </ng-container>
                            <div *ngIf="(authState.company_type | includes: 'ivf_clinic') && authState.ivf_id">
                                <a routerLink="/ivf/profile/edit/{{ authState.id }}/{{ authState.ivf_id }}/e">IVF Profile</a>
                            </div>
                        </ng-container>
                        <div><a routerLink="/providers/costs">Services & Costs</a></div>
                        <div><a routerLink="/providers/deals">Leads & Deals</a></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'parent'">
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'parent', label: 'For Intended Parents'}"></ng-container>
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'resources', label: 'Resources'}"></ng-container>
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'about', label: 'About Us'}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'admin'">
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'parent', label: 'For Intended Parents'}"></ng-container>
                        <div><a href="{{ getRoot() }}/providers/">For Providers</a></div>
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'resources', label: 'Resources'}"></ng-container>
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'about', label: 'About Us'}"></ng-container>
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'admin', label: 'For Admin'}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'parent', label: 'For Intended Parents'}"></ng-container>
                        <div><a href="{{ getRoot() }}/providers/">For Providers</a></div>
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'resources', label: 'Resources'}"></ng-container>
                        <ng-container *ngTemplateOutlet="expandable; context: {category: 'about', label: 'About Us'}"></ng-container>
                    </ng-container>

                    <div class="backdrop"></div>
                </div>
            </ng-template>


            <div class="premium-container">
                <div class="full-premium" *ngIf="stripe_subscription_can_subscribe && showSubscriptionButtonValue && stripe_subscription_status !== 'active'">
                    <button mat-flat-button color="accent" class="interested" (click)="onInterested()">Upgrade to Premium Membership</button>
                </div>
                <div class="short-premium" *ngIf="stripe_subscription_can_subscribe && showSubscriptionButtonValue && stripe_subscription_status !== 'active'">
                    <button mat-flat-button color="accent" class="interested" (click)="onInterested()">Upgrade to Premium Membership</button>
                </div>
            </div>

            <div class="language" (mouseenter)="openLanguageDropdown($event.target)" (mouseleave)="hideDropdown($event)" *ngIf="!showSubscriptionButtonValue || (isSmallScreen$ | async) === false">
                <img [src]="activeLanguage?.flag" />
                <span>{{ activeLanguage?.code | uppercase }}</span>
            </div>
            <div class="language" (mouseenter)="openLanguageDropdown($event.target)" (mouseleave)="hideDropdown($event)" *ngIf="showSubscriptionButtonValue && stripe_subscription_status === 'active' && (isSmallScreen$ | async) === true">
                <img [src]="activeLanguage?.flag" />
                <span>{{ activeLanguage?.code | uppercase }}</span>
            </div>

            <button *ngIf="(loginStatus$ | async) === false" class="login-button" mat-flat-button [style.visibility]="(page$ | async) === '/login' ? 'hidden' : 'visible'" (click)="login()">
				Login
			</button>
            <div class="loader" *ngIf="(loginStatus$ | async) === null">
                <mat-progress-spinner mode="indeterminate" color="primary" diameter="16"></mat-progress-spinner> loading...
            </div>
            <div class="user" *ngIf="loginStatus$ | async">
                <a mat-icon-button class="chat" routerLink="/chat">
                    <div class="chat__flex">
                        <span class="chat__flex__img" matBadgeColor="warn" matBadgeSize="small" matBadgePosition="below" [matBadge]="unreadCount$ | async" [matBadgeHidden]="!(unreadCount$ | async)">
							<img src="assets/bubble-outlined.svg" alt="Chat Icon" />
						</span>
                        <span class="chat__flex__msg">Inbox</span>
                    </div>
                </a>
                <mat-divider vertical></mat-divider>

                <div *ngIf="name$ | async as name" class="user-name" [matMenuTriggerFor]="userMenu">
                    <div class="user_img">{{ name ? name[0] : '#' }}</div>
                    <div class="name">{{ name }}</div>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-menu #userMenu xPosition="before" class="user-menu">
        <a mat-menu-item [routerLink]="(userType$ | async) === 'provider' ? '/providers/settings' : '/account'">
            <img src="assets/user.svg" alt="user" />
            <span>My Account</span>
        </a>
        <a mat-menu-item *ngIf="stripe_subscription_can_subscribe" [routerLink]="'/subscription'">
            <img src="assets/user.svg" alt="user" />
            <span>Subscription</span>
        </a>
        <div mat-menu-item (click)="signout()">
            <img src="assets/sign_out.svg" alt="sign_out" />
            <span>Sign Out</span>
        </div>
    </mat-menu>
</header>

<ng-template #expandable let-category="category" let-label="label">
    <ng-container [ngSwitch]="isSmallScreen$ | async">
        <div *ngSwitchCase="false" (mouseenter)="openDropdown($event.target, category)" (mouseleave)="hideDropdown($event)">
            {{ label }}
        </div>
        <ng-container *ngSwitchCase="true">
            <mat-divider></mat-divider>

            <div *ngFor="let item of categories[category]">
                <a *ngIf="item.path | includes: 'https://'" [href]="item.path" target="_blank">{{ item.label }}</a>
                <a *ngIf="!(item.path | includes: 'https://')" [routerLink]="item.path">{{ item.label }}</a>
            </div>

            <mat-divider></mat-divider>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #dropdownRef let-category="category">
    <ul class="dropdown" (mouseleave)="hideDropdown(null)">
        <li *ngFor="let item of categories[category]">
            <a *ngIf="item.path | includes: 'https://'" [href]="item.path" target="_blank">{{ item.label }}</a>
            <a *ngIf="!(item.path | includes: 'https://')" [routerLink]="item.path">{{ item.label }}</a>
        </li>
    </ul>
</ng-template>

<ng-template #languageDropdownRef>
    <div (mouseleave)="hideDropdown(null)" class="languages">
        <div *ngFor="let item of languages" class="language" [class.active]="activeLanguage.code === item.code">
            <img [src]="item.flag" />
            <a (click)="changeLanguage(item.code)">{{ item.code | uppercase }}</a>
        </div>
    </div>
</ng-template>

<ng-template #logoLink>
    <a class="mat-headline"
       routerLink="/"
       *ngIf="this.stripe_customer_id">
        <picture>
            <source srcset="assets/home/logo.png.webp" type="image/webp" />
            <source srcset="assets/home/logo.png" type="image/png" />
            <img alt="GoStork" src="assets/home/logo.png" />
        </picture>
    </a>
    <a class="mat-headline"
       href="{{ this.getRoot() }}"
       *ngIf="!this.stripe_customer_id">
        <picture>
            <source srcset="assets/home/logo.png.webp" type="image/webp" />
            <source srcset="assets/home/logo.png" type="image/png" />
            <img alt="GoStork" src="assets/home/logo.png" />
        </picture>
    </a>
</ng-template>
