import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InsvgComponent } from './insvg.component';
import { MaterialModule } from '../../material.module';

@NgModule({
	declarations: [InsvgComponent],
	imports: [
		CommonModule,
		MaterialModule
	],
	exports: [InsvgComponent]
})
export class InsvgModule { }
