import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProviderType } from '@/config';
import { AuthGuard } from '@/services/auth.guard';

import { ProviderProfileComponent } from '../provider-profile/provider-profile.component';
import { IvfComponent } from './ivf.component';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: '',
				canActivate: [AuthGuard],
				data: {
					roles: ['parent', 'admin', 'provider'],
					fallbackUrl: ['/questionnaire/start'],
					breadcrumb: 'Fertility Clinics',
				},
				component: IvfComponent,
				pathMatch: 'full',
			},
			{
				path: 'questionnaire',
				redirectTo: '/questionnaire/start',
			},
			{
				path: 'questionnaire/:step',
				redirectTo: '/questionnaire/start',
			},
			{
				path: 'profile/edit/:providerId/:id/:slug',
				component: ProviderProfileComponent,
				data: {
					type: ProviderType.IVF_CLINIC,
				},
			},
			{
				path: 'profile/:providerId/:id/:slug',
				component: ProviderProfileComponent,
				data: {
					type: ProviderType.IVF_CLINIC,
				},
			},
		]),
	],
	exports: [RouterModule],
})
export class IvfRoutingModule {}
