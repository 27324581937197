import {Component, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription, merge, of} from 'rxjs';
import {startWith, tap, debounceTime, distinctUntilChanged, switchMap, catchError, map} from 'rxjs/operators';

import {providerNames, ProviderType} from '../../../config';
import {User, AdminService} from '../../../services/admin.service';
import {UtilsService} from '../../../services/utils.service';

type UserExtended = User & {
	open: string;
	name: string;
	partner_name: string;
	relationReadable: string;
	identifyReadable: string;
	verifiedAtReadable: string;
	action: string;
	type: ProviderType;
	annexe_created_at: Record<ProviderType, Date>;
};

@Component({
	selector: 'app-user-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
})
export class UsersListComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(MatSort)
	sort: MatSort;
	@ViewChild(MatPaginator)
	paginator: MatPaginator;

	readonly columns: {n: string; s: string | keyof UserExtended}[] = [
		{n: '', s: 'open'},
		{n: 'ID', s: 'id'},
		{n: 'Full Name', s: 'name'},
		{n: 'Email', s: 'email'},
		{n: 'Phone', s: 'phone'},
		{n: 'ED Profile', s: 'ed_annexe_created_at'},
		{n: 'SUR Profile', s: 'sur_annexe_created_at'},
		{n: 'IVF Profile', s: 'ivf_annexe_created_at'},
		{n: 'Verified', s: 'verified_at'},
		{n: 'Status', s: 'status'},
		{n: 'Subscription Status', s: 'stripe_subscription_status'},
		{n: 'Subscription Length', s: 'stripe_subscription_length'},
		{n: 'Subscription Amount', s: 'stripe_subscription_amount'},
		{n: '', s: 'action'},
	];
	readonly providerNames = providerNames;
	readonly columnsSelectors = this.columns.map((item) => item.s);
	readonly detailsColumns: {n: string; s: keyof UserExtended}[] = [
		{n: 'Birthday', s: 'birthday'},
		{n: 'Idenification', s: 'identifyReadable'},
		{n: 'Relationship Status', s: 'relationReadable'},
		{n: 'Partner Name', s: 'partner_name'},
		{n: 'Verified At', s: 'verifiedAtReadable'},
		{n: 'Address', s: 'address'},
		{n: 'Country', s: 'country'},
		{n: 'State', s: 'state'},
		{n: 'City', s: 'city'},
		{n: 'ZIP', s: 'zip'},
	];
	readonly rowsCount = 30;

	loading = true;
	length = 0;
	expandedElement: Record<string, unknown> | null = null;

	dataSource = new MatTableDataSource([] as UserExtended[]);
	filter = new FormControl('');
	dateRange = new FormGroup({
		start: new FormControl(),
		end: new FormControl(),
	});

	_subscription = new Subscription();

	constructor(private adminService: AdminService, private snack: MatSnackBar, private util: UtilsService) {}

	_logError(error: any) {
		console.error(error);
		this.snack.open('Smth went wrong');
	}

	expandRow(element: Record<string, unknown>, event: MouseEvent) {
		const target = event.target as HTMLElement;
		if (target.nodeName === 'A') return;
		this.expandedElement = this.expandedElement === element ? null : element;
	}

	updateUserStatus(id: number, status: boolean) {
		this.adminService.updateUserStatus(id, status).subscribe({
			next: () => this.snack.open(`Status for user #${id} set to ${status ? 'active' : 'inactive'}.`),
			error: (error) => this._logError(error),
		});
	}

	changeVerificationStatus(id: number, status: boolean) {
		this.adminService.changeVerificationStatus(id, status).subscribe({
			next: () => this.snack.open(`Verification status for user #${id} set to ${status ? 'active' : 'inactive'}.`),
			error: (error) => this._logError(error),
		});
	}

	deleteUser(id: number) {
		const sure = confirm(`You are going to delete user #${id}`);
		if (!sure) return;
		this.adminService.deleteUser(id).subscribe({
			next: () => {
				this.snack.open(`User #${id} delete success.`);
				this.dataSource.data = this.dataSource.data.filter((item) => item.id !== id);
			},
			error: (error) => this._logError(error),
		});
	}

	ngOnInit(): void {
		this.dataSource.sort = this.sort;
		this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId];
	}

	ngAfterViewInit() {
		this._subscription.add(this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0)));

		this._subscription.add(
			merge(this.sort.sortChange, this.paginator.page, this.filter.valueChanges, this.dateRange.valueChanges)
				.pipe(
					startWith([]),
					tap(() => (this.loading = true)),
					debounceTime(400),
					tap(() => (this.loading = false)),
					distinctUntilChanged((a: any, b: any) => (typeof a === 'string' ? a === b : false)),
					switchMap(() => {
						this.loading = true;
						return this.adminService
							.getUsers(
								this.sort.active,
								this.sort.direction + ' NULLS LAST',
								this.paginator.pageIndex,
								this.rowsCount,
								this.filter.value.trim(),
								this.dateRange.value,
							)
							.pipe(
								catchError((error) => {
									console.error(error);
									return of({count: 0, data: [] as User[], page: 0});
								}),
							);
					}),
					map((res) => {
						this.loading = false;
						this.length = res.count;
						if (res.page) this.paginator.pageIndex = res.page;
						return res.data as UserExtended[];
					}),
				)
				.subscribe({
					next: (res) =>
						(this.dataSource.data = res.map((item) => {
							item.name = `${item.first_name || '‧‧‧'} ${item.last_name || '‧‧‧'}`;
							item.partner_name = `${item.partner_first_name || '‧‧‧'} ${item.partner_last_name || '‧‧‧'}`;
							item.relationReadable = item.relation.split('_').map(this.util.toCapitalizedCase).join(' ');
							item.identifyReadable = item.identify.split('_').map(this.util.toCapitalizedCase).join(' ');
							item.verifiedAtReadable = item.verified_at?.toLocaleString() || '‧‧‧';
							item.type = item.deals.map(({type}) => type)?.[0];
							item.annexe_created_at = Object.fromEntries(
								item.user_annexe.map(({type, created_at}) => [type, new Date(created_at)]),
							) as Record<ProviderType, Date>;
							if (item.location) {
								item.country = item.location.country;
								item.state = item.location.state;
								item.city = item.location.city;
								item.address = item.location.address || [item.country, item.state, item.city].filter(Boolean).join(', ');
							}
							return item;
						})),
					error: () => {
						this.loading = false;
						this.dataSource.data = [];
					},
				}),
		);
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}
}
