import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DateTime} from 'luxon';
import {first, map, shareReplay, tap} from 'rxjs/operators';
import {GoStorkProducts, PrettyProducts} from '@/consts/config';
import {nanoid} from '@/helpers/nanoid';
import {parseConfigs} from '@/helpers/schedules';
import {AdminService} from '@/services/admin.service';
import {AnalyticsService} from '@/services/analytics.service';
import {AuthStoreService} from '@/services/auth.service';
import {CalendarViewState} from '@/services/contact-agency.service';
import {timezoneService} from '@/services/timezone.service';
import {UtilsService} from '@/services/utils.service';

@Component({
	selector: 'concierge-ftu-popup',
	templateUrl: './concierge-ftu-popup.component.html',
	styleUrls: ['./concierge-ftu-popup.component.scss'],
	animations: [
		trigger('openClose', [
			transition(':leave', [
				animate(
					'200ms',
					style({
						transform: 'translateY(-100%)',
						opacity: 0,
					}),
				),
			]),
		]),
	],
})
export class ConciergeFtuPopupComponent implements OnInit, OnDestroy {
	static buttonToken = nanoid();
	static bookToken = nanoid();

	@Input()
	type: GoStorkProducts;

	@ViewChild('calendarRef')
	calendarRef: TemplateRef<void>;

	showCalendar = false;

	userType: 'parent' | 'provider' | 'admin' = 'parent';

	calendarConfigs$ = this.adminService.getCalendars().pipe(
		tap((configs) => {
			const {durations, defaultDuration} = parseConfigs(this.utilsService, configs);

			this.durations = durations;
			this.duration = defaultDuration;
		}),
		shareReplay(1),
	);

	durations = [];
	duration = 60;

	loading = false;

	CalendarViewState = CalendarViewState;
	viewState = CalendarViewState.SCHEDULE;
	bookingTime: DateTime;
	bookingCalendarId: string;
	private root: HTMLDivElement;

	bookingCalendar$ = this.calendarConfigs$.pipe(
		map((configs) => configs.find(({calendar_id}) => calendar_id === this.bookingCalendarId)),
	);
	private user: Record<string, any>;
	private _currentConciergeAppointment: null | {
		appointment_time: DateTime;
	};
	private shouldShow = false;
	isShown = false;
	constructor(
		private dialog: MatDialog,
		private router: Router,
		private adminService: AdminService,
		private authStore: AuthStoreService,
		private utilsService: UtilsService,
		private changeDetectorRef: ChangeDetectorRef,
		private analytics: AnalyticsService,
	) {}

	readyToShow() {
		this.shouldShow = true;
		this.show();
	}

	show() {
		const mountUrl = this.router.url;
		if (!this.user) {
			return;
		}
		if (this.userType !== 'parent') {
			return;
		}
		const currentUrl = this.router.url;
		if (mountUrl !== currentUrl) {
			return;
		}
		if (!this.user.shouldShowConciergeFTU) {
			return;
		}
		if (this.isShown) {
			return;
		}
		this.showCalendar = true;
		this.isShown = true;
		this.loading = false;
		document.querySelector('html').classList.add('cdk-global-scrollblock');
	}

	close(soft = false) {
		//this.showBar = false;
		if (!soft) {
			this.isShown = false;
		}
	}

	onTimeSelect(time: DateTime, calendarId: string) {
		this.bookingTime = time;
		this.bookingCalendarId = calendarId;
		this.viewState = CalendarViewState.CONFIRM;
	}

	get appointmentTimeJS() {
		return this.currentConciergeAppointment.appointment_time.toJSDate();
	}

	private _timezone = timezoneService.timezone;
	public get timezone() {
		return timezoneService.timezone;
	}

	cancelAppointment() {
		this.viewState = CalendarViewState.CANCELLED;
		if (!this.currentConciergeAppointment) {
			return;
		}

		const action = `${PrettyProducts[this.type] || this.type} Search`;

		this.analytics.push({
			token: ConciergeFtuPopupComponent.buttonToken,
			category: 'User Area',
			action,
			label: 'Concierge - CTA Cancel',
		});

		this.adminService
			.removeAppointment(this.currentConciergeAppointment.id)
			.toPromise()
			.then((result) => {
				console.log(result);
			});

		this.currentConciergeAppointment = null;
	}

	reschedule() {
		this.viewState = CalendarViewState.SCHEDULE;

		const action = `${PrettyProducts[this.type] || this.type} Search`;

		this.analytics.push({
			token: ConciergeFtuPopupComponent.buttonToken,
			category: 'User Area',
			action,
			label: 'Concierge - CTA Reschedule',
		});
	}

	confirmAppointment() {
		this.loading = true;

		const action = `${PrettyProducts[this.type] || this.type} Search`;

		this.analytics.push({
			token: ConciergeFtuPopupComponent.buttonToken,
			category: 'User Area',
			action,
			label: 'Concierge - Scheduled',
		});

		const originalTimezone = timezoneService.timezone;
		timezoneService.timezone = this._timezone;

		this.adminService
			.scheduleAppointment(this.bookingCalendarId, this.bookingTime.toISO(), this.duration, this.currentConciergeAppointment?.id)
			.subscribe((appointment) => {
				this.loading = false;
				this.currentConciergeAppointment = appointment;
				this.viewState = CalendarViewState.COMPLETE;
				timezoneService.timezone = originalTimezone;
			});
	}

	get currentConciergeAppointment() {
		return this._currentConciergeAppointment;
	}

	set currentConciergeAppointment(appointment: any) {
		this._currentConciergeAppointment = appointment;
		if (appointment) {
			this.bookingCalendarId = appointment.lead_user_id;
			this.bookingCalendar$ = this.calendarConfigs$.pipe(map((configs) => configs.find(({id}) => id === appointment.lead_user_id)));
			this._currentConciergeAppointment.appointment_time = DateTime.fromISO(appointment.appointment_time);
			const maxMeetingTime = this._currentConciergeAppointment.appointment_time.plus({minutes: 30});
			if (new Date() > maxMeetingTime.toJSDate()) {
				this._currentConciergeAppointment = null;
			}
		}
	}

	ngOnInit() {
		this.userType = this.authStore.getType();
		this.authStore.getUser().subscribe((user) => {
			this.user = user;
			this.currentConciergeAppointment = this.user.conciergeAppointment;
			if (this.currentConciergeAppointment) {
				this.viewState = CalendarViewState.COMPLETE;
			}
			if (this.shouldShow) {
				this.show();
			}
		});
		this.calendarConfigs$.pipe(first()).subscribe((configs) => {
			this.changeDetectorRef.markForCheck();
		});
	}

	ngOnDestroy() {
		this.isShown = false;
	}

	onChangeTimezone(timezone: string) {
		this._timezone = timezone;
	}
}
