<mat-form-field appearance="outline">
	<mat-label>{{label}}</mat-label>
	<div (click)="openDropdown()" matPrefix>
		<img class="prefix" *ngIf="code?.label; else noflag" src="assets/flags/{{code.labelLowerCased}}.png" alt="">
		<ng-template #noflag>
			<mat-icon>phone</mat-icon>
		</ng-template>
		<div class="line"></div>
	</div>
	<input
		matInput
		#input
		[formControl]="control"
		[matAutocomplete]="autocomplete"
		[matAutocompleteDisabled]="true"
		type='tel'
		inputmode="tel"
		autocomplete="tel">
	<mat-autocomplete #autocomplete="matAutocomplete">
		<mat-option *ngFor="let option of codes" value="+{{option.code}}" (click)="setCode(option)">
			<img src="assets/flags/{{option.labelLowerCased}}.png" loading="lazy" [alt]="option.label">
			<span>{{option.name}}</span>
			<small>+{{option.code}}</small>
		</mat-option>
	</mat-autocomplete>
	<mat-error>
		<span *ngIf='error'>{{error}}</span>
	</mat-error>
</mat-form-field>
