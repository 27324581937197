import {HttpClient} from '@angular/common/http';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	SimpleChanges,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';
import {FormControl, ValidatorFn, ControlContainer} from '@angular/forms';
import {environment as env} from '@/../environments/environment';
import {Subscription, BehaviorSubject} from 'rxjs';

import {skipWhile} from 'rxjs/operators';

@Component({
	selector: 'app-form-field [type="address"]',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
})
export class AddressFieldComponent implements OnInit, OnChanges, OnDestroy {
	@Input()
	label: string;
	@Input()
	validation: ValidatorFn[] = [];
	@Input()
	errors: Record<string, string> = {};
	@Input()
	value = '';
	@Input()
	autofill = false;
	@Output()
	valueChange = new EventEmitter<string>();
	@Output()
	coordinates = new EventEmitter<[number, number]>();
	@Output()
	details = new EventEmitter<Record<string, string>>();

	@Input()
	controlName: string;

	@ViewChild('input')
	input: ElementRef<HTMLInputElement>;

	change$: Subscription[];

	control = new FormControl(this.value);
	_control = new FormControl(this.value);

	revision = 0;
	responses: {title: string; id: string}[] = [];
	session: google.maps.places.AutocompleteSessionToken;
	service: google.maps.places.AutocompleteService;
	detailsService: google.maps.places.PlacesService;

	id = 0;

	ready = new BehaviorSubject(false);

	constructor(
		@Optional() private controlContainer: ControlContainer,
		private http: HttpClient,
		private changeDetectorRef: ChangeDetectorRef,
	) {}

	public update(value: string) {
		this.ready.pipe(skipWhile((ready) => !ready)).subscribe(() => {
			if (value) {
				this.onChange(value, true);
			} else {
				this.control.setValue('');
				setTimeout(() => this.onBlur());
			}
		});
	}

	ngOnInit() {
		if (this.controlName) {
			this._control = this.controlContainer.control.get(this.controlName) as FormControl;
			this.control.setValue(this._control.value);
			this.change$ = [
				this.control.valueChanges.subscribe((value) => this.onChange(value)),
				this._control.valueChanges.subscribe((value) => this.control.setValue(value, {emitEvent: false})),
			];
		} else {
			this.change$ = [
				this.control.valueChanges.subscribe((value) => this.onChange(value)),
				this._control.valueChanges.subscribe((value) => this.valueChange.emit(this._control.valid ? value : null)),
			];
			this._control.setValidators(this.validation);
			this.control.setValidators([() => (this._control.valid ? null : this._control.errors)]);
			this._control.setValue('', {emitEvent: false});
		}

		window['deactivate'] = () => this._control.setValue('~~~void~~~');

		const scriptNode = document.getElementById('places-script');
		if (scriptNode) {
			const interval = setInterval(() => {
				if (window.google) {
					clearInterval(interval);
					this.initMaps();
				}
			}, 1000);
			return;
		}
		const script = document.createElement('script');
		script.id = 'places-script';
		script.type = 'text/javascript';
		script.src = `https://maps.googleapis.com/maps/api/js?key=${env.google_places_token}&libraries=places&language=en`;
		script.onload = () => this.initMaps();
		document.getElementsByTagName('head')[0].appendChild(script);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value) {
			this.control.setValue(changes.value.currentValue, {emitEvent: false});
			this._control.setValue(changes.value.currentValue, {emitEvent: false});
			this.control.updateValueAndValidity({emitEvent: false});
			this._control.updateValueAndValidity({emitEvent: false});
		}
	}

	ngOnDestroy() {
		this.change$?.forEach((item) => item.unsubscribe());
		if (this.id) document.getElementById(`address-${this.id}`).remove();

		delete window['deactivate'];
	}

	onBlur() {
		if (!this.control.value) {
			this.details.emit({
				country: '',
				state: '',
				city: '',
				zip: '',
			});
			this._control.setValue('');
		}
	}

	identifyLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					this.findLocation(position.coords.latitude, position.coords.longitude);
				},
				() => this.requestLocationByIp(),
				{enableHighAccuracy: true},
			);
		} else {
			this.requestLocationByIp();
		}
	}

	initMaps() {
		console.log('[initMaps]');
		const mapEl = document.createElement('div');
		if (this.id) document.getElementById(`address-${this.id}`).remove();
		this.id = Math.random();
		mapEl.id = `address-${this.id}`;
		mapEl.style.display = 'none';
		document.body.appendChild(mapEl);
		this.session = new google.maps.places.AutocompleteSessionToken();
		this.service = new google.maps.places.AutocompleteService();
		const map = new google.maps.Map(mapEl);
		this.detailsService = new google.maps.places.PlacesService(map);
		this.ready.next(true);
	}

	requestLocationByIp() {
		this.http
			.post<{location: {lng: number; lat: number}}>(`https://www.googleapis.com/geolocation/v1/geolocate?key=${env.google_places_token}`, {
				considerIp: 'true',
			})
			.subscribe((value) => {
				this.findLocation(value.location.lat, value.location.lng);
			});
	}

	findLocation(lat, lng) {
		const location = new google.maps.LatLng(lat, lng);
		new google.maps.Geocoder().geocode({location}, (res, status) => {
			if (status === google.maps.GeocoderStatus.OK) {
				const country = res[0].address_components.find((item) => item.types.includes('country'));
				const state = res[0].address_components.find((item) => item.types.includes('administrative_area_level_1'));
				const city =
					res[0].address_components.find((item) => item.types.includes('locality')) ||
					res[0].address_components.find((item) => item.types.includes('administrative_area_level_2'));
				const zip = res[0].address_components.find((item) => item.types.includes('postal_code'));
				this.coordinates.emit([lng, lat]);
				const result = {
					country: country ? country.long_name : '',
					state: state ? state.short_name : '',
					city: city ? city.long_name : '',
				};
				const value = Object.values(result)
					.filter((item) => item)
					.reverse()
					.join(', ');
				this.details.emit(result);
				this.control.setValue(value, {emitEvent: false});
				this._control.setValue(value, {emitEvent: false});
				this.control.updateValueAndValidity({emitEvent: false});
				this._control.updateValueAndValidity({emitEvent: false});
			}
		});
	}

	onSelect({id}) {
		this.detailsService.getDetails(
			{
				placeId: id,
				sessionToken: this.session,
				fields: ['formatted_address', 'geometry', 'address_component'],
			},
			(res, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					this.session = new google.maps.places.AutocompleteSessionToken();
					this._control.setValue(res.formatted_address);
					this.coordinates.emit([res.geometry.location.lng(), res.geometry.location.lat()]);
					const country = res.address_components.find((item) => item.types.includes('country'));
					const state = res.address_components.find((item) => item.types.includes('administrative_area_level_1'));
					const city =
						res.address_components.find((item) => item.types.includes('locality')) ||
						res.address_components.find((item) => item.types.includes('administrative_area_level_2'));
					const zip = res.address_components.find((item) => item.types.includes('postal_code'));
					this.details.emit({
						country: country ? country.long_name : '',
						state: state ? state.short_name : '',
						city: city ? city.short_name : '',
						zip: zip ? zip.long_name : '',
					});
					this.input.nativeElement.blur();
					this.changeDetectorRef.detectChanges();
				}
			},
		);
	}

	getTitle(event) {
		if (typeof event === 'object' && event !== null) return event.title;
		else return event;
	}

	onChange(value: string, force = false) {
		if (value && value.length > 1) {
			const revision = (this.revision += 1);
			this.service.getPlacePredictions(
				{
					input: value,
					types: ['geocode'],
					sessionToken: this.session,
				},
				(res, status) => {
					if (revision !== this.revision) {
						return;
					}
					this.responses =
						!res || status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
							? []
							: res.map((item) => ({title: item.description, id: item.place_id}));
					this.changeDetectorRef.detectChanges();
					if (force && this.responses.length) {
						this.onSelect({id: this.responses[0].id});
					}
				},
			);
		}
		if (this._control.value) {
			this._control.setValue(null);
			this.control.updateValueAndValidity({emitEvent: false});
		}
	}

	getError() {
		for (const error in this.control.errors) {
			if (this.errors[error]) return this.errors[error];
		}
	}
}
