import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	Optional
} from '@angular/core';
import { FormControl, ValidatorFn, ControlContainer } from '@angular/forms';

import { AppDateAdapter } from '../../../services/date.service';

@Component({
	selector: 'app-form-field [type="date"]',
	templateUrl: './date.component.html',
	styleUrls: ['./date.component.scss']
})
export class DateFieldComponent implements OnInit, OnChanges {
	@Input()
	label: string;
	@Input()
	validation: ValidatorFn[] = [];
	@Input()
	errors: Record<string, string> = {};
	@Input()
	value: Date = null;
	@Output()
	valueChange = new EventEmitter<string>();

	@Input()
	controlName: string;

	now = new Date();
	control = new FormControl(this.value);
	_control = new FormControl('');

	touchUI = document.documentElement.clientWidth < 600 || document.documentElement.clientHeight < 600;

	constructor(
		private date: AppDateAdapter,
		@Optional() private controlContainer: ControlContainer
	) { }

	ngOnInit() {
		if (this.controlName) {
			this.control = this.controlContainer.control.get(this.controlName) as FormControl;
			this.value = this.control.value;
		} else {
			this.control.setValidators(this.validation);
		}
		if (this.value) {
			this._control.setValue(this.date.format(this.value, 'input'));
			this.control.updateValueAndValidity();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value && changes.value.currentValue && (changes.value.previousValue === null || changes.value.previousValue === undefined)) {
			this.control.setValue(changes.value.currentValue, { emitEvent: false });
			this._control.setValue(this.date.format(changes.value.currentValue, 'input'), { emitEvent: false });
		}
	}

	onDateChange(event: InputEvent | string) {
		if (typeof event !== 'string') {
			const value = (event.target as HTMLInputElement).value;
			if (event.data && event.data.trim()) {
				if (isNaN(Number(event.data))) {
					this._control.setValue(this._control.value.slice(0, -1));
				} else if (value.length === 2 || value.length === 5) {
					this._control.setValue(this._control.value + '/');
				} else if (value.length === 3 || value.length === 6 && value[value.length - 1] !== '/') {
					this._control.setValue(this._control.value.slice(0, -1) + '/' + value[value.length - 1]);
				}
			}
		}
		this.control.setValue(this.date.parse(this._control.value));
		this._control.setErrors(this.control.errors);
		this.valueChange.emit(this.control.valid ? this.control.value : null);
	}

	onPickerChange(event: Date) {
		const str = this.date.format(event, 'input');
		this._control.setValue(str);
		this.onDateChange(str);
	}

	getError() {
		for (const error in this.control.errors) {
			if (this.errors[error])
				return this.errors[error];
		}
	}
}
