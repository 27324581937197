import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateTime} from 'luxon';
import {ProviderType} from '@/config';
import {ServiceMessageType} from '@/models/chat.model';
import {ChatService} from '@/services/chat.service';
import {CalendarViewState, ContactAgencyService} from '@/services/contact-agency.service';
import {CalendarListComponent, Contact, Location} from '../calendar/calendar-list/calendar-list.component';

export interface MobileChatData {
	contacts: Contact[];
	withCalendar: boolean;
	type: ProviderType;
	providerId: number;
	name: string;
	locations: Location[];
}

@Component({
	selector: 'app-mobile-chat',
	templateUrl: './mobile-chat.component.html',
	styleUrls: ['./mobile-chat.component.scss'],
})
export class MobileChatComponent implements OnInit {
	@ViewChild(CalendarListComponent)
	calendarList: CalendarListComponent;

	get hintText() {
		return this.data.withCalendar
			? !this.calendarList || this.calendarList.viewState === CalendarViewState.SCHEDULE
				? 'You will confirm details after selecting a time.'
				: 'You will receive an email with confirmation details.'
			: 'You will receive a response from the clinic on GoStork within X business days.';
	}

	message = '';
	messageSuccess = false;

	constructor(
		private dialogRef: MatDialogRef<MobileChatComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MobileChatData,
		private chatService: ChatService,
	) {}

	ngOnInit() {}

	closeDialog() {
		this.dialogRef.close();
	}

	openChat() {
		this.chatService.createChat2(ProviderType.IVF_CLINIC, this.data.providerId, this.message).subscribe(() => {
			this.messageSuccess = true;
			setTimeout(() => this.dialogRef.close(), 2000);
		});
		this.message = '';
	}

	createAppointment({time, calendarId}: {time: DateTime; calendarId: string}) {
		this.chatService
			.createChat2(
				this.data.type,
				this.data.providerId,
				ContactAgencyService.buildServiceMessage(ServiceMessageType.CreatedAppointment, {
					time: time.toISO(),
					calendarId,
				}),
			)
			.subscribe(({chat}) => {
				this.calendarList.dealId = chat.deal_id;
				this.calendarList.onAppointmentConfirm(true);
			});
	}
}
