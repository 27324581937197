import {animate, style, transition, trigger} from '@angular/animations';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DateTime} from 'luxon';
import {first, map, shareReplay, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {AdminService} from '@/services/admin.service';
import {CalendarViewState} from '@/services/contact-agency.service';
import {UtilsService} from '@/services/utils.service';
import {combineLatest} from 'rxjs';
import {parseConfigs} from "@/helpers/schedules";
import {timezoneService} from "@/services/timezone.service";

@Component({
	selector: 'app-concierge-calendar',
	templateUrl: './concierge-calendar.component.html',
	styleUrls: ['./concierge-calendar.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({opacity: 0, transform: 'translateY(20px)'}),
				animate('100ms ease', style({opacity: 1, transform: 'translateY(0)'})),
			]),
		]),
	],
})
export class ConciergeCalendarComponent implements OnInit {
	calendarConfigs$ = this.adminService.getCalendars().pipe(
		withLatestFrom(this.route.params),
		map(([configs, params]) => (params.calendarId ? configs.filter((item) => item.calendar_id === params.calendarId) : configs)),
		tap((configs) => {
			const { durations, defaultDuration } = parseConfigs(this.utilsService, configs);

			this.durations = durations;
			this.duration = defaultDuration;
		}),
		shareReplay(1),
	);

	durations = [];
	duration = 60;

	loading = false;

	CalendarViewState = CalendarViewState;
	viewState = CalendarViewState.SCHEDULE;

	bookingTime: DateTime;
	bookingCalendarId: string;

	bookingCalendar$ = this.calendarConfigs$.pipe(map((configs) => configs.find(({calendar_id}) => calendar_id === this.bookingCalendarId)));

	event$ = this.route.queryParams.pipe(map((params) => params.event as 'reschedule' | 'cancel' | 'confirm'));
	id$ = this.route.queryParams.pipe(map((params) => +params.id));

	tzones = this.utilsService.tzones;
	timezone = timezoneService.timezone;

	constructor(private adminService: AdminService, private utilsService: UtilsService, private route: ActivatedRoute) {}

	confirmAppointment() {
		const originalTimezone = timezoneService.timezone;
		timezoneService.timezone = this.timezone || timezoneService.timezone;

		this.loading = true;
		combineLatest([this.event$, this.id$])
			.pipe(
				switchMap(([event, id]) =>
					this.adminService.scheduleAppointment(
						this.bookingCalendarId,
						this.bookingTime.toISO(),
						this.duration,
						event === 'reschedule' ? id : undefined,
					),
				),
			)
			.subscribe(() => {
				timezoneService.timezone = originalTimezone;
				this.loading = false;
				this.viewState = CalendarViewState.COMPLETE;
			});
	}

	onTimeSelect(time: DateTime, calendarId: string) {
		this.bookingTime = time;
		this.bookingCalendarId = calendarId;
		this.viewState = CalendarViewState.CONFIRM;
		this.loading = false;
	}

	ngOnInit() {
		this.event$.pipe(first(), withLatestFrom(this.id$)).subscribe(([event, id]) => {
			if (event === 'cancel') {
				this.adminService.removeAppointment(id).subscribe();
			}
			if (event === 'confirm') {
				this.adminService.confirmAppointment(id).subscribe();
			}
		});
	}

	changeTimezone($event: string) {
		this.timezone = $event;
	}
}
