<div class="menu">
</div>

<ng-template #subscriptionRef>
    <div class="ng-scope">
        <div class="wrapper ">
            <div class="page">
                <div mat-dialog-content class="main">
                    <div class="sr-root sr-root-subscribe">
                        <div class="sr-content sr-content-subscribe">
                            <div class="join-membership-content-items-header">GoStork Premium Membership</div>
                            <ul>
                                <li><span>Full donor profiles</span></li>
                                <li><span>Detailed journey cost breakdowns</span></li>
                                <li><span>Compare profiles side-by-side</span></li>
                                <li><span>Chat with egg donor agencies</span></li>
                                <li><span>Book consultation calls with egg donor agencies</span></li>
                                <li><span>GoStork Concierge - unlimited 1 on 1 calls</span></li>
                                <li><span>Easily cancel any time</span></li>
                            </ul>
                            <div class="price" *ngIf="stripe_subscription_status !== 'active'">
                                <div class="price">Subscribe for only $99/month</div>
                                <br />
                            </div>

                            <div class="questions">Questions? <a class="text-decoration-underline acolor fontWeight600" href="mailto:support@gostork.com"><u>Ask GoStork Support</u></a></div>
                        </div>
                        <div class="sr-main" *ngIf="stripe_subscription_status !== 'active'">
                            <div class="sr-main-container">
                                <div class="sr-form-row">
                                    <label for="payment-form" class="subscribe-label"></label>
                                </div>
                                <div id="payment-request-button">
                                    <!-- A Stripe Element will be inserted here. -->
                                </div>
                                <br />
                                <div id="payment-message">Or pay with card</div>
                                <br />
                                <form id="payment-form">
                                    <div id="payment-element">
                                        <!-- Elements will create form elements here -->
                                    </div>
                                    <br/>
                                    <button id="submit">Subscribe</button>
                                    <div id="error-message">
                                        <!-- Display error message to your customers here -->
                                    </div>
                                </form>
                            </div>

                            <div class="not-now" *ngIf="data !== null" (click)="clickNotNow(false)">
                                <button mat-icon-button mat-dialog-close>Not Now</button>
                            </div>

                            <div class="not-now" *ngIf="data === null" (click)="clickNotNow(true)">
                                <button mat-icon-button>Not Now</button>
                            </div>
                        </div>
                        <label for="payment-form" class="subscribe-label"></label>
                        <label for="payment-form" class="subscribe-label"></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #cancelRef>
    <div class="ng-scope">
        <div class="wrapper ">
            <div class="page">
                <div mat-dialog-content class="main">
                    <div class="sr-root sr-root-subscribe">
                        <div class="sr-content sr-content-subscribe">
                            <div class="join-membership-content-items-header">GoStork Premium Membership</div>
                            <ul>
                                <li><span>Full donor profiles</span></li>
                                <li><span>Detailed journey cost breakdowns</span></li>
                                <li><span>Compare profiles side-by-side</span></li>
                                <li><span>Chat with egg donor agencies</span></li>
                                <li><span>Book consultation calls with egg donor agencies</span></li>
                                <li><span>GoStork Concierge - unlimited 1 on 1 calls</span></li>
                                <li><span>Easily cancel any time</span></li>
                            </ul>

                            <div class="questions">Questions? <a class="text-decoration-underline acolor fontWeight600" href="mailto:support@gostork.com"><u>Ask GoStork Support</u></a></div>
                            <br />
                            <span class="renew" *ngIf="stripe_cancel_at_period_end">Your subscription is cancelled but will remain active until {{
                                stripe_current_period_end_fomatted }}</span>

                            <div class="cancel-sub" *ngIf="!stripe_cancel_at_period_end">
                                <button (click)="cancelSubscription()" color="accent ">Cancel Subscription</button>
                            </div>
                            <span class="renew" *ngIf="!stripe_cancel_at_period_end">Your subscription is active and
                                will auto renew on {{ stripe_current_period_end_fomatted }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #successCancelRef>
    <div class="ng-scope ">
        <div class="wrapper ">
            <div class="page-cancel ">
                <div class="main-cancel ">
                    <div class="join-membership-content-items-success">Subscription Canceled</div>
                    <br />

                    <div class="join-membership-content-items-desc">You may rejoin anytime.</div>
                    <br />

                    <span class="renew">Your subscription is active until {{
                        stripe_current_period_end_fomatted }}</span>
                    <div class="sr-root sr-root-subscribe">
                        <div class="sr-content sr-content-subscribe">
                            <div class="join-membership-content-items-header">GoStork Premium Membership</div>
                            <ul>
                                <li><span>Full donor profiles</span></li>
                                <li><span>Detailed journey cost breakdowns</span></li>
                                <li><span>Compare profiles side-by-side</span></li>
                                <li><span>Chat with egg donor agencies</span></li>
                                <li><span>Book consultation calls with egg donor agencies</span></li>
                                <li><span>GoStork Concierge - unlimited 1 on 1 calls</span></li>
                                <li><span>Easily cancel any time</span></li>
                            </ul>

                            <div class="questions">Questions? <a class="text-decoration-underline acolor fontWeight600" href="mailto:support@gostork.com"><u>Ask GoStork Support</u></a></div>
                            <br />
                            <div class="premium" *ngIf="data">
                                <button [mat-dialog-close]="true" (click)="close()" color="accent">Close</button>
                            </div>
                            <div class="premium" *ngIf="!data">
                                <button (click)="close() " color="accent ">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #successRef>
    <div class="ng-scope">
        <div class="wrapper">
            <div class="page-success">
                <div class="main-success">
                    <div class="join-membership-content-items-success">Success!</div>
                    <br />
                    <div class="join-membership-content-items-desc">You are now a GoStork Premium Member.</div>
                    <br />

                    <div class="premium" *ngIf="data">
                        <button [mat-dialog-close]="true" (click)="close()" color="accent">Close</button>
                    </div>
                    <div class="premium" *ngIf="!data">
                        <button (click)="close()" color="accent">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #errorRef>
    <div class="ng-scope ">
        <div class="wrapper ">
            <div class="page ">
                <div class="main ">
                    <div class="sr-root sr-root-subscribe ">
                        <div class="sr-content sr-content-subscribe ">
                            <div class="join-membership-content-items-header ">GoStork Premium Membership</div>
                            <ul>
                                <li><span>Full donors profiles</span></li>
                                <li><span>Detailed journey cost breakdowns</span></li>
                                <li><span>Compare profiles side-by-side</span></li>
                                <li><span>Chat with egg donor agencies</span></li>
                                <li><span>Book consultation calls with egg donor agencies</span></li>
                                <li><span>Unlimited support from GoStork Concierge</span></li>
                                <li><span>Easily cancel any time</span></li>
                            </ul>
                            <div class="price " *ngIf="stripe_subscription_can_subscribe && stripe_subscription_status !=='active' ">
                                <div class="price ">Subscribe for only $99/month</div>
                                <br />
                            </div>

                            <div class="questions ">Questions? <a class="text-decoration-underline acolor fontWeight600 " href="mailto:support@gostork.com "><u>Ask GoStork Support</u></a></div>
                        </div>
                        <div class="sr-main " *ngIf="stripe_subscription_can_subscribe && stripe_subscription_status==='active' ">
                            <div>
                                <div class="sr-form-row ">
                                    <label for="payment-form" class="subscribe-label ">Enjoy your GoStork
                                        Subscription</label>
                                </div>
                                <span *ngIf="stripe_cancel_at_period_end ">ERROR</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngTemplateOutlet="currentStep "></ng-container>
