import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MaterialModule} from '@/material.module';

import {SuccessRatesChartComponent} from './success-rates-chart.component';

@NgModule({
	declarations: [SuccessRatesChartComponent],
	imports: [CommonModule, MaterialModule],
	exports: [SuccessRatesChartComponent],
})
export class SuccessRatesChartModule {}
