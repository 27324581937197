import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, first, map, mergeMap, startWith, tap } from 'rxjs/operators';

import { UtilsService } from './utils.service';

export type IDonor = {
	id: number;
	origin_id: string;
	availability: string;
	origin_availability: string;
	previous_donor: boolean;
	origin_previous_donor: string;
	prev_fertility: boolean;
	origin_prev_fertility: string;
	eye_color: string;
	origin_eye_color: string;
	hair_color: string;
	origin_hair_color: string;
	height: number;
	origin_height: string;
	age: number;
	origin_age: string;
	country: string;
	origin_country: string;
	state: string;
	origin_state: string;
	education_level: string;
	origin_education_level: string;
	high_school_gpa: number;
	origin_high_school_gpa: string;
	act: number;
	origin_act: string;
	sat: number;
	origin_sat: string;
	college_gpa: number;
	origin_college_gpa: string;
	race: string[];
	origin_race: string;
	religion: string;
	origin_religion: string;
	ethnicity: string[];
	origin_ethnicity: string;
	relationship_status: string;
	origin_relationship_status: string;
	blood_type: string;
	origin_blood_type: string;
	egg_donor_compensation: number;
	origin_egg_donor_compensation: string;
	total_compensation: number;
	img: string;
	origin: string;
	agency_id: number;
	img_cached?: boolean;
	created_at: Date;
	video: string;
	donor_type: 'fresh' | 'frozen_eggs';
	frozen_egg_donor_details?: {
		eggs_in_lot: number;
	};
} & { [key: string]: any };

export interface DonationHistory {
	date: string;
	eggs: string;
	embryos: string;
	health: string;
	result: string;
}

export interface DonorsState extends EntityState<IDonor, number> {
	count: number;
	agencies: { [key: string]: any }[];
	backup: IDonor[][];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'donors', idKey: 'id' })
export class DonorsStore extends EntityStore<DonorsState, IDonor> {
	constructor() {
		super({ count: 0, agencies: [] });
	}
}

@Injectable({ providedIn: 'root' })
export class DonorsQuery extends QueryEntity<DonorsState> {
	constructor(protected store: DonorsStore) {
		super(store);
	}
}

@Injectable({ providedIn: 'root' })
export class DonorsStoreService {
	get count() {
		return this.query.getValue().count;
	}

	get loading() {
		return this.query.selectLoading();
	}

	watched = new BehaviorSubject(new Set<number>(JSON.parse(localStorage.getItem('__watched') || '[]')));
	options = new BehaviorSubject({
		offset: 0,
		count: 0,
		filters: null,
		favourites: false,
		sortBy: '',
		revision: 0,
	});
	public donors = this.options.pipe(
		startWith([]),
		distinctUntilChanged((a, b) => {
			const _a = this.utils.deepCopy(a);
			const _b = this.utils.deepCopy(b);
			delete _a['revision'];
			delete _b['revision'];
			return this.utils.deepEquals(_a, _b);
		}),
		mergeMap(() => {
			this.store.setLoading(true);
			const { filters, offset, count, favourites, sortBy, revision } = this.options.value;
			return filters
				? this.http
						.put('/api/users/filters', filters, {
							params: {
								offset: offset.toString(),
								count: count.toString(),
								favourites: favourites.toString(),
								sort: sortBy,
								rev: (revision || 0).toString(),
							},
						})
						.pipe(
							tap(
								(res: { data: IDonor[]; count: number; revision?: number }) => {
									this.store.upsertMany(res.data);
									this.store.update({ count: res.count });
									this.store.setLoading(false);
								},
								(error) => {
									if (error.status === 401) {
										this.router.navigateByUrl('/login');
									}
								},
							),
						)
				: this.http
						.get('/api/egg-donors', {
							params: {
								offset: offset.toString(),
								count: count.toString(),
								favourites: favourites.toString(),
								sort: sortBy,
							},
						})
						.pipe(
							tap(
								(res: { data: IDonor[]; count: number; revision?: number }) => {
									this.store.upsertMany(res.data);
									this.store.update({ count: res.count });
									this.store.setLoading(false);
								},
								(error) => {
									if (error.status === 401) {
										this.router.navigateByUrl('/login');
									}
								},
							),
						);
		}),
		map((res) => ({ ...res, data: res.data.map((item) => ({ ...item, created_at: new Date(item.created_at) })) })),
	);

	private _fetchRequest: Subscription;

	constructor(
		private store: DonorsStore,
		private query: DonorsQuery,
		private http: HttpClient,
		private router: Router,
		private utils: UtilsService,
	) {}

	backup(data: IDonor[][]) {
		this.store.update({ backup: data });
	}

	restore() {
		const backup = this.store.getValue().backup;
		return backup ? backup.slice() : null;
	}

	countSubscription() {
		return this.query.select('count');
	}

	clear() {
		this.sponsoredStatus = null;
		this.store.remove();
		this.store.reset();
		this.store.update({ count: 0 });
	}

	getDonorById(ids: number | number[]): Observable<IDonor[]> {
		if (Array.isArray(ids)) {
			const requests$: Observable<IDonor>[] = [];
			const existing: IDonor[] = [];
			for (const id of ids) {
				const current = this.query.getEntity(id);
				// bodge to check if profile is more or less complete
				if (current && current.origin) {
					existing.push(current);
				} else {
					requests$.push(this.getGeneralProfileById(id));
				}
			}
			return requests$.length ? forkJoin(requests$).pipe(map((res) => res.concat(existing))) : of(existing);
		} else
			return this.query.hasEntity(ids)
				? this.query.selectMany([ids]).pipe(first())
				: this.getGeneralProfileById(ids).pipe(map((res) => [res]));
	}

	getGeneralProfileById(id: number) {
		return this.http.get<IDonor>(`/api/egg-donors/${id}?general=true`).pipe(tap((donor) => this.store.update(donor.id, donor)));
	}

	fetchDonorById(ids: number | number[], type: 'short' | 'long' = 'long'): Observable<{ [key: string]: any }[]> {
		const result = [];
		(Array.isArray(ids) ? ids : [ids]).forEach((id) => {
			result.push(this.http.get(`/api/egg-donors/${id}?long=${type === 'long'}`));
		});
		return forkJoin(result);
	}

	setOptions(offset: number, count: number, filters: { [key: string]: any }, favourites = false, sortBy = '', revision?: number) {
		this.options.next({ offset, count, filters, favourites, sortBy, revision });
	}

	fetchDonors(
		offset: number,
		count: number,
		filters?: { [key: string]: any },
		favourites = false,
		sortBy = '',
		order = '',
		force = false,
	) {
		this.store.setLoading(true);
		if (filters) {
			return this.http
				.put('/api/users/filters', filters, {
					params: {
						offset: offset.toString(),
						count: count.toString(),
						favourites: favourites.toString(),
						sort: sortBy,
						order,
					},
				})
				.pipe(
					tap(
						(res: { data: IDonor[]; count: number }) => {
							this.store.upsertMany(res.data);
							this.store.update({ count: res.count });
							this.store.setLoading(false);
						},
						(error) => {
							if (error.status === 401) {
								this.router.navigateByUrl('/login');
							}
						},
					),
				);
		} else {
			return this._request(
				this.http
					.get('/api/egg-donors', {
						params: {
							offset: offset.toString(),
							count: count.toString(),
							favourites: favourites.toString(),
							sort: sortBy,
							order,
						},
					})
					.pipe(
						tap(
							(res: { data: IDonor[]; count: number }) => {
								this.store.upsertMany(res.data);
								this.store.update({ count: res.count });
								this.store.setLoading(false);
							},
							(error) => {
								if (error.status === 401) {
									this.router.navigateByUrl('/login');
								}
							},
						),
					),
				force,
			);
		}
	}

	updateDonors(offset: number, count: number) {
		this.store.setLoading(true);
		this.http
			.get('/api/egg-donors', {
				params: {
					offset: offset.toString(),
					count: count.toString(),
				},
			})
			.subscribe(
				(res: { data: IDonor[]; count: number }) => {
					this.store.set(res.data);
					this.store.update({ count: res.count });
					this.store.setLoading(false);
				},
				(error) => {
					if (error.status === 401) {
						this.router.navigateByUrl('/login');
					}
				},
			);
	}

	getDonorPDF(id: number) {
		return this.http.get(`/api/egg-donors/pdf/${id}`, { responseType: 'arraybuffer', params: { hostname: location.origin } });
	}

	getAgencyById(ids: number | number[]): Observable<{ [key: string]: any }[]> {
		return new Observable((subs) => {
			const internal = this.query.select('agencies').subscribe((data) => {
				const result = [];
				(Array.isArray(ids) ? ids : [ids]).forEach((id) => {
					result.push(data.find((item) => item.id === id));
				});
				subs.next(result);
			});
			return {
				unsubscribe: () => {
					subs.unsubscribe();
					subs.complete();
					internal.unsubscribe();
				},
			};
		});
	}

	updateAgencies() {
		this.store.setLoading(true);
		this.http.get('/api/providers').subscribe(
			(res: { [key: string]: any }[]) => {
				this.store.update({ agencies: res });
				this.store.setLoading(false);
			},
			(error) => {
				if (error.status === 401) {
					this.router.navigateByUrl('/login');
				}
			},
		);
	}

	updateFilteredDonors(filters: any, offset: number, count: number) {
		this.store.setLoading(true);
		this.http
			.put('/api/users/filters', this.utils.mapFilters(filters), {
				params: {
					offset: offset.toString(),
					count: count.toString(),
				},
			})
			.subscribe(
				(res: { data: IDonor[]; count: number }) => {
					this.store.upsertMany(res.data);
					this.store.update({ count: res.count });
					this.store.setLoading(false);
				},
				(error) => {
					if (error.status === 401) {
						this.router.navigateByUrl('/login');
					}
				},
			);
	}

	createDeal(donor_id: number) {
		return this.http.post('/api/deals', { donor_id });
	}

	setImgCached(id: number, state: boolean = null) {
		if (state === null) {
			this.store.update(id, { img_cached: true });
		} else {
			this.store.update(id, { img_cached: state });
		}
	}

	setWatched(id: number) {
		this.watched.next(this.watched.value.add(id));
		localStorage.setItem('__watched', JSON.stringify(Array.from(this.watched.value)));
	}

	isInWatched(id: number) {
		return this.watched.value.has(id);
	}

	private _request<T extends Observable<any>>(observable: T, force = false) {
		return new Observable((subs) => {
			if (this._fetchRequest) this._fetchRequest.unsubscribe();
			this._fetchRequest = force ? null : observable.subscribe((data) => subs.next(data)).add(() => subs.next(null));
			return {
				unsubscribe: () => {
					subs.unsubscribe();
					subs.complete();
				},
			};
		}) as T;
	}

	private sponsoredStatus: BehaviorSubject<{ maxSponsored: number; sponsoredCount: number }>;

	async setPremiumStatus(eggDonorId: number, is_premium: boolean) {
		const res = await this.http
			.put<{ updated: boolean; maxSponsoredDonors: number; sponsoredDonors: number; message?: string }>(
				`/api/egg-donors/${eggDonorId}/set-premium`,
				{ is_premium },
			)
			.toPromise();
		const result = { maxSponsored: res.maxSponsoredDonors, sponsoredCount: res.sponsoredDonors };

		this.sponsoredStatus.next(result);

		return {
			...result,
			message: res.message,
			updated: res.updated,
		};
	}

	getPremiumStatus() {
		return new Promise<BehaviorSubject<{ maxSponsored: number; sponsoredCount: number }>>((resolve) => {
			if (!this.sponsoredStatus) {
				this.sponsoredStatus = new BehaviorSubject<{ maxSponsored: number; sponsoredCount: number }>({
					maxSponsored: 0,
					sponsoredCount: 0,
				});
				this.http
					.get<{ maxSponsoredDonors: number; sponsoredDonors: number }>(`/api/egg-donors/premium-status`)
					.subscribe((res) => {
						resolve(this.sponsoredStatus);
						this.sponsoredStatus.next({
							maxSponsored: res.maxSponsoredDonors,
							sponsoredCount: res.sponsoredDonors,
						});
					});
				return;
			}
			resolve(this.sponsoredStatus);
		});
	}
}

export enum DonorTypes {
	All = 'all',
	Fresh = 'fresh',
	FrozenEggs = 'frozen_eggs',
}

export enum DonationTypes {
	Open = 'open',
	SemiOpen = 'semi_open',
	Anonymous = 'anonymous',
}

export interface IFilters {
	previous_donor: boolean;
	video_available: boolean;
	donor_type: DonorTypes;
	donation_types: Set<DonationTypes>;
	eye_color: Set<string>;
	hair_color: Set<string>;
	height: [number?, number?];
	weight: [number?, number?];
	age: [number?, number?];
	country: string;
	state: string;
	education_level: string;
	race: Set<string>;
	religion: Set<string>;
	ethnicity: string[];
	blood: string[];
	high_school: [number?, number?];
	act: [number?, number?];
	sat: [number?, number?];
	college: [number?, number?];
	agency?: string;
	units?: 'imperial' | 'metric';
}

export const educationWeight = ['High School', 'Some College', 'Associate Degree', 'Bachelor Degree', 'Post Graduate'];
