<mat-form-field appearance="outline">
	<mat-label>{{label}}</mat-label>
	<input *ngIf="type === 'number'; else textInput" matInput [formControl]="control" type="number" (keypress)="inputInterceptor ? inputInterceptor($event) : null">
	<ng-template #textInput>
		<input [formControl]="control" matInput [type]="hidePass ? 'text' : type" [autocomplete]="autocomplete" (keypress)="inputInterceptor ? inputInterceptor($event) : null">
	</ng-template>
	<mat-icon matPrefix *ngIf="type === 'password'; else simplePrefix" fontSet="material-icons-outlined">lock</mat-icon>
	<ng-template #simplePrefix>
		<div matPrefix *ngIf="withPrefix">
			<ng-content select="[app-prefix]"></ng-content>
		</div>
	</ng-template>
	<button
		*ngIf='type == "password"; else simpleSuffix'
		matSuffix
		mat-icon-button
		type="button"
		[attr.aria-label]="'Hide password'"
		[attr.aria-pressed]="hidePass"
		(click)="hidePass = !hidePass">
		<mat-icon fontSet="material-icons-outlined">{{hidePass ? 'visibility' : 'visibility_off'}}</mat-icon>
	</button>
	<ng-template #simpleSuffix>
		<button matSuffix mat-icon-button disabled *ngIf="suffix">
			<mat-icon>
				<ng-content select="span"></ng-content>
			</mat-icon>
		</button>
	</ng-template>
	<mat-error *ngIf="errors">{{error}}</mat-error>
</mat-form-field>

<div *ngIf="showPassValidators && control" class="pass-check">
	<svg style="height: 0; pointer-events: none; position: absolute;">
		<linearGradient id="linear-gradient" x1="-0.085" y1="-0.059" x2="0.642" y2="0.782" gradientUnits="objectBoundingBox">
			<stop offset="0" stop-color="#9bcb6c"/>
			<stop offset="1" stop-color="#c4aedd"/>
		</linearGradient>
	</svg>
	<div>
		<app-checkbox [size]="12" [value]="control.value && !control.hasError('minlength')"></app-checkbox>
		<span>At least 8 characters</span>
	</div>
	<div>
		<app-checkbox [size]="12" [value]="control.value && !control.hasError('capital')"></app-checkbox>
		<span>At least 1 capital letter</span>
	</div>
	<div>
		<app-checkbox [size]="12" [value]="control.value && !control.hasError('lower')"></app-checkbox>
		<span>At least 1 lowercase letter</span>
	</div>
	<div>
		<app-checkbox [size]="12" [value]="control.value && !control.hasError('number')"></app-checkbox>
		<span>At least 1 number</span>
	</div>
</div>
