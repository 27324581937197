import {coerceNumberProperty} from '@angular/cdk/coercion';
import {CdkDragMove} from '@angular/cdk/drag-drop';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {AnimeInstance} from 'animejs';

export interface ChangeEvent {
	min: Weight;
	max: Weight;
	unit: 'imperial' | 'metric';
}

@Component({
	selector: 'app-weight-min-max',
	templateUrl: './weight-min-max.component.html',
	styleUrls: ['./min-max.component.scss'],
})
export class WeightMinMaxComponent implements AfterViewInit {
	@Input()
	withIcons = true;

	@Input()
	small = false;

	@Output()
	changed = new EventEmitter<ChangeEvent>();

	@ViewChild('rulerContainer', {static: false})
	rulerContainer: ElementRef<HTMLDivElement>;

	_bounds = {
		min_value: new Weight(undefined, this.units),
		max_value: new Weight(undefined, this.units),
	};

	@Input()
	set min(value: number) {
		this._bounds = {
			...this._bounds,
			min_value: new Weight(coerceNumberProperty(value), this.units),
		};
	}

	public get min() {
		return this._bounds.min_value.asUnits(this.units);
	}

	public get currentMin() {
		return this.value.min.asUnits(this.units);
	}

	@Input()
	set max(value: number) {
		this._bounds = {
			...this._bounds,
			max_value: new Weight(coerceNumberProperty(value), this.units),
		};
	}

	public get max() {
		return this._bounds.max_value.asUnits(this.units);
	}

	public get currentMax() {
		return this.value.max.asUnits(this.units);
	}

	@Input()
	set values([_min, _max]: number[]) {
		const min = _min ? new Weight(_min, "metric") : this._bounds.min_value;
		const max = _max ? new Weight(_max, "metric") : this._bounds.max_value;
		this.value = {min, max};
		setTimeout(() => this.updatePositionToValues());
	}

	@Input()
	set defaultUnit(units: 'metric' | 'imperial') {
		this.units = units;
	}

	readonly dotWidth = 8;

	value = {
		min: new Weight(undefined, this.units),
		max: new Weight(undefined, this.units),
	};

	@Input()
	units?: 'imperial' | 'metric';

	get labelsWithPad() {
		return this.px.max - this.px.min < 35;
	}

	animations: {
		min: AnimeInstance[];
		max: AnimeInstance[];
	} = {
		min: [],
		max: [],
	};
	px = {
		min: 0,
		max: 0,
	};

	dragPosRight = {x: 0, y: 0};
	public get rightX() {
		return this.dragPosRight.x;
	}
	public set rightX(value) {
		this.dragPosRight = {x: value, y: 0};
	}
	dragPosLeft = {x: 0, y: 0};

	public get leftX() {
		return this.dragPosLeft.x;
	}
	public set leftX(value) {
		this.dragPosLeft = {x: value, y: 0};
	}

	get scale(): ('a')[] {
		const totalSteps = this.max - this.min;
		if (isNaN(totalSteps)) {
			return [];
		}
		return Array.from(new Array(totalSteps)).fill('a');
	}

	get width() {
		return this.rulerContainer?.nativeElement.clientWidth - 2 * this.dotWidth || 1;
	}

	get perc() {
		return {
			min: this.px.min / this.width / 2 + 0.5,
			max: this.px.max / this.width / 2 + 0.5,
		};
	}

	constructor() {}

	updatePositionToValues() {
		const absoluteMin = this.currentMin - this.min;
		const absoluteMax = this.currentMax - this.min;

		const maxAbsoluteValue = this.max - this.min;

		const minX = (absoluteMin * this.width) / maxAbsoluteValue;
		const maxX = (absoluteMax * this.width) / maxAbsoluteValue;

		this.leftX = minX;
		this.rightX = maxX;

		this.px = {
			min: this.leftX,
			max: this.rightX,
		};

		const perc = this.perc;

		setTimeout(() => {
			this.animations.min.forEach((item) => item.seek(item.duration * perc.min));
			this.animations.max.forEach((item) => item.seek(item.duration * perc.max));
		});
	}

	getFinalX(x: number) {
		const stepWidth = this.width / (this.scale.length - 1);

		const shouldRoundLeft = x % stepWidth < stepWidth / 2;

		return x - (x % stepWidth) + (shouldRoundLeft ? 0 : stepWidth);
	}

	onDragEnded() {
		this.leftX = this.getFinalX(this.px.min);
		this.rightX = this.getFinalX(this.px.max);
		this._onDragMoved('left', this.leftX);
		this._onDragMoved('right', this.rightX);

		this.onChange();
	}

	convertXToValue(x: number) {
		const absoluteMax = this.max - this.min;

		return this.min + (x * absoluteMax) / this.width;
	}

	onDragMoved(dot: 'left' | 'right', event: CdkDragMove) {
		this._onDragMoved(dot, event.source.getFreeDragPosition().x);

		const perc = this.perc;

		this.animations.min.forEach((item) => item.seek(item.duration * perc.min));
		this.animations.max.forEach((item) => item.seek(item.duration * perc.max));
	}

	_onDragMoved(dot: 'left' | 'right', x: number, skipOverlapCheck = false) {
		const stepWidth = this.width / this.scale.length;

		let value = new Weight(this.convertXToValue(x), this.units);

		if (dot === 'left') {
			if (value.asUnits(this.units) < this.min) {
				value = this._bounds.min_value;
			}
			this.value = {
				min: value,
				max: this.value.max,
			};
			this.px.min = x;

			// check if left dot will overlap right one
			if (!skipOverlapCheck && x > this.px.max - stepWidth) {
				// never allow it to go outside of the container
				this.rightX = Math.min(x + stepWidth, this.width);
				this._onDragMoved('right', this.rightX, true);
			}
		} else {
			if (value.asUnits(this.units) > this.max) {
				value = this._bounds.max_value;
			}
			this.value = {
				min: this.value.min,
				max: value,
			};
			this.px.max = x;

			// check if right dot will overlap left one
			if (!skipOverlapCheck && x < this.px.min + stepWidth) {
				// never allow it to go outside of the container
				this.leftX = Math.max(x - stepWidth, 0);
				this._onDragMoved('left', this.leftX, true);
			}
		}
	}

	onSwitch(e: {label: string; value: boolean}) {
		this.units = e.value ? 'metric' : 'imperial';
		this.onChange();
	}

	onChange() {
		this.changed.emit({
			min: this.value.min,
			max: this.value.max,
			unit: this.units,
		});
	}

	ngAfterViewInit(): void {
		document.querySelectorAll<SVGPathElement>('path[fill]').forEach((item) => {
			const current = item.getAttribute('fill');
			if (!current.includes('http') && current.includes('url')) item.setAttribute('fill', `url(${location.href + current.slice(4, -1)}`);
		});
		if (this.withIcons === false && this.rulerContainer) {
			const scale = this.scale.length;
			const width = this.rulerContainer.nativeElement.clientWidth;
			const step =
				width / scale + (this.isImperial ? 0 : -0.18);
			const delta = this._bounds.max_value.kg - this._bounds.min_value.kg;
			const max_value = (this.value.max.kg - this._bounds.min_value.kg) / delta;
			const min_value = (this.value.min.kg - this._bounds.min_value.kg) / delta;
			const next_max = Math.round(Math.round((width * max_value) / step) * step);
			const next_min = Math.round(Math.round((width * min_value) / step) * step);
			this.rightX = next_max - step;
			this.leftX = next_min;
		}

		this.animations.min.forEach((item) => item.seek((item.duration * 60) / 100));
		this.animations.max.forEach((item) => item.seek(item.duration));
	}

	get isImperial() {
		return this.units === 'imperial';
	}
}

export class Weight {
	private _kg = 0;
	private _pound = 0;

	constructor(weight?: number, units: "imperial" | "metric" = 'metric') {
		if (!weight) {
			return;
		}
		if (units === "imperial") {
			this.pound = weight;
		}
		if (units === "metric") {
			this.kg = weight;
		}
	}

	public asUnits(units: "imperial" | "metric") {
		if (units === "imperial") {
			return this.pound;
		}
		if (units === "metric") {
			return this.kg;
		}
	}

	public asFormattedUnits(units: "imperial" | "metric") {
		if (units === "imperial") {
			return `${this.pound} lbs`;
		}
		if (units === "metric") {
			return `${this.kg} kg`;
		}
	}

	get pound(): number {
		return this._pound;
	}
	set pound(value: number) {
		this._kg = Math.round(value / 2.205);
		this._pound = Math.round(value);
	}

	get kg(): number {
		return this._kg;
	}
	set kg(value: number) {
		this._pound = Math.round(value * 2.205);
		this._kg = Math.round(value);
	}
}
