import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation,
	Optional,
	ViewChild,
	TemplateRef,
	ElementRef,
	HostBinding,
	Self,
	ViewContainerRef,
	ChangeDetectionStrategy,
	Directive,
} from '@angular/core';
import {FormControl, ValidatorFn, ControlContainer, NgControl, ControlValueAccessor} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {TemplatePortal} from '@angular/cdk/portal';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Subscription, Subject, Observable, of} from 'rxjs';
import {startWith, map, distinctUntilChanged, debounceTime, shareReplay, tap} from 'rxjs/operators';

export interface Option {
	label: string;
	value?: string;
	icon?: string;
	color?: string;
	isGroup?: boolean;
	isChild?: boolean;
	values?: Option[];
}

@Component({
	selector: 'app-form-field [type="select"]',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SelectFieldComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild('selectFormField')
	public selectFormField: SelectFormFieldFabric<string>;
	@Input()
	label: string;
	@Input()
	options: Option[] = [];
	@Input()
	validation: ValidatorFn[] = [];
	@Input()
	errors: Record<string, string> = {};
	@Input()
	multiple = false;
	@Input()
	value = '';
	@Output()
	valueChange = new EventEmitter<string>();

	@HostBinding('attr.disabled')
	@Input()
	disabled = false;

	@Input()
	controlName: string;

	@Input()
	withSearch = false;

	@Input()
	height = 225;

	control = new FormControl(this.value);
	change$: Subscription;

	constructor(@Optional() private controlContainer: ControlContainer) {}

	ngOnInit() {
		if (this.controlName) {
			this.control = this.controlContainer.control.get(this.controlName) as FormControl;
		} else {
			this.control.setValidators(this.validation);
			this.change$ = this.control.valueChanges.pipe(distinctUntilChanged()).subscribe(() => this.valueChange.emit(this.control.value));
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value && changes.value.previousValue !== changes.value.currentValue)
			this.control.setValue(changes.value.currentValue, {emitEvent: false});
	}

	ngOnDestroy() {
		this.change$?.unsubscribe();
	}

	get error() {
		for (const error in this.control.errors) {
			if (this.errors[error]) return this.errors[error];
		}
	}
}

@Directive()
export class SelectFormFieldFabric<T> implements MatFormFieldControl<T>, ControlValueAccessor, OnDestroy {
	static nextId = 0;
	@HostBinding()
	id = `app-select-form-field-${SelectFormFieldComponent.nextId++}`;

	@ViewChild('dropdownPortal')
	dropdownPortal: TemplateRef<null>;
	@ViewChild('searchInput')
	searchInput: ElementRef<HTMLInputElement>;

	@Input()
	private _value_1 = null;
	public get value() {
		return this._value_1;
	}
	public set value(value) {
		this._value_1 = value;
	}

	@Input()
	get placeholder() {
		return this._placeholder;
	}
	set placeholder(item: string) {
		this._placeholder = item;
		this.stateChanges.next();
	}
	private _placeholder = '';

	@Input()
	get required() {
		return this._required;
	}
	set required(item: boolean) {
		this._required = coerceBooleanProperty(item);
		this.stateChanges.next();
	}
	private _required = false;

	@HostBinding('attr.disabled')
	@Input()
	get disabled() {
		return this._disabled;
	}
	set disabled(item: boolean) {
		this._disabled = coerceBooleanProperty(item);
		this.stateChanges.next();
	}
	private _disabled = false;

	@Input()
	height = 256;

	@HostBinding('attr.aria-describedby')
	describedBy = '';

	@Input()
	public get options(): Option[] {
		return this._options;
	}
	public set options(value: Option[]) {
		this._options = value;
		this.searchControl.setValue(this.searchControl.value);
	}
	protected _options: Option[];

	get empty() {
		return !this.value;
	}
	get shouldLabelFloat() {
		return this.isOpened || !this.empty;
	}
	get focused() {
		return this.isOpened;
	}

	get errorState(): boolean {
		return this.ngControl.invalid && this.touched;
	}

	touched = false;

	controlType = 'select-form-field';

	isOpened = false;

	stateChanges = new Subject<void>();
	overlayRef: OverlayRef = null;
	backdropClick$: Subscription;

	searchControl?: FormControl;

	_onChange: (_: any) => void = null;
	_onTouched: () => void = null;

	constructor(
		@Optional() @Self() public ngControl: NgControl,
		private elementRef: ElementRef,
		private overlay: Overlay,
		private viewContainerRef: ViewContainerRef,
	) {
		if (this.ngControl != null) this.ngControl.valueAccessor = this;
	}

	onWindowScroll = (event: Event) => {
		if (this.isOpened) console.log(event);
	};

	onSelect(option: Option) {
		this.touched = true;
		this.value = option.value;
		this.overlayRef?.dispose();
		this.backdropClick$.unsubscribe();
		this.isOpened = false;
	}

	setDescribedByIds(ids: string[]) {
		this.describedBy = ids.join(' ');
	}

	onContainerClick(event: MouseEvent) {
		if (this.isOpened) {
			if (this.searchInput) this.searchInput.nativeElement.focus();
			return;
		}
		this.isOpened = true;
		let fieldFlex: HTMLElement = this.elementRef.nativeElement;
		while (fieldFlex && !fieldFlex.classList.contains('mat-form-field-flex')) fieldFlex = fieldFlex.parentElement;
		fieldFlex = fieldFlex || this.elementRef.nativeElement;
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(fieldFlex)
			.withPositions([
				{
					originX: 'center',
					originY: 'bottom',
					overlayX: 'center',
					overlayY: 'top',
				},
				{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom',
				},
			]);
		this.overlayRef = this.overlay.create({
			positionStrategy,
			scrollStrategy: this.overlay.scrollStrategies.block(),
			hasBackdrop: true,
			disposeOnNavigation: true,
			width: fieldFlex.getBoundingClientRect().width,
			backdropClass: 'cdk-overlay-transparent-backdrop',
		});
		const portal = new TemplatePortal(this.dropdownPortal, this.viewContainerRef);
		this.backdropClick$ = this.overlayRef.backdropClick().subscribe(() => {
			this.overlayRef.dispose();
			this.backdropClick$.unsubscribe();
			if (this.searchControl) this.searchControl.setValue('');
			this.isOpened = false;
			this.stateChanges.next();
		});
		this.overlayRef.attach(portal);
		if (this.searchInput) this.searchInput.nativeElement.focus();
	}

	writeValue(obj: any) {
		this.value = obj;
	}

	registerOnChange(fn: (_: any) => void) {
		this._onChange = fn;
	}

	registerOnTouched(fn: any) {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	ngOnDestroy() {
		this.stateChanges.complete();
	}
}

@Component({
	selector: 'app-select-form-field :not([multiple])',
	providers: [{provide: MatFormFieldControl, useExisting: SelectFormFieldComponent}],
	templateUrl: './select-field.component.html',
	styleUrls: ['./select-field.component.scss'],
})
export class SelectFormFieldComponent extends SelectFormFieldFabric<string> implements OnDestroy {
	@HostBinding('class.with-value')
	get withValue() {
		return !this.empty;
	}
	@HostBinding('class.opened')
	get isDropdownOpened() {
		return this.isOpened;
	}

	@Input()
	public get withSearch() {
		return this._withSearch;
	}
	public set withSearch(value) {
		this._withSearch = value;
		this.updateFilteredOptions();
	}
	private _withSearch = false;

	@Input()
	get value() {
		return this._value;
	}
	set value(item: string) {
		this._value = item;
		if (this._onChange) this._onChange(item);
		if (this._onTouched) this._onTouched();
		this.stateChanges?.next();
	}
	_value = '';

	get empty() {
		return !this._value;
	}
	get selectedLabel() {
		if (!this.value) return '';
		for (const item of this.options) {
			if (item.values) {
				for (const subItem of item.values) if (subItem.value === this.value) return subItem.label;
			}
			if (item.value === this.value) return item.label;
		}
		return this.value;
	}

	public get options(): Option[] {
		return this._options;
	}
	public set options(value: Option[]) {
		this._options = value;
		this.upackedOptions = value;
		this.searchControl.setValue(this.searchControl.value);
	}

	private _upackedOptions: Option[] = [];
	public get upackedOptions(): Option[] {
		return this._upackedOptions;
	}
	public set upackedOptions(value: Option[]) {
		this._upackedOptions = value?.flatMap((item) =>
			item.values ? [{...item, isGroup: true}, ...item.values.map((option) => ({...option, isChild: true}))] : item,
		);
	}

	searchControl = new FormControl('');
	filteredOptions$: Observable<Option[]>;

	onSelect(option: Option) {
		this.touched = true;
		this.value = option.value;
		this.overlayRef?.dispose();
		this.backdropClick$.unsubscribe();
		this.searchControl.setValue('', {emitEvent: false});
		this.isOpened = false;
	}

	updateFilteredOptions() {
		this.filteredOptions$ = this.withSearch
			? this.searchControl.valueChanges.pipe(
					startWith(this.searchControl.value || ''),
					debounceTime(100),
					map((value) => value.trim().toLowerCase()),
					distinctUntilChanged(),
					map((value) =>
						value
							? this.options
									.map((item) => {
										if (item.label.toLowerCase().includes(value)) return item;
										if (item.values) {
											item = {...item};
											item.values = item.values.filter((subItem) => subItem.label.toLowerCase().includes(value));
											return item.values.length ? item : null;
										}
										return null;
									})
									.filter(Boolean)
							: this.options,
					),
					shareReplay(1),
					startWith(this.options),
					tap((value) => (this.upackedOptions = value)),
					map(() => this.upackedOptions),
			  )
			: this.searchControl.valueChanges.pipe(
					startWith(''),
					map(() => this.options),
					shareReplay(1),
			  );
	}
}

@Component({
	selector: 'app-select-form-field [multiple]',
	providers: [{provide: MatFormFieldControl, useExisting: MultipleSelectFormFieldComponent}],
	templateUrl: './multiple-select-field.component.html',
	styleUrls: ['./multiple-select-field.component.scss'],
})
export class MultipleSelectFormFieldComponent extends SelectFormFieldFabric<string[]> {
	@HostBinding('class.with-value')
	get withValue() {
		return !this.empty;
	}
	@HostBinding('class.opened')
	get isDropdownOpened() {
		return this.isOpened;
	}

	@Input()
	withSearch = false;

	@Input()
	get value() {
		return Array.from(this._value);
	}
	set value(item: string[]) {
		this._value = new Set(item);
		if (this._onChange) this._onChange(item);
		if (this._onTouched) this._onTouched();
		this.stateChanges?.next();
	}
	_value = new Set<string>();

	get empty() {
		return this._value.size === 0;
	}
	get selectedLabel() {
		return this.value ? this.options.filter((item) => this._value.has(item.value)).map((item) => item.label || item.value) : [];
	}

	searchControl = new FormControl('');
	filteredOptions$: Observable<Option[]> = this.searchControl.valueChanges.pipe(
		debounceTime(400),
		startWith(this.searchControl.value || ''),
		map((value) => value.trim().toLowerCase()),
		map((value) => (value ? this.options.filter((item) => item.label.toLowerCase().includes(value)) : this.options)),
	);

	onSelect(option: Option) {
		this.touched = true;
		if (this._value.has(option.value)) this._value.delete(option.value);
		else this._value.add(option.value);
		this.value = Array.from(this._value);
	}

	clear() {
		this._value.clear();
		this.value = [];
	}
}
