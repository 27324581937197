import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {map} from 'rxjs/operators';
import {ProviderType} from '../config';
import {ProviderContactRole} from './contact-agency.service';
import {Annexe} from './parent.service';

export enum UnifiedDealStatus {
	PREVIEW = 'Preview',
	NEW = 'New',
	CHATING = 'Chatting',
	NO_REPLY = 'No Reply',
	BOOKED = 'Booked',
	CANCELED = 'Canceled',
	CONSULTATION_PHONE_CALL = 'Consultation Phone Call',
	EGG_DONOR_UNAVAILABLE = 'Egg Donor Unavailable',
	DONOR_MATHING = 'Donor Matching',
	AGENCY_CONTRACT_SIGNING = 'Agency Contract Signed',
	NEGOTIATING_EGG_DONOR_CONTRACT = 'Negotiating Egg Donor Contract',
	EGG_DONOR_CONTRACT_SIGNING = 'Egg Donor Contract Signing',
	COMPLETE = 'Completed',
}

export interface Deal {
	id: number;
	type: ProviderType;
	status: UnifiedDealStatus;
	provider_id: number;
	egg_donor_id: number | null;
	user_id: number;
	created_at: Date;
	updated_at: Date;
}

export interface Appointment {
	id: number;
	appointment_time: Date;
	deal_id: number;
	event_id: string;
	created_at: Date;
	updated_at: Date;
}

export interface DetailsForProvider {
	appointments: Appointment[];
	user: Record<string, any>;
	annexe: Record<ProviderType, Annexe>;
}

export interface DealExtended extends Deal {
	user: {
		address: string;
		email: string;
		first_name: string;
		last_name: string;
		partner_first_name: string;
		partner_last_name: string;
		phone: string;
	};
	egg_donor: {
		id: number;
		origin: string;
		origin_id: string;
		theirsId: string;
	};
	chat: {
		id: string;
	};
}

@Injectable({
	providedIn: 'root',
})
export class DealsService {
	constructor(private http: HttpClient) {}

	createPreviewDeal(providerId: number) {
		return this.http.post('/api/v2/deals', {providerId, isPreview: true});
	}

	dealTypeToContacyRole(dealType: ProviderType) {
		switch (dealType) {
			case ProviderType.EGG_AGENCY:
				return ProviderContactRole.EGG_DONOR_AGENCY_COORDINATOR;
			case ProviderType.SURROGACY_AGENCY:
				return ProviderContactRole.SURROGACY_AGENCY_COORDINATOR;
			case ProviderType.IVF_CLINIC:
				return ProviderContactRole.IVF_CLINIC_COORDINATOR;
			default:
				return ProviderContactRole.OWNER;
		}
	}

	changeDealStatus(id: number, status: UnifiedDealStatus) {
		return this.http.put('/api/v2/deals', {id, status});
	}

	getDetailsForProvider(userId: number) {
		return this.http.get<DetailsForProvider>('/api/m/details-for-provider', {params: {userId: userId.toString()}});
	}

	getDeals(type: ProviderType, sort?: string, order?: string, page = 0, count = 30, filter: Record<string, any> = {}) {
		const textFilters = {
			filter: filter.text,
			filter_by_status: filter.status,
			filter_by_company: filter.company,
		};
		for (const item in textFilters) {
			if (!textFilters[item]) delete textFilters[item];
		}

		return this.http
			.get<{count: number; data: DealExtended[]; page?: number}>('/api/v2/deals', {
				params: {
					type,
					sort,
					order,
					page: page.toString(),
					count: count.toString(),
					leads_only: (filter.leads || false).toString(),
					deals_only: (filter.deals || false).toString(),
					id: filter.id,
					...textFilters,
				},
			})
			.pipe(
				map((res) => {
					for (const item of res.data) {
						item.created_at = new Date(item.created_at);
						item.updated_at = new Date(item.updated_at);
						// !fixme
						item['user.name'] = `${item['user.first_name']} ${item['user.last_name']}`;
						item['user.partner_name'] = item['user.partner_first_name']
							? `${item['user.partner_first_name']} ${item['user.partner_last_name']}`
							: '';
					}
					return res;
				}),
			);
	}
}
