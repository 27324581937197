<svg
	data-name="Layer 1"
	xmlns="http://www.w3.org/2000/svg"
	viewBox="-1 -1 104.09 16.91"
	[class.readonly]="mode === 'show'"
	[matTooltip]="mode === 'show' ? _rating || rating : ''">
	<defs>
		<linearGradient gradientUnits="userSpaceOnUse" [id]="rand">
			<stop [attr.offset]="((_rating || rating) / 5) || 0" stop-color="#f9b803" stop-opacity="1"></stop>
			<stop attr.offset="1" stop-color="transparent" stop-opacity="1"></stop>
		</linearGradient>
	</defs>
	<g attr.fill="url(#{{rand}})">
		<path data-name="Path 8151-5" d="M37,6.89a1.16,1.16,0,0,0,0-1.62A1,1,0,0,0,36.34,5l-4-.54c-.11,0-.11-.1-.22-.1L30.4.86A1.17,1.17,0,0,0,28.86.32a1.36,1.36,0,0,0-.55.54L26.55,4.2c0,.11,0,.11-.11.21l-4,.54a1.12,1.12,0,0,0-1,1.29.77.77,0,0,0,.33.65l2.86,2.69c.11,0,.11.11.11.21l-.66,3.88a1.12,1.12,0,0,0,1,1.29,1.87,1.87,0,0,0,.77-.11L29.41,13h.22l3.52,1.83a1.2,1.2,0,0,0,1.65-.54,1.55,1.55,0,0,0,.11-.75l-.77-3.77c0-.1,0-.1.11-.21Z" transform="translate(0.09 -0.09)"/>
		<path data-name="Path 8151-5" d="M15.44,6.89a1.16,1.16,0,0,0,0-1.62A1,1,0,0,0,14.78,5l-4-.54c-.11,0-.11-.1-.22-.1L9,.75A1.3,1.3,0,0,0,7.3.22a1.28,1.28,0,0,0-.55.53L5,4.2c0,.11-.11.11-.22.11L.92,5a1.12,1.12,0,0,0-1,1.29.77.77,0,0,0,.33.65L3.12,9.58c0,.11.11.21,0,.21l-.66,3.88a1.12,1.12,0,0,0,1,1.29,1.87,1.87,0,0,0,.77-.11L7.74,13H8l3.52,1.83a1.2,1.2,0,0,0,1.65-.54,1.55,1.55,0,0,0,.11-.75l-.77-3.77c0-.1,0-.1.11-.21Z" transform="translate(0.09 -0.09)"/>
		<path data-name="Path 8151-5" d="M58.55,6.89a1.16,1.16,0,0,0,0-1.62A1,1,0,0,0,57.89,5L54,4.41c-.11,0-.11-.1-.22-.1L52.06.86A1.17,1.17,0,0,0,50.52.32,1.36,1.36,0,0,0,50,.86L48.21,4.31c0,.1-.11.1-.22.1L44,5a1.13,1.13,0,0,0-1,1.29.77.77,0,0,0,.33.65l2.86,2.69a.33.33,0,0,1,.11.21l-.66,3.88a1.12,1.12,0,0,0,1,1.29,1.87,1.87,0,0,0,.77-.11L51,13h.22l3.52,1.83a1.2,1.2,0,0,0,1.65-.54,1.55,1.55,0,0,0,.11-.75l-.77-3.77c0-.1,0-.1.11-.21Z" transform="translate(0.09 -0.09)"/>
		<path data-name="Path 8151-5" d="M80.11,6.89a1.16,1.16,0,0,0,0-1.62A1,1,0,0,0,79.45,5L75.6,4.41c-.11,0-.11-.1-.22-.1L73.62.86A1.17,1.17,0,0,0,72.08.32a1.36,1.36,0,0,0-.55.54L69.77,4.31c0,.1-.11.1-.22.1l-4,.54a1.12,1.12,0,0,0-1,1.29.77.77,0,0,0,.33.65l2.86,2.69c.11,0,.11.11.11.21l-.66,3.88a1.12,1.12,0,0,0,1,1.29,1.87,1.87,0,0,0,.77-.11L72.52,13h.22l3.52,1.83a1.2,1.2,0,0,0,1.65-.54,1.55,1.55,0,0,0,.11-.75l-.77-3.77c0-.1,0-.1.11-.21Z" transform="translate(0.09 -0.09)"/>
		<path data-name="Path 8151-5" d="M101.67,6.83a1.15,1.15,0,0,0,0-1.61A.93.93,0,0,0,101,4.9l-3.85-.54c-.11,0-.11-.11-.22-.11L95.18.81A1.17,1.17,0,0,0,93.64.27a1.3,1.3,0,0,0-.55.54L91.33,4.25c0,.11-.11.11-.22.11l-4,.54a1.11,1.11,0,0,0-1,1.29.75.75,0,0,0,.33.64l2.86,2.69c.11,0,.11.11.11.22l-.66,3.87a1.12,1.12,0,0,0,1,1.3,1.87,1.87,0,0,0,.77-.11L94.08,13h.22l3.52,1.83a1.2,1.2,0,0,0,1.65-.54,1.57,1.57,0,0,0,.11-.75l-.77-3.77c0-.11,0-.11.11-.22Z" transform="translate(0.09 -0.09)"/>
	</g>
</svg>
