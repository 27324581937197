<mat-form-field appearance="outline">
	<mat-label>{{label}}</mat-label>
	<input
		[formControl]="_control"
		matInput
		(input)="onDateChange($event)"
		inputmode="numeric"
		placeholder="MM/DD/YYYY"
		autocomplete="bday">
	<mat-error>{{getError()}}</mat-error>
	<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	<mat-datepicker #picker [touchUi]="touchUI" startView="multi-year"></mat-datepicker>
	<input
		type="hidden"
		[max]="now"
		[matDatepicker]="picker"
		(dateChange)="onPickerChange($event.value)">
</mat-form-field>
