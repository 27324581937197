<ng-container *ngIf="(event$ | async) === 'confirm'">
	<div class="logo">
		<img src="assets/logo-solo.png" />
		<div>GoStork</div>
	</div>

	<div class="event">
		<b>Thank you!</b> <br />
		Your appointment has been confirmed.
	</div>
</ng-container>
<ng-container *ngIf="(event$ | async) !== 'confirm'">
	<h2 *ngIf="(event$ | async) === 'cancel'">
		Your Concierge Call with GoStork was Cancelled. <br />
		Want to Reschedule?
	</h2>
	<h2 *ngIf="(event$ | async) !== 'cancel'">
		{{ (event$ | async) === 'reschedule' ? 'Reschedule' : 'Book' }} Your Concierge Call with GoStork
	</h2>

	<div class="calendars">
		<ng-container [ngSwitch]="viewState">
			<ng-container *ngSwitchCase="CalendarViewState.SCHEDULE">
				<div *ngFor="let config of calendarConfigs$ | async; let index = index" @fadeInOut class="calendar">


					<div class="name">
						<b> {{ config.first_name }} {{ config.last_name }} </b>
					</div>

					<div *ngIf="config.duration_of_meetings.length > 1; else exactDuration" class="duration">
						<div>
							<b>How long do you need?</b>
						</div>
						<mat-button-toggle-group [value]="duration" (change)="duration = $event.value">
							<mat-button-toggle *ngFor="let d of durations[index]" [value]="d.value">
								{{ d.label }}
							</mat-button-toggle>
						</mat-button-toggle-group>
					</div>

					<ng-template #exactDuration>
						<div class="duration">
							<div>
								<b>
									You will have <span class="duration__text">{{ durations[index][0].label }}</span>
								</b>
							</div>
							<div><b>for your call</b></div>
							<div></div>
						</div>
					</ng-template>
					<div class="calendar__timezone">
						<app-form-field type="select" [withSearch]="true" [options]="tzones" [value]="timezone" (valueChange)="changeTimezone($event)">
							<div matPrefix>Timezone:</div>
						</app-form-field>
					</div>
					<app-calendar
						[timezone]="timezone"
						[adminConfig]="config"
						[duration]="duration"
						[calendarId]="config.calendar_id"
						(cellClick)="onTimeSelect($event, config.calendar_id)"
					></app-calendar>
				</div>
			</ng-container>

			<div *ngSwitchCase="CalendarViewState.CONFIRM" class="confirm" @fadeInOut>
				<div *ngIf="bookingCalendar$ | async as bookingCalendar" class="name">
					<b> {{ bookingCalendar.first_name }} {{ bookingCalendar.last_name }} </b>
				</div>

				<mat-form-field appearance="outline" class="area" (click)="viewState = CalendarViewState.SCHEDULE">
					<mat-label>Date & Time</mat-label>
					<input matInput [value]="bookingTime.toJSDate() | timezoneDate: 'EEEE, MMMM d \'at\' h:mm a': timezone" required />
					<span matSuffix>Edit</span>
				</mat-form-field>
				<button mat-flat-button color="primary" [disabled]="loading" (click)="confirmAppointment()">Confirm</button>
			</div>

			<div *ngSwitchCase="CalendarViewState.COMPLETE" class="complete">Thank you for scheduling a concierge call</div>
		</ng-container>
	</div>
</ng-container>
<div class="bg">
	<img src="assets/profile_background.svg" alt="" />
</div>
