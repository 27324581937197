import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

import { AuthStoreService } from 'src/app/services/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import { LocationUpdateService } from '@/components/calendar/calendar-list/LocationUpdateService';
import { CheckoutSources } from '@/consts/config';
import { setCSSVar } from '@/helpers/getCssVar';
import { nanoid } from '@/helpers/nanoid';
import { AnalyticsService } from '@/services/analytics.service';
import { CheckoutService } from '@/services/checkout.service';
import { DonorsStoreService } from '@/services/donors.service';
import { ParentStoreService } from '@/services/parent.service';
import { SurrogatesStoreService } from '@/services/surrogates.service';
import { UtilsService } from '@/services/utils.service';
import { environment } from '../../../environments/environment';
import { clearAkita } from '../../../main';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	@ViewChild('dropdownRef')
	dropdownRef: TemplateRef<null>;
	@ViewChild('languageDropdownRef')
	languageDropdownRef: TemplateRef<null>;
	@ViewChild('premium')
	premium: TemplateRef<null>;

	stripe_customer_id: string;
	stripe_subscription_id: string;
	stripe_subscription_status: string;
	showSubscriptionButtonValue: Boolean = false;
	stripe_subscription_can_subscribe: Boolean = false;

	premiumStatus$ = this.authService.state.pipe(startWith(null as boolean));
	loginStatus$ = this.authService.state.pipe(startWith(null as boolean));
	authState$ = this.authService.value$;
	userType$ = this.loginStatus$.pipe(map(() => this.authService.getType()));

	name$ = this.authService.getName();

	unreadCount$ = this.chatService.unreadMessages$;

	page$ = this.router.events.pipe(
		filter((event) => event instanceof NavigationEnd),
		map((event: NavigationEnd) =>
			event.url.includes('?')
				? event.url.slice(0, event.url.indexOf('?'))
				: event.url.includes('questionnaire')
				? event.url.slice(0, event.url.lastIndexOf('/'))
				: event.url,
		),
		shareReplay(1),
	);

	showNavigationOnMobile = false;

	categories = {
		parent: [
			{ path: '/egg-donor', label: 'Find Egg Donors' },
			{ path: '/surrogacy', label: 'Find Surrogacy Agencies' },
			{ path: '/ivf', label: 'Find Fertility Clinics' },
		],
		resources: [
			{ path: `${this.getRoot()}/resources`, label: 'Helpful Articles' },
			{ path: `${this.getRoot()}/blog-home`, label: 'Our Blog' },
			{ path: `${this.getRoot()}/faq`, label: 'FAQ' },
			{ path: `${this.getRoot()}/us-abortion-policies/`, label: 'US Abortion Policies' },
			{ path: `${this.getRoot()}/united-states-surrogacy-law-map/`, label: 'US Surrogacy Law Map' },
		],
		admin: [
			{ path: '/providers/list', label: 'Providers' },
			{ path: '/users/list', label: 'Users' },
			{ path: '/users/admin', label: 'Admin' },
			{ path: '/providers/deals', label: 'Leads & Deals' },
		],
		about: [
			{ path: `${this.getRoot()}/about-us/`, label: 'About Us' },
			{ path: `${this.getRoot()}/resolve/`, label: 'GoStork Gives Back' },
		],
	};
	languages = [];

	get activeLanguage() {
		const currentCode = Localize.getLanguage();
		return this.languages.find(({ code }) => code === currentCode);
	}

	activeOverlayRef: OverlayRef;
	isSmallScreen$ = this.utils.isSmallScreen;

	locationUpdateSubscription: Subscription;

	constructor(
		private authService: AuthStoreService,
		private router: Router,
		private zone: NgZone,
		private chatService: ChatService,
		private analytics: AnalyticsService,
		private overlay: Overlay,
		private vc: ViewContainerRef,
		private utils: UtilsService,
		private parentService: ParentStoreService,
		private donorStoreService: DonorsStoreService,
		private surrogateStoreService: SurrogatesStoreService,
		private checkoutService: CheckoutService,
		private locationUpdateService: LocationUpdateService,
	) {}

	updatedStatus(selectedLocation) {
		if (selectedLocation !== this.showSubscriptionButtonValue) {
			this.showSubscriptionButtonValue = selectedLocation;
		}
		//this.showSubscriptionButtonValue = selectedLocation;
		console.log(`Donor Page : ${this.showSubscriptionButtonValue}`);
	}

	ngOnDestroy(): void {
		this.locationUpdateSubscription?.unsubscribe();
	}

	ngOnInit() {
		this.authService.getUser().subscribe((user) => {
			this.stripe_subscription_can_subscribe = user.stripe_subscription_can_subscribe;
			this.stripe_customer_id = user.stripe_customer_id;
			this.stripe_subscription_id = user.stripe_subscription_id;
			this.stripe_subscription_status = user.stripe_subscription_status;
		});

		this.locationUpdateSubscription = this.locationUpdateService.data.subscribe((response) => {
			this.updatedStatus(response); // you will receive the data from sender component here.
		});

		Localize.getAvailableLanguages((error, languages) => {
			if (error) {
				throw error;
			}

			this.languages = languages.map((item) => ({
				...item,
				flag: this.mapLangToFlag(item.code),
			}));
			setCSSVar('--language', Localize.getLanguage());
			setCSSVar('--direction', Localize.getLanguage() === 'he' ? 'rtl' : 'ltr');
		});
	}

	onInterested() {
		this.checkoutService.openDialog(CheckoutSources.EggDonor);
	}

	showButtonSubscription() {
		this.showSubscriptionButtonValue = true;
	}

	mapLangToFlag(lang: string) {
		const country = {
			en: 'us',
			he: 'il',
		}[lang];
		return `https://flagcdn.com/${country}.svg`;
	}

	openDropdown(el: HTMLElement, category: keyof HeaderComponent['categories']) {
		this.activeOverlayRef?.dispose();
		this.activeOverlayRef = this.overlay.create({
			disposeOnNavigation: true,
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(el)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
						offsetY: -30,
						offsetX: -24,
					},
				]),
		});
		const dropdownPortal = new TemplatePortal(this.dropdownRef, this.vc, { category });
		this.activeOverlayRef.attach(dropdownPortal);
	}

	hideDropdown(event: MouseEvent) {
		let movedToOverlay = false;
		let node = event?.relatedTarget as HTMLElement;

		while (node) {
			if (node === this.activeOverlayRef?.overlayElement) {
				movedToOverlay = true;
				break;
			}
			node = node.parentElement;
		}

		if (!movedToOverlay) {
			this.activeOverlayRef?.dispose();
		}
	}

	openLanguageDropdown(el: HTMLElement) {
		this.activeOverlayRef?.dispose();
		this.activeOverlayRef = this.overlay.create({
			disposeOnNavigation: true,
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(el)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
					},
				]),
		});
		const dropdownPortal = new TemplatePortal(this.languageDropdownRef, this.vc, { category: 'languages' });
		this.activeOverlayRef.attach(dropdownPortal);
	}

	changeLanguage(code: string) {
		Localize.setLanguage(code);
		setCSSVar('--language', code);
		setCSSVar('--direction', code === 'he' ? 'rtl' : 'ltr');

		this.analytics.push({
			token: nanoid(12),
			category: 'Language',
			action: 'Change language',
			label: code,
		});
	}

	signout() {
		this.authService.logout().subscribe(async () => {
			this.parentService.clear();
			this.donorStoreService.clear();
			this.surrogateStoreService.clear();
			await clearAkita();
			window.location.href = '/login';
		});
	}

	login() {
		this.analytics.push({
			token: nanoid(12),
			category: 'User Area',
			action: 'login',
			label: 'CTA - My Account',
		});
		const baseUrl = window.location.origin;

		const url = new URL(baseUrl + this.router.routerState.snapshot.url);

		this.router.navigate(['/login'], {
			queryParams: {
				r: url.searchParams.get('r') || this.router.routerState.snapshot.url,
			},
		});
	}

	toggleNavigation() {
		this.showNavigationOnMobile = !this.showNavigationOnMobile;
		if (this.showNavigationOnMobile) {
			this.zone.runOutsideAngular(() => {
				const listener = () => {
					this.zone.run(() => (this.showNavigationOnMobile = false));
					window.removeEventListener('click', listener);
				};
				setTimeout(() => window.addEventListener('click', listener));
			});
		}
	}

	getRoot() {
		if (environment.appEnv === 'dev') {
			return `https://test.gostork.com`;
		}
		if (environment.appEnv === 'production') {
			return 'https://www.gostork.com';
		}

		return `https://${environment.appEnv}.gostork.com`;
	}
}
