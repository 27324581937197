import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';

import { ProviderType } from '@/config';
import { GoStorkProducts } from '@/consts/config';
import { SurrogateProfileComponent } from '@/screens/surrogate-profile/profile.component';
import { environment } from '../environments/environment';
import { TComponent } from './app.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { AppointmentComponent } from './screens/appointment/appointment.component';
import { ChatComponent } from './screens/chat/chat.component';
import { CheckoutScreenComponent } from './screens/checkout-screen/checkout-screen.component';
import { ComingSoonComponent } from './screens/coming-soon/coming-soon.component';
import { ConciergeCalendarComponent } from './screens/concierge-calendar/concierge-calendar.component';
import { ProfileComponent } from './screens/donor-profile/profile.component';
import { CompareComponent } from './screens/donors/compare/compare.component';
import { LoginComponent } from './screens/login/login.component';
import { ProfileParentComponent } from './screens/parent-profile/profile-parent.component';

import { AttachCalendarComponent } from './screens/surrogacy/attach-calendar.component';
import { SurrogatesCompareComponent } from './screens/surrogates/compare/compare.component';
import { AuthGuard } from './services/auth.guard';

const conciergeFallbackUrl = () => {
	if (window.location.href.includes('id=')) {
		return ['/login'];
	}
	return ['/register'];
};

const loginFallbackUrl = (type: 'parent' | 'provider' | 'admin', user: any, questionnaire: any) => {
	if (type === 'admin') {
		return ['/chat/'];
	}
	if (type === 'provider') {
		return ['/providers/deals'];
	}
	if (type === 'parent') {
		const lastAccessedProduct = localStorage.getItem('last_accessed_product') as GoStorkProducts;
		switch (lastAccessedProduct) {
			case GoStorkProducts.IVF:
				return ['/ivf'];
			case GoStorkProducts.Surrogacy:
				return ['/surrogacy'];
			case GoStorkProducts.EggDonors:
				return ['/egg-donor'];
			default:
				return questionnaire[ProviderType.SURROGACY_AGENCY] ? ['/surrogacy'] : ['/egg-donor'];
		}
	}

	return ['/'];
};

export const setLastProduct = (product: GoStorkProducts) => {
	if (localStorage) {
		localStorage.setItem('last_accessed_product', product);
	}
};

const loginRouteInfo = {
	canActivate: [AuthGuard],
	data: {
		expected: false,
		roles: [],
		getFallbackUrl: loginFallbackUrl,
	},
	component: LoginComponent,
};

const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		data: {
			expected: false,
			roles: ['parent', 'admin', 'provider'],
			fallbackUrl: ['/login'],
			breadcrumb: 'Home',
		},
		loadChildren: () => import('./screens/module-choose/home.module').then((m) => m.HomeModule),
	},
	{
		path: 'register',
		canActivate: [AuthGuard],
		data: {
			expected: false,
			roles: [],
			fallbackUrl: ['/'],
		},
		loadChildren: () => import('./screens/module-choose/home.module').then((m) => m.HomeModule),
	},
	{
		path: 'dashboard',
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'parent', 'provider'], withRedirect: true },
		loadChildren: () => import('./screens/dashboard/dashboard.module').then((m) => m.DashboardModule),
	},
	{
		path: 'policies',
		loadChildren: () => import('./screens/policies/policies.module').then((m) => m.PoliciesModule),
	},
	{
		path: 'egg-donor',
		children: [
			{
				path: '',
				loadChildren: () => import('./screens/donors/donors.module').then((m) => m.DonorsModule),
				canActivate: [AuthGuard],
				data: {
					roles: ['parent', 'admin', 'provider'],
					fallbackUrl: ['/questionnaire'],
				},
			},
			{
				path: 'compare',
				component: CompareComponent,
				canActivate: [AuthGuard],
				data: {
					roles: ['parent', 'admin', 'provider'],
					fallbackUrl: ['/questionnaire'],
				},
			},
			{
				path: 'donor/:id',
				canActivate: [AuthGuard],
				data: {
					roles: ['parent', 'admin', 'provider'],
					withRedirect: true,
				},
				component: ProfileComponent,
			},
		],
	},
	{
		path: 'surrogates',
		children: [
			{
				path: '',
				loadChildren: () => import('./screens/surrogates/surrogates.module').then((m) => m.SurrogatesModule),
				canActivate: [AuthGuard],
				data: {
					roles: ['parent', 'admin', 'provider'],
					fallbackUrl: ['questionnaire', 'start'],
				},
			},
			{
				path: 'compare',
				component: SurrogatesCompareComponent,
				canActivate: [AuthGuard],
				data: {
					roles: ['parent', 'admin', 'provider'],
					fallbackUrl: ['login'],
				},
			},
			{
				path: ':id',
				canActivate: [AuthGuard],
				data: {
					roles: ['parent', 'admin', 'provider'],
					fallbackUrl: ['login'],
					withRedirect: true,
				},
				component: SurrogateProfileComponent,
			},
		],
	},
	{ path: 'egg-donor/q', redirectTo: '/questionnaire/start', pathMatch: 'full' },
	{ path: 'egg-donor/questionnaire', redirectTo: '/questionnaire/start', pathMatch: 'full' },
	{
		path: 'egg-donor/questionnaire/:id',
		redirectTo: '/questionnaire/start',
		pathMatch: 'full',
	},
	{
		path: 'login',
		...loginRouteInfo,
	},
	{
		path: 'login/forgot',
		...loginRouteInfo,
	},
	{
		path: 'login/reset',
		...loginRouteInfo,
	},
	{
		path: 'providers',
		loadChildren: () => import('./screens/providers/providers.module').then((m) => m.ProvidersModule),
	},
	{
		path: 'account',
		canActivate: [AuthGuard],
		data: { roles: ['parent', 'admin'] },
		component: ProfileParentComponent,
	},
	{
		path: 'subscription',
		canActivate: [AuthGuard],
		data: { roles: ['parent', 'admin'] },
		component: CheckoutScreenComponent,
	},
	{
		path: '__map',
		component: TComponent,
	},
	{ path: 'q', redirectTo: '/questionnaire/start', pathMatch: 'full' },
	{ path: 'questionnaire', redirectTo: 'questionnaire/start', pathMatch: 'full' },
	{
		path: 'questionnaire',
		children: [
			{
				matcher: (segments) => {
					const posParams: Record<string, UrlSegment> = {
						section: segments[0],
					};

					if (segments[1]) {
						posParams.question = segments[1];
					}

					return {
						consumed: segments,
						posParams,
					};
				},
				loadChildren: () => import('./screens/onboarding/onboarding.module').then((m) => m.OnboardingModule),
			},
			{
				path: '',
				redirectTo: 'start',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'surrogacy',
		loadChildren: () => import('./screens/surrogacy/surrogacy.module').then((m) => m.SurrogacyModule),
	},
	{
		path: 'ivf',
		loadChildren: () => import('./screens/ivf/ivf.module').then((m) => m.IvfModule),
	},
	{
		path: 'ivf',
		component: ComingSoonComponent,
	},
	{
		path: 'sperm',
		component: ComingSoonComponent,
	},
	{
		path: 'auth/calendar',
		component: AttachCalendarComponent,
	},
	{
		path: 'users',
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
		loadChildren: () => import('./screens/users/users.module').then((m) => m.UsersModule),
	},
	{
		path: 'chat',
		redirectTo: 'chat/',
		pathMatch: 'full',
	},
	{
		path: 'chat/:chatId',
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'parent', 'provider'], withRedirect: true, breadcrumb: 'Chats' },
		component: ChatComponent,
	},
	{
		path: 'concierge',
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'parent'], withRedirect: true, getFallbackUrl: conciergeFallbackUrl },
		component: ConciergeCalendarComponent,
	},
	{
		path: 'concierge/:calendarId',
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'parent'], withRedirect: true, getFallbackUrl: conciergeFallbackUrl },
		component: ConciergeCalendarComponent,
	},
	{
		path: 'appointment',
		component: AppointmentComponent,
	},
	{
		path: '**',
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: !environment.production,
			scrollPositionRestoration: 'enabled',
			relativeLinkResolution: 'legacy',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
