import {DateTime} from 'luxon';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';

export abstract class Store<T> {
	private static readonly prefix = 'store__';
	private readonly _state: BehaviorSubject<T>;

	constructor(initialState: T, private storageKey?: string) {
		if (storageKey) {
			const saved = localStorage.getItem(Store.prefix + storageKey);
			if (saved) {
				try {
					const deserialized = Store.deserialize(saved);
					const keys = Object.keys(deserialized);
					if (keys.length === Object.keys(initialState).length && keys.every((key) => key in initialState)) {
						initialState = Store.deserialize(saved);
					}
				} catch (error) {}
			}
		}
		this._state = new BehaviorSubject(initialState);
	}

	static serialize(data: unknown) {
		return JSON.stringify(data, (key, value) => {
			if (/^\d{4}\-\d{2}\-\d{2}T\d{2}\:\d{2}\:\d{2}\.\d+\S*$/.test(value)) {
				return `store__DateTime::${value}`;
			} else if (value instanceof Map) {
				return `store__Map::${this.serialize(Array.from(value.entries()))}`;
			}

			return value;
		});
	}

	static deserialize<K>(data: string): K {
		return JSON.parse(data, (key, value) => {
			if (typeof value === 'string') {
				const match = value.match(/store__([^:]+)::([\s\S]*)/);
				if (match) {
					const [, type, strValue] = match;
					switch (type) {
						case 'DateTime':
							return DateTime.fromISO(strValue);
						case 'Map':
							return new Map(this.deserialize(strValue));
						default:
							console.warn(`[Store] unknown type of the value => ${value}`);
							return strValue;
					}
				}

				return value;
			}

			return value;
		});
	}

	protected set(state: T) {
		this._state.next(state);
		if (this.storageKey) {
			localStorage.setItem(Store.prefix + this.storageKey, Store.serialize(state));
		}
	}

	protected update(state: Partial<T>) {
		const fullState = {...this.state, ...state};
		this.set(fullState);
	}

	// eslint-disable-next-line space-before-function-paren
	protected linearObservable<K extends (...args: any[]) => Observable<any>, U extends Parameters<K>>(source: K): K {
		const subject = new Subject<U>();
		return ((...args: U) => {
			subject.next(args);
			return subject.pipe(switchMap(source), first());
		}) as K;
	}

	public get state() {
		return this._state.value;
	}

	public get state$() {
		return this._state.asObservable();
	}
}
