import {HttpClient, HttpUrlEncodingCodec} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CheckoutSources} from '@/consts/config';
import {StaticScrollBlockStrategy} from '@/helpers/static-block';
import {CheckoutComponent} from '../components/checkout/checkout.component';
import {AnalyticsService} from './analytics.service';
import {AuthStoreService} from './auth.service';

@Injectable()
export class CheckoutService {
	constructor(
		private matDialog: MatDialog,
		private authService: AuthStoreService,
		private http: HttpClient,
		private analytics: AnalyticsService,
		public router: Router,
	) {}

	openDialog(source?: CheckoutSources) {
		const dialogRef = this.matDialog.open(CheckoutComponent, {
			maxWidth: '1000px',
			//width: 'auto',
			minHeight: '700px',
			position: {top: '0'},
			panelClass: 'no-top-borders',
			scrollStrategy: new StaticScrollBlockStrategy(),
			data: {
				name: this.authService.value.name,
				success: false,
				source,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`); // Pizza!
			if (result) {
				this.router.navigate(['/egg-donor']).then(() => {
					window.location.reload();
				});
			}
		});
		return dialogRef;
	}

	openDialogSuccess() {
		return this.matDialog.open(CheckoutComponent, {
			maxWidth: '1000px',
			//width: 'auto',
			minHeight: '200px',
			maxHeight: '600px',
			position: {top: '0'},
			panelClass: 'no-top-borders',
			data: {
				name: this.authService.value.name,
				success: true,
			},
		});
	}
}

export class UrlParameterEncodingCodec extends HttpUrlEncodingCodec {
	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
}
