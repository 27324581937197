<ng-container *ngIf="changingQuestionId">
	<div class="changeAnswerBackdrop" (click)="changingQuestionId = undefined"></div>
	<div *ngIf="changingQuestionId" class="changeAnswer" [class.expandDown]="expandDown" [style.left.px]="changingPopupX">
		<div class="questionTitle" [ngSwitch]="changingQuestionId">
			<ng-container *ngSwitchCase="'buildingFamily'">How can a fertility clinic help you?</ng-container>
			<ng-container *ngSwitchCase="'carrier'">Who is planning to carry the pregnancy?</ng-container>
			<ng-container *ngSwitchCase="'eggsOwner'">Whose eggs or embryos are you planning to use for IVF?</ng-container>
			<ng-container *ngSwitchCase="'eggsOwnerAge'">What is the age of the person providing the {{ eggsOwnerName }}?</ng-container>
			<ng-container *ngSwitchCase="'inVirto'">
				Has the person providing the {{ eggsOwnerName }} pursued fertility treatment before?
			</ng-container>
		</div>
		<mat-radio-group
			*ngIf="changingQuestionId !== 'eggsOwnerAge'; else ageQuestion"
			class="simple"
			color="primary"
			[ngModel]="changingQuestionValue"
			(change)="changeAnswer($event.value)"
		>
			<mat-radio-button
				*ngFor="let option of options[changingQuestionId]"
				class="simple"
				labelPosition="before"
				[value]="option.value"
				(click)="option.value === changingQuestionValue ? (changingQuestionId = undefined) : null"
			>
				{{ option.label }}
			</mat-radio-button>
		</mat-radio-group>

		<ng-template #ageQuestion>
			<div class="ageAnswer">
				<mat-form-field appearance="outline">
					<input
						matInput
						autocomplete="off"
						[ngModel]="changingQuestionValue"
						(ngModelChange)="inputValue = $event"
						(keypress)="onInput($event)"
						type="number"
						min="0"
						placeholder="Age"
					/>
					<button mat-icon-button matSuffix class="enterButton" (click)="changeAnswer(inputValue)">
						<mat-icon>keyboard_return</mat-icon>
					</button>
				</mat-form-field>
			</div>
		</ng-template>
	</div>
</ng-container>

<div class="actualJourneyTitle">Your Journey</div>
<div class="journeyAnswers">
	<div *ngFor="let question of changableQuestions" class="journeyAnswer" (click)="openChangePopup(question.id, $event)">
		<span>{{ question.label }}</span>
		<mat-icon>expand_{{ changingQuestionId === question.id ? 'less' : 'more' }}</mat-icon>
	</div>
</div>
