import { ProviderType } from '@/config';

export enum GoStorkProducts {
	IVF = 'ivf',
	Surrogacy = 'surrogacy',
	EggDonors = 'egg',
}

export const ProviderTypeToProductMap = {
	[ProviderType.IVF_CLINIC]: GoStorkProducts.IVF,
	[ProviderType.EGG_AGENCY]: GoStorkProducts.EggDonors,
	[ProviderType.SURROGACY_AGENCY]: GoStorkProducts.Surrogacy,
	[ProviderType.SURROGATES_AGENCY]: GoStorkProducts.Surrogacy,
};

export const ProductToProviderTypes = {
	[GoStorkProducts.IVF]: [ProviderType.IVF_CLINIC],
	[GoStorkProducts.EggDonors]: [ProviderType.EGG_AGENCY],
	[GoStorkProducts.Surrogacy]: [ProviderType.SURROGACY_AGENCY, ProviderType.SURROGATES_AGENCY],
};

export const PrettyProducts: Record<GoStorkProducts, string> = {
	[GoStorkProducts.IVF]: 'IVF',
	[GoStorkProducts.EggDonors]: 'Egg Donor',
	[GoStorkProducts.Surrogacy]: 'Surrogacy',
};

export const OnboardingPrettyProducts: Record<GoStorkProducts, string> = {
	[GoStorkProducts.IVF]: 'Fertility',
	[GoStorkProducts.EggDonors]: 'Egg Donor',
	[GoStorkProducts.Surrogacy]: 'Surrogacy',
};

export const AnalyticsProducts: Record<GoStorkProducts, string> = {
	[GoStorkProducts.IVF]: 'fertility_clinic',
	[GoStorkProducts.EggDonors]: 'egg_donation',
	[GoStorkProducts.Surrogacy]: 'surrogacy',
};

export enum CheckoutSources {
	EggDonor = 'egg-donor',
	TopHeader = 'top-header',
	Subscription = 'subscription',
}

const defaultDonorMessage = `Hello,
I am interested in learning more about Egg Donor #{donorId} and your agency.
Sincerely,
{name}`;

const defaultSurrogatesMessage = `Hello,
I am interested in learning more about surrogate #{surrogateId} and your agency.
Sincerely,
{name}`;

const defaultSurrogacyMessage = `Hello,
I am interested in learning more about your agency.
Sincerely,
{name}`;

export const ChatMessages = {
	defaultDonorMessage,
	defaultSurrogacyMessage,
	defaultSurrogatesMessage,
};
