<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

<table mat-table [dataSource]="data" matSort matSortActive="created_at" matSortDisableClear matSortDirection="desc">
	<ng-container matColumnDef="id">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
		<td mat-cell *matCellDef="let row">{{ row.id }}</td>
	</ng-container>

	<ng-container matColumnDef="name">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
		<td mat-cell *matCellDef="let row">{{ row.first_name }} {{ row.last_name }}</td>
	</ng-container>

	<ng-container matColumnDef="email">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
		<td mat-cell *matCellDef="let row">
			<a href="mailto:{{ row.email }}">{{ row.email }}</a>
		</td>
	</ng-container>

	<ng-container matColumnDef="role">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
		<td mat-cell *matCellDef="let row">
			<mat-form-field appearance="outline">
				<mat-select [value]="row.role" (valueChange)="updateRole(row.id, $event)">
					<mat-option *ngFor="let option of roleOptions" [value]="option.value">{{ option.label }}</mat-option>
				</mat-select>
			</mat-form-field>
		</td>
	</ng-container>

	<ng-container matColumnDef="created_at">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
		<td mat-cell *matCellDef="let row">{{ row.created_at | date: 'short' }}</td>
	</ng-container>

	<ng-container matColumnDef="destroy">
		<th mat-header-cell *matHeaderCellDef></th>
		<td mat-cell *matCellDef="let row">
			<button mat-icon-button color="warn" (click)="deleteUser(row.id)"><mat-icon>delete</mat-icon></button>
		</td>
	</ng-container>

	<ng-container matColumnDef="add">
		<td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
			<button mat-flat-button color="primary" (click)="openCreateUserDialog()"><mat-icon>add</mat-icon> Add New</button>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	<tr mat-footer-row *matFooterRowDef="['add']"></tr>
</table>

<ng-template #createUserRef let-data>
	<h2 mat-dialog-title>Create New User</h2>

	<div mat-dialog-content>
		<mat-form-field appearance="outline">
			<mat-label>Email</mat-label>
			<input matInput required type="email" [(ngModel)]="data.email" />
		</mat-form-field>

		<div class="flexrow">
			<mat-form-field appearance="outline">
				<mat-label>First Name</mat-label>
				<input matInput required [(ngModel)]="data.firstName" />
			</mat-form-field>

			<mat-form-field appearance="outline">
				<mat-label>Last Name</mat-label>
				<input matInput required [(ngModel)]="data.lastName" />
			</mat-form-field>
		</div>

		<mat-form-field appearance="outline">
			<mat-label>Role</mat-label>
			<mat-select required [(value)]="data.role">
				<mat-option *ngFor="let option of roleOptions" [value]="option.value">{{ option.label }}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close>Cancel</button>
		<button mat-flat-button color="primary" [mat-dialog-close]="data">Create</button>
	</mat-dialog-actions>
</ng-template>

<ng-template #userCreatedRef let-data>
	<h2 mat-dialog-title>User successfully created</h2>
	<div mat-dialog-content>
		<p>
			This is a temporary password for the created user.
			<br />
			They can use it to login or they can reset their password at any time.
		</p>
		<p class="password" (click)="copyPassword(data.password)">
			{{ data.password }}
		</p>
	</div>
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close>Close</button>
	</mat-dialog-actions>
</ng-template>
