import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MaterialModule} from '@/material.module';
import {TimezoneDateModule} from '@/pipes/timezoneDate.module';
import {InsvgModule} from '../../components/insvg/insvg.module';
import {CalendarModule} from '../calendar/calendar.module';
import {ConciergeFtuPopupComponent} from './concierge-ftu-popup.component';

@NgModule({
	declarations: [ConciergeFtuPopupComponent],
	imports: [CommonModule, CalendarModule, MaterialModule, InsvgModule, TimezoneDateModule],
	exports: [ConciergeFtuPopupComponent],
})
export class ConciergeFtuPopupModule {}
