import {animate, style, transition, trigger} from '@angular/animations';
import {Component, HostBinding} from '@angular/core';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [style({opacity: 0}), animate('.1s ease-in-out', style({opacity: 1}))]),
			transition(':leave', [style({opacity: 1}), animate('.1s ease-in-out', style({opacity: 0}))]),
		]),
	],
})
export class LoaderComponent {
	@HostBinding('@inOutAnimation')
	get inOutAnimation() {
		return true;
	}
}
