import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MaterialModule} from '@/material.module';

import {VerificationCodeComponent, VerificationCodeControlComponent} from './verification-code.component';

@NgModule({
	declarations: [VerificationCodeComponent, VerificationCodeControlComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
	exports: [VerificationCodeComponent],
})
export class VerificationCodeModule {}
