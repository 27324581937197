import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';

import {filter, first, map, startWith, switchMap} from 'rxjs/operators';
import {LabelValueIcon} from '@/models/common.model';
import {IVF_SERVICES, NIvfFilters} from '@/models/ivf.model';
import {CacheService} from '@/services/cache.service';
import {ProvidersStoreService} from '@/services/providers.service';
import {IvfService} from './ivf.service';

@Component({
	selector: 'app-ivf-profile-edit',
	templateUrl: './ivf-profile-edit.component.html',
	styleUrls: ['./ivf-profile-edit.component.scss'],
})
export class IvfProfileEditComponent implements OnInit {
	@ViewChild('selectYoutubeVideoRef')
	selectYoutubeVideoRef: TemplateRef<void>;

	id$ = this.route.params.pipe(map(({id}) => id));
	ivf$ = this.id$.pipe(
		switchMap((id) => this.ivfService.getOne(id, true)),
		map((ivf) => this.ivfService.prepareName(ivf)),
		map((ivf) => this.ivfService.prepareImage(ivf)),
		filter((ivf) => !!ivf),
	);
	languages$ = this.cache.select<{languages: string[]}>('options').pipe(
		map((res) => (res.languages || []).map((item) => ({label: item, value: item}))),
		startWith([]),
	);

	form: FormGroup;

	get teamForm() {
		return this.form.get('team') as FormArray;
	}

	selectedTeamIndex: number;
	get selectedTeamForm() {
		return this.teamForm.at(this.selectedTeamIndex);
	}

	separatorKeysCodes = [ENTER, COMMA];

	videoUploadingProgress = 0;

	constructor(
		private route: ActivatedRoute,
		private ivfService: IvfService,
		private fb: FormBuilder,
		private cache: CacheService,
		private providerService: ProvidersStoreService,
		private snack: MatSnackBar,
		private matDialog: MatDialog,
		@Inject(IVF_SERVICES) public ivfServices: LabelValueIcon<NIvfFilters.IvfService>[],
	) {}

	ngOnInit() {
		this.ivf$.pipe(first()).subscribe((ivf) => {
			this.form = this.fb.group({
				img: [ivf.img],
				video: [ivf.video],
				about: [ivf.about],
				whatMakesUsUnique: [ivf.whatMakesUsUnique],
				lgbtFriendly: [ivf.lgbtFriendly || false],
				internationalIntendedParents: [ivf.internationalIntendedParents || false],
				research: [ivf.research],
				services: this.fb.group(
					Object.fromEntries(this.ivfServices.map((service) => [service.value, ivf.services?.includes(service.value)])),
				),
				memberships: [ivf.memberships],
				team: this.fb.array(
					ivf.team.map((item) =>
						this.fb.group({
							name: [item.name || ''],
							about: [item.about || ''],
							languages: [item.languages || []],
							certifications: [item.certifications || []],
							education: [item.education || []],
							memberships: [item.memberships || []],
							publications: [item.publications || []],
							research: [item.research || ''],
							position: [item.position || ''],
							location: [item.location || ''],
							img: [item.img],
						}),
					),
				),
				maxAgeFemale: [ivf.maxAgeFemale],
				maxAgeMale: [ivf.maxAgeMale],
				combinedAge: [ivf.combinedAge],
				maxAgeFemaleED: [ivf.maxAgeFemaleED],
				maxAgeMaleED: [ivf.maxAgeMaleED],
				combinedAgeED: [ivf.combinedAgeED],
			});
		});
	}

	updateSelectedTeam(index: number) {
		this.selectedTeamIndex = this.selectedTeamIndex === index ? null : index;
	}

	addCoreChip(formId: string, event: MatChipInputEvent) {
		const actualValue = event.value?.trim();
		if (actualValue) {
			const control = this.form.get(formId);
			control.setValue([...control.value, actualValue]);
			event.chipInput.clear();
		}
	}

	removeCoreChip(formId: string, toRemove: string) {
		const control = this.form.get(formId);
		control.setValue(control.value.filter((item) => item !== toRemove));
	}

	addTeamChip(formId: string, event: MatChipInputEvent) {
		const actualValue = event.value?.trim();
		if (actualValue) {
			const control = this.selectedTeamForm.get(formId);
			control.setValue([...control.value, actualValue]);
			event.chipInput.clear();
		}
	}

	removeTeamChip(formId: string, toRemove: string) {
		const control = this.selectedTeamForm.get(formId);
		control.setValue(control.value.filter((item) => item !== toRemove));
	}

	save() {
		const data = this.form.value;
		data.services = Object.keys(data.services).filter((key) => data.services[key]);

		data.research ||= null;
		data.memberships ||= [];
		data.services ||= [];
		data.accreditations ||= [];
		data.whatMakesUsUnique ||= null;

		this.form.disable();

		this.ivf$
			.pipe(
				first(),
				switchMap(({id}) => this.ivfService.updateProfile(id, data)),
				first(),
			)
			.subscribe(() => {
				this.snack.open('Profile successfully saved', null, {duration: 3000});
				this.form.enable();
			});
	}

	uploadImage(file: File, forTeam = false) {
		if (!file) {
			return;
		}

		this.form.disable();

		this.providerService
			.uploadFile(file, forTeam ? 'ivf-team-profile' : 'ivf-profile')
			.pipe(filter((event) => event.type === HttpEventType.Response))
			.subscribe((event) => {
				if (forTeam) {
					this.selectedTeamForm.get('img').setValue(`/images/${(event as HttpResponse<{file: string}>).body.file}`);
				} else {
					this.form.get('img').setValue(`/images/${(event as HttpResponse<{file: string}>).body.file}`);
				}
				this.form.enable();
			});
	}

	addTeamMemeber() {
		this.teamForm.push(
			this.fb.group({
				name: [''],
				about: [''],
				languages: [[]],
				certifications: [[]],
				education: [[]],
				memberships: [[]],
				publications: [[]],
				research: [''],
				position: [''],
				location: [''],
				img: [],
			}),
		);
	}

	removeTeamMember(index: number) {
		this.teamForm.removeAt(index);
	}

	uploadVideo(file: File) {
		if (!file) {
			return;
		}

		if (file.size > 0xc800000) {
			return this.snack.open('File is too large. Max size is 200MB.');
		}

		this.videoUploadingProgress = 10;

		this.providerService.uploadFile(file, 'ivf-profile').subscribe((event) => {
			if (event.type === HttpEventType.UploadProgress && event.total) {
				this.videoUploadingProgress = Math.round((event.loaded / event.total) * 100);
			} else if (event.type === HttpEventType.Response) {
				this.form.get('video').setValue(`/images/${event.body.file}`);
				this.videoUploadingProgress = 0;
			}
		});
	}

	removeVideo() {
		this.form.get('video').setValue(null);
	}

	openVideoDialog() {
		this.matDialog
			.open(this.selectYoutubeVideoRef, {
				hasBackdrop: true,
				closeOnNavigation: true,
			})
			.afterClosed()
			.subscribe((data) => data && this.form.get('video').setValue(data));
	}
}
