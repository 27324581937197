<div class="__">
	<h6>Other</h6>
	<a target="_blank" href="/policies/internal">Policies</a>
	<a target="_blank" href="/policies/terms-and-conditions">Terms and Conditions</a>
	<a target="_blank" href="/policies/privacy-policy">Privacy Policy</a>
	<a target="_blank" href="/policies/cookie-policy">Cookie Policy</a>
	<a target="_blank" href="/">Home</a>
	<a target="_blank" href="/for-providers">For Providers</a>
	<a target="_blank" href="https://www.gostork.com/blog/">Blog</a>
	<a target="_blank" href="https://www.gostork.com/blog/faq/">FAQ</a>
	<a target="_blank" href="/our-story">About Us</a>
	<h6>Egg</h6>
	<a target="_blank" href="/egg-donor/questionnaire">Egg Questionnaire</a>
	<a target="_blank" href="/egg-donor">Egg Search Results</a>
	<a target="_blank" href="/egg-donor/compare">Egg Compare</a>
	<a target="_blank" href="/egg-donor/donor">Egg Profile</a>
	<h6>User</h6>
	<a target="_blank" href="/login">Login</a>
	<a target="_blank" href="/login/forgot">Forgot Password</a>
	<a target="_blank" href="/login/reset">Reset Password</a>
	<h6>Provider</h6>
	<a target="_blank" href="/providers/register">Register</a>
	<a target="_blank" href="/providers/account">Profile</a>
	<a target="_blank" href="/providers/deals">Deals</a>
	<a target="_blank" href="/providers/costs">Costs</a>
	<h6>Parent</h6>
	<a target="_blank" href="/account">Profile</a>
</div>
