import {Component, Inject, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators, FormArray} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {Subscription, Observable, timer} from 'rxjs';
import {share, take, map} from 'rxjs/operators';

import {AuthStoreService} from '../../services/auth.service';
import {AnalyticsService} from '../../services/analytics.service';

@Component({
	selector: 'app-code-prompt',
	templateUrl: './code-prompt.component.html',
	styleUrls: ['./code-prompt.component.scss'],
})
export class CodePromptComponent implements OnInit, OnDestroy {
	@ViewChildren('control')
	controls: QueryList<ElementRef<HTMLInputElement>>;

	readonly codeLength = 6;

	fields = new FormArray(
		Array.from({length: this.codeLength}).map(
			() => new FormControl(null, [Validators.required, Validators.min(0), Validators.max(9), Validators.maxLength(1)]),
		),
	);
	code = '';
	loading = false;

	change$: Subscription;
	timer$: Observable<number>;

	error = '';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: {email: string; type: 'surrogacy' | 'donors'},
		private authService: AuthStoreService,
		private dialogRef: MatDialogRef<CodePromptComponent>,
		private analytics: AnalyticsService,
	) {}

	onKeyup(event: KeyboardEvent) {
		if (event.code === 'Backspace') {
			const _controls = this.controls.toArray();
			const focused = _controls.findIndex((item) => item.nativeElement === document.activeElement);
			_controls[focused - 1]?.nativeElement.focus();
			_controls[focused - 1]?.nativeElement.select();
		}
	}

	onKeypress(event: KeyboardEvent) {
		if(event.key){
			const code = event.key.charCodeAt(0);
			if ((code > 57 || code < 48) && code !== 69) {
				return false;
			}
		}
	}

	onFocus(event: FocusEvent) {
		(event.target as HTMLInputElement).select();
	}

	onPaste(event: ClipboardEvent) {
		if (event.clipboardData.files.length) {
			return false;
		}
		const data = (event.clipboardData.getData('text/plain') || '').replace(/ /g, '');
		if (isNaN(+data)) {
			return false;
		}
		this.fields.setValue(data.split('').slice(0, this.fields.length));
	}

	onSubmit(event: Event) {
		event.preventDefault();
		if (this.loading) {
			return;
		}

		this.loading = true;
		this.authService.verifyCode(this.data.email, this.code).subscribe({
			next: () => {
				this.analytics.emit(this.data.type, 'Account Verification');
				this.dialogRef.close(true);
			},
			error: (error) => {
				this.error = '';
				this.loading = false;
				if (error instanceof HttpErrorResponse) {
					if (error.status === 403 || error.status === 400) this.error = 'Code is not valid. Try again.';
					else throw error;
				} else {
					throw error;
				}
			},
		});
	}

	resendCode(type: 'phone' | 'email') {
		this.timer$ = timer(0, 1000).pipe(
			share(),
			take(61),
			map((value) => 60 - value),
		);
		this.authService.resendCode(this.data.email, type).subscribe();
	}

	ngOnInit() {
		this.change$ = this.fields.valueChanges.subscribe((value: string[]) => {
			const newCode = value.join('');
			if (newCode.length > this.codeLength) {
				return this.fields.setValue(this.code.split(''));
			}
			if (newCode.length >= this.code.length) {
				const _controls = this.controls.toArray();
				const focused = _controls.findIndex((item) => item.nativeElement === document.activeElement);
				_controls[focused + 1]?.nativeElement.focus();
				_controls[focused + 1]?.nativeElement.select();
			}
			this.code = value.join('');
		});
	}

	ngOnDestroy() {
		this.change$.unsubscribe();
	}
}
