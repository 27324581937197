<div class="actions">
	<mat-form-field appearance="outline">
		<mat-label>On this Page:</mat-label>
		<mat-select placeholder="Select Section"></mat-select>
	</mat-form-field>

	<div class="additional">
		<!-- <mat-checkbox color="primary" [ngModel]="inCompare$ | async" (ngModelChange)="toggleInCompare()">Compare to others</mat-checkbox> -->
		<div></div>

		<app-heart [value]="inFav$ | async" (valueChange)="toggleInFav()" matTooltip="Add to Favorites"></app-heart>
	</div>

	<div class="actual" [class.single]="(contacts$ | async) === null">
		<button mat-flat-button color="accent" class="chatWithClinic" (click)="openMobileChat(true)">
			<mat-icon>chat_bubble_outline</mat-icon>
			<br />
			<span>Chat With Clinic</span>
		</button>
		<button *ngIf="contacts$ | async" mat-flat-button color="primary" class="scheduleCall" (click)="openMobileChat(false)">
			<mat-icon>schedule</mat-icon>
			<br />
			<span>Schedule call with Clinic</span>
		</button>
	</div>
</div>

<div class="backToSearch">
	<a *ngIf="(isEditing$ | async) === false" [routerLink]="listLinks[type$ | async]"><mat-icon>chevron_left</mat-icon>Back to the search</a>
</div>

<div class="profile">
	<div class="profile__info">
		<div [ngSwitch]="type$ | async" id="about">
			<ng-container *ngIf="isEditing$ | async; else viewModeRef">
				<app-ivf-profile-edit #editingRef *ngSwitchCase="ProviderType.IVF_CLINIC"></app-ivf-profile-edit>
			</ng-container>
			<ng-template #viewModeRef>
				<app-ivf-profile *ngSwitchCase="ProviderType.IVF_CLINIC"></app-ivf-profile>
			</ng-template>
		</div>
	</div>
	<div
		*ngIf="(isSmallScreen$ | async) === false"
		class="profile__calendar"
		matTooltip="Unavailable, because clinic restricts maximun age of patients"
		matTooltipPosition="before"
		[matTooltipDisabled]="(ivf$ | async)?.fitsAgeLimit !== false"
		[class.disabled]="(ivf$ | async)?.fitsAgeLimit === false"
	>
		<div *ngIf="isEditing$ | async; else calendarRef">
			<button mat-flat-button color="primary" class="saveProfile" (click)="saveProfile()">Save</button>
		</div>
		<ng-template #calendarRef>
			<ng-container *ngIf="contacts$ | async as contacts">
				<div class="title">Schedule your consultation call</div>
				<app-calendar-list
					[contacts]="contacts"
					[locations]="locations$ | async"
					(onSelectTime)="onSelectTime($event)"
					(beforeCreateAppointment)="createAppointment($event)"
				></app-calendar-list>

				<div class="or">OR</div>
			</ng-container>

			<div class="subtitle">Chat with clinic</div>

			<div class="profile__message">
				<mat-form-field appearance="outline" class="profile__message__area" [class.success]="bookingMessageSuccess">
					<textarea
						matInput
						(click)="onChatClick()"
						rows="5"
						placeholder="Start chatting with this clinic today!"
						[disabled]="bookingMessageSuccess"
						[(ngModel)]="bookingMessage"
					></textarea>
				</mat-form-field>

				<button mat-raised-button color="primary" class="profile__message__send" (click)="sendMessage()">Chat With Clinic</button>
			</div>
		</ng-template>
	</div>
</div>
