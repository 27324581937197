<mat-form-field appearance="outline">
	<mat-label>{{ label }}</mat-label>

	<ng-container matPrefix>
		<ng-content select="[matPrefix]"></ng-content>
	</ng-container>

	<app-select-form-field
		#selectFormField
		multiple
		*ngIf="multiple; else single"
		[withSearch]="withSearch"
		[formControl]="control"
		[options]="options"
		[height]="height"
		[disabled]="disabled"
	></app-select-form-field>
	<ng-template #single>
		<app-select-form-field
			#selectFormField
			[withSearch]="withSearch"
			[formControl]="control"
			[options]="options"
			[height]="height"
			[disabled]="disabled"
		></app-select-form-field>
	</ng-template>

	<button matSuffix mat-icon-button disabled style="top: 0.3em">
		<mat-icon matSuffix>keyboard_arrow_down</mat-icon>
	</button>

	<mat-error>{{ error }}</mat-error>
</mat-form-field>
