<app-compare-bar *ngIf="(checked$ | async) && (comparing$ | async)?.length" type="surrogates" baseUrl="../">
</app-compare-bar>

<div class="profile-container">
    <div class="images-container">
        <button class="backward" (click)="onScroll('backward')" mat-icon-button>
            <app-insvg name="chevron" link="assets/chevron-right.svg"></app-insvg>
        </button>
        <div class="images" #carousel>
            <video #video *ngIf="surrogate?.video" muted autoplay (click)="onImageOpen(-1)">
                <source src="/images/{{surrogate.video}}">
            </video>
            <img *ngFor="let item of images; let i = index" (click)="onImageOpen(i)" [src]="'/images/' + item" />
        </div>
        <button class="forward" (click)="onScroll('forward')" mat-icon-button>
            <app-insvg name="chevron" link="assets/chevron-right.svg"></app-insvg>
        </button>
    </div>
    <ng-container *ngIf="false; else tabView">
        <div class="profile-description">
            <ng-container [ngTemplateOutlet]="sectionHeader"></ng-container>
            <div *ngFor="let tab of tabs" class="profile-description-section">
                <div class="profile-description-section-header">{{tab.title}}</div>
                <ng-container [ngTemplateOutlet]="tab.template"></ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #tabView>
        <mat-tab-group mat-align-tabs="center" color="primary" backgroundColor="primary" [selectedIndex]="selectedIndex" (selectedIndexChange)="onTabChange($event)">
            <mat-tab *ngFor="let tab of tabs" [label]="tab.title"></mat-tab>
        </mat-tab-group>
        <div *ngIf="surrogate" class="profile-description">
            <a class="back-to-search mobile-none" routerLink="/surrogates">
                <mat-icon>arrow_back</mat-icon>Back to the search
            </a>
            <ng-container [ngTemplateOutlet]="sectionHeader"></ng-container>
        </div>
    </ng-template>

    <section class="summary-container" *ngIf="surrogate && surrogate.content" id="about">
        <div class="card summary" >
            <div class="card-header">Summary</div>
            <div class="card-content card-content-flex">
                <div class="card-column">
                    <div class="field">
                        <div class="question">Age</div>
                        <div class="answer">{{ surrogate.age }}</div>
                    </div>
                    <div class="field">
                        <div class="question">BMI</div>
                        <div class="answer">{{ surrogate.bmi }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Base Compensation</div>
                        <div class="answer">{{ utils.parseCost(surrogate.compensation) }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Location</div>
                        <div class="answer">{{ surrogate.parsedLocation }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Agrees to abortion/selective reduction</div>
                        <div class="answer">{{ surrogate.agreesToAbortion }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Agrees to twins</div>
                        <div class="answer">{{ surrogate.agreesToTwins }}</div>
                    </div>
                </div>
                <div class="card-column">
                    <div class="field">
                        <div class="question">COVID Vaccinated</div>
                        <div class="answer">{{ surrogate.covidVaccinated }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Live births</div>
                        <div class="answer">{{ surrogate.liveBirthsCount }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Miscarriages</div>
                        <div class="answer">{{ surrogate.miscarriagesCount }}</div>
                    </div>
                    <div class="field">
                        <div class="question">C-Sections</div>
                        <div class="answer">{{ surrogate.cSectionsCount }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Relationship Status</div>
                        <div class="answer">{{ utils.toCapitalizedCase(surrogate.relationshipStatus) }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Open to same-sex couple</div>
                        <div class="answer">{{ surrogate.sameSexCouple }}</div>
                    </div>
                </div>
            </div>
            <div class="card-content freetext" style="white-space: pre-line" *ngIf="surrogate.summary">
             {{ surrogate.summary }}
            </div>
        </div>

        <ng-container *ngFor="let section of Object.values(surrogate.content)">
            <ng-container *ngIf="isNotEmpty(section.content)">
                <div class="card" >
                    <div class="card-header">{{ section.title }}</div>
                    <div class="card-content card-content-flex">
                        <ng-container *ngIf="section.content.length > 0 && section.type !== 'kvp'">
                            <table>
                                <thead>
                                    <tr>
                                        <th *ngFor="let key of keysForTable(section.content)">{{ key }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of orderPregnancyResults(section.content)">
                                        <td *ngFor="let key of keysForTable(section.content)">{{ utils.toHumanReadableOrUnknown(row.content[key]) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                        <ng-container *ngIf="!section.content.length && !section.type">
                            <div *ngFor="let c of splitSection(section.content, Math.ceil(Object.keys(section.content).length / 2))" class="card-column">
                                <div *ngFor="let kvp of c | keyvalue" class="field">
                                    <div class="question">{{kvp.key}}</div>
                                    <div class="answer">{{kvp.value}}</div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="section.type === 'kvp'">
                            <div *ngFor="let c of chunk(section.content, Math.ceil(Object.keys(section.content).length / 2))" class="card-column">
                                <div class="field" *ngFor="let field of c">
                                    <div class="question">{{field.question}}</div>
                                    <div class="answer">{{field.answer}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-content card-content-flex" *ngIf="section.content.table && section.content.table.length">
                        <table>
                            <thead>
                            <tr>
                                <th *ngFor="let key of keysForTable(section.content.table)">{{ utils.toCapitalizedCase(key) }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of section.content.table">
                                <td *ngFor="let key of keysForTable(section.content.table)">{{ row[key] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="card-content card-content-flex" *ngIf="section.table && section.table.length">
                        <table>
                            <thead>
                            <tr>
                                <th *ngFor="let key of keysForTable(section.table)">{{ utils.toCapitalizedCase(key) }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of section.table">
                                <td *ngFor="let key of keysForTable(section.table)">{{ row[key] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container>
            <app-surrogacy-cost *ngIf="agency?.costs" [agency]="agency" [surrogateCompensation]="surrogate.compensation === '-' ? null : surrogate.compensation" [isSmallScreen]="isSmallScreen" [reportCost]="reportCost"></app-surrogacy-cost>
        </ng-container>
    </section>
</div>

<ng-template #actions>
    <div class="actions">
        <app-heart [value]="isInFavourite$ | async" (valueChange)="onChange('stared')" matTooltip="Add to Favorites">
        </app-heart>
        <button mat-flat-button data-an-category="user" data-an-action="contact_agency" data-an-label="egg_donor" data-an-value="profile" color="accent" class="interested" [class.isStatic]="showContactAgencyAsStatic"
            (click)="onChange('interested')">
            {{ (hasChat$ | async) ? 'Continue Messaging' : 'Contact Agency' }}
        </button>
        <button class="share mobile-none" matTooltip="Share" (click)="onChange('share', $event)" mat-mini-fab>
            <app-insvg name="export" link="assets/export.svg"></app-insvg>
        </button>
        <div class="checkbox">
            <app-checkbox [value]="checked$ | async" (valueChange)="onChange('checked')" [size]="16">{{(checked$ | async) ? 'Remove from Compare' : 'Add to Compare'}}</app-checkbox>
        </div>
    </div>
</ng-template>

<ng-template #overlay>
    <div class="overlay-container">
        <button class="backward" (click)="onImageChange('backward')" mat-icon-button>
            <app-insvg name="chevron" link="assets/chevron-right.svg"></app-insvg>
        </button>
        <video *ngIf="overlayImage === -1; else imageRef" autoplay controls>
            <source src="/images/{{surrogate.video}}">
        </video>
        <ng-template #imageRef>
            <img src="/images/{{images[overlayImage]}}">
        </ng-template>
        <button mat-icon-button (click)="overlayRef.dispose()" class="close">
            <mat-icon [inline]="true">close</mat-icon>
        </button>
        <button class="forward" (click)="onImageChange('forward')" mat-icon-button>
            <app-insvg name="chevron" link="assets/chevron-right.svg"></app-insvg>
        </button>
    </div>
</ng-template>

<ng-template #sectionHeader>
    <div class="profile-header">
        <div class="id">Surrogate #{{('0000' + surrogate.id).slice(-6)}}</div>
        <div class="other" [class.availability]="surrogate.experiencedSurrogate === 'Yes'">
            <div class="avail">
                <span class="sign" [ngClass]="{'green': surrogate.status === 'available', 'error': surrogate.deletedAt || surrogate.status === 'new'}"></span>
                <span>{{surrogate.deletedAt ? 'Unavailable, deleted at ' + (surrogate.deletedAt | date) :
                    utils.toCapitalizedCase(surrogate.status)}}</span>
            </div>
            <div *ngIf="surrogate.experiencedSurrogate === 'Yes'" class="prevD">Previous Surrogate</div>
            <div *ngIf="surrogate.parsedLocation" class="loc">{{surrogate.parsedLocation}}</div>
            <div class="total" *ngIf="surrogate.compensation" (click)="onTabChange(1)">
                <span>Base Compensation: {{utils.parseCost(surrogate.compensation)}}</span>
            </div>
            <div class="total" *ngIf="totalCost" (click)="onTabChange(1)">
                <span>Total Compensation: {{ totalCost }}</span>
            </div>
        </div>
        <div *ngIf="isSmallScreen" class="actions-mobile">
            <ng-container *ngTemplateOutlet="actions"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #stop>
    <div class="overlay-card" [ngSwitch]="costsTutorialStep">

        <ng-container *ngSwitchCase="0">
            <div class="stop">
                Important note!
                <img src="assets/message-box.svg">
            </div>
            <p>Agency's fees and associated costs are provided by the agency associated with the egg donor.<br>Costs are estimates and may vary in some cases.</p>
            <button mat-raised-button color="accent" (click)="costsTutorialStep = 1">Continue</button>
        </ng-container>

        <ng-container *ngSwitchCase="1">
            <div class="click">
                <div class="click__animated">
                    <app-insvg class="click__animated__bubble" name="dialog-bubble" link="assets/dialog-bubble.svg">
                    </app-insvg>
                    <div class="click__animated__dashes">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <img class="click__animated__cursor" src="assets/cursor.svg">
                </div>
                <div class="click__message">You can view additional information<br>provided by the agency regarding
                    <br>each cost by simply hovering on<br>each row</div>
            </div>
            <button mat-raised-button color="accent" (click)="finishTutorial()">See Costs</button>
        </ng-container>

        <!-- preloading -->
        <div style="display: none;">
            <app-insvg name="dialog-bubble" link="assets/dialog-bubble.svg"></app-insvg>
            <img src="assets/cursor.svg">
        </div>
    </div>
</ng-template>
