import {Component, Input, Output, EventEmitter, OnInit, OnChanges} from '@angular/core';
import {DateTime} from 'luxon';

@Component({
	selector: 'app-calendar-header',
	templateUrl: './calendar-header.component.html',
	styleUrls: ['./calendar-header.component.scss'],
})
export class CalendarHeaderComponent implements OnInit, OnChanges {
	@Input()
	numberOfDaysToShow = 5;
	@Output()
	startDateChanged = new EventEmitter<DateTime>();

	_startFromDate = DateTime.local();
	get startFromDate() {
		return this._startFromDate;
	}
	@Input()
	set startFromDate(value: DateTime) {
		if (value.toMillis() === this._startFromDate.toMillis()) return;
		this._startFromDate = value;
		this.dates = this.getDates();
	}
	dates: DateTime[] = [];

	get disabled() {
		return this.dates[0] ? this.dates[0].toMillis() <= DateTime.local().startOf('day').toMillis() : true;
	}

	constructor() {}

	public setStartDate(startFromDate: DateTime) {
		this.startFromDate = startFromDate;
		this.dates = this.getDates();
	}

	getDates() {
		return Array.from<DateTime>({length: this.numberOfDaysToShow}).map((_, index) => this.startFromDate.startOf('day').plus({days: index}));
	}

	moveCalendar(action: 'left' | 'right') {
		if (action === 'left' && this.disabled) return;
		this.startFromDate =
			action === 'left' ? this.dates[0].minus({days: this.numberOfDaysToShow}) : this.dates[0].plus({days: this.numberOfDaysToShow});
		this.startDateChanged.emit(this.dates[0]);
	}

	ngOnInit() {
		this.dates = this.getDates();
	}

	ngOnChanges() {
		this.dates = this.getDates();
	}
}
