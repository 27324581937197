import {Injectable} from '@angular/core';
import {
	CanActivateChild,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivate,
	CanLoad,
	Route,
	UrlSegment,
} from '@angular/router';
import {Observable} from 'rxjs';
import {first, map, skipWhile, tap} from 'rxjs/operators';

import {AuthStoreService} from './auth.service';
import {ParentStoreService} from "@/services/parent.service";

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
	canActivate = this.check;
	canActivateChild = this.check;

	constructor(private authService: AuthStoreService, private router: Router, private parentStore: ParentStoreService) {}

	private check(route: ActivatedRouteSnapshot | Route, state: RouterStateSnapshot | {url: string}): Observable<boolean> {
		return this.authService.loading.pipe(
			skipWhile((loading) => loading),
			first(),
			map(() => {
				const {expected = true, forbid = false, roles = [], getFallbackUrl, fallbackUrl, withRedirect = false} = route.data || {};

				const status = this.authService.state.value;
				if (!status && expected === false) {
					return true;
				}

				const type = this.authService.getType();
				if (forbid ? !roles.includes(type) : status && roles.includes(type)) {
					return true;
				}

				if (this.canRunUnprotected(route)) {
					return true;
				}

				const questionnaire = this.parentStore.getUserQuestionnaire();

				this.router.navigate(getFallbackUrl ? getFallbackUrl(type, this.authService.getUser(), questionnaire) : fallbackUrl || ['/login'], {
					queryParams: { r: withRedirect ? state.url : null },
				});
				return false;
			}),
		);
	}

	canLoad(route: Route, segments: UrlSegment[]) {
		return this.check(route, {url: segments.join('/')});
	}

	canRunUnprotected(route: ActivatedRouteSnapshot | Route) {
		if (route['routeConfig']?.path === 'concierge' && route['queryParams']?.event === 'confirm'){
			return true;
		}

		return false;
	}
}
