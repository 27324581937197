import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@/material.module';
import {CalendarModule} from '../calendar/calendar.module';
import {MobileChatComponent} from './mobile-chat.component';

@NgModule({
	declarations: [MobileChatComponent],
	imports: [CommonModule, MaterialModule, CalendarModule, ReactiveFormsModule, FormsModule],
})
export class MobileChatModule {}
