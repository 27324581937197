import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, of} from 'rxjs';
import {first, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {AppointmentEvent} from '@/components/calendar/calendar-list/calendar-list.component';
import {ProviderType} from '@/config';
import {ServiceMessageType} from '@/models/chat.model';
import {ChatService} from '@/services/chat.service';
import {ContactAgencyService} from '@/services/contact-agency.service';
import {IvfService} from '../ivf/ivf.service';

enum AppointmentAction {
	Cancel = 'cancel',
	CancelMulti = 'cancel-multi',
	Reschedule = 'reschedule',
	Confirm = 'confirm',
	ConfirmMulti = 'confirm-multi',
}

@Component({
	selector: 'app-appointment',
	templateUrl: './appointment.component.html',
	styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent implements OnInit {
	appointmentId$ = this.route.queryParams.pipe(map((query) => +query.id));
	dealId$ = this.route.queryParams.pipe(map((query) => +query.deal_id));
	providerId$ = this.route.queryParams.pipe(map((query) => +query.provider_id));
	ivfId$ = this.route.queryParams.pipe(map((query) => +query.ivf_id));
	surrogacyId$ = this.route.queryParams.pipe(map((query) => +query.surrogacy_id));
	chatId$ = this.route.queryParams.pipe(map((query) => query.chat_id as string));
	eggDonorId$ = this.route.queryParams.pipe(map((query) => +query.egg_donor_id));
	type$ = this.route.queryParams.pipe(map((query) => query.type as ProviderType));
	event$ = this.route.queryParams.pipe(map((query) => query.event as AppointmentAction));
	ids$ = this.route.queryParams.pipe(map((query) => (query.ids ? JSON.parse(query.ids) : []) as Array<{id: number; chatId: string}>));

	contacts$ = combineLatest([this.providerId$, this.type$]).pipe(
		switchMap(([id, type]) => this.contactAgencyService.getContacts(id, type)),
		map((contacts) => contacts.filter((item) => item.calendar_id && item.show_calendar)),
		map((contacts) => (contacts.length ? contacts : null)),
	);
	locations$ = combineLatest([this.ivfId$, this.type$]).pipe(
		switchMap(([id, type]) => (type === ProviderType.IVF_CLINIC ? this.ivfService.getDistances(id) : of([]))),
	);

	constructor(
		private route: ActivatedRoute,
		private contactAgencyService: ContactAgencyService,
		private ivfService: IvfService,
		private chatService: ChatService,
	) {
	}

	ngOnInit(): void {
		this.event$.pipe(first()).subscribe((event) => {
			switch (event) {
				case AppointmentAction.Cancel:
					this.appointmentId$
						.pipe(
							first(),
							switchMap((id) => this.contactAgencyService.cancelAppointment(id)),
							withLatestFrom(this.chatId$.pipe(first())),
						)
						.subscribe(([appointment, chatId]) =>
							this.chatService.addMessage(
								chatId,
								ContactAgencyService.buildServiceMessage(ServiceMessageType.CancelAppointment, {
									calendarId: appointment.provider_contact?.calendar_id,
									time: appointment.appointment_time as string,
								}),
							),
						);
					break;
				case AppointmentAction.CancelMulti:
					this.ids$.pipe(first()).subscribe((ids) =>
						ids.forEach(({id, chatId}) =>
							this.contactAgencyService.cancelAppointment(id).subscribe((appointment) =>
								this.chatService.addMessage(
									chatId,
									ContactAgencyService.buildServiceMessage(ServiceMessageType.CancelAppointment, {
										calendarId: appointment.provider_contact?.calendar_id,
										time: appointment.appointment_time as string,
									}),
								),
							),
						),
					);
					break;
				case AppointmentAction.Confirm:
					this.appointmentId$
						.pipe(
							first(),
							switchMap((id) => this.contactAgencyService.confirmAppointment(id)),
						)
						.subscribe();
					break;
				case AppointmentAction.ConfirmMulti:
					this.ids$.pipe(first()).subscribe((ids) => ids.forEach(({id}) => this.contactAgencyService.confirmAppointment(id).subscribe()));
					break;
			}
		});
	}

	onUpdateAppointment(event: AppointmentEvent) {
		this.chatId$.pipe(first()).subscribe((chatId) =>
			this.chatService.addMessage(
				chatId,
				ContactAgencyService.buildServiceMessage(ServiceMessageType.UpdateAppointment, {
					calendarId: event.calendarId,
					time: event.time.toISO(),
				}),
			),
		);
	}
}
