import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { first, map, shareReplay } from 'rxjs/operators';

import { ProviderType } from 'src/app/config';
import { ServiceMessageType } from 'src/app/models/chat.model';
import { ChatService } from 'src/app/services/chat.service';
import { AppointmentEvent, ContactAgencyService, ProviderContactRole } from 'src/app/services/contact-agency.service';
import { ChatMessages } from '@/consts/config';
import { nanoid } from '@/helpers/nanoid';
import { AnalyticsService } from '@/services/analytics.service';
import { CalendarListComponent } from '../calendar/calendar-list/calendar-list.component';

enum ActiveTab {
	MESSAGE,
	CALL,
	SUCCESS,
}

interface ContactDialogData {
	type: ProviderType;
	agencyName: string;
	name: string;
	donorId?: number;
	surrogateId?: number;
	companyId: number;
	event?: AppointmentEvent;
}

const messageMap = {
	[ProviderType.EGG_AGENCY]: ChatMessages.defaultDonorMessage,
	[ProviderType.SURROGATES_AGENCY]: ChatMessages.defaultSurrogatesMessage,
	[ProviderType.SURROGACY_AGENCY]: ChatMessages.defaultSurrogacyMessage,
};

@Component({
	selector: 'app-contact-agency',
	templateUrl: './contact-agency.component.html',
	styleUrls: ['./contact-agency.component.scss'],
})
export class ContactAgencyComponent implements OnInit, AfterViewInit {
	@ViewChild('messageTab')
	messageTabRef: TemplateRef<void>;
	@ViewChild('callTab')
	callTabRef: TemplateRef<void>;
	@ViewChild('success')
	successRef: TemplateRef<null>;

	@ViewChild(CalendarListComponent)
	calendarListRef: CalendarListComponent;

	contactType =
		this.data.type === ProviderType.EGG_AGENCY
			? ProviderContactRole.EGG_DONOR_AGENCY_COORDINATOR
			: ProviderContactRole.SURROGACY_AGENCY_COORDINATOR;
	activeTab = ActiveTab.MESSAGE;
	ActiveTab = ActiveTab;
	tabs: TemplateRef<void>[] = [];

	message = messageMap[this.data.type].replace(/{([^}]+)}/g, (_, key) => this.data[key]);

	providerContacts$ = this.contactAgencyService.getCalendarIds().pipe(
		map((ids) => (ids ? ids[this.data.companyId] : null)),
		map((providerIds) =>
			providerIds
				? [
						...(providerIds[
							this.data.donorId
								? ProviderContactRole.EGG_DONOR_AGENCY_COORDINATOR
								: ProviderContactRole.SURROGACY_AGENCY_COORDINATOR
						] || []),
						...(providerIds[ProviderContactRole.OWNER] || []),
				  ]
				: null,
		),
		map((providerIds) =>
			providerIds
				?.filter((item, index) => providerIds.findIndex((i) => i.id === item.id) === index)
				.filter((item) => item.calendarId && item.showCalendar),
		),
		map((providerIds) => (providerIds?.length ? providerIds : null)),
		map((contacts) =>
			contacts?.map((item) => ({
				id: item.id,
				first_name: item.name,
				last_name: '',
				calendar_id: item.calendarId,
			})),
		),
		shareReplay(1),
	);

	loading = false;
	sentMessage = false;
	hasScheduledAppointment = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ContactDialogData,
		private dialogRef: MatDialogRef<ContactAgencyComponent>,
		private chatService: ChatService,
		private contactAgencyService: ContactAgencyService,
		private analytics: AnalyticsService,
	) {}

	switchScreen() {
		this.activeTab = this.activeTab === ActiveTab.MESSAGE ? ActiveTab.CALL : ActiveTab.MESSAGE;
	}

	sendMessage() {
		this.loading = true;
		console.log(`[sendMessage] this.message => ${this.message}`);
		this.analytics.push({
			token: nanoid(12),
			category: 'User Area',
			action: this.data.donorId ? 'Egg Donor Profile' : 'Surrogacy Agency',
			label: 'CTA - Send Message',
		});
		this.chatService
			.createChat(this.data.companyId, this.message, { eggDonorId: this.data.donorId, surrogateId: this.data.surrogateId })
			.subscribe(({ chat }) => {
				this.sentMessage = true;
				this.switchScreen();
			});
	}

	createAppointment({ time, calendarId }: { time: DateTime; calendarId: string }) {
		this.chatService
			.createChat(
				this.data.companyId,
				ContactAgencyService.buildServiceMessage(ServiceMessageType.CreatedAppointment, {
					time: time.toISO(),
					calendarId,
				}),
				{ eggDonorId: this.data.donorId, surrogateId: this.data.surrogateId },
			)
			.subscribe(({ chat }) => {
				this.calendarListRef.dealId = chat.deal_id;
				this.calendarListRef.onAppointmentConfirm(true);
			});
	}

	appointmentCreated() {
		this.analytics.push({
			token: nanoid(12),
			category: 'User Area',
			action: this.data.donorId ? 'Egg Donor Profile' : 'Surrogacy Agency',
			label: 'CTA - Schedule a Call',
		});
		(this.data.donorId ? this.chatService.donorHasChat$(this.data.donorId) : this.chatService.surrogacyHasChat$(this.data.companyId))
			.pipe(first())
			.subscribe((chatId) => {
				this.activeTab = ActiveTab.SUCCESS;
				setTimeout(() => this.dialogRef.close(), 1000);
			});
	}

	ngOnInit() {
		console.log('[ngOnInit]');
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.tabs = [this.messageTabRef, this.callTabRef, this.successRef];
			if (this.data.event) {
				this.activeTab = ActiveTab.CALL;
			}
			this.contactAgencyService.fetchUpcomingAppointments(this.data.companyId).subscribe(
				(res) => {
					this.hasScheduledAppointment = res.appointments.length > 0;
				},
				(err) => {
					this.hasScheduledAppointment = false;
				},
			);
		});
	}
}
