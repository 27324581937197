import {animate, style, transition, trigger} from '@angular/animations';
import {OverlayRef} from '@angular/cdk/overlay';
import {Platform} from '@angular/cdk/platform';
import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {environment} from '../environments/environment';
import {AnalyticsService} from './services/analytics.service';
import {AuthStoreService} from './services/auth.service';
import {ChatService} from './services/chat.service';
import {DonorsStoreService} from './services/donors.service';
import {MetaService} from './services/meta.service';
import {ItemType, ParentStoreService} from './services/parent.service';
import {Orientation, UtilsService} from './services/utils.service';

declare let ga: (...args: any) => void;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [style({opacity: 0}), animate('.2s ease', style({opacity: 1}))]),
			transition(':leave', [style({opacity: 1}), animate('.4s ease', style({opacity: 0}))]),
		]),
	],
})
export class AppComponent implements OnInit, OnDestroy {
	version: string = environment.production ? '' : environment.version;

	iddqd = false;
	subs: Record<string, Subscription> = {};
	showCookieBanner: boolean;

	notificationOverlayRef: OverlayRef = null;

	get isPopupOpened() {
		return this.parentStore.isPopupOpened;
	}

	constructor(
		private authService: AuthStoreService,
		private store: DonorsStoreService,
		private parentStore: ParentStoreService,
		private router: Router,
		private zone: NgZone,
		private meta: MetaService,
		private analytics: AnalyticsService,
		private utils: UtilsService,
		private platform: Platform,
		private chatService: ChatService,
	) {}

	onIDDQD = (event: KeyboardEvent) => {
		if (event && event.key) {
			const cur = (sessionStorage.getItem('code') || '') + event.key.toLowerCase();

			if (cur === 'iddqd') {
				this.zone.run(() => (this.iddqd = true));
				this.authService.login('doe@example.com', 'qwerty').subscribe();
				sessionStorage.setItem('code', '');
				setTimeout(() => this.zone.run(() => (this.iddqd = false)), 2000);
			} else if (!'iddqd'.startsWith(cur)) {
				sessionStorage.setItem('code', '');
			} else {
				sessionStorage.setItem('code', cur);
			}
		}
	};

	onCookieAccept() {
		this.showCookieBanner = false;
		localStorage.setItem('cookie', 'true');
	}

	updateMeta(link: string, url: string) {
		switch (link) {
			case '/for-providers':
				this.meta.setSharing({
					title: 'For Providers | GoStork | United States',
					description:
						'GoStork is the only online platform that allows you to showcase your services as a Provider to a growing user base of Intended Parents. Be part of their search as they build their own families and make difficult decisions.',
					url,
				});
				break;
			case '/':
				this.meta.setSharing({
					title: 'Home | GoStork | United States',
					description:
						'Find, compare and connect with top fertility providers on GoStork, the largest fertility marketplace.',
					url,
				});
				break;
			case '/login':
				this.meta.setSharing({
					title: 'Login | GoStork | United States',
					description:
						'Log back into your GoStork account, the marketplace where fertility patients find, compare and connect with top fertility providers',
					url,
				});
				break;
			case '/policies/cookie-policy':
				this.meta.setSharing({
					title: 'Policies/Cookie Policy | GoStork | United States',
					description:
						"Learn more about GoStork's Cookie Policy",
					url,
				});
				break;
			case '/policies/terms-and-conditions':
				this.meta.setSharing({
					title: 'Policies/Terms and Conditions | GoStork | United States',
					description:
						"Learn more about the GoStork platform terms and conditions of use",
					url,
				});
				break;
			case '/policies/privacy-policy':
				this.meta.setSharing({
					title: 'Policies/Privacy Policy | GoStork | United States',
					description:
						"Your personal data on GoStork will be kept 100% private and is used only to reach out to you with helpful information. We never share or sell any data.",
					url,
				});
				break;
			case '/surrogacy':
				this.meta.setSharing({
					title: 'Surrogacy | GoStork | United States',
					description:
						"Compare surrogacy agencies, including costs, services offered, average time to match with a gestational carrier, carrier screening process & more | GoStork",
					url,
				});
				break;
			case '/surrogates':
				this.meta.setSharing({
					title: 'Surrogates | GoStork | United States',
					description:
						"Compare surrogacy agencies, including costs, services offered, average time to match with a gestational carrier, carrier screening process & more | GoStork",
					url,
				});
				break;
			case '/our-story':
				this.meta.setSharing({
					title: 'Our Story | GoStork | United States',
					description:
						"Learn more about our story and how we help fertility patients make more informed decisions about their family-building journey | GoStork",
					url,
				});
				break;
			case '/ivf':
				this.meta.setSharing({
					title: 'IVF | GoStork | United States',
					description:
						"Search and filter top fertility clinics, review comprehensive profiles, including personalized success rates and detailed costs breakdown | GoStork",
					url,
				});
				break;
			case '/egg-donor':
				this.meta.setSharing({
					title: 'Egg Donor | GoStork | United States',
					description:
						"Over 10,000 egg donors in one platform. Compare egg donor family and medical background, appearance, education, fertility/donation history & costs | GoStork",
					url,
				});
				break;
			case '/surrogacy/questionnaire/1':
				this.meta.setSharing({
					title: 'Surrogacy/Questionnaire/1 | GoStork | United States',
					description:
						"We're here to help you find your ideal surrogacy agency. Answer a few questions to get started | GoStork",
					url,
				});
				break;
			case 'egg-donor?r=%2Fegg-donor%2Fquestionnaire%2F1':
				this.meta.setSharing({
					title: 'Egg Donor/Questionnaire/1 | GoStork | United States',
					description:
						"We're here to help you find your ideal egg donor. Answer a few questions to get started | GoStork",
					url,
				});
				break;
			case '//login':
				this.meta.setSharing({
					title: '/Login | GoStork | United States',
					description:
						"Log back into your GoStork account, the marketplace where fertility patients find, compare and connect with top fertility providers",
					url,
				});
				break;
			default:
				this.meta.setSharing({
					title: 'GoStork | Search Surrogacy Agencies, Donors, Fertility Clinics | United States',
					description:
						'Whether you are looking for Fertility clinics or considering surrogacy and looking for agencies or egg donors, GoStork will connect you with the right information and the best partners to make your journey as smooth as possible',
					url,
				});
				break;
		}
	}

	updateViewportUnit() {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}

	ngOnInit(): void {
		if (this.platform.SAFARI) document.body.classList.add('is-safari');
		this.showCookieBanner = !localStorage.getItem('cookie');

		this.zone.runOutsideAngular(() => {
			window.addEventListener('keydown', this.onIDDQD);
			window.addEventListener('resize', this.updateViewportUnit);
		});
		this.updateViewportUnit();

		localStorage.setItem('version', this.version);

		this.authService.update().subscribe();
		this.subs.auth = this.authService.state.subscribe((status) => {
			this.utils.getOptions().subscribe();
			const userType = this.authService.getType();
			if (status) {
				this.chatService.loadChats().subscribe();
			}
			if (status && userType === 'parent') {
				this.store.updateAgencies();
				this.parentStore.update().subscribe();
				this.analytics.updateUserDimension();
			} else if (status && userType === 'provider') {
				this.store.updateAgencies();
			} else if (status && userType === 'admin') {
				this.store.updateAgencies();
				this.parentStore.update().subscribe();
			}
			this.parentStore.setPage(0, ItemType.DONOR);
		});
		this.subs.router = this.router.events.subscribe((event) => {
			const screen = this.router.url.includes('?')
				? this.router.url.slice(0, this.router.url.indexOf('?'))
				: this.router.url.includes('questionnaire')
				? this.router.url.slice(0, this.router.url.lastIndexOf('/'))
				: this.router.url;
			this.updateMeta(screen, location.href);
			if (event instanceof NavigationEnd) {
				this.analytics.pageView(event.urlAfterRedirects);
			}
		});
		if (screen?.orientation?.lock)
			screen.orientation.lock('portrait-primary').catch(() => console.warn('Screen orientation lock is not supported'));
		this.utils.orientation.subscribe((value) => {
			if (value === Orientation.LANDSCAPE && this.utils.breakpointObserver.isMatched(`(max-height: ${this.utils.tinyScreenQuery})`))
				console.warn('Landscape mode on low-resolution devices is not supported. You may want to switch to portrait mode');
		});
		this.analytics.attachGlobalListener();
	}

	ngOnDestroy(): void {
		Object.values(this.subs).forEach((item) => item.unsubscribe());

		this.zone.runOutsideAngular(() => {
			window.removeEventListener('keydown', this.onIDDQD);
			window.addEventListener('resize', this.updateViewportUnit);
		});
	}
}

@Component({
	selector: 'app-t',
	templateUrl: './_.component.html',
	styles: [
		`
			.__ {
				background-color: red;
				color: white;
				min-height: calc(100vh - 50px);
				min-width: 100vw;
			}
			h6 {
				display: block;
				font-size: 24px;
				padding-top: 32px;
				text-align: center;
			}
			a {
				color: white;
				display: block;
				font-size: 16px;
				text-align: center;
			}
		`,
	],
})
export class TComponent {}
