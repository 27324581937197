import {Pipe, PipeTransform} from '@angular/core';

import {UtilsService} from '../../services/utils.service';

@Pipe({name: 'join'})
export class JoinPipe implements PipeTransform {
	transform(value: any[], joint = ', '): string {
		return value.join(joint);
	}
}

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
	transform<T>(value: T[], comparator: (value: T, index: number, array: T[]) => boolean = Boolean): T[] {
		return value.filter(comparator);
	}
}

@Pipe({name: 'find'})
export class FindPipe implements PipeTransform {
	constructor(private utils: UtilsService) {}

	transform<T>(value: T[], f: T): T {
		return ['string', 'number', 'boolean'].includes(typeof f)
			? value.find((item) => item === f)
			: value.find((item) => this.utils.deepEquals(item, f));
	}
}
