import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpClient, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { orderBy, uniqBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, exhaustMap, map, shareReplay, startWith, tap } from 'rxjs/operators';

import { StaticScrollBlockStrategy } from '@/helpers/static-block';
import { ContactAgencyComponent } from '../components/contact-agency/contact-agency.component';
import { ProviderType } from '../config';
import { ServiceMessageType } from '../models/chat.model';
import { AnalyticsService } from './analytics.service';
import { AuthStoreService } from './auth.service';
import { ChatContact } from './chat.service';
import { Calendar, Config, Day, RawBusy } from './company.service';

import { timezoneService } from './timezone.service';

interface BaseDialogConfig {
	companyId: number;
	event?: AppointmentEvent;
}

interface DonorDialogConfig extends BaseDialogConfig {
	donorId: number;
}

interface SurrogateDialogConfig extends BaseDialogConfig {
	surrogateId: number;
}

interface SurrogacyDialogConfig extends BaseDialogConfig {
	agencyName: string;
}

export enum ProviderContactRole {
	EGG_DONOR_AGENCY_COORDINATOR = 'egg_donor_agency_coordinator',
	SURROGACY_AGENCY_COORDINATOR = 'surrogacy_agency_coordinator',
	IVF_CLINIC_COORDINATOR = 'ivf_clinic_coordinator',
	SPERM_BANK_COORDINATOR = 'sperm_bank_coordinator',
	LAW_FIRM_COORDINATOR = 'law_firm_coordinator',
	OWNER = 'owner',
}

interface CreateAppointment {
	appointmentTime: string;
	duration: number;
	calendarId: string;
	dealId: number;
	eggDonorId?: number;
	surrogateId?: number;
	oldAppointmentId?: number;
}

export interface Appointment {
	id: number;
	appointment_time: string | DateTime;
	event_id: string;
	provider_contact_id: number;
	egg_donor_id: number | null;
}

export enum CalendarViewState {
	SCHEDULE,
	CONFIRM,
	COMPLETE,
	CANCELLED,
}

export enum AppointmentEvent {
	Reschedule = 'reschedule',
	Cancell = 'cancel',
}

const AgencyScheduleEveryNthMinute = 15;

@Injectable()
export class ContactAgencyService {
	busyCache = {} as Record<string, { timestamp: DateTime; value$: Observable<{ events: Interval[]; next_available: DateTime }> }>;
	configCache = {} as Record<string, Observable<Omit<Config, 'duration_of_meetings'> & { duration_of_meetings: number[] }>>;
	idsCache = {} as {
		timestamp: DateTime;
		ids: Record<
			number,
			Record<
				ProviderContactRole,
				{
					id: number;
					name: string;
					calendarId: string | null;
					showCalendar: boolean;
				}[]
			>
		>;
	};

	private _appointmentsScheduler = new ReplaySubject<void>(1);
	private _appointments$ = this._appointmentsScheduler.pipe(
		startWith(null),
		debounceTime(500),
		exhaustMap(() => this.http.get<Appointment[]>('/api/v2/deals/appointments')),
		catchError(() => []),
		map((appointments: Appointment[]) =>
			appointments.map((item) => ((item.appointment_time = DateTime.fromISO(item.appointment_time.toString())), item)),
		),
		shareReplay(1),
	);

	constructor(
		private matDialog: MatDialog,
		private authService: AuthStoreService,
		private http: HttpClient,
		private analytics: AnalyticsService,
	) {}

	static buildServiceMessage(type: ServiceMessageType, params: Record<string, string>) {
		return `[[SQRTM1]] ${type} ${Object.keys(params)
			.map((key) => `(${key})[${params[key]}]`)
			.join(' ')}`;
	}

	static unwrapServiceMessage(message: string) {
		const keyRegExp = /\(([^\)]+)\)/g;
		const result = {} as Record<string, string | null>;
		let keyExec: RegExpExecArray;
		do {
			keyExec = keyRegExp.exec(message);
			if (keyExec) {
				const key = keyExec[1];
				const regexp = new RegExp(`\\(${key}\\)\\[([^\\)]+)\\]`);
				const found = message.match(regexp);
				result[key] = found ? found[1] : null;
			}
		} while (keyExec);
		return result;
	}

	openDialog(type: ProviderType, config: DonorDialogConfig | SurrogacyDialogConfig | SurrogateDialogConfig) {
		this.analytics.emit(type === ProviderType.EGG_AGENCY ? 'donors' : 'surrogacy', 'appointment', 'open-popup');
		return this.matDialog.open(ContactAgencyComponent, {
			maxWidth: '636px',
			width: '100%',
			position: { top: '0' },
			panelClass: 'no-top-borders',
			scrollStrategy: new StaticScrollBlockStrategy(),
			data: {
				type,
				...config,
				name: this.authService.value.name,
			},
		});
	}

	fetchConfigById(calendarId: string) {
		if (this.configCache[calendarId]) {
			return this.configCache[calendarId];
		}
		return (this.configCache[calendarId] = this.http.get<Config>('/api/v2/providers/config/' + calendarId).pipe(
			map(
				(config) =>
					config && {
						...config,
						duration_of_meetings: Array.isArray(config.duration_of_meetings)
							? config.duration_of_meetings
							: [config.duration_of_meetings],
					},
			),
			shareReplay(1),
		));
	}

	getBusy(calendarId: string, start: string, duration: number, count: number) {
		const cacheId = `${calendarId}_${start}`;
		if (this.busyCache[cacheId]?.timestamp > DateTime.local().minus({ minutes: 2 })) {
			return this.busyCache[cacheId].value$;
		}
		const value$ = this.http
			.get<{ events: RawBusy[]; next_available: string }>('/api/v2/providers/calendar', {
				params: new HttpParams({
					fromObject: {
						provider_id: calendarId,
						start,
						days: count.toString(),
						duration: duration.toString(),
					},
					encoder: new UrlParameterEncodingCodec(),
				}),
			})
			.pipe(
				map((res) => ({
					events: res.events.map((item) => Interval.fromISO(`${item.start}/${item.end}`)),
					next_available: res.next_available
						? DateTime.fromISO(res.next_available)
						: DateTime.fromISO(start).plus({ week: 1 }).minus({ day: 1 }),
				})),
				catchError((error) => {
					console.error(error);
					return of({ events: null as Interval[], next_available: null as DateTime });
				}),
				shareReplay(1),
			);
		this.busyCache[cacheId] = { timestamp: DateTime.local(), value$ };
		return value$;
	}

	parseCalendar(
		startOfDay: DateTime,
		numberOfDays: number,
		busy: Interval[],
		{ time_zone = 'UTC', availability = [], amount_before_meeting = 0 }: Config,
		duration: number,
		displayZone: string,
		everyNthMinute: number,
	) {
		if (busy === null || !duration) return [];
		// console.time('[parseCalendar]');
		const config = new Map<Day, Array<{ start: DateTime; end: DateTime }>>();
		const zeroDate = DateTime.local().setZone(displayZone, { keepLocalTime: true }).startOf('day');
		for (const item of availability)
			for (const day of item.days)
				config.set(day, [
					...(config.get(day) || []),
					{
						start: zeroDate.plus({ minutes: item.start }).setZone(time_zone, { keepLocalTime: true }),
						end: zeroDate.plus({ minutes: item.end }).setZone(time_zone, { keepLocalTime: true }),
					},
				]);

		const result: Calendar = [];
		const startOfLastDay = startOfDay.plus({ days: numberOfDays });
		const now = DateTime.local().setZone(displayZone, { keepLocalTime: true }).plus({ minutes: amount_before_meeting });
		while (startOfDay < startOfLastDay) {
			const dayOfWeek = startOfDay.setLocale('en').weekdayShort.toUpperCase() as Day;
			const day = config.get(dayOfWeek);

			if (day) {
				const schedule: DateTime[] = [];
				for (const av of day) {
					const start = startOfDay.plus(av.start.diff(zeroDate));
					const end = startOfDay.plus(av.end.diff(zeroDate));
					for (
						let current = start;
						current <= end.minus({ minutes: duration });
						current = current.plus({ minutes: everyNthMinute })
					) {
						if (current > now) {
							const meeting = Interval.fromDateTimes(current, current.plus({ minutes: duration }));
							const isBusy = busy.find((item) => item.overlaps(meeting));
							if (!isBusy) {
								schedule.push(current);
							}
						}
					}
				}
				result.push(
					schedule.length
						? orderBy(
								uniqBy(schedule, (d) => d.toMillis()),
								(d) => d.toMillis(),
								'asc',
						  )
						: null,
				);
			} else {
				result.push(null);
			}

			startOfDay = startOfDay.plus({ days: 1 });
		}
		// console.timeEnd('[parseCalendar]');

		const maxLength = result.reduce((prev, cur) => (cur?.length > prev ? cur.length : prev), 0);

		return result.map((item) =>
			item
				? item.length < maxLength
					? (item.push(...Array.from<null>({ length: maxLength - item.length }).fill(null)), item)
					: item
				: null,
		);
	}

	getCalendar(calendarId: string, startFromDate: DateTime, numberOfDays: number, duration = 60, timezone: string) {
		return forkJoin([
			this.getBusy(calendarId, startFromDate.startOf('day').toISO(), duration, numberOfDays),
			this.fetchConfigById(calendarId),
		]).pipe(
			tap(() =>
				this.getBusy(
					calendarId,
					startFromDate.plus({ days: numberOfDays }).startOf('day').toISO(),
					duration,
					numberOfDays,
				).subscribe(),
			),
			map(([busy, config]) =>
				this.parseCalendar(
					startFromDate.startOf('day'),
					numberOfDays,
					busy.events,
					config || ({} as Config),
					duration,
					timezone || timezoneService.timezone,
					AgencyScheduleEveryNthMinute,
				),
			),
		);
	}

	getCalendarIds() {
		if (this.idsCache.timestamp > DateTime.local().minus({ minutes: 20 })) {
			return of(this.idsCache.ids);
		}
		return this.http.get<this['idsCache']['ids']>('/api/v2/providers/contacts').pipe(
			tap((res) => {
				this.idsCache.ids = res;
				this.idsCache.timestamp = DateTime.local();
			}),
		);
	}

	createAppointment(data: CreateAppointment) {
		this.analytics.emit(data.eggDonorId ? 'donors' : 'surrogacy', 'appointment', 'schedule');
		return this.http.post('/api/v2/deals/appointments', data).pipe(tap(() => this._appointmentsScheduler.next()));
	}

	getAppointments() {
		return this._appointments$;
	}

	fetchUpcomingAppointments(providerId: number) {
		return this.http.get<{ appointments: Appointment[] }>('/api/user/scheduled-appointments', { params: { providerId } });
	}

	cancelAppointment(id: number) {
		return this.http
			.delete<Appointment & { provider_contact: { calendar_id: string } }>('/api/v2/deals/appointments', {
				params: {
					appointmentId: id.toString(),
				},
			})
			.pipe(tap(() => this._appointmentsScheduler.next()));
	}

	confirmAppointment(id: number) {
		return this.http
			.put<void>('/api/v2/deals/appointments', {
				appointmentId: id,
			})
			.pipe(tap(() => this._appointmentsScheduler.next()));
	}

	getContacts(id: number, type: ProviderType) {
		return this.http
			.get<{ contacts: ChatContact[] }>('/api/v2/providers/filter-contacts', {
				params: {
					providerId: id.toString(),
					type,
				},
			})
			.pipe(map(({ contacts }) => contacts));
	}
}

export class UrlParameterEncodingCodec extends HttpUrlEncodingCodec {
	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
}
