import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Query, Store, StoreConfig} from '@datorama/akita';
import {BehaviorSubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {ProviderType} from '../config';
import {Day} from './company.service';
import {Location} from './location.service';

export interface ProvidersState {}

export function createInitialState(): ProvidersState {
	return {};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'provider'})
export class ProvidersStore extends Store<ProvidersState> {
	constructor() {
		super(createInitialState());
	}
}

@Injectable({providedIn: 'root'})
export class ProvidersQuery extends Query<ProvidersState> {
	constructor(protected store: ProvidersStore) {
		super(store);
	}
}

@Injectable({providedIn: 'root'})
export class ProvidersStoreService {
	public state = new BehaviorSubject<boolean>(null);

	constructor(private store: ProvidersStore, private query: ProvidersQuery, private http: HttpClient) {}

	get loading() {
		return this.query.selectLoading();
	}

	getProviders(sort: string = '', order: string = '', page = 0, count = 30, filter: Record<string, any> = {}) {
		return this.http.get<{count: number; data: Record<string, any>[]; page?: number}>('/api/providers/all', {
			params: {
				sort,
				order,
				page: page.toString(),
				count: count.toString(),
				filter: filter.text || '',
				filter_by_status: filter.status || '',
				id: filter.id || '',
			},
		});
	}

	updateProviderStatus(id: number, status: string) {
		return this.http.put('/api/providers/status', {id, status});
	}

	updateProvider(
		id: number,
		providerProps: Partial<{
			sponsorshipPriority: number;
			sponsoredDonors: number;
			sponsoredDonorsCount: number;
			sponsoredSurrogates: number;
			sponsoredSurrogatesCount: number;
		}>,
	) {
		return this.http.patch(`/api/providers/${id}`, providerProps);
	}

	updateSurrogacyStatus(id: number, status: string) {
		return this.http.put('/api/v2/surrogacy/status/' + id, {status});
	}

	checkCosts() {
		return this.http.get<{status: boolean}>('/api/providers/check-costs').pipe(map((res) => res.status));
	}

	getCosts() {
		this.store.setLoading(true);

		return this.http
			.get<{values: Record<string, number | string>; comments: Record<string, string>}>('/api/providers/costs')
			.pipe(tap(() => this.store.setLoading(false)));
	}

	getFullCosts(id?: number) {
		return this.http.get<Cost[]>('/api/v2/providers/costs', {params: {id: (id || '').toString()}}).pipe(
			map((res) => {
				const result = {};
				for (const key in ProviderType) {
					result[ProviderType[key]] = {};
				}
				for (const item of res) {
					if (!item.type) continue;
					result[item.type][item.name] = {...item};
				}
				return result as Record<ProviderType, Record<string, Partial<Cost>>>;
			}),
		);
	}

	getDeals(type: ProviderType, sort?: string, order?: string, page = 0, count = 30, filter: Record<string, any> = {}) {
		this.store.setLoading(true);

		const textFilters = {
			filter: filter.text,
			filter_by_status: filter.status,
			filter_by_company: filter.company,
		};
		for (const item in textFilters) {
			if (!textFilters[item]) delete textFilters[item];
		}

		return this.http
			.get<{count: number; data: Record<string, any>[]; page?: number}>(
				type === ProviderType.EGG_AGENCY ? '/api/deals' : '/api/v2/deals/surrogacy',
				{
					params: {
						sort,
						order,
						page: page.toString(),
						count: count.toString(),
						leads_only: (filter.leads || false).toString(),
						deals_only: (filter.deals || false).toString(),
						id: filter.id,
						...textFilters,
					},
				},
			)
			.pipe(
				map((res) => {
					this.store.setLoading(false);
					for (const item of res.data) {
						item.created_at = new Date(item.created_at);
						item.updated_at = new Date(item.updated_at);
						if (item.appointment_time) {
							item.appointment_time = new Date(item.appointment_time);
						}
						if (item.questionnaire) {
							item.questionnaire = this.mapFilters(item.questionnaire);
						}
						item.name = `${item.user.first_name} ${item.user.last_name}`;
						item.partner_name = item.user.partner_first_name
							? `${item.user.partner_first_name} ${item.user.partner_last_name}`
							: null;
						item.company_name = item.provider ? item.provider.company_name : null;
						item.email = item.user.email;
						if (item.egg_donor) {
							item.origin_id = item.egg_donor.origin_id;
							item.theirsId = item.egg_donor.theirsId;
						}
					}
					return res;
				}),
			);
	}

	updateDeal(id: number, status: DealsStatus | LeadsStatus | string, type: ProviderType) {
		return this.http.put('/api/v2/deals', {id, status, type});
	}

	private mapFilters(response: {[key: string]: any}) {
		const filters = {} as Record<string, any>;
		for (const key in response) {
			switch (key) {
				case 'eye_color':
				case 'hair_color':
				case 'race':
				case 'religion':
				case 'ethnicity':
					if (Array.isArray(response[key]) && response[key].length) filters[key] = response[key];
					break;
				case 'high_school_gpa':
				case 'act':
				case 'sat':
				case 'college_gpa':
					if (!Array.isArray(response[key]) || !response[key].filter((item) => item).length) {
						break;
					}
					if (!response[key][0]) {
						filters[key] = `<${response[key][1]}`;
					} else if (!response[key][1]) {
						filters[key] = `>${response[key][0]}`;
					} else {
						filters[key] = response[key];
					}
					break;
				default:
					if (response[key] !== null) {
						filters[key] = response[key];
					}
					break;
			}
		}
		return filters;
	}

	uploadFile(file: File, type: string) {
		const form = new FormData();
		form.append('file', file, file.name);
		return this.http.post<{file: string}>('/api/v2/upload-file', form, {reportProgress: true, observe: 'events', params: {type}});
	}

	startUploadJob(files: FileList) {
		const form = new FormData();
		for (let i = 0; i < files.length; i++) {
			const file = files.item(i);
			form.append('file', file, file.name);
		}
		return this.http.post<{id: string}>('/api/v1/upload-jobs', form, {reportProgress: true, observe: 'events'});
	}

	getUploadProgress(uuid: string) {
		return this.http.get<{id: string; status: string; progress: number}>(`/api/v1/upload-jobs/${uuid}`).toPromise();
	}

	getCompleteProfile() {
		return this.http.get<CompleteProfile>('/api/v2/providers/me').pipe(
			map((value) => ({
				...value,
				calendar: {
					...value.calendar,
					duration_of_meetings: Array.isArray(value.calendar.duration_of_meetings)
						? value.calendar.duration_of_meetings
						: [value.calendar.duration_of_meetings],
				},
			})),
		);
	}

	updateProfile(type: string, content: Record<string, any>) {
		return this.http.put('/api/v2/providers', content, {params: {type}});
	}
}

export enum LeadsStatus {
	New = 'new',
	WelcomeEmail = 'welcome_email',
	ConsultationPhoneCall = 'consultation_phone_call',
	DonorMatching = 'donor_matching',
	NegotiatingAgencyContract = 'negotiating_agency_contract',
}
export enum DealsStatus {
	AgencyContractSigning = 'agency_contract_signing',
	NegotiatingEggDonorContract = 'negotiating_egg_donor_contract',
	EggDonorContractSigning = 'egg_donor_contract_signing',
	ParentTransferringMoneyToAgency = 'parent_transferring_money_to_agency',
	PaymentToGostork = 'payment_to_gostork',
}

export interface IDeal {
	created_at: Date;
	donor_id: number;
	egg_donor: {
		id: number;
		origin_id: string;
		origin: string;
	};
	id: number;
	parent_id: number;
	status: LeadsStatus | DealsStatus;
	updated_at: Date;
	user: {
		id: number;
		email: string;
		first_name: string;
		last_name: string;
		phone: string;
		address: string;
		partner_first_name: string;
		partner_last_name: string;
	};
	name: string;
	partner_name: string;
	origin_id: string;
	provider: Record<string, any>;
	company_name?: string;
}

export interface IDealSur {
	appointment_time: Date;
	created_at: Date;
	updated_at: Date;
	id: number;
	status: SurrogacyAppointmentStatus;
	user: {
		id: number;
		address: string;
		email: string;
		first_name: string;
		last_name: string;
		partner_first_name: string;
		partner_last_name: string;
		phone: string;
	};
}

export interface CompleteProfile {
	agency_account: {
		company_name: string;
		company_type: string[];
		ivf?: {
			location: Location[];
		};
		location: {
			unit: string;
			address: string;
			zip: string;
			city: string;
			state: string;
			country: string;
			latlng: {
				lat: number;
				lng: number;
			};
		}[];
	};
	users: {
		id: number;
		calendar_id: string;
		email: string;
		first_name: string;
		last_name: string;
		phone: string;
		role: string[];
		with_calendar: boolean;
		location: Location[] | null;
		current?: true;
	}[];
	calendar: {
		time_zone: string;
		availability: {
			days: Day[];
			start: number;
			end: number;
		}[];
		duration_of_meetings: number | number[];
		subject_of_meeting_invitation: string;
		location_of_meeting: string;
		meeting_invitation_description: string;
	};
}

export enum SurrogacyAppointmentStatus {
	BOOKED = 'booked',
	FOLLOW_UP_EMAIL = 'follow_up_email',
	FOLLOW_UP_CALL = 'follow_up_call',
	WELCOME_EMAIL = 'welcome_email',
	CONSULTATION_PHONE_CALL = 'consultation_phone_call',
	NO_REPLY = 'no_reply',
	CANCELED = 'canceled',
	AGENCY_CONTRACT_SIGNING = 'agency_contract_signing',
	COMPLETED = 'completed',
}

export interface Cost {
	value?: number;
	max_value?: number;
	special_value?: string;
	type: ProviderType;
	name: string;
	used_as: string;
	message: string;
	included: boolean;
	services: string[];
}
