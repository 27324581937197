import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-coming-soon',
	templateUrl: './coming-soon.component.html',
	styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent {

	constructor(
		private http: HttpClient
	) { }

	readonly required = [Validators.required, Validators.email];
	data: string;
	disabled = false;

	onSubmit() {
		this.disabled = true;
		this.http.post('/api/subscribe', { email: this.data }).subscribe();
	}
}
