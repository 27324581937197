import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DateTime, Interval} from 'luxon';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';

import {ProviderType} from '../config';
import {RawBusy} from './company.service';
import {ContactAgencyService, UrlParameterEncodingCodec} from './contact-agency.service';
import {Deal} from './deals.service';
import {Annexe} from './parent.service';

const AdminScheduleEveryNthMinute = 30;

@Injectable({providedIn: 'root'})
export class AdminService {
	constructor(private http: HttpClient, private contactAgency: ContactAgencyService) {}

	getUsers(sort = '', order = '', page = 0, count = 30, filter = '', range = {start: '', end: ''}) {
		return this.http
			.get<{count: number; data: User[]; page: number}>('/api/v2/admin/users', {
				params: {
					sort,
					order,
					page: page.toString(),
					count: count.toString(),
					filter,
					...range,
				},
			})
			.pipe(
				map((res) => {
					res.data = res.data.map((item) => {
						item.created_at = item.created_at && new Date(item.created_at);
						item.verified_at = item.verified_at && new Date(item.verified_at);
						return item;
					});
					return res;
				}),
			);
	}

	getAdminUser(sort = '', order = '') {
		return this.http.get<{users: User[]}>('/api/v2/admin/adminUsers', {
			params: {
				sort,
				order,
			},
		});
	}

	updateRole(id: number, role: Role) {
		return this.http.post('/api/v2/admin/updateUserRole', {userId: id, role});
	}

	createUser(data: CreatUserRequets) {
		return this.http.post<{password: string}>('/api/v2/admin/createUserWithRole', data);
	}

	updateUserStatus(id: number, status: boolean) {
		return this.http.post<void>('/api/v2/admin/user/status', {id, status});
	}

	changeVerificationStatus(id: number, verificationState: boolean) {
		return this.http.post<void>('/api/user/verify-manually', {id, verificationState});
	}

	deleteUser(id: number) {
		return this.http.delete<void>(`/api/v2/admin/user/${id}`);
	}

	calendars$: Observable<any[]>;

	getCalendars() {
		if (!this.calendars$) {
			this.calendars$ = this.http.get<Record<string, any>[]>('/api/v2/admin/calendars').pipe(
				map((configs) => configs.filter((config) => config.duration_of_meetings && config.availability)),
				shareReplay(),
			);
		}

		return this.calendars$;
	}

	getBusy(calendarId: string, start: string, duration: number, count: number) {
		const value$ = this.http
			.get<{events: RawBusy[]}>('/api/v2/admin/events', {
				params: new HttpParams({
					fromObject: {
						calendarId,
						start,
						days: count.toString(),
						duration: duration.toString(),
					},
					encoder: new UrlParameterEncodingCodec(),
				}),
			})
			.pipe(
				map((res) => ({
					events: res.events.map((item) => Interval.fromISO(`${item.start}/${item.end}`)),
				})),
				catchError((error) => {
					console.error(error);
					return of({events: null as Interval[]});
				}),
			);
		return value$;
	}

	getAdminCalendar(calendarId: string, config: any, startFromDate: DateTime, numberOfDays: number, duration = 60, timezone: string) {
		return this.getBusy(calendarId, startFromDate.startOf('day').toISO(), duration, numberOfDays).pipe(
			map((busy) =>
				this.contactAgency.parseCalendar(
					startFromDate.startOf('day'),
					numberOfDays,
					busy.events,
					config || {},
					duration,
					timezone,
					AdminScheduleEveryNthMinute,
				),
			),
		);
	}

	scheduleAppointment(calendarId: string, appointmentTime: string, duration: number, oldAppointmentId?: number) {
		return this.http.post('/api/v2/admin/scheduleAppointment', {
			calendarId,
			appointmentTime,
			duration,
			oldAppointmentId,
		});
	}

	removeAppointment(appointmentId: number) {
		return this.http.post('/api/v2/admin/removeAppointment', {
			id: appointmentId,
		});
	}

	confirmAppointment(appointmentId: number) {
		return this.http.post('/api/v2/admin/confirmAppointment', {
			id: appointmentId,
		});
	}
}

export enum Identify {
	HETEROSEXUAL = 'heterosexual',
	LGBT = 'lgbt',
}

export enum Relationship {
	MARRIED = 'married',
	COMMITTED_RELATIONSHIP = 'committed_relationship',
	SINGLE = 'single',
}

export enum SurrogateRelationshipStatus {
	MARRIED = 'married',
	COMMITTED_RELATIONSHIP = 'partnered',
	SINGLE = 'single',
	CIVIL_UNION = 'civil_union',
	DIVORCED = 'divorced',
	SEPERATED = 'seperated',
}

export enum Role {
	Admin = 'admin',
	Analytic = 'analytic',
	Concierge = 'concierge',
	User = 'user',
}

export interface User {
	id: number;
	first_name: string;
	last_name: string;
	birthday: string;
	email: string;
	phone: string;
	identify: Identify;
	relation: Relationship;
	address: string;
	unit: string;
	country: string;
	state: string;
	city: string;
	zip: string;
	partner_first_name: string;
	partner_last_name: string;
	created_at: Date;
	verified_at: Date;
	product: ProviderType[];
	status: boolean;
	deals: Deal[];
	user_annexe: Annexe[];

	role: Role;

	location: {
		address: string;
		country: string;
		state: string;
		city: string;
	};
}

export interface CreatUserRequets {
	email: string;
	firstName: string;
	lastName: string;
	role: Role;
}
