import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MaterialModule} from '../../material.module';
import {AdminComponent} from './admin/admin.component';
import {UsersListComponent} from './list/list.component';

import {UsersRoutingModule} from './users-routing.module';

@NgModule({
	declarations: [UsersListComponent, AdminComponent],
	imports: [CommonModule, UsersRoutingModule, MaterialModule, FormsModule, ReactiveFormsModule],
	exports: [AdminComponent],
})
export class UsersModule {}
