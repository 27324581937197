<!-- breadcrumb.component.html -->
<mat-toolbar>
	<ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
		<a mat-button *ngIf="!last; else lastBreadcrumb" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
		<ng-template #lastBreadcrumb>
			<span class="breadcrumb-label">{{ breadcrumb.label }}</span>
		</ng-template>
		<mat-icon *ngIf="!last">chevron_right</mat-icon>
	</ng-container>
</mat-toolbar>
