import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MaterialModule} from '@/material.module';

import {FormFieldModule} from '../form-field/form-field.module';

import {IvfFiltersComponent} from './ivf-filters.component';

@NgModule({
	declarations: [IvfFiltersComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, FormFieldModule, MaterialModule],
	exports: [IvfFiltersComponent],
})
export class IvfFiltersModule {}
