import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CalendarModule } from '@/components/calendar/calendar.module';
import { CheckboxModule } from '@/components/checkbox/checkbox.module';
import { HeartModule } from '@/components/heart/heart.module';
import { LocationEntryModule } from '@/components/location-entry/location-entry.module';
import { VideoModule } from '@/components/video/video.module';
import { MaterialModule } from '@/material.module';
import { PipesModule } from '@/pipes/pipes.module';
import { IvfCardComponent } from '@/screens/ivf/ivf-card/ivf-card.component';
import { NumericGroupModule } from '@/screens/ivf/numeric-group/numeric-group.module';

@NgModule({
	declarations: [IvfCardComponent],
	imports: [
		MaterialModule,
		CommonModule,
		HeartModule,
		CheckboxModule,
		RouterModule,
		CalendarModule,
		LocationEntryModule,
		NumericGroupModule,
		VideoModule,
		FormsModule,
		PipesModule,
	],
	exports: [IvfCardComponent],
})
export class IvfCardModule {}
