<div class="filters">
    <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <input matInput [formControl]="filter" />
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date" autocomplete="off" />
            <input matEndDate formControlName="end" placeholder="End date" autocomplete="off" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
</div>

<div class="table-container">
    <div *ngIf="loading" class="loader">
        <mat-spinner color="accent" diameter="36"></mat-spinner>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matSortDisableClear matSortDirection="desc" matSortActive="id" multiTemplateDataRows>
        <ng-container matColumnDef="open">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="expandRow(element, $event)">
					<mat-icon class="expand-arrow">{{ element === expandedElement ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
				</button>
            </td>
        </ng-container>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element.id }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Full Name</th>
            <td mat-cell *matCellDef="let element">
                <span>
					<a routerLink="/providers/deals" [queryParams]="{filter: element.name, type: element.type}" [class.empty]="!element.type">{{
						element.name
					}}</a>
				</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let element">
                <span>
					<a href="mailto:{{ element.email }}">{{ element.email }}</a>
				</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let element">
                <span>
					<a href="tel:{{ element.phone }}">{{ element.phone }}</a>
				</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="ed_annexe_created_at">
            <th mat-header-cell *matHeaderCellDef>ED Profile</th>
            <td mat-cell *matCellDef="let element">
                <span>
					{{ element.annexe_created_at.egg_agency | date: 'short' }}
				</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="sur_annexe_created_at">
            <th mat-header-cell *matHeaderCellDef>SUR Profile</th>
            <td mat-cell *matCellDef="let element">
                <span>
					{{ element.annexe_created_at.surrogacy | date: 'short' }}
				</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="ivf_annexe_created_at">
            <th mat-header-cell *matHeaderCellDef>IVF Profile</th>
            <td mat-cell *matCellDef="let element">
                <span>
					{{ element.annexe_created_at.ivf_clinic | date: 'short' }}
				</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="verified_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Is Verified</th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle color="primary" [checked]="!!element.verified_at" (change)="changeVerificationStatus(element.id, $event.checked)"></mat-slide-toggle>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle color="primary" [checked]="element.status" (change)="updateUserStatus(element.id, $event.checked)"></mat-slide-toggle>
            </td>
        </ng-container>

        <ng-container matColumnDef="stripe_subscription_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stripe Subscription Status</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element.stripe_subscription_status }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="stripe_subscription_length">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stripe Subscription Length</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element.stripe_subscription_length ? element.stripe_subscription_length+' Months ' : '' }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="stripe_subscription_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stripe Subscription Amount</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element.stripe_subscription_amount ? '$'+element.stripe_subscription_amount : '' }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{id: element.id}">
					<mat-icon>more_vert</mat-icon>
				</button>
            </td>
        </ng-container>

        <ng-container matColumnDef="details">
            <td mat-cell *matCellDef="let element" [attr.colSpan]="columns.length">
                <div class="details" [class.expanded]="expandedElement === element">
                    <table>
                        <tr *ngFor="let row of detailsColumns">
                            <td>
                                <b>{{ row.n }}</b>
                            </td>
                            <td>{{ element[row.s] }}</td>
                        </tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsSelectors"></tr>
        <tr mat-row class="element-row" *matRowDef="let element; columns: columnsSelectors"></tr>
        <tr mat-row class="element-details-row" *matRowDef="let element; columns: ['details']"></tr>
    </table>
    <mat-paginator [length]="length" [pageSize]="rowsCount"></mat-paginator>
</div>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-id="id">
        <button mat-menu-item (click)="deleteUser(id)">
			<mat-icon>delete</mat-icon>
			<span>Delete</span>
		</button>
    </ng-template>
</mat-menu>