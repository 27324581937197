import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	Input,
	EventEmitter,
	OnInit,
	ChangeDetectorRef,
	ElementRef,
	Output,
	HostBinding,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ContactAgencyService} from 'src/app/services/contact-agency.service';
import {Appointment} from 'src/app/services/parent.service';
import {timezoneService} from 'src/app/services/timezone.service';
import {AdminService} from '@/services/admin.service';
import {Calendar} from '../../services/company.service';
import {CalendarHeaderComponent} from './calendar-header/calendar-header.component';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
	@ViewChild(CalendarHeaderComponent, {static: true})
	headerRef: CalendarHeaderComponent;

	buttonHeight = 40;
	buttonsPerColumn = 7;
	numberOfDays = 4;

	isRescheduling = false;

	@Input()
	existingAppointment: Appointment;

	@Input()
	adminConfig: any;

	@Output()
	timezoneChange: EventEmitter<string> = new EventEmitter<string>();

	get appointmentTimeJS() {
		return this.existingAppointment?.appointment_time.toJSDate();
	}

	private _timezone = timezoneService.timezone;
	public get timezone() {
		return this._timezone;
	}
	@Input()
	public set timezone(value) {
		if (this._timezone === value) {
			return;
		}
		this._timezone = value;
		this.timezoneChange.emit(value);
		this.updateCalendar(this.lastStartFromDate);
	}

	@HostBinding('class.exists')
	get disableCalendar() {
		return this.existingAppointment && !this.isRescheduling;
	}

	_forceMobile = false;
	@Input()
	set forceMobile(value: boolean) {
		console.log('='.repeat(100), value);
		this._forceMobile = coerceBooleanProperty(value);
		if (this._forceMobile) {
			this.elementRef.nativeElement.classList.add('mobile');
			this.buttonsPerColumn = 6;
			this.buttonHeight = 34;
		} else {
			this.elementRef.nativeElement.classList.remove('mobile');
			this.buttonsPerColumn = 7;
			this.buttonHeight = 40;
		}
	}
	get forceMobile() {
		return this._forceMobile;
	}

	_calendarId: string;
	@Input()
	set calendarId(value: string) {
		this._calendarId = value;
		this.updateCalendar(this.headerRef.startFromDate);
	}
	get calendarId() {
		return this._calendarId;
	}

	@Input()
	public get duration() {
		return this._duration;
	}
	public set duration(value) {
		this._duration = value;
		this.updateCalendar(this.headerRef.startFromDate);
	}
	private _duration = 60;

	@Output()
	cellClick = new EventEmitter<DateTime>();
	@Output()
	deleteAppointment = new EventEmitter<number>();
	@Output()
	updateAppointment = new EventEmitter<{date: DateTime; id: number}>();

	startFromSubscription: Subscription;
	lastStartFromDate = DateTime.local().setZone(this.timezone, {keepLocalTime: true});

	_calendar: Calendar = null;
	meetingLocation = '';
	isCalendarLoading = false;
	isMoreActive = false;
	emptyList = [];
	titleDate = [];

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private elementRef: ElementRef<HTMLElement>,
		private contactAgency: ContactAgencyService,
		private adminService: AdminService,
	) {}

	updateCalendar(startFromDate: DateTime) {
		startFromDate = startFromDate.setZone(this.timezone, {keepLocalTime: true});

		this.isCalendarLoading = true;
		this.isMoreActive = false;
		this.elementRef.nativeElement.classList.remove('more');
		setTimeout(() => this.elementRef.nativeElement.scrollTo({top: 0, behavior: 'smooth'}));
		this._calendar = Array.from({length: this.numberOfDays});
		this.emptyList = Array.from({length: this.buttonsPerColumn});
		if (!this.calendarId) {
			return;
		}

		(this.adminConfig
			? this.adminService.getAdminCalendar(
					this.calendarId,
					this.adminConfig,
					startFromDate,
					this.numberOfDays,
					this.duration,
					this._timezone,
			  )
			: this.contactAgency.getCalendar(this.calendarId, startFromDate, this.numberOfDays, this.duration, this._timezone)
		)
			.pipe(
				catchError((err: HttpErrorResponse) => {
					if (err.status !== 400) console.error(err);
					return null;
				}),
			)
			.subscribe((calendar: Calendar | null) => {
				if (!calendar || calendar.length === 0) return;
				this._calendar = calendar;
				this.emptyList = Array.from({length: this._calendar.find((item) => item)?.length || this.buttonsPerColumn});
				this.isCalendarLoading = false;
				this.changeDetectorRef.detectChanges();
				if (this.adminConfig) {
					this.meetingLocation = this.adminConfig.location_of_meeting;
				} else {
					this.contactAgency
						.fetchConfigById(this.calendarId)
						.subscribe((config) => (this.meetingLocation = config?.location_of_meeting || ''));
				}
			});
		this.titleDate = Array.from<DateTime>({length: this.numberOfDays}).map((_, index) =>
			startFromDate.startOf('day').plus({days: index}),
		);
	}

	expand() {
		this.isMoreActive = true;
		this.elementRef.nativeElement.classList.add('more');
		setTimeout(() => this.elementRef.nativeElement.scrollTo({top: 40 * (this.buttonsPerColumn - 2), behavior: 'smooth'}));
	}

	ngOnInit() {
		this.updateCalendar(DateTime.local());
		this.startFromSubscription = this.headerRef.startDateChanged.asObservable().subscribe((startFromDate) => {
			this.lastStartFromDate = startFromDate;
			this.updateCalendar(startFromDate);
		});
	}

	ngOnDestroy() {
		this.startFromSubscription?.unsubscribe();
	}
}
