import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

import {cloneDeep} from 'lodash-es';
import {NIvfFilters} from '@/models/ivf.model';

import {options} from '../questions';

const changableQuestions = ['buildingFamily', 'carrier', 'eggsOwner', 'eggsOwnerAge', 'inVirto'];

@Component({
	selector: 'app-ivf-journey',
	templateUrl: './ivf-journey.component.html',
	styleUrls: ['./ivf-journey.component.scss'],
})
export class IvfJourneyComponent implements OnChanges {
	@Input()
	buildingFamily: NIvfFilters.BuildingFamily;
	@Input()
	carrier: NIvfFilters.PregnancyCarreir;
	@Input()
	eggsOwner: NIvfFilters.EggsOwner;
	@Input()
	eggsOwnerAge: number;
	@Input()
	inVirto: NIvfFilters.InVirto;

	@Input()
	expandDown = false;

	@Output()
	changeValue = new EventEmitter<{id: string; value: any}>();

	changableQuestions: Array<{id: string; label: string}> = [];
	changingQuestionId: string;
	changingPopupX = 0;
	popupRoot: HTMLElement = null;

	get eggsOwnerName() {
		return this.buildingFamily === NIvfFilters.BuildingFamily.Freezing ||
			this.eggsOwner === NIvfFilters.EggsOwner.DonorEggs ||
			this.eggsOwner === NIvfFilters.EggsOwner.MyEggs
			? 'eggs'
			: 'embryos';
	}

	get changingQuestionValue() {
		switch (this.changingQuestionId) {
			case 'buildingFamily':
				return this.buildingFamily;
			case 'carrier':
				return this.carrier;
			case 'eggsOwner':
				return this.eggsOwner;
			case 'eggsOwnerAge':
				return this.eggsOwnerAge;
			case 'inVirto':
				return this.inVirto;
		}
	}

	inputValue = '';

	options = cloneDeep(options);

	get questions() {
		const questionsToIgnore = [];

		if (this.buildingFamily === NIvfFilters.BuildingFamily.Freezing) {
			questionsToIgnore.push('eggsOwner', 'carrier', 'spermDonor', 'inVirto');
		}

		if (
			this.buildingFamily !== NIvfFilters.BuildingFamily.Freezing &&
			(this.eggsOwner === NIvfFilters.EggsOwner.DonorEggs || this.eggsOwner === NIvfFilters.EggsOwner.DobnorEmbryo)
		) {
			questionsToIgnore.push('eggsOwnerAge');
		}

		if (this.carrier !== NIvfFilters.PregnancyCarreir.GestationalCarrier) {
			questionsToIgnore.push('gestationalCarrier');
		}

		if (this.carrier === NIvfFilters.PregnancyCarreir.Reciprocal) {
			this.options.eggsOwner = options.eggsOwner.slice(0, 2);
		} else {
			this.options.eggsOwner = options.eggsOwner;
		}

		return changableQuestions.filter((item) => !questionsToIgnore.includes(item));
	}

	constructor(private breakpointObserver: BreakpointObserver) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changableQuestions.some((key) => changes[key])) {
			this.updateReview();
		}
	}

	updateReview() {
		const result = [];
		const questions = this.questions;

		const getSimple = (s: string, v: any) =>
			questions.includes(s) &&
			result.push({
				id: s,
				label: (options[s].find((item) => item.value === v) || options[s][0]).label,
			});

		result.push({
			id: 'buildingFamily',
			label: this.buildingFamily === NIvfFilters.BuildingFamily.Pregnancy ? 'Live Birth' : 'Freeze My Eggs',
		});

		getSimple('eggsOwner', this.eggsOwner);

		if (questions.includes('eggsOwnerAge')) {
			result.push({
				id: 'eggsOwnerAge',
				label: `Age: ${this.eggsOwnerAge || ''}`,
			});
		}

		getSimple('carrier', this.carrier);

		if (questions.includes('inVirto')) {
			result.push({
				id: 'inVirto',
				label: (this.inVirto === NIvfFilters.InVirto.Yes ? 'With' : 'No') + ' Past Treatment',
			});
		}

		this.changableQuestions = result;

		if (this.changingQuestionId !== 'eggsOwnerAge') {
			this.changingQuestionId = undefined;
		}
	}

	openChangePopup(id: string, event: MouseEvent) {
		this.changingQuestionId = this.changingQuestionId === id ? undefined : id;

		const isDesktop = this.breakpointObserver.isMatched('(min-width: 813px)');
		if (!isDesktop) {
			return;
		}

		let element = event.target as HTMLElement;
		do {
			element = element.parentElement;
		} while (element && !element.classList.contains('journeyAnswer'));

		element = element || (event.target as HTMLElement);

		this.changingPopupX = element.offsetLeft - element.clientWidth / 2;
	}

	changeAnswer(value: any) {
		this.changeValue.next({
			id: this.changingQuestionId,
			value,
		});

		this.changingQuestionId = undefined;
		this.popupRoot = null;
	}

	onInput(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.changeAnswer(this.inputValue);
			setTimeout(() => (this.changingQuestionId = undefined));
		}
	}
}
