import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
//import { NgxStripeModule } from 'ngx-stripe';

import { TimezoneDateModule } from '@/pipes/timezoneDate.module';
import { TimezoneDatePipe } from '@/pipes/timezoneDate.pipe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, TComponent } from './app.component';
import { CalendarModule } from './components/calendar/calendar.module';
import { CodePromptComponent } from './components/code-prompt/code-prompt.component';
import { FormFieldModule } from './components/form-field/form-field.module';
import { HeaderModule } from './components/header/header.module';
import { InsvgModule } from './components/insvg/insvg.module';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { NotificationComponent } from './components/notification/notification.component';
import { WhenToStartModule } from './components/when-to-start/when-to-start.module';
import { AppointmentComponent } from './screens/appointment/appointment.component';
import { ChatModule } from './screens/chat/chat.module';
import { CheckoutScreenModule } from './screens/checkout-screen/checkout-screen.module';
import { ComingSoonComponent } from './screens/coming-soon/coming-soon.component';
import { ConciergeCalendarModule } from './screens/concierge-calendar/concierge-calendar.module';
import { LoginComponent } from './screens/login/login.component';
import { ProfileParentModule } from './screens/parent-profile/profile-parent.module';
import { ProviderProfileModule } from './screens/provider-profile/provider-profile.module';

import { RequestInterceptor } from './services/auth.interceptor';
import { CheckoutService } from './services/checkout.service';
import { ContactAgencyService } from './services/contact-agency.service';
import { AppDateAdapter, APP_DATE_FORMATS } from './services/date.service';
import { SentryErrorHandler } from './services/sentry.service';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		NotFoundComponent,
		TComponent,
		NotificationComponent,
		ComingSoonComponent,
		CodePromptComponent,
		AppointmentComponent,
	],
	imports: [
		BrowserModule,
		//NgxStripeModule.forRoot('pk_test_51L5rc6KAF38xqaFONxzLpwCcdtVnN2l2MEKcpztfG7QNTmOAn62aQ1BLF5aW3gOOXAJOGp6EoACva053O3TmFtRf00BM1zJTsz'),
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		HttpClientModule,
		// lib
		environment.production ? [] : AkitaNgDevtools,
		// MaterialModule,
		MatFormFieldModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatCardModule,
		MatIconModule,
		MatInputModule,
		MatButtonModule,
		// local
		InsvgModule,
		ProfileParentModule,
		CheckoutScreenModule,
		FormFieldModule,
		ChatModule,
		HeaderModule,
		WhenToStartModule,
		ConciergeCalendarModule,
		ProviderProfileModule,
		CalendarModule,
	],
	providers: [
		{
			provide: DateAdapter,
			useClass: AppDateAdapter,
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: APP_DATE_FORMATS,
		},
		{
			provide: APP_BASE_HREF,
			useValue: '/',
		},
		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useValue: { touchGestures: 'off' },
		},
		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useValue: { showDelay: 100 },
		},
		{
			provide: ErrorHandler,
			useClass: SentryErrorHandler,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true,
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {
				duration: 5000,
			},
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				hideRequiredMarker: true,
				floatLabel: 'auto',
			},
		},
		ContactAgencyService,
		CheckoutService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
