<div class="button-container">
	<button mat-button [disabled]="disabled" (click)="moveCalendar('left')">
		<mat-icon>keyboard_arrow_left</mat-icon>
	</button>
	<div class="date-container">
		<div class="date-block" *ngFor="let date of dates">
			<span>
				<span class="time">{{ date.toJSDate() | date: 'EEE' }}</span>
			</span>
			<span>{{ date.toJSDate() | date: 'MMM d' }}</span>
		</div>
	</div>
	<button mat-button (click)="moveCalendar('right')">
		<mat-icon>keyboard_arrow_right</mat-icon>
	</button>
</div>
