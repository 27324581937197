<div class="head">
	<div></div>
	<div *ngIf="nth" class="nth" [style.backgroundColor]="colour">Program {{ nth }}</div>
</div>

<div class="name">
	<div class="label">{{ program.name }}</div>
	<small class="hint">Your Estimated Cost</small>
</div>

<mat-divider></mat-divider>

<div *ngIf="program.max_value; else singleCost" class="value">
	{{ program.value | currency: 'USD':'symbol':'1.0-1' }} to {{ program.max_value | currency: 'USD':'symbol':'1.0-1' }}
</div>
<ng-template #singleCost>
	<div class="value">{{ program.value | currency: 'USD':'symbol':'1.0-1' }}</div>
</ng-template>

<mat-divider></mat-divider>

<div class="services">
	<div *ngFor="let service of program.services" role="listitem" class="service">
		<mat-icon>check</mat-icon>
		<div>{{ service }}</div>
	</div>
</div>
