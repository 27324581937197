<svg attr.viewBox="-{{ offsetX }} {{ offsetY }} {{ width }} {{ height }}" height="100%" width="100%">
	<defs>
		<pattern id="bg" patternUnits="userSpaceOnUse" [attr.width]="56" [attr.height]="height">
			<line [attr.x1]="minX" [attr.y1]="minY + 18" [attr.x2]="minX" [attr.y2]="height" stroke="#bbbbbb" />
		</pattern>
		<pattern id="lines" patternUnits="userSpaceOnUse" [attr.width]="barWidth" [attr.height]="10">
			<line [attr.x1]="minX" [attr.y1]="0" [attr.x2]="barWidth" [attr.y2]="10" stroke="#bbbbbb" />
		</pattern>
	</defs>
	<g>
		<rect [attr.x]="minX" [attr.y]="minY" [attr.width]="maxX" [attr.height]="maxY" fill="url(#bg)" />
	</g>

	<g>
		<text
			*ngFor="let p of percentage; let index = index"
			class="percent"
			text-anchor="end"
			[attr.x]="minX - offsetX + 30"
			[attr.y]="maxY - (maxY - 10) * (index / percentage.length)"
		>
			{{ p }}
		</text>
	</g>

	<g>
		<g *ngFor="let group of rawData; let groupIndex = index">
			<ng-container *ngFor="let col of group; let colIndex = index">
				<rect
					*ngIf="col || col === 0"
					[attr.rx]="barWidth / 2"
					matTooltipPosition="above"
					[matTooltip]="col | percent"
					[attr.x]="colIndex * barWidth + groupIndex * group.length * barWidth + (groupGap * groupIndex + groupGap / 2)"
					[attr.y]="maxY - maxY * (col || 0.02)"
					[attr.width]="barWidth"
					[attr.height]="maxY * (col || 0.02)"
					[attr.fill]="colours[colIndex]"
				></rect>
				<rect
					*ngIf="col === null"
					matTooltipPosition="above"
					matTooltip="No data available"
					[attr.rx]="barWidth / 2"
					stroke="#f0efef"
					fill="url(#lines)"
					[attr.x]="colIndex * barWidth + groupIndex * group.length * barWidth + (groupGap * groupIndex + groupGap / 2)"
					[attr.y]="maxX"
					[attr.width]="barWidth"
					[attr.height]="maxY"
				></rect>
			</ng-container>
		</g>
	</g>

	<g *ngIf="withLabels && rawData && rawData[0]">
		<text
			*ngFor="let label of labels; let index = index"
			class="age"
			text-anchor="middle"
			[attr.x]="(rawData[0].length / 2) * barWidth + index * rawData[0].length * barWidth + (groupGap * index + groupGap / 2)"
			[attr.y]="maxY + offsetY - 2"
		>
			{{ label }}
		</text>
	</g>
</svg>
