const alphabet = 'ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW';

export const nanoid = (size = 8): string => {
	const bytes = new Uint8Array(size);
	crypto.getRandomValues(bytes);
	let id = '';
	while (size--) {
		id += alphabet[bytes[size] & 63];
	}
	return id;
};
