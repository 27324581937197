import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

import { AnimeInstance } from 'animejs';
import anime from 'animejs/lib/anime.es';

@Component({
	selector: 'app-toggle',
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements AfterViewInit {
	@Input()
	labels: [string, string] = ['', ''];
	@Input()
	default = false;
	@Output()
	changed = new EventEmitter<{ label: string, value: boolean }>();

	forward: AnimeInstance;
	backward: AnimeInstance;

	value: boolean =  null;

	constructor() { }

	onChange() {
		this.value = this.value === null ? !this.default : !this.value;
		if (this.value) {
			this.forward.play();
		} else {
			this.backward.play();
		}
		setTimeout(() => {
			this.changed.emit({
				label: this.labels[this.value ? 1 : 0],
				value: this.value
			});
		}, 150);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.forward = anime({
				targets: 'app-toggle .dot',
				translateX: [
					{ value: 44, duration: 300 }
				],
				scaleX: [
					{ value: 1.3, duration: 100, easing: 'easeOutExpo' },
					{ value: 1, duration: 100 }
				],
				easing: 'easeOutElastic(1, .8)',
				autoplay: false
			});
			this.backward = anime({
				targets: 'app-toggle .dot',
				translateX: [
					{ value: 44, duration: 0 },
					{ value: 0, duration: 300 }
				],
				scaleX: [
					{ value: 1.3, duration: 100, easing: 'easeOutExpo' },
					{ value: 1, duration: 100 }
				],
				easing: 'easeOutElastic(1, .8)',
				autoplay: false
			});
			if (this.default) {
				this.forward.seek(300);
			} else {
				this.backward.seek(300);
			}
		});
	}
}
