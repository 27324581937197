import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';


import {FormFieldModule} from '../../components/form-field/form-field.module';
import {MaterialModule} from '../../material.module';

import { CheckoutComponent } from './checkout.component';

@NgModule({
	declarations: [CheckoutComponent],
	imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, FormFieldModule],
	exports: [CheckoutComponent],
})
export class CheckoutModule {}
