import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableAkitaProdMode, persistState} from '@datorama/akita';
import * as localForage from 'localforage';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import events from './events';
import GoogleAnalytics4 from "react-ga4";

declare const ga: (...args: string[]) => void;

GoogleAnalytics4.initialize(environment.ga4_ua);
GoogleAnalytics4._gaCommandSet('transport', 'beacon');

ga('create', `UA-${environment.ga_ua}`, 'auto');
ga('set', 'transport', 'beacon');
ga('send', 'pageview');

if (environment.production) {
	enableProdMode();
	enableAkitaProdMode();
}

events();

export const storeVersion = 14;

const instance = localForage.createInstance({
	driver: [localForage.LOCALSTORAGE],
	name: 'Akita',
	version: storeVersion,
	storeName: 'akita-storage',
});

export const clearAkita = async () => {
	await instance.clear();
	await instance.setItem('__version__', storeVersion);
};

void (async () => {
	const previousVersion = Number(await instance.getItem('__version__'));

	if (previousVersion !== storeVersion) {
		await clearAkita();
		location.reload();
	}
})();

const exclude = (obj: Record<string, any>, ...keys: string[]) => {
	for (const key of keys) if (obj[key]) delete obj[key];
	return obj;
};

persistState({
	storage: instance,
	exclude: ['auth', 'donors', 'wizard', 'company'],
	preStorageUpdate: (name: string, state) => {
		if (name === 'parent' && (state.ui || state.appointments)) return exclude({...state}, 'ui', 'appointments');
		return state;
	},
});

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

bootstrap().catch((err) => console.error(err));
