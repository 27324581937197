import {Component, Input, OnInit} from '@angular/core';
import {IvfService} from '@/screens/ivf/ivf.service';

@Component({
	selector: 'app-success-rates-chart',
	templateUrl: './success-rates-chart.component.html',
	styleUrls: ['./success-rates-chart.component.scss'],
})
export class SuccessRatesChartComponent implements OnInit {
	@Input()
	type: 'multiage' | 'average';

	@Input()
	rawData: number[][];

	@Input()
	withLabels: boolean;

	@Input()
	public set data(value: Record<string, unknown>) {
		this._data = value;
		this.recalculateRawData();
	}
	public get data(): Record<string, unknown> {
		return this._data;
	}
	@Input()
	public set key(value: string) {
		this._key = value;
		this.recalculateRawData();
	}
	public get key(): string {
		return this._key;
	}

	readonly height = 170;
	readonly width = 323;
	readonly offsetX = 50;
	readonly offsetY = 20;
	readonly minX = 0;
	get maxX() {
		return this.width - this.offsetX;
	}
	readonly minY = 0;
	readonly maxY = this.height - this.offsetY;

	get groupGap() {
		return this.type === 'multiage' ? 36 : 40;
	}

	readonly percentage = Array.from({length: 6}).map((_, index) => `${index * 20}%`);
	readonly suffixLabels = {
		Any_35: '<35',
		'35_37': '35-37',
		'38_40': '38-40',
		'41_42': '41-42',
		'42_Any': '>42',
	};
	readonly labels = this.ivfService.suffixes.map((suffix) => this.suffixLabels[suffix]);

	readonly colours = ['#c6e2ab', '#596377'];

	readonly barWidth = 10;

	private _data: Record<string, unknown>;
	private _nationalData: Record<string, unknown>;
	private _key: string;

	constructor(private ivfService: IvfService) {}

	recalculateRawData() {
		if (this.data && this.key) {
			switch (this.type) {
				case 'average':
					const avgKey = this.key + this.ivfService.avgSuffix;
					this.rawData = [[this.data[avgKey] as number, this._nationalData && (this._nationalData[avgKey] as number)]];
					break;
				case 'multiage':
					this.rawData = this.ivfService.suffixes.map((suffix) => [
						this.data[this.key + suffix] as number,
						this._nationalData && (this._nationalData[this.key + suffix] as number),
					]);
					break;
			}
			this.rawData = this.rawData.map((row) => row.map((cell) => (cell === null ? cell : +cell)));
		}
	}

	ngOnInit() {
		this.ivfService.getNational().subscribe((data) => {
			this._nationalData = data;
			this.recalculateRawData();
		});
	}
}
