import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {ControlContainer, FormControl, ValidatorFn} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-form-field [type="text"], app-form-field [type="password"], app-form-field [type="number"]',
	templateUrl: './text.component.html',
	styleUrls: ['./text.component.scss']
})
export class TextFieldComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild('span')
	suffix: ElementRef<HTMLSpanElement>;

	@Input()
	label: string;
	@Input()
	withPrefix = false;
	@Input()
	autocomplete = 'off';
	@Input()
	validation: ValidatorFn[] = [];
	@Input()
	errors: Record<string, string>;
	@Input()
	type = 'text';
	@Input()
	showPassValidators = false;
	@Input()
	disabled = false;
	@Input()
	value = '';
	@Input()
	inputInterceptor?: (event: KeyboardEvent) => boolean;
	@Output()
	valueChange = new EventEmitter<string>();

	@Input()
	controlName: string;

	change$: Subscription;
	public control = new FormControl(this.value);
	hidePass = false;

	constructor(
		@Optional() private controlContainer: ControlContainer
	) { }

	ngOnInit() {
		if (this.controlName) {
			this.control = this.controlContainer.control.get(this.controlName) as FormControl;
		} else {
			this.change$ = this.control.valueChanges
				.subscribe(() => this.valueChange.emit(this.control.value));
			this.control.setValidators(this.validation);
			if (this.value)
				this.control.updateValueAndValidity();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if ((changes.value && !changes.value.previousValue) || (changes.value && !changes.value.currentValue))
			this.control.setValue(changes.value.currentValue, { emitEvent: false });
		if (changes.disabled)
			if (changes.disabled.currentValue)
				this.control.disable({ emitEvent: false });
			else
				this.control.enable({ emitEvent: false });
	}

	ngOnDestroy() {
		this.change$?.unsubscribe();
	}

	get error() {
		for (const error in this.control.errors) {
			if (this.errors[error])
				return this.errors[error];
		}
	}
}
