import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminComponent} from './admin/admin.component';

import {UsersListComponent} from './list/list.component';

const routes: Routes = [
	{
		path: 'list',
		component: UsersListComponent,
	},
	{
		path: 'admin',
		component: AdminComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class UsersRoutingModule {}
