import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
	OnInit,
	Optional,
	OnDestroy,
	ElementRef,
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormControl, ValidatorFn, ControlContainer} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {AsYouType, CountryCallingCode, CountryCode, getCountryCallingCode} from 'libphonenumber-js';

import {Extra as originCodes} from './countries';
import {map, startWith} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-form-field [type="phone"]',
	templateUrl: './phone.component.html',
	styleUrls: ['./phone.component.scss'],
})
export class PhoneFieldComponent implements OnInit, OnChanges, OnDestroy {
	@Input()
	label: string;
	@Input()
	validation: ValidatorFn[] = [];
	@Input()
	errors: Record<string, string> = {};
	@Input()
	value = '';
	@Output()
	valueChange = new EventEmitter<string>();

	@Input()
	controlName: string;

	@ViewChild(MatAutocompleteTrigger)
	autocomplete: MatAutocompleteTrigger;
	@ViewChild('input')
	inputRef: ElementRef<HTMLInputElement>;

	code: ICode;
	phone = new AsYouType();
	control = new FormControl(this.value);
	codes: ICode[] = originCodes;

	change$: Subscription;

	constructor(@Optional() private controlContainer: ControlContainer, private http: HttpClient) {}

	setCode(option: ICode) {
		this.code = option;
		this.phone = new AsYouType(this.code.label as CountryCode);
		this.control.setValue(this.phone.input(`+${option.code}`));
	}

	updateCode() {
		if (this.control.value.startsWith('+1')) {
			this.code = {
				code: getCountryCallingCode('US'),
				label: 'US',
				labelLowerCased: 'us',
				name: 'United States of America',
			};
		}

		if (this.phone.country && this.phone.country !== this.code?.label) {
			this.code = originCodes.find((item) => item.label === this.phone.country);
		}
	}

	openDropdown() {
		this.codes = originCodes;
		this.autocomplete.openPanel();
	}

	onChange(value: string) {
		value = value || '';
		const currentPosition = this.inputRef
			? this.inputRef.nativeElement.selectionStart === (this.control.value || '').length
				? null
				: this.inputRef.nativeElement.selectionStart
			: null;
		let filter = value.replace('+', '');
		if (!this.code?.label) {
			this.codes = filter.length
				? originCodes.filter((item) => item.code.startsWith(filter)).sort((a, b) => +a.code - +b.code)
				: originCodes;
			if (this.codes[0] && this.codes[0].code === filter) {
				this.setCode(this.codes[0]);
			} else if (this.codes.length) {
				return;
			}
		} else if (!filter.length) {
			this.code = null;
		} else if (value[0] !== '+') {
			filter = this.code.code + filter;
		}
		if (this.autocomplete?.panelOpen) {
			this.autocomplete.closePanel();
		}
		this.phone.reset();
		this.control.setValue(this.phone.input(`+${filter}`), {emitEvent: false});
		this.updateCode();
		this.valueChange.emit(this.control.valid ? this.control.value : null);
		if (currentPosition) {
			setTimeout(() => this.inputRef.nativeElement.setSelectionRange(currentPosition, currentPosition));
		}
	}

	getCurrentCountry() {
		return this.http.get<{alpha2: string}>('/api/alpha2').pipe(map(({alpha2}) => this.codes.find((item) => item.label === alpha2)));
	}

	get error() {
		for (const error in this.control.errors) {
			if (this.errors[error]) return this.errors[error];
		}
	}

	ngOnInit() {
		if (this.controlName) {
			this.control = this.controlContainer.control.get(this.controlName) as FormControl;
			this.control.setValidators([
				this.control.validator,
				(control) => {
					try {
						return this.phone.getNumber().isValid() ? null : {invalid: {value: control.value}};
					} catch (e) {
						return {invalid: {value: control.value}};
					}
				},
			]);
		} else {
			this.control.setValidators([
				...this.validation,
				(control) => {
					try {
						return this.phone.getNumber().isValid() ? null : {invalid: {value: control.value}};
					} catch (e) {
						return {invalid: {value: control.value}};
					}
				},
			]);
		}
		this.change$ = this.control.valueChanges.pipe(startWith(this.control.value)).subscribe((data) => this.onChange(data));
		if (!this.control.value) this.getCurrentCountry().subscribe((data) => data && (this.control.value || this.setCode(data)));
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.value &&
			changes.value.currentValue &&
			(changes.value.previousValue === null || changes.value.previousValue === undefined)
		) {
			this.phone.reset();
			this.control.setValue(this.phone.input(changes.value.currentValue), {emitEvent: false});
			this.updateCode();
		}
	}

	ngOnDestroy() {
		this.change$.unsubscribe();
	}
}

interface ICode {
	name: string;
	label: string;
	labelLowerCased: string;
	code: CountryCallingCode;
}
