import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';

import {MaterialModule} from '../../material.module';
import {CheckboxModule} from '../checkbox/checkbox.module';
import {InsvgModule} from '../insvg/insvg.module';

import {AddressFieldComponent} from './address/address.component';
import {AreaFieldComponent} from './area/area.component';
import {AutocompleteFieldComponent} from './autocomplete/autocomplete.component';
import {CostsFieldComponent} from './costs/costs.component';
import {DateFieldComponent} from './date/date.component';
import {JoinPipe, FindPipe, FilterPipe} from './join.pipe';
import {MultilineTextFieldComponent} from './multiline-text-field/multiline-text-field.component';
import {PhoneFieldComponent} from './phone/phone.component';
import {SelectFieldComponent, SelectFormFieldComponent, MultipleSelectFormFieldComponent} from './select/select.component';
import {SliderComponent} from './slider/slider.component';

import {TextFieldComponent} from './text/text.component';

@NgModule({
	declarations: [
		TextFieldComponent,
		DateFieldComponent,
		PhoneFieldComponent,
		AddressFieldComponent,
		SelectFieldComponent,
		AutocompleteFieldComponent,
		AreaFieldComponent,
		MultilineTextFieldComponent,
		CostsFieldComponent,
		SelectFormFieldComponent,
		MultipleSelectFormFieldComponent,
		JoinPipe,
		SliderComponent,
		FindPipe,
		FilterPipe,
	],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, InsvgModule, CheckboxModule, HttpClientModule],
	exports: [
		TextFieldComponent,
		DateFieldComponent,
		PhoneFieldComponent,
		AddressFieldComponent,
		SelectFieldComponent,
		AutocompleteFieldComponent,
		AreaFieldComponent,
		MultilineTextFieldComponent,
		CostsFieldComponent,
		JoinPipe,
		SliderComponent,
		FindPipe,
		SelectFormFieldComponent,
		FilterPipe,
	],
})
export class FormFieldModule {}
