import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EntityState, EntityStore, QueryEntity, StoreConfig} from '@datorama/akita';
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, first, map, mergeMap, startWith, tap} from 'rxjs/operators';

import {SurrogateRelationshipStatus} from '@/services/admin.service';
import {ItemType, UtilsService} from './utils.service';

export type ISurrogate = {
	id: number;
	images: string[];
	age: number;
	bmi: number;
	location: Location;
	canDelete: boolean;
	totalCost: number;
	relationshipStatus?: string;
	compensation?: number;
	agreesToTwins: boolean;
	agreesToAbortion: boolean;
	experiencedSurrogate: boolean;
	covidVaccinated: boolean;
	liveBirthsCount: number;
	cSectionsCount: number;
	miscarriagesCount: number;
	'Agency.id': number;
	'Agency.provider_mapping.id': number;
	'Agency.provider_mapping.provider_id': number;
	'Agency.provider_mapping.provider.id': number;
	'Agency.provider_mapping.provider.company_name': string;
	position: number;
	parsedLocation: string;
	summary: string;
	createdAt: string;
	updatedAt: string;
	isPremium: boolean;
} & {[key: string]: any};

export type ISurrogateExtended = Omit<ISurrogate, 'experiencedSurrogate'> & {
	status: string;
	content: Record<string, any>;
	experiencedSurrogate: string;
	video: string;
} & {[key: string]: any};

export interface Location {
	raw: string;
	state?: string;
	country?: string;
	city?: string;
}

export interface SurrogatesState extends EntityState<ISurrogate, number> {
	count: number;
	agencies: {[key: string]: any}[];
	backup: ISurrogate[][];
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'surrogates', idKey: 'id'})
export class SurrogatesStore extends EntityStore<SurrogatesState, ISurrogate> {
	constructor() {
		super({count: 0, agencies: []});
	}
}

@Injectable({providedIn: 'root'})
export class SurrogatesQuery extends QueryEntity<SurrogatesState> {
	constructor(protected store: SurrogatesStore) {
		super(store);
	}
}

@Injectable({providedIn: 'root'})
export class SurrogatesStoreService {
	get count() {
		return this.query.getValue().count;
	}

	get loading() {
		return this.query.selectLoading();
	}

	watched = new BehaviorSubject(new Set<number>(JSON.parse(localStorage.getItem('__watched') || '[]')));
	options = new BehaviorSubject({
		offset: 0,
		count: 0,
		filters: null,
		favourites: false,
		sortBy: '',
		revision: 0,
	});
	public surrogates = this.options.pipe(
		startWith([]),
		distinctUntilChanged((a, b) => {
			const _a = this.utils.deepCopy(a);
			const _b = this.utils.deepCopy(b);
			delete _a['revision'];
			delete _b['revision'];
			return this.utils.deepEquals(_a, _b);
		}),
		mergeMap(() => {
			this.store.setLoading(true);
			const {filters, offset, count, favourites, sortBy, revision} = this.options.value;
			return filters
				? this.http
						.put('/api/users/filters', filters, {
							params: {
								type: 'surrogates',
								offset: offset.toString(),
								count: count.toString(),
								favourites: favourites.toString(),
								sort: sortBy,
								rev: (revision || 0).toString(),
							},
						})
						.pipe(
							tap(
								(res: {data: ISurrogate[]; count: number; revision?: number}) => {
									this.store.upsertMany(res.data);
									this.store.update({count: res.count});
									this.store.setLoading(false);
								},
								(error) => {
									if (error.status === 401) {
										this.router.navigateByUrl('/login');
									}
								},
							),
						)
				: this.http
						.get('/api/surrogates', {
							params: {
								offset: offset.toString(),
								count: count.toString(),
								favourites: favourites.toString(),
								sort: sortBy,
							},
						})
						.pipe(
							tap(
								(res: {data: ISurrogate[]; count: number; revision?: number}) => {
									this.store.upsertMany(res.data);
									this.store.update({count: res.count});
									this.store.setLoading(false);
								},
								(error) => {
									if (error.status === 401) {
										this.router.navigateByUrl('/login');
									}
								},
							),
						);
		}),
		map((res) => ({...res, data: res.data.map((item) => ({...item, created_at: new Date(item.createdAt)}))})),
	);

	private _fetchRequest: Subscription;

	constructor(
		private store: SurrogatesStore,
		private query: SurrogatesQuery,
		private http: HttpClient,
		private router: Router,
		private utils: UtilsService,
	) {}

	backup(data: ISurrogate[][]) {
		this.store.update({backup: data});
	}

	restore() {
		const backup = this.store.getValue().backup;
		return backup ? backup.slice() : null;
	}

	countSubscription() {
		return this.query.select('count');
	}

	clear() {
		this.store.remove();
		this.store.update({count: 0});
	}

	getSurrogateById(ids: number | number[]): Observable<ISurrogate[]> {
		if (Array.isArray(ids)) {
			const requests$: Observable<ISurrogate>[] = [];
			const existing: ISurrogate[] = [];
			for (const id of ids) {
				requests$.push(this.getGeneralProfileById(id));
			}
			return requests$.length ? forkJoin(requests$).pipe(map((res) => res.concat(existing))) : of(existing);
		} else
			return this.query.hasEntity(ids)
				? this.query.selectMany([ids]).pipe(first())
				: this.getGeneralProfileById(ids).pipe(map((res) => [res]));
	}

	getGeneralProfileById(id: number) {
		return this.http
			.get<ISurrogate>(`/api/v1/surrogates/${id}?general=true`)
			.pipe(tap((surrogate: ISurrogate) => this.store.update(surrogate.id, surrogate)));
	}

	fetchSurrogateById(ids: number | number[], type: 'short' | 'long' = 'long'): Observable<{[key: string]: any}[]> {
		const result = [];
		(Array.isArray(ids) ? ids : [ids]).forEach((id) => {
			result.push(this.http.get(`/api/v1/surrogates/${id}?long=${type === 'long'}`));
		});
		return forkJoin(result);
	}

	setOptions(offset: number, count: number, filters: {[key: string]: any}, favourites = false, sortBy = '', revision?: number) {
		this.options.next({offset, count, filters, favourites, sortBy, revision});
	}

	fetchSurrogates(
		offset: number,
		count: number,
		filters: {[key: string]: any},
		favourites = false,
		sortBy = '',
		order = '',
		force = false,
	) {
		this.store.setLoading(true);
		if (filters) {
			return this.http
				.put('/api/users/filters', filters, {
					params: {
						type: 'surrogates',
						offset: offset.toString(),
						count: count.toString(),
						favourites: favourites.toString(),
						sort: sortBy,
						order,
					},
				})
				.pipe(
					tap(
						(res: {data: ISurrogate[]; count: number}) => {
							this.store.upsertMany(res.data);
							this.store.update({count: res.count});
							this.store.setLoading(false);
						},
						(error) => {
							if (error.status === 401) {
								this.router.navigateByUrl('/login');
							}
						},
					),
				);
		} else {
			return this._request(
				this.http
					.get('/api/v1/surrogates', {
						params: {
							offset: offset.toString(),
							count: count.toString(),
							favourites: favourites.toString(),
							sort: sortBy,
							order,
						},
					})
					.pipe(
						tap(
							(res: {data: ISurrogate[]; count: number}) => {
								this.store.upsertMany(res.data);
								this.store.update({count: res.count});
								this.store.setLoading(false);
							},
							(error) => {
								if (error.status === 401) {
									this.router.navigateByUrl('/login');
								}
							},
						),
					),
				force,
			);
		}
	}

	removeSurrogatesByIds(ids: number[]) {
		this.store.setLoading(true);
		this.store.remove(ids);
		const entities = this.store.getValue();
		this.store.update({count: entities.count});
		return this.http.delete('/api/v1/surrogates', {
			params: {
				ids,
			},
		});
	}

	updateSurrogates(offset: number, count: number) {
		this.store.setLoading(true);
		this.http
			.get('/api/surrogates', {
				params: {
					offset: offset.toString(),
					count: count.toString(),
				},
			})
			.subscribe(
				(res: {data: ISurrogate[]; count: number}) => {
					this.store.set(res.data);
					this.store.update({count: res.count});
					this.store.setLoading(false);
				},
				(error) => {
					if (error.status === 401) {
						this.router.navigateByUrl('/login');
					}
				},
			);
	}

	getAgencyById(ids: number | number[]): Observable<{[key: string]: any}[]> {
		return new Observable((subs) => {
			const internal = this.query.select('agencies').subscribe((data) => {
				const result = [];
				(Array.isArray(ids) ? ids : [ids]).forEach((id) => {
					result.push(data.find((item) => item.id === id));
				});
				subs.next(result);
			});
			return {
				unsubscribe: () => {
					subs.unsubscribe();
					subs.complete();
					internal.unsubscribe();
				},
			};
		});
	}

	updateFilteredSurrogates(filters: any, offset: number, count: number) {
		this.store.setLoading(true);
		this.http
			.put('/api/users/filters', this.utils.mapFilters(filters, ItemType.SURROGATE), {
				params: {
					type: 'surrogates',
					offset: offset.toString(),
					count: count.toString(),
				},
			})
			.subscribe(
				(res: {data: ISurrogate[]; count: number}) => {
					this.store.upsertMany(res.data);
					this.store.update({count: res.count});
					this.store.setLoading(false);
				},
				(error) => {
					if (error.status === 401) {
						this.router.navigateByUrl('/login');
					}
				},
			);
	}

	createDeal(surrogate_id: number) {
		return this.http.post('/api/deals', {surrogate_id});
	}

	setImgCached(id: number, state: boolean = null) {
		if (state === null) {
			this.store.update(id, {img_cached: true});
		} else {
			this.store.update(id, {img_cached: state});
		}
	}

	setWatched(id: number) {
		this.watched.next(this.watched.value.add(id));
		localStorage.setItem('__watched', JSON.stringify(Array.from(this.watched.value)));
	}

	isInWatched(id: number) {
		return this.watched.value.has(id);
	}

	private _request<T extends Observable<any>>(observable: T, force = false) {
		return new Observable((subs) => {
			if (this._fetchRequest) this._fetchRequest.unsubscribe();
			this._fetchRequest = force ? null : observable.subscribe((data) => subs.next(data)).add(() => subs.next(null));
			return {
				unsubscribe: () => {
					subs.unsubscribe();
					subs.complete();
				},
			};
		}) as T;
	}

	private sponsoredStatus: BehaviorSubject<{maxSponsored: number; sponsoredCount: number}>;

	async setPremiumStatus(surrogateId: number, is_premium: boolean) {
		const res = await this.http
			.put<{updated: boolean; maxSponsored: number; sponsoredCount: number; message?: string}>(
				`/api/v1/surrogates/${surrogateId}/set-premium`,
				{is_premium},
			)
			.toPromise();
		this.sponsoredStatus.next({maxSponsored: res.maxSponsored, sponsoredCount: res.sponsoredCount});

		return res;
	}

	getPremiumStatus() {
		return new Promise<BehaviorSubject<{maxSponsored: number; sponsoredCount: number}>>((resolve) => {
			if (!this.sponsoredStatus) {
				this.sponsoredStatus = new BehaviorSubject<{maxSponsored: number; sponsoredCount: number}>({
					maxSponsored: 0,
					sponsoredCount: 0,
				});
				this.http.get<{maxSponsored: number; sponsoredCount: number}>(`/api/v1/surrogates/premium-status`).subscribe((res) => {
					resolve(this.sponsoredStatus);
					this.sponsoredStatus.next(res);
				});
				return;
			}
			resolve(this.sponsoredStatus);
		});
	}
}

export interface IFilters {
	experienced_surrogate: boolean;
	age: [number?, number?];
	bmi: [number?, number?];
	compensation: [number?, number?];
	total_cost: [number?, number?];
	abortions_allowed: boolean;
	twins_allowed: boolean;
	covid_vaccinated: boolean;
	same_sex_couple_allowed: boolean;
	max_c_sections?: number;
	max_miscarriages?: number;
	max_live_births?: number;
	relationship_status: Set<SurrogateRelationshipStatus>;
	country: string;
	state: string;
	agency?: string;
	video_available: boolean;
}
