import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {CalendarModule} from '@/components/calendar/calendar.module';
import {CardModule} from '@/components/card/card.module';
import {HeartModule} from '@/components/heart/heart.module';
import {MaterialModule} from '@/material.module';

import {IvfModule} from '../ivf/ivf.module';
import {ProviderProfileComponent} from './provider-profile.component';

@NgModule({
	declarations: [ProviderProfileComponent],
	imports: [
		CommonModule,
		RouterModule,
		IvfModule,
		CalendarModule,
		ReactiveFormsModule,
		FormsModule,
		CardModule,
		HeartModule,
		MaterialModule,
	],
})
export class ProviderProfileModule {}
