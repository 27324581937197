import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MaterialModule} from '@/material.module';

import {PasswordChecksComponent} from './password-checks.component';

@NgModule({
	declarations: [PasswordChecksComponent],
	imports: [CommonModule, MaterialModule],
	exports: [PasswordChecksComponent],
})
export class PasswordChecksModule {}
