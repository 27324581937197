import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, ErrorHandler} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as Sentry from '@sentry/browser';
import {EmptyError} from 'rxjs';

import {environment} from '../../environments/environment';

Sentry.init({
	dsn: 'https://221e12544f2a449f8267d152bcb992eb@sentry.io/5186591',
	ignoreErrors: ['Non-Error exception captured'],
	environment: environment.appEnv
});
const CHUNK_FAILED = /Loading chunk [\d]+ failed/;

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	constructor(private snackBar: MatSnackBar) {}

	handleError(error) {
		if (error instanceof EmptyError || error instanceof HttpErrorResponse) {
			return;
		}

		if (CHUNK_FAILED.test(error.message)) {
			return window.location.reload();
		}

		if ((environment.production || environment.alpha) && !document.querySelector('.sentry-error-embed-wrapper')) {
			const eventId = Sentry.captureException(error.originalError || error);
			console.error(error);
			Sentry.showReportDialog({eventId});
		} else {
			console.error(error);
			const snack = this.snackBar.open(error.message || error, 'Close', {
				horizontalPosition: 'center',
				verticalPosition: 'top',
				panelClass: 'snack-error',
			});
			snack.onAction().subscribe(() => snack.dismiss());
		}
	}
}
