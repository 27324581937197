<button mat-icon-button mat-dialog-close class="close"><mat-icon>close</mat-icon></button>

<h1>Contact {{ data.agencyName || 'Agency' }}</h1>

<ng-container [ngTemplateOutlet]="tabs[activeTab]"></ng-container>

<button *ngIf="!sentMessage && (activeTab !== ActiveTab.SUCCESS && (providerContacts$ | async)?.length)" mat-button color="primary" (click)="switchScreen()">
	<span>Or {{ activeTab === 0 ? 'Schedule a Call' : 'Send a Message' }}</span>
	<mat-icon>arrow_forward</mat-icon>
</button>

<ng-template #messageTab>
	<mat-form-field appearance="outline" class="area">
		<textarea matInput rows="5" [(ngModel)]="message"></textarea>
	</mat-form-field>

	<button mat-raised-button color="primary" [disabled]="loading" (click)="sendMessage()">Send Message</button>
</ng-template>

<ng-template #callTab>
	<div *ngIf="sentMessage" class="success">
		<p *ngIf="!hasScheduledAppointment" >
		Message was sent successfully to {{ data.agencyName || 'this agency' }}, you will also need to schedule a call with them.
		</p>
		<p *ngIf="hasScheduledAppointment">
		Message was sent successfully to {{ data.agencyName || 'this agency' }}, you already have a scheduled call with them.
		</p>
	</div>
	<div *ngIf="!sentMessage && hasScheduledAppointment" class="success">
		<p>
			You already have a scheduled call with {{ data.agencyName || 'this agency' }}.
		</p>
	</div>
	<app-calendar-list
		[contacts]="providerContacts$ | async"
		[eggDonorId]="data.donorId"
		(beforeCreateAppointment)="createAppointment($event)"
		(createAppointment)="appointmentCreated()"
	></app-calendar-list>
</ng-template>

<ng-template #success>
	<div class="success">
		<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjOGJjMzRhIj48cGF0aCBkPSJNMjEuODU1IDEwLjMwM2MuMDg2LjU1NC4xNDUgMS4xMTguMTQ1IDEuNjk3IDAgNi4wNzUtNC45MjUgMTEtMTEgMTFzLTExLTQuOTI1LTExLTExIDQuOTI1LTExIDExLTExYzIuMzQ4IDAgNC41MTguNzQxIDYuMzA0IDEuOTkzbC0xLjQyMSAxLjQ1N2MtMS40MDgtLjkxMy0zLjA4My0xLjQ1LTQuODgzLTEuNDUtNC45NjMgMC05IDQuMDM4LTkgOXM0LjAzNyA5IDkgOWM0Ljg5NCAwIDguODc5LTMuOTI4IDguOTktOC43OTVsMS44NjUtMS45MDJ6bS0uOTUxLTguMTM2bC05LjQwNCA5LjYzOS0zLjg0My0zLjYxNC0zLjA5NSAzLjA5OCA2LjkzOCA2LjcxIDEyLjUtMTIuNzM3LTMuMDk2LTMuMDk2eiIvPjwvc3ZnPg==" />
		<p>Message was sent successfully</p>
	</div>
</ng-template>
