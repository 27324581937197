<div class="ruler">
	<span class="min">{{ min }}</span>
	<app-toggle [default]="!isImperial" [labels]="['Lbs', 'Kg']" (changed)="onSwitch($event)"></app-toggle>
	<span class="max">{{ max }}</span>

	<div class="scale">
		<span *ngFor="let type of scale" [class]="type"></span>
	</div>
	<app-insvg
		*ngIf="withIcons"
		link="assets/height_woman.svg"
		name="height_woman"
		style.transform="translate(calc(-50% + {{px.min}}px), -135%) scale({{1 * perc.min}})"
		class="a_woman"
		id="a_woman_left"
	></app-insvg>
	<app-insvg
		*ngIf="withIcons"
		link="assets/height_woman.svg"
		name="height_woman"
		style.transform="translate(calc(-50% + {{px.max}}px), -135%) scale({{1 * perc.max}})"
		class="a_woman"
		id="a_woman_right"
	></app-insvg>
	<div class="t" #rulerContainer>
		<div
			class="t_left"
			cdkDrag
			(cdkDragEnded)="onDragEnded()"
			(cdkDragMoved)="onDragMoved('left', $event)"
			cdkDragLockAxis="x"
			[cdkDragFreeDragPosition]="dragPosLeft"
			[cdkDragBoundary]="rulerContainer"
		>
			<span
				[ngClass]="{
					titleVisible: value.min.kg !== _bounds.min_value.kg,
					withPad: labelsWithPad
				}"
				>{{ currentMin }}</span
			>
		</div>
		<div
			class="t_right"
			cdkDrag
			(cdkDragEnded)="onDragEnded()"
			(cdkDragMoved)="onDragMoved('right', $event)"
			cdkDragLockAxis="x"
			[cdkDragFreeDragPosition]="dragPosRight"
			[cdkDragBoundary]="rulerContainer"
		>
			<span
				[ngClass]="{
					titleVisible: value.max.kg !== _bounds.max_value.kg,
					withPad: labelsWithPad
				}"
				>{{ currentMax }}</span
			>
		</div>
	</div>
</div>
