import {Injectable} from '@angular/core';
import {InfiniteSource} from '@/services/infinite.datasource';

interface Scroll {
	startIndex: number;
	position: number;
	heights: number[];
	padding: number;
}

@Injectable({providedIn: 'root'})
export class ScrollerService {
	private scroll: Record<string, Scroll> = {};
	private dataSource: Record<string, InfiniteSource<unknown>> = {};

	saveScrollPosition(id: string, scroll: Scroll) {
		this.scroll[id] = scroll;
	}

	getScrollPosition(id: string) {
		return this.scroll[id];
	}

	setDataSource(id: string, dataSource: InfiniteSource<unknown>) {
		this.dataSource[id] = dataSource;
	}

	getDataSource(id: string) {
		return this.dataSource[id];
	}

	registerScroller(id: string, dataSource: InfiniteSource<unknown>) {
		this.dataSource[id] = dataSource;
		this.scroll[id] = {
			startIndex: 0,
			position: 0,
			heights: [],
			padding: 0,
		};
	}
}
