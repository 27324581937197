// breadcrumb.module.ts
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
	declarations: [BreadcrumbComponent],
	imports: [CommonModule, RouterModule, MatToolbarModule, MatButtonModule, MatIconModule],
	exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
