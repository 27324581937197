<!-- TODO: Component demands refactoring -->

<div *ngIf="userType === 'admin'" class="header providers">
	<h1>Providers</h1>
</div>
<app-breadcrumb *ngIf="false"></app-breadcrumb>

<div class="header chats">

	<button *ngIf="userType === 'admin'" mat-icon-button (click)="openLeftMenuForAdmin()">
		<mat-icon>keyboard_arrow_left</mat-icon>
	</button>

	<app-breadcrumb *ngIf="!isSmallScreen"></app-breadcrumb>
	<h1 *ngIf="isSmallScreen">Messages</h1>
	<button mat-icon-button class="filter" [matMenuTriggerFor]="filterMenu">
		<app-insvg name="filter" link="assets/filter.svg"></app-insvg>
	</button>
	<mat-menu #filterMenu xPosition="before">
		<button mat-menu-item class="filter__option" (click)="changeTypeFilter(null)">All</button>
		<button mat-menu-item class="filter__option" (click)="changeTypeFilter(FilterEnum.UNREAD_MESSAGES)">Unread Messages</button>
		<button mat-menu-item class="filter__option" (click)="changeTypeFilter(FilterEnum.EGG_AGENCY)">Egg Donor Agencies</button>
		<button mat-menu-item class="filter__option" (click)="changeTypeFilter(FilterEnum.SURROGACY_AGENCY)">Surrogacy Agencies</button>
	</mat-menu>
</div>

<div class="header activeChat">
	<a mat-icon-button routerLink="../">
		<mat-icon>keyboard_arrow_left</mat-icon>
	</a>
	<div *ngIf="selectedChat$ | async as selectedChat" class="header__hs">
		<h1>
			<div>{{ selectedChat.title }}</div>
			<div class="header__hs__details" (click)="openDetails()">(Details)</div>
		</h1>
		<h2>{{ selectedChat.subtitle }}</h2>
	</div>
	<button mat-flat-button color="accent" (click)="openDetailsOnCalendar()">
		{{ userType === 'parent' ? 'Schedule a Call with Agency' : 'Details' }}
	</button>
</div>

<div class="header details">
	<button mat-icon-button (click)="closeDetails()">
		<mat-icon>keyboard_arrow_left</mat-icon>
	</button>
	<h1>Details</h1>
</div>

<div *ngIf="userType === 'admin'" class="content providers">
	<button mat-button class="provider" [class.active]="!providerFilter$.value" (click)="changeProviderFilter(null)">All</button>
	<button
		*ngFor="let provider of providers; trackBy: trackBy"
		mat-button
		class="provider"
		[class.active]="providerFilter$.value === provider.id"
		[class.current]="(selectedChat$ | async)?.provider_id === provider.id"
		(click)="changeProviderFilter(provider.id)"
	>
		{{ provider.company_name }}
	</button>
</div>

<div class="content chats">
	<ng-container *ngFor="let group of chatGroups$ | async; trackBy: trackBy">
		<a
			*ngIf="group.last.lastMessage | async as lastMessage"
			class="chat"
			[routerLink]="group.length === 1 ? '/chat/' + group.last.id : null"
			[replaceUrl]="true"
			[class.active]="group.last.id === (selectedChat$ | async)?.id"
			[class.unread]="!lastMessage.read && (userType === 'admin' || lastMessage.author !== meAuthor)"
			(click)="toggleGroup(group.last.id)"
		>
			<div class="chat__img">
				<img *ngIf="userType !== 'admin' && group.last.isSupportChat" [src]="images.support" />
				<img *ngIf="userType === 'parent' && !group.last.isSupportChat" [src]="group.last.img" />
				<img
					*ngIf="(userType === 'provider' && !group.last.isSupportChat) || userType === 'admin'"
					src="https://eu.ui-avatars.com/api/?length=1&size-80&rounded=true&bold=true&background=F9F2FF&color=663898&name={{
						group.last.provider?.company_name || group.last.user?.first_name
					}}"
				/>
			</div>
			<div class="chat__message">
				<div class="chat__message__head">
					<b>{{ group.title || group.last.title }}</b>
					<mat-icon *ngIf="group.length > 1">keyboard_arrow_{{ chatGroupsHidden[group.last.id] ? 'right' : 'down' }}</mat-icon>
				</div>

				<ng-container *ngTemplateOutlet="lastMessageRef; context: {message: lastMessage, chat: group.last}"></ng-container>
			</div>
		</a>

		<div *ngIf="group.length > 1" class="chat__group" [class.hidden]="chatGroupsHidden[group.last.id]">
			<a
				*ngFor="let chat of group.all; trackBy: trackBy"
				class="chat"
				routerLink="/chat/{{ chat.id }}"
				[replaceUrl]="true"
				[class.active]="chat.id === (selectedChat$ | async)?.id"
				[class.unread]="!(chat.lastMessage | async)?.read && (userType === 'admin' || (chat.lastMessage | async)?.author !== meAuthor)"
			>
				<div class="chat__img">
					<img
						*ngIf="userType === 'parent' && !chat.location"
						src="{{ getChatImg(chat) }}"
					/>
					<img
						*ngIf="userType === 'parent' && chat.location"
						src="https://eu.ui-avatars.com/api/?length=1&size-80&rounded=true&bold=true&background=F9F2FF&color=663898&name={{
							chat.location?.city
						}}"
					/>
					<img
						*ngIf="userType === 'provider' || userType === 'admin'"
						src="https://eu.ui-avatars.com/api/?length=1&size-80&rounded=true&bold=true&background=F9F2FF&color=663898&name={{
							chat.user?.first_name
						}}"
					/>
				</div>
				<div class="chat__message">
					<b>{{ chat.title }}</b>

					<ng-container *ngTemplateOutlet="lastMessageRef; context: {message: chat.lastMessage | async, chat: chat}"></ng-container>
				</div>
			</a>
		</div>
	</ng-container>
</div>

<div class="content activeChat">
	<div *ngIf="isConnecting$ | async" class="connecting">
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>

	<ng-container *ngIf="selectedChat$ | async as selectedChat">
		<div #messagesList class="messages-scroll" (scroll)="listScrollSubject.next()">
			<div class="messages">
				<div *ngIf="isListUpdating" class="loader">
					<mat-spinner color="accent" diameter="36"></mat-spinner>
				</div>
				<div
					*ngFor="let message of selectedChatMessages$ | async; trackBy: trackBy"
					class="message"
					[class.read]="message.read !== false"
					[attr.type]="message.type"
				>
					<ng-container [ngSwitch]="message.type">
						<div *ngSwitchCase="ServiceMessageType.Date" class="message__text">
							{{ message.typeData.date | date: 'MMM d, LLLL' }}
						</div>

						<ng-container
							*ngSwitchCase="
								([ServiceMessageType.CreatedAppointment, ServiceMessageType.UpdateAppointment, ServiceMessageType.CancelAppointment]
								| includes: message.type)
									? message.type
									: ''
							"
						>
							<ng-container [ngSwitch]="userType">
								<div class="message__service__icon">
									<app-insvg name="paper-plane" link="assets/paper-plane.svg"></app-insvg>
								</div>

								<div class="message__service__text">
									<ng-container *ngSwitchCase="'parent'">
										{{ message.author === Author.USER ? 'You ' : names.provider || 'Agency ' }}
										{{
											message.type === ServiceMessageType.CreatedAppointment
												? 'booked'
												: message.type === ServiceMessageType.UpdateAppointment
												? 'rescheduled'
												: 'canceled'
										}}
										a meeting with {{ message.author === Author.USER ? message.typeData.name + ' from ' + names.provider : 'you' }}
									</ng-container>

									<ng-container *ngSwitchDefault>
										{{ message.author === Author.USER ? names.user + ' has ' : 'You have ' }}
										{{
											message.type === ServiceMessageType.CreatedAppointment
												? 'booked'
												: message.type === ServiceMessageType.UpdateAppointment
												? 'rescheduled'
												: 'canceled'
										}}
										a meeting with {{ message.author === Author.USER ? 'you' : names.user }}
									</ng-container>

									on {{ message.typeData.time | date: "EEEE, MMMM d 'at' h:mm a" }}
								</div>
							</ng-container>
						</ng-container>

						<ng-container *ngSwitchCase="ServiceMessageType.InviteForAppointment">
							<ng-container [ngSwitch]="userType">
								<ng-container *ngSwitchCase="'parent'">
									<div class="message__text">
										<ng-container *ngIf="message.author !== meAuthor">
											{{ message.typeData.name }} from {{ selectedChat.provider?.company_name }} has invited you to schedule a call!
										</ng-container>
									</div>
									<img class="tile" [src]="images[message.author]" />

									<div class="message__calendar">
										<app-calendar-list
											[filterContactId]="message.typeData.contactId"
											[contacts]="selectedChat.provider?.provider_contacts"
											[eggDonorId]="selectedChat.egg_donor_id"
											[dealId]="selectedChat.deal_id"
											(createAppointment)="createAppointment($event)"
											(updateAppointment)="updateAppointment($event)"
											(deleteAppointment)="deleteAppointment($event)"
										></app-calendar-list>
									</div>
								</ng-container>

								<ng-container *ngSwitchDefault>
									<div class="message__service__icon">
										<app-insvg name="paper-plane" link="assets/paper-plane.svg"></app-insvg>
									</div>
									<div class="message__service__text">
										An invitation to schedule an appointment has been sent to {{ names['user_short'] }} on
										{{ message.created_at | date: 'MMM d, LLLL' }}
									</div>
								</ng-container>
							</ng-container>
						</ng-container>

						<ng-container *ngSwitchCase="ServiceMessageType.SuggestDonor">
							<div class="message__from">
								<small>{{ message.created_at | date: 'h:mm a' }}</small>
							</div>
							<p class="message__text" [ngSwitch]="userType">
								<ng-container *ngSwitchCase="'parent'">
									New egg donor suggestion from<br />{{ selectedChat.provider?.company_name }}
								</ng-container>
								<ng-container *ngSwitchDefault> You have suggested the following egg donor to {{ names['user_short'] }} </ng-container>
							</p>
							<div *ngIf="(message.typeData.data$ | async) || {} as donor" class="message__donor">
								<img src="images/{{ donor.img }}" class="profile__main__image" />
								<div class="message__donor__content">
									<p class="content__id">Egg Donor #{{ donor.id }}</p>

									<div class="content__details">
										<div class="content__details__row"><b>Age: </b>{{ utilService.toHumanReadableOrUnknown(donor.age) }}</div>
										<div class="content__details__row"><b>Hair Color: </b>{{ utilService.toHumanReadableOrUnknown(donor.hair_color) }}</div>
										<div class="content__details__row"><b>Eye Color: </b>{{ utilService.toHumanReadableOrUnknown(donor.eye_color) }}</div>
										<div class="content__details__row"><b>Ethnicity: </b>{{ useArray(donor.ethnicity) }}</div>
										<div class="content__details__row"><b>Race: </b>{{ useArray(donor.race) }}</div>
										<div class="content__details__row"><b>Religion: </b>{{ utilService.toHumanReadableOrUnknown(donor.religion) }}</div>
										<div class="content__details__row"><b>Height: </b>{{ useHeight(donor.height) }}</div>
										<div class="content__details__row"><b>Weight: </b>{{ useWeight(donor.weight) }}</div>
										<div class="content__details__row"><b>Education Level: </b>{{ utilService.toHumanReadableOrUnknown(donor.education_level) }}</div>
										<div class="content__details__row"><b>Location: </b>{{ utilService.toHumanReadableOrUnknown(donor.formattedLocation) }}</div>
										<div class="content__details__row"><b>Donation Types: </b>{{ useArray(donor.formattedDonationTypes) | titlecase }}</div>

										<div class="content__details__row">
											<b>Egg Donor Compensation: </b
											>{{ donor.egg_donor_compensation || donor.agency_egg_donor_compensation | currency: 'USD':'symbol':'1.0-0' }}
										</div>
										<div class="content__details__row">
											<b>Total Cost: </b>{{ donor.total_compensation | currency: 'USD':'symbol':'1.0-0' }}
										</div>
									</div>
								</div>
								<div class="content__actions">
									<a mat-button color="primary" routerLink="/egg-donor/donor/{{ donor.id }}" target="_blank">
										<span>View Full Profile</span>
										<mat-icon>arrow_forward</mat-icon>
									</a>
									<a *ngIf="userType !== 'parent'" mat-button color="primary" target="_blank" [href]="donor.origin" target="_blank">
										<span>View Original Profile</span>
										<mat-icon>arrow_forward</mat-icon>
									</a>
								</div>
							</div>

							<div *ngIf="userType === 'parent'" class="message__feedback">
								<p class="message__text">Let the agency know if you like this egg donor</p>
								<div class="message__feedback__actions">
									<button class="action-up" mat-icon-button (click)="rateMessage(message.id, true)">
										<app-insvg name="thumb" positive="true" link="assets/thumb.svg" [class.active]="message.reaction"></app-insvg>
									</button>
									<button class="action-down" mat-icon-button (click)="rateMessage(message.id, false)">
										<app-insvg
											name="thumb"
											positive="false"
											link="assets/thumb.svg"
											[class.active]="message.reaction === false"
										></app-insvg>
									</button>
								</div>
							</div>
						</ng-container>

						<ng-container *ngSwitchCase="ServiceMessageType.SuggestSurrogate">
							<div class="message__from">
								<small>{{ message.created_at | date: 'h:mm a' }}</small>
							</div>
							<p class="message__text" [ngSwitch]="userType">
								<ng-container *ngSwitchCase="'parent'">New surrogate suggestion from<br />{{ selectedChat.provider?.company_name }}</ng-container>
								<ng-container *ngSwitchDefault> You have suggested the following surrogate to {{ names['user_short'] }} </ng-container>
							</p>
							<div *ngIf="(message.typeData.data$ | async) as surrogate" class="message__surrogate">
								<img src="images/{{ surrogate.images[0] }}" class="profile__main__image" />
								<div class="message__donor__content">
									<p class="content__id">Surrogate #{{ surrogate.id }}</p>

									<div class="content__details">
										<div class="content__details__row"><b>Age: </b>{{ surrogate.age }}</div>
										<div class="content__details__row"><b>Relationship Status: </b>{{ utilService.toCapitalizedCase(surrogate.relationshipStatus) }}</div>
										<div class="content__details__row"><b>Location: </b>{{ surrogate.parsedLocation }}</div>
										<div class="content__details__row"><b>BMI: </b>{{ surrogate.bmi || '-' }}</div>
										<div class="content__details__row"><b>Experienced Surrogate: </b>{{ (utilService.castBooleanOrUnknown(surrogate.experiencedSurrogate) | titlecase) }}</div>
										<div class="content__details__row"><b>Agrees to abortion or selective reduction: </b>{{ (utilService.castBooleanOrUnknown(surrogate.agreesToAbortion) | titlecase) }}</div>
										<div class="content__details__row"><b>Agrees to carry twins: </b>{{ (utilService.castBooleanOrUnknown(surrogate.agreesToTwins) | titlecase) }}</div>
										<div class="content__details__row"><b>COVID Vaccinated: </b>{{ (utilService.castBooleanOrUnknown(surrogate.covidVaccinated) | titlecase) }}</div>
										<div class="content__details__row"><b>No. of Livebirths: </b>{{ utilService.castNumberOrUnknown(surrogate.liveBirthsCount) }}</div>
										<div class="content__details__row"><b>No. of Miscarriages: </b>{{ utilService.castNumberOrUnknown(surrogate.miscarriagesCount) }}</div>
										<div class="content__details__row"><b>No. of C-Sections: </b>{{ utilService.castNumberOrUnknown(surrogate.cSectionsCount) }}</div>
										<div class="content__details__row"><b>Open to same-sex couple: </b>{{ (utilService.castBooleanOrUnknown(surrogate.sameSexCouple) | titlecase) }}</div>

										<div class="content__details__row">
											<b>Surrogate Compensation: </b
											>{{ surrogate.compensation | currency: 'USD':'symbol':'1.0-0' }}
										</div>
										<div class="content__details__row"><b>Total Cost: </b>{{ surrogate.totalCost | currency: 'USD':'symbol':'1.0-0' }}</div>
									</div>
								</div>
								<div class="content__actions">
									<a mat-button color="primary" routerLink="/surrogate/{{ surrogate.id }}" target="_blank">
										<span>View Full Profile</span>
										<mat-icon>arrow_forward</mat-icon>
									</a>
									<a *ngIf="userType !== 'parent'" mat-button color="primary" target="_blank" [href]="surrogate.originUrl" target="_blank">
										<span>View Original Profile</span>
										<mat-icon>arrow_forward</mat-icon>
									</a>
								</div>
							</div>

							<div *ngIf="userType === 'parent'" class="message__feedback">
								<p class="message__text">Let the agency know if you like this surrogate</p>
								<div class="message__feedback__actions">
									<button class="action-up" mat-icon-button (click)="rateMessage(message.id, true)">
										<app-insvg name="thumb" positive="true" link="assets/thumb.svg" [class.active]="message.reaction"></app-insvg>
									</button>
									<button class="action-down" mat-icon-button (click)="rateMessage(message.id, false)">
										<app-insvg
												name="thumb"
												positive="false"
												link="assets/thumb.svg"
												[class.active]="message.reaction === false"
										></app-insvg>
									</button>
								</div>
							</div>
						</ng-container>

						<ng-container *ngSwitchCase="ServiceMessageType.MessageReaction">
							<div class="message__service__icon">
								<app-insvg name="thumb" link="assets/thumb.svg" [attr.positive]="message.typeData.reaction"></app-insvg>
							</div>

							<div [ngSwitch]="userType" class="message__service__text">
								<ng-container *ngSwitchCase="'parent'"> The Agency has been notified that you </ng-container>

								<ng-container *ngSwitchDefault>
									{{ names.user }}
								</ng-container>

								{{ message.typeData.reaction ? '' : 'dis' }}liked this suggestion!
							</div>
						</ng-container>

						<ng-container *ngSwitchCase="ServiceMessageType.NoPhonesUser">
							<img class="tile" [src]="images.system" />
							<div class="message__from">
								<b>{{ names.system }}</b>
								<small>{{ message.created_at | date: 'h:mm a' }}</small>
							</div>

							<ng-container [ngSwitch]="userType">
								<ng-container *ngSwitchCase="'parent'">
									<div class="message__text">
										<span
											>In order to talk with {{ selectedChat.provider?.company_name }} please schedule a call with them via their calendar
											below.</span
										>
									</div>

									<div class="message__calendar">
										<app-calendar-list
											[filterContactId]="message.typeData.contactId"
											[contacts]="selectedChat.provider?.provider_contacts"
											[eggDonorId]="selectedChat.egg_donor_id"
											[dealId]="selectedChat.deal_id"
											(createAppointment)="createAppointment($event)"
											(updateAppointment)="updateAppointment($event)"
											(deleteAppointment)="deleteAppointment($event)"
										></app-calendar-list>
									</div>
								</ng-container>

								<ng-container *ngSwitchDefault>
									<div class="message__text">
										<span
											>{{ names.user }} is trying to send you their phone number. We sent your calendar availability to
											{{ selectedChat.user?.first_name }} so that {{ selectedChat.user?.first_name }} can schedule a call with you</span
										>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>

						<ng-container
							*ngSwitchCase="
								([ServiceMessageType.NoPhonesProvider, ServiceMessageType.DealWithOtherAgencySigned] | includes: message.type)
									? message.type
									: ''
							"
						>
							<img class="tile" [src]="images.system" />
							<div class="message__from">
								<b>{{ names.system }}</b>
								<small>{{ message.created_at | date: 'h:mm a' }}</small>
							</div>

							<div *ngSwitchCase="ServiceMessageType.NoPhonesProvider" class="message__text">
								<span>
									In order to talk with {{ names.user }} please invite {{ selectedChat.user?.first_name }} to schedule a call with you by
									clicking on the
								</span>
								<span>
									<button
										mat-button
										class="profile__appointments__invite"
										[disabled]="!(withCalendar$ | async) || userInvited"
										(click)="inviteUser()"
									>
										<app-insvg
											[name]="userInvited ? 'paper-plane' : 'message'"
											link="assets/{{ userInvited ? 'paper-plane' : 'message' }}.svg"
										></app-insvg>
										<span>{{ userInvited ? 'Invitation Sent' : 'Invite to Schedule' }}</span>
									</button>
								</span>
								<span
									>button. This will allow {{ selectedChat.user?.first_name }} to see your availability and choose the best time for
									{{ selectedChat.user?.first_name }} to talk with you.</span
								>
							</div>
							<div *ngSwitchCase="ServiceMessageType.DealWithOtherAgencySigned" class="message__text">
								Hi,<br />
								We’re writing to inform you that {{ selectedChat.user?.first_name }} {{ selectedChat.user?.last_name }} has moved on with
								another agency.<br />
								For your convenience, we have changed their status to ‘Cancelled’. You do not need to take any other actions. <br />
								Best,<br />
								GoStork
							</div>
						</ng-container>

						<ng-container *ngSwitchDefault>
							<img class="tile" [src]="images[message.author || meAuthor]" />
							<div class="message__from">
								<div>
									<b>
										<span *ngIf="(selectedChatContacts$ | async)[message.provider_contact_id] as contact">
											{{ contact.first_name }} {{ contact.last_name }} from
										</span>
										<span>{{ names[message.author || meAuthor] }}</span>
									</b>
									<small>{{ message.created_at | date: 'h:mm a' }}</small>
								</div>

								<small *ngIf="message.last" class="message__status">
									{{ message.status }}
								</small>
							</div>
							<div class="message__text" [innerHTML]="message.text"></div>
							<div *ngIf="message.attachments" class="message__attachments attachments__type-{{ message.attachmentsType }}">
								<ng-container [ngSwitch]="message.attachmentsType">
									<img *ngSwitchCase="AttachmentType.IMAGE" loading="lazy" src="images/{{ message.attachments }}" />
									<video
										*ngSwitchCase="AttachmentType.VIDEO"
										controls
										loading="lazy"
										src="images/{{ message.attachments }}#t=0.001"
									></video>
									<div
										*ngSwitchCase="AttachmentType.DOC"
										class="attachments__placeholder"
										(click)="openFile('images/' + message.attachments)"
									>
										<mat-icon>text_snippet</mat-icon>
										<span>{{ message.attachments | getFileName }}</span>
									</div>
								</ng-container>
								<button mat-icon-button class="attachments__download" (click)="downloadFile('images/' + message.attachments)">
									<mat-icon>get_app</mat-icon>
								</button>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="writing">
			<input
				#attachmentsInput
				type="file"
				accept="image/png,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/mp4,video/webm,video/quicktime"
				(change)="uploadFile($event.target.files)"
			/>

			<button
				mat-icon-button
				color="primary"
				class="attach"
				matBadge="1"
				[matBadgeHidden]="!uploadedFileLink$.value"
				(click)="clickWithDelay(attachmentsInput, 150)"
			>
				<mat-icon>attach_file</mat-icon>
				<mat-progress-spinner
					color="primary"
					[class.filled]="uploadedFileLink$.value"
					[mode]="uploadProgress === 100 ? 'indeterminate' : 'determinate'"
					[diameter]="35"
					[value]="uploadProgress"
				></mat-progress-spinner>
			</button>
			<div class="writing__area">
				<textarea
					#area
					placeholder="Type a message..."
					[(ngModel)]="currentMessage"
					(keydown)="onKeyDown($event)"
					(input)="updateHeightOfTextarea($event.target)"
				></textarea>
				<button
					mat-flat-button
					color="primary"
					class="writing__submit"
					[matTooltip]='sendTooltip()'
					[disabled]='!chatReady'
					[class.active]="(currentMessage || uploadedFileLink$.value)"
					(click)="sendMessage()"
				>
					Send
				</button>

				<div class="writing__attachments">
					<div *ngIf="uploadedFileTrusted$ | async as file" class="writing__attachment">
						<ng-container [ngSwitch]="file.mime">
							<img *ngSwitchCase="'image'" [src]="file.path" />
							<video *ngSwitchCase="'video'" src="{{ file.path }}#t=0.001"></video>
							<div *ngSwitchDefault class="attachments__placeholder">
								<mat-icon>text_snippet</mat-icon>
								<span>{{ file.filename | getFileName }}</span>
							</div>
						</ng-container>

						<button class="writing__attachment__remove" (click)="attachmentsInput.value = ''; removeAttachment()">
							<mat-icon>close</mat-icon>
						</button>
					</div>
				</div>
			</div>

			<mat-form-field *ngIf="userType === 'admin' && !selectedChat.isSupportChat" appearance="outline">
				<mat-label>Receiver</mat-label>
				<mat-select [(ngModel)]="messageReceiver">
					<mat-optgroup label="Agency">
						<mat-option *ngFor="let contact of selectedChat.provider?.provider_contacts" [value]="contact.id"
							>{{ contact.first_name }} {{ contact.last_name }}</mat-option
						>
					</mat-optgroup>
					<mat-option value="user">User</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</ng-container>
</div>

<div class="content details">
	<div class="h-20"></div>

	<ng-container *ngIf="(details$ | async) as details">
		<ng-container [ngSwitch]="userType">
			<ng-container *ngSwitchCase="'parent'">
				<ng-container *ngIf="detailsType === ProviderType.EGG_AGENCY">
					<div class="profile__main">
						<img src="images/{{ details.img }}" class="profile__main__image" />
						<div class="main__content">
							<b>Egg Donor #{{ details.id }}</b>
							<p>{{ (selectedChat$ | async).provider?.company_name }}</p>
							<p>{{ details.state }}, {{ details.country }}</p>
							<div class="main__content__availability">
								<span
									class="availability__sign"
									[ngClass]="{
										green: details.availability === 'Available',
										orange: details.availability === 'In Cycle',
										red: details.deleted_at || details.availability === 'Under Review'
									}"
								></span>
								<span>{{ details.deleted_at ? 'Unavailable, deleted at ' + (details.deleted_at | date) : details.availability }}</span>
							</div>
							<a mat-button color="primary" routerLink="/egg-donor/donor/{{ details.id }}" target="_blank">
								<span>View Full Profile</span>
								<mat-icon>arrow_forward</mat-icon>
							</a>
						</div>
					</div>

					<div class="profile__details">
						<div class="profile__details__row"><b>Age: </b>{{ utilService.toHumanReadableOrUnknown(details.age) }}</div>
						<div class="profile__details__row"><b>Hair Color: </b>{{ utilService.toHumanReadableOrUnknown(details.hair_color) }}</div>
						<div class="profile__details__row"><b>Eye Color: </b>{{ utilService.toHumanReadableOrUnknown(details.eye_color) }}</div>
						<div class="profile__details__row"><b>Ethnicity: </b>{{ useArray(details.ethnicity) | titlecase }}</div>
						<div class="profile__details__row"><b>Race: </b>{{ useArray(details.race) | titlecase }}</div>
						<div class="profile__details__row"><b>Religion: </b>{{ utilService.toHumanReadableOrUnknown(details.religion) }}</div>
						<div class="profile__details__row"><b>Height: </b>{{ useHeight(details.height) }}</div>
						<div class="profile__details__row"><b>Weight: </b>{{ useWeight(details.weight) }}</div>
						<div class="profile__details__row"><b>Education Level: </b>{{ utilService.toHumanReadableOrUnknown(details.education_level) }}</div>
						<div class="profile__details__row"><b>Location: </b>{{ utilService.toHumanReadableOrUnknown(details.formattedLocation) }}</div>
						<div class="profile__details__row"><b>Donation Types: </b>{{ (useArray(details.formattedDonationTypes) || '-') | titlecase }}</div>
						<div class="profile__details__row">
							<b>Egg Donor Compensation: </b
							>{{ details.egg_donor_compensation || details.agency_egg_donor_compensation | currency: 'USD':'symbol':'1.0-0' }}
						</div>
						<div class="profile__details__row"><b>Total Cost: </b>{{ details.total_compensation | currency: 'USD':'symbol':'1.0-0' }}</div>
					</div>
				</ng-container>

				<ng-container *ngIf="detailsType === ProviderType.SURROGACY_AGENCY && hasSurrogate">
					<div class="profile__main">
						<img *ngIf="details.images" src="images/{{ details.images[0] }}" class="profile__main__image" />
						<div class="main__content">
							<b>Surrogate #{{ details.id }}</b>
							<p>{{ (selectedChat$ | async).provider?.company_name }}</p>
							<p>{{ details.parsedLocation }}</p>
							<div class="main__content__availability">
								<span
										class="availability__sign"
										[ngClass]="{
										green: details.status === 'available',
										red: details.deletedAt || details.status !== 'available'
									}"
								></span>
								<span>{{ details.deleted_at ? 'Unavailable, deleted at ' + (details.deleted_at | date) : details.availability }}</span>
							</div>
							<a mat-button color="primary" routerLink="/surrogates/{{ details.id }}" target="_blank">
								<span>View Full Profile</span>
								<mat-icon>arrow_forward</mat-icon>
							</a>
							<a *ngIf="userType !== 'parent'" mat-button color="primary" target="_blank" [href]="details.originUrl" target="_blank">
								<span>View Original Profile</span>
								<mat-icon>arrow_forward</mat-icon>
							</a>
						</div>
					</div>

					<div class="profile__details">
						<div class="profile__details__row"><b>Age: </b>{{ details.age }}</div>
						<div class="profile__details__row"><b>Relationship Status: </b>{{ utilService.toCapitalizedCase(details.relationshipStatus) }}</div>
						<div class="profile__details__row"><b>Location: </b>{{ details.parsedLocation }}</div>
						<div class="profile__details__row"><b>BMI: </b>{{ details.bmi || '-' }}</div>
						<div class="profile__details__row"><b>Experienced Surrogate: </b>{{ (utilService.castBooleanOrUnknown(details.experiencedSurrogate) | titlecase) }}</div>
						<div class="profile__details__row"><b>Agrees to abortion or selective reduction: </b>{{ (utilService.castBooleanOrUnknown(details.agreesToAbortion) | titlecase) }}</div>
						<div class="profile__details__row"><b>Agrees to carry twins: </b>{{ (utilService.castBooleanOrUnknown(details.agreesToTwins) | titlecase) }}</div>
						<div class="profile__details__row"><b>COVID Vaccinated: </b>{{ (utilService.castBooleanOrUnknown(details.covidVaccinated) | titlecase) }}</div>
						<div class="profile__details__row"><b>No. of Livebirths: </b>{{ utilService.castNumberOrUnknown(details.liveBirthsCount) }}</div>
						<div class="profile__details__row"><b>No. of Miscarriages: </b>{{ utilService.castNumberOrUnknown(details.miscarriagesCount) }}</div>
						<div class="profile__details__row"><b>No. of C-Sections: </b>{{ utilService.castNumberOrUnknown(details.cSectionsCount) }}</div>
						<div class="profile__details__row"><b>Open to same-sex couple: </b>{{ (utilService.castBooleanOrUnknown(details.sameSexCouple) | titlecase) }}</div>

						<div class="profile__details__row">
							<b>Surrogate Compensation: </b
							>{{ details.compensation || details.compensation | currency: 'USD':'symbol':'1.0-0' }}
						</div>
						<div class="profile__details__row"><b>Total Cost: </b>{{ details.totalCost | currency: 'USD':'symbol':'1.0-0' }}</div>
					</div>
				</ng-container>
				<ng-container *ngIf="detailsType === ProviderType.SURROGACY_AGENCY && !hasSurrogate">
					<div class="profile__main">
						<img src="images/bi-sur/{{ details.id }}/{{ details.img }}" class="profile__main__image" />
						<div class="main__content">
							<b>{{ details.provider?.company_name }}</b>
							<div *ngIf="details.number_of_reviews" class="main__content__reviews">
								<app-rating [rating]="details.rating"></app-rating>
								<a routerLink="/surrogacy/agency/{{ details.id }}" fragment="reviews" target="_blank"
									>{{ details.number_of_reviews }} Review{{ details.number_of_reviews === 1 ? '' : 's' }}</a
								>
							</div>
							<p *ngFor="let location of details.provider?.location || []">{{ location.city }}, {{ location.state }}</p>
							<div class="main__content__accessibility">
								<img
									src="assets/international_parents.png"
									matTooltip="We are {{ details.internationalIntendedParents ? '' : 'not ' }}accepting International Parents"
									[class.disabled]="!details.internationalIntendedParents"
								/>
								<img
									src="assets/LGBT_friendly.svg"
									matTooltip="We {{ details.lgbtFriendly ? 'provide' : 'do not provide' }} LGBTQ+-specific services"
									[class.disabled]="!details.lgbtFriendly"
								/>
							</div>
							<a mat-button color="primary" routerLink="/surrogacy/agency/{{ details.id }}" target="_blank">
								<span>View Full Profile</span>
								<mat-icon>arrow_forward</mat-icon>
							</a>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="detailsType === ProviderType.IVF_CLINIC">
					<div class="profile__main">
						<img [src]="details.img" class="profile__main__image" />
						<div class="main__content">
							<div>
								<b>{{ details.actualName?.[0] }}</b>
							</div>
							<div *ngIf="details.actualName?.[1]" class="main__content__subname">{{ details.actualName?.[1] }}</div>

							<div *ngIf="details.numberOfReviews" class="main__content__reviews">
								<app-rating [rating]="details.rating"></app-rating>
								<a [routerLink]="details.link" fragment="reviews" target="_blank">
									{{ details.numberOfReviews | i18nPlural: {'=0': 'No Reviews', '=1': 'One Review', other: '# Reviews'} }}
								</a>
							</div>
							<p class="main__content__locaitons">
								<span *ngFor="let location of details.location || []">{{ location.city }}, {{ location.state }}</span>
							</p>
							<div class="main__content__accessibility">
								<img
									src="assets/international_parents.png"
									matTooltip="We are {{ details.internationalIntendedParents ? '' : 'not ' }}accepting International Parents"
									[class.disabled]="!details.internationalIntendedParents"
								/>
								<img
									src="assets/bipoc.svg"
									matTooltip="We {{ (details.services | includes: 'have_bipoc_physicians') ? 'have' : 'do not have' }} BIPOC physicians"
									[class.disabled]="!(details.services | includes: 'have_bipoc_physicians')"
								/>
								<img
									src="assets/LGBT_friendly.svg"
									matTooltip="We {{ details.lgbtFriendly ? 'provide' : 'do not provide' }} LGBTQ+-specific services"
									[class.disabled]="!details.lgbtFriendly"
								/>
								<img
									src="assets/hiv.svg"
									matTooltip="We {{ (details.services | includes: 'hiv_patients') ? 'have' : 'do not have' }} HIV patients"
									[class.disabled]="!(details.services | includes: 'hiv_patients')"
								/>
							</div>
							<a mat-button color="primary" [routerLink]="details.link" target="_blank">
								<span>View Full Profile</span>
								<mat-icon>arrow_forward</mat-icon>
							</a>
						</div>
					</div>
				</ng-container>

				<div #detailsCalendar *ngIf="selectedChat$ | async as selectedChat" class="profile__calendar">
					<h3 *ngIf="!(withAppointment$ | async)">Schedule a Call With<br />{{ selectedChat.provider?.company_name }}</h3>
					<h3 *ngIf="withAppointment$ | async">Your Call With<br />{{ selectedChat.provider?.company_name }}<br />is Confirmed</h3>

					<app-calendar-list
						[contacts]="selectedChat.provider?.provider_contacts"
						[eggDonorId]="selectedChat.egg_donor_id"
						[dealId]="selectedChat.deal_id"
						(createAppointment)="createAppointment($event)"
						(updateAppointment)="updateAppointment($event)"
						(deleteAppointment)="deleteAppointment($event)"
					></app-calendar-list>
				</div>
			</ng-container>

			<ng-container *ngSwitchDefault>
				<ng-container *ngIf="userDetails$ | async as userDetails">
					<div *ngIf="userDetails.user && userDetails.annexe" class="profile__details">
						<div class="profile__heading"><b>Parent's Information</b></div>
						<div class="profile__details__row"><b>ID:</b> {{ userDetails.user.id }}</div>
						<div class="profile__details__row">
							<b>Name: </b>
							<span> {{ userDetails.user.first_name }} {{ userDetails.user.last_name }} </span>
							<span *ngIf="userDetails.user.partner_first_name">
								, {{ userDetails.user.partner_first_name }} {{ userDetails.user.partner_last_name }}
							</span>
						</div>
						<div class="profile__details__row">
							<b>Address:</b>
							<ng-container *ngIf="userDetails.user.location">
								{{ userDetails.user.location.city }}, {{ userDetails.user.location.state }},
								{{ userDetails.user.location.country }}
							</ng-container>
						</div>
						<div class="profile__details__row"><b>U.S Resident:</b> {{ userDetails.user.us_resident ? 'Yes' : 'No' }}</div>
						<div class="profile__details__row"><b>LGBTQ+:</b> {{ userDetails.user.identify === 'lgbt' ? 'Yes' : 'No' }}</div>
						<div class="profile__details__row"><b>BIPOC:</b> {{ userDetails.user.bipoc ? 'Yes' : 'No' }}</div>
						<div class="profile__details__row"><b>Relationship Status:</b> {{ userDetails.user.relation | titlecase }}</div>
						<div class="profile__details__row">
							<b>Patient Age:</b> {{ userDetails.annexe[ProviderType.IVF_CLINIC]?.questionnaire.eggsOwnerAge }}
						</div>
						<div class="profile__details__row"><b>Partner's Age:</b> {{ userDetails.user.partner_age }}</div>
						<div class="profile__details__row">
							<b>Fertility Treatment Done Before:</b>
							{{ IvfOptions.inVirto[userDetails.annexe[ProviderType.IVF_CLINIC]?.questionnaire.inVirto] }}
						</div>
						<div class="profile__details__row">
							<b>Want to Start My Journey In:</b>
							{{ IvfOptions.whenToStart[userDetails.annexe[ProviderType.IVF_CLINIC]?.questionnaire.whenToStart] }}
						</div>
						<div class="profile__details__row">
							<b>Most Important to Patient:</b>
							{{ IvfOptions.mostImportant[userDetails.annexe[ProviderType.IVF_CLINIC]?.questionnaire.sort] }}
						</div>
						<div class="profile__details__row">
							<b>Preferred Location:</b>
							<ng-container *ngIf="userDetails.annexe[ProviderType.IVF_CLINIC]?.questionnaire.country">
								{{
									[
										userDetails.annexe[ProviderType.IVF_CLINIC].questionnaire.city,
										userDetails.annexe[ProviderType.IVF_CLINIC].questionnaire.state,
										userDetails.annexe[ProviderType.IVF_CLINIC].questionnaire.country
									]
										| filter
										| join: ', '
								}}
							</ng-container>
						</div>
						<div class="profile__details__row">
							<b>Does Patient Need a Sperm Donor:</b>
							{{ IvfOptions.spermDonor[userDetails.annexe[ProviderType.IVF_CLINIC]?.questionnaire.spermDonor] }}
						</div>
						<div class="profile__details__row">
							<b>Does Patient Need a Surrogate:</b>
							{{ IvfOptions.gestationalCarrier[userDetails.annexe[ProviderType.IVF_CLINIC]?.questionnaire.gestationalCarrier] }}
						</div>
						<div class="profile__details__row">
							<b>Patient Journey: </b>
							<span [innerHTML]="userDetails.annexe[ProviderType.IVF_CLINIC]?.questionnaire.journey" class="journey"></span>
						</div>
					</div>
					<div class="profile__deal">
						<div class="profile__heading"><b>Deal Status</b></div>
						<app-form-field
							type="select"
							label="Select"
							[class.disabled]="userType === 'admin'"
							[options]="detailsType === ProviderType.EGG_AGENCY ? dealStatuses : surrogacyDealStatuses"
							[value]="(selectedChat$ | async).deal.status"
							(valueChange)="updateDealStatus($event)"
						></app-form-field>
					</div>
					<div class="profile__appointments">
						<div class="profile__heading"><b>Appointments</b></div>
						<p *ngFor="let appointment of userDetails.appointments || ['No Upcoming Appointment Has Been Scheduled']">
							{{ appointment.appointment_time ? (appointment.appointment_time | date: "EEEE, MMMM d 'at' h:mm a") : appointment }}
						</p>
						<button
							mat-button
							class="profile__appointments__invite"
							[disabled]="!(withCalendar$ | async) || userInvited"
							(click)="inviteUser()"
						>
							<app-insvg
								[name]="userInvited ? 'paper-plane' : 'message'"
								link="assets/{{ userInvited ? 'paper-plane' : 'message' }}.svg"
							></app-insvg>
							<span>{{ userInvited ? 'Invitation Sent' : 'Invite to Schedule' }}</span>
						</button>
						<mat-hint>{{ names['user_short'] }} will receive an email to schedule an appointment with you.</mat-hint>
					</div>

					<ng-container *ngIf="detailsType === ProviderType.EGG_AGENCY">
						<div class="profile__main profile__main__provider">
							<img src="images/{{ details.img }}" class="profile__main__image" />
							<div class="main__content">
								<b>Egg Donor #{{ details.id }}</b>
								<p>{{ (selectedChat$ | async).provider?.company_name }}</p>
								<p>{{ details.state }}, {{ details.country }}</p>
								<div class="main__content__availability">
									<span
										class="availability__sign"
										[ngClass]="{
											green: details.availability === 'Available',
											orange: details.availability === 'In Cycle',
											red: details.deleted_at || details.availability === 'Under Review'
										}"
									></span>
									<span>{{ details.deleted_at ? 'Unavailable, deleted at ' + (details.deleted_at | date) : details.availability }}</span>
								</div>
								<a mat-button color="primary" routerLink="/egg-donor/donor/{{ details.id }}" target="_blank">
									<span>View Full Profile</span>
									<mat-icon>arrow_forward</mat-icon>
								</a>
								<a mat-button color="primary" target="_blank" [href]="details.origin" target="_blank">
									<span>View Original Profile</span>
									<mat-icon>arrow_forward</mat-icon>
								</a>
							</div>
						</div>
						<div class="profile__details">
							<div class="profile__details__row"><b>Age: </b>{{ utilService.toHumanReadableOrUnknown(details.age) }}</div>
							<div class="profile__details__row"><b>Hair Color: </b>{{ utilService.toHumanReadableOrUnknown(details.hair_color) }}</div>
							<div class="profile__details__row"><b>Eye Color: </b>{{ utilService.toHumanReadableOrUnknown(details.eye_color) }}</div>
							<div class="profile__details__row"><b>Ethnicity: </b>{{ useArray(details.ethnicity) }}</div>
							<div class="profile__details__row"><b>Race: </b>{{ useArray(details.race) }}</div>
							<div class="profile__details__row"><b>Religion: </b>{{ utilService.toHumanReadableOrUnknown(details.religion) }}</div>
							<div class="profile__details__row"><b>Height: </b>{{ useHeight(details.height) }}</div>
							<div class="profile__details__row"><b>Weight: </b>{{ useWeight(details.weight) }}</div>
							<div class="profile__details__row"><b>Education Level: </b>{{ utilService.toHumanReadableOrUnknown(details.education_level) }}</div>
							<div class="profile__details__row"><b>Location: </b>{{ utilService.toHumanReadableOrUnknown(details.formattedLocation) }}</div>
							<div class="profile__details__row"><b>Donation Types: </b>{{ useArray(details.formattedDonationTypes) | titlecase }}</div>
							<div class="profile__details__row">
								<b>Egg Donor Compensation: </b>{{ details.egg_donor_compensation | currency: 'USD':'symbol':'1.0-0' }}
							</div>
							<div class="profile__details__row">
								<b>Total Cost: </b>{{ details.total_compensation | currency: 'USD':'symbol':'1.0-0' }}
							</div>
						</div>
						<div *ngIf="userDetails.user?.filter" class="profile__questionnaire">
							<div class="profile__heading"><b>Questionnaire</b></div>
							<div class="iconic">
								<div class="eye ic">
									<div
										class="circle {{ userDetails.user?.filter.eye_color ? (userDetails.user?.filter.eye_color[0] | lowercase) : 'black' }}"
									>
										<div class="eyelash-group-up">
											<div class="eyelash-1"></div>
											<div class="eyelash-2"></div>
											<div class="eyelash-3"></div>
										</div>
										<div class="dot"></div>
										<div class="outer"></div>
										<div class="eyelash-group-down">
											<div class="eyelash-1"></div>
											<div class="eyelash-2"></div>
											<div class="eyelash-3"></div>
										</div>
									</div>
									<span>{{ userDetails.user?.filter.eye_color ? userDetails.user?.filter.eye_color.join(', ') + ' Eyes' : '-' }}</span>
								</div>
								<div class="hair ic">
									<div class="{{ userDetails.user?.filter.hair_color ? (userDetails.user?.filter.hair_color[0] | lowercase) : 'black' }}">
										<app-insvg link="assets/woman.svg" name="woman"></app-insvg>
									</div>
									<span>{{ userDetails.user?.filter.hair_color ? userDetails.user?.filter.hair_color.join(', ') + ' Hair' : '-' }}</span>
								</div>
								<div class="height ic">
									<div>
										<app-insvg link="assets/ruler.svg" name="ruler"></app-insvg>
										<app-insvg link="assets/a_woman.svg" name="a_woman"></app-insvg>
									</div>
									<span>{{ useHeight(userDetails.user?.filter.height || [135, 211]) }}</span>
								</div>
								<div class="age ic">
									<app-insvg link="assets/a_tree.svg" name="tree"></app-insvg>
									<span>{{ userDetails.user?.filter.age ? userDetails.user?.filter.age.join('-') : '19-36' }}</span>
								</div>
							</div>
							<div class="educationic">
								<div class="education ic">
									<app-insvg
										[name]="((userDetails.user?.filter.education_level | lowercase) || 'none').replace(' ', '_')"
										link="assets/{{
											(userDetails.user?.filter.education_level ? 'education/' + userDetails.user?.filter.education_level : 'none')
												.toLowerCase()
												.replace(' ', '_')
										}}.svg"
									></app-insvg>
									<span>{{ userDetails.user?.filter.education_level || '-' }}</span>
								</div>
								<ul>
									<li>
										<div class="fli">
											<span>High School GPA: </span>
											<span>{{ userDetails.user?.filter.high_school_gpa || '-' }}</span>
										</div>
									</li>
									<li>
										<div class="fli">
											<span>SAT: </span>
											<span>{{ userDetails.user?.filter.sat || '-' }}</span>
										</div>
									</li>
									<li>
										<div class="fli">
											<span>ACT: </span>
											<span>{{ userDetails.user?.filter.act || '-' }}</span>
										</div>
									</li>
									<li>
										<div class="fli">
											<span>College GPA: </span>
											<span>{{ userDetails.user?.filter.college_gpa || '-' }}</span>
										</div>
									</li>
								</ul>
							</div>
							<div class="fli">
								<b>Ethnicity:</b>
								<span>{{ userDetails.user?.filter.ethnicity ? (userDetails.user?.filter.ethnicity.join(', ') | titlecase) : '-' }}</span>
							</div>
							<div class="fli">
								<b>Race:</b>
								<span>{{ userDetails.user?.filter.race ? userDetails.user?.filter.race.join(', ') : '-' }}</span>
							</div>
							<div class="fli">
								<b>Religion:</b>
								<span>{{ userDetails.user?.filter.religion ? userDetails.user?.filter.religion.join(', ') : '-' }}</span>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="detailsType === ProviderType.SURROGACY_AGENCY && hasSurrogate">
						<div class="profile__main">
							<img *ngIf="details.images" src="images/{{ details.images[0] }}" class="profile__main__image" />
							<div class="main__content">
								<b>Surrogate #{{ details.id }}</b>
								<p>{{ (selectedChat$ | async).provider?.company_name }}</p>
								<p>{{ details.parsedLocation }}</p>
								<div class="main__content__availability">
								<span
										class="availability__sign"
										[ngClass]="{
										green: details.status === 'available',
										red: details.deletedAt || details.status !== 'available'
									}"
								></span>
									<span>{{ details.deleted_at ? 'Unavailable, deleted at ' + (details.deleted_at | date) : details.availability }}</span>
								</div>
								<a mat-button color="primary" routerLink="/surrogates/{{ details.id }}" target="_blank">
									<span>View Full Profile</span>
									<mat-icon>arrow_forward</mat-icon>
								</a>
								<a *ngIf="userType !== 'parent'" mat-button color="primary" target="_blank" [href]="details.originUrl" target="_blank">
									<span>View Original Profile</span>
									<mat-icon>arrow_forward</mat-icon>
								</a>
							</div>
						</div>

						<div class="profile__details">
							<div class="profile__details__row"><b>Age: </b>{{ details.age }}</div>
							<div class="profile__details__row"><b>Relationship Status: </b>{{ utilService.toCapitalizedCase(details.relationshipStatus) }}</div>
							<div class="profile__details__row"><b>Location: </b>{{ details.parsedLocation }}</div>
							<div class="profile__details__row"><b>BMI: </b>{{ details.bmi || '-' }}</div>
							<div class="profile__details__row"><b>Experienced Surrogate: </b>{{ (utilService.castBooleanOrUnknown(details.experiencedSurrogate) | titlecase) }}</div>
							<div class="profile__details__row"><b>Agrees to abortion or selective reduction: </b>{{ (utilService.castBooleanOrUnknown(details.agreesToAbortion) | titlecase) }}</div>
							<div class="profile__details__row"><b>Agrees to carry twins: </b>{{ (utilService.castBooleanOrUnknown(details.agreesToTwins) | titlecase) }}</div>
							<div class="profile__details__row"><b>COVID Vaccinated: </b>{{ (utilService.castBooleanOrUnknown(details.covidVaccinated) | titlecase) }}</div>
							<div class="profile__details__row"><b>No. of Livebirths: </b>{{ utilService.castNumberOrUnknown(details.liveBirthsCount) }}</div>
							<div class="profile__details__row"><b>No. of Miscarriages: </b>{{ utilService.castNumberOrUnknown(details.miscarriagesCount) }}</div>
							<div class="profile__details__row"><b>No. of C-Sections: </b>{{ utilService.castNumberOrUnknown(details.cSectionsCount) }}</div>
							<div class="profile__details__row"><b>Open to same-sex couple: </b>{{ (utilService.castBooleanOrUnknown(details.sameSexCouple) | titlecase) }}</div>

							<div class="profile__details__row">
								<b>Surrogate Compensation: </b
								>{{ details.compensation || details.compensation | currency: 'USD':'symbol':'1.0-0' }}
							</div>
							<div class="profile__details__row"><b>Total Cost: </b>{{ details.totalCost | currency: 'USD':'symbol':'1.0-0' }}</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>

	<div class="h-20"></div>
</div>

<ng-template #lastMessageRef let-message="message" let-chat="chat">
	<p [ngSwitch]="message.type">
		<ng-container *ngSwitchCase="ServiceMessageType.InviteForAppointment">
			{{
				userType === 'parent'
					? message.typeData.name + ' from ' + chat.provider?.company_name
					: 'An invitation to schedule an appointment has been sent'
			}}
			has invited you to schedule a call!
		</ng-container>

		<ng-container
			*ngSwitchCase="
				([ServiceMessageType.CreatedAppointment, ServiceMessageType.UpdateAppointment, ServiceMessageType.CancelAppointment]
				| includes: message.type)
					? message.type
					: ''
			"
		>
			{{
				meAuthor === message.author
					? 'You'
					: message.author === Author.USER
					? chat.user?.first_name + ' ' + chat.user?.last_name
					: chat.provider?.company_name + ' has'
			}}
			{{
				message.type === ServiceMessageType.CreatedAppointment
					? 'booked'
					: message.type === ServiceMessageType.UpdateAppointment
					? 'rescheduled'
					: 'canceled'
			}}
			a meeting
		</ng-container>

		<ng-container *ngSwitchCase="ServiceMessageType.SuggestDonor">
			{{
				userType === 'parent'
					? 'New egg donor suggestion from ' + chat.provider?.company_name
					: 'You have suggested the following egg donor'
			}}
		</ng-container>

		<ng-container *ngSwitchCase="ServiceMessageType.SuggestSurrogate">
			{{
			userType === 'parent'
					? 'New surrogate suggestion from ' + chat.provider?.company_name
					: 'You have suggested the following surrogate'
			}}
		</ng-container>

		<ng-container *ngSwitchCase="ServiceMessageType.MessageReaction">
			{{ userType === 'parent' ? 'The Agency has been notified that you' : chat.user?.first_name }}
			{{ message.typeData.reaction ? '' : 'dis' }}liked suggested donor
		</ng-container>

		<ng-container *ngSwitchCase="ServiceMessageType.NoPhonesUser">
			{{
				userType === 'parent'
					? 'In order to talk with ' + chat.provider?.company_name + ' please schedule a call with them via their calendar below.'
					: chat.user?.first_name + ' is trying to send you their phone number.'
			}}
		</ng-container>

		<ng-container *ngSwitchCase="ServiceMessageType.NoPhonesProvider">
			In order to talk with {{ chat.user?.first_name }} please invite {{ chat.user?.first_name }} to schedule a call
		</ng-container>

		<ng-container *ngSwitchCase="ServiceMessageType.DealWithOtherAgencySigned">
			Hi, We’re writing to inform you that {{ chat.user?.first_name }} {{ chat.user?.last_name }} has moved on with another agency.
		</ng-container>

		<ng-container *ngSwitchDefault>
			<span [innerHTML]="message.text || (message.attachments | getFileName)"></span>
		</ng-container>
	</p>
	<small>{{ message.created_at | date }}</small>
</ng-template>
