<div *ngIf="video" class="why-1">
	<div class="why-2">
		<iframe
			*ngIf="youtubeVideoLink; else plainVideo"
			width="100%"
			#iframeRef
			type="text/html"
			[src]="youtubeVideoLink"
			frameborder="0"
		></iframe>
		<ng-template #plainVideo>
			<button mat-button *ngIf="!isVideoPlaying" (click)="openVideo()">
				<img src="assets/play.svg" />
			</button>
			<video #videoRef src="{{ video }}#t=0.001" [controls]="isVideoPlaying"></video>
		</ng-template>
	</div>
</div>
