import { ScrollStrategy } from '@angular/cdk/overlay';
import { OverlayReference } from '@angular/cdk/overlay/overlay-reference';

export class StaticScrollBlockStrategy implements ScrollStrategy {
	attach(overlayRef: OverlayReference): void {}

	disable(): void {
		document.documentElement.classList.remove('static-scroll-block');
	}

	enable(): void {
		document.documentElement.classList.add('static-scroll-block');
	}
}
