import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardComponent } from './card.component';
import { KeepInViewDirective } from './keepInView.directive';

@NgModule({
	declarations: [CardComponent, KeepInViewDirective],
	imports: [
		CommonModule
	],
	exports: [CardComponent, KeepInViewDirective]
})
export class CardModule { }
