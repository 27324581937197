<div class="container">
    <div class="text">
        <div class="main-text">
            <span>stay tuned, more providers are coming soon!</span>
        </div>
        <p class="body-text">GoStork is adding even more providers to make your journey even easier. Soon you'll also be
            able to search,
            compare and instantly connect with Fertility clinics and surrogacy agencies from all around the United States.</p>
    </div>
    <div class="subscribe">
        <mat-card>
            <div class="content">
                <div class="main-text">
                    <span>Be the first to know</span>
                </div>
                <div class="body-text">
                    <span>Subscribe to GoStork's mailing list to get notified of our latest updates and releases</span>
                </div>
                <form>
                    <div class="form-field">
                        <app-form-field
                                type="text"
                                label="email"
                                [(value)]="data"
                                [disabled]="disabled"
                                [validation]="required"
                                autocomplete="email"
                                (submit)="onSubmit()"
                        ></app-form-field>
                    </div>
                    <div class="button">
                        <button mat-raised-button color="accent" (click)="onSubmit()">SUBSCRIBE</button>
                    </div>
                </form>
            </div>
        </mat-card>
    </div>
    <div class="footer">
        <div class="waves">
            <app-insvg name="waves-main-1" link="assets/waves-main-1.svg" class="waves-item" id="waves-main-1"></app-insvg>
            <app-insvg name="waves-main-2" link="assets/waves-main-2.svg" class="waves-item" id="waves-main-2"></app-insvg>
            <app-insvg name="waves-aqua" link="assets/waves-aqua.svg" class="waves-item" id="waves-aqua-1"></app-insvg>
            <app-insvg name="waves-aqua" link="assets/waves-aqua.svg" class="waves-item" id="waves-aqua-2"></app-insvg>
        </div>
        <div class="duck">
            <app-insvg name="main-duck" link="assets/duck-main.svg" class="duck-item" id="duck-main"></app-insvg>
            <app-insvg name="secondary-duck" link="assets/duck-secondary.svg" class="duck-item" id="duck-secondary-1"></app-insvg>
            <app-insvg name="secondary-duck" link="assets/duck-secondary.svg" class="duck-item" id="duck-secondary-2"></app-insvg>
            <app-insvg name="secondary-duck" link="assets/duck-secondary.svg" class="duck-item" id="duck-secondary-3"></app-insvg>
        </div>
    </div>
</div>
