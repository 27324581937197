/**
 * This file is full of awsful methods and untyped variables
 * Everything here will be rewritten from scratch in less then a month
 */

import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import Fuse from 'fuse.js';
import {DateTime} from 'luxon';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, map, skipWhile} from 'rxjs/operators';

import {Option} from '@/components/form-field/select/select.component';
import {ProviderType} from '@/config';
import {nanoid} from '@/helpers/nanoid';
import {AnalyticsService} from '@/services/analytics.service';
import {AuthStoreService} from '@/services/auth.service';
import {CacheService} from '@/services/cache.service';
import {Location} from '@/services/location.service';
import {Annexe} from '@/services/parent.service';
import {UtilsService} from '@/services/utils.service';
import {IFilters} from '../ivf/ivf.service';

import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

import {Router} from '@angular/router';
import {CheckoutService} from '@/services/checkout.service';

type IvfLocaiton = Pick<IFilters, 'city' | 'country' | 'state' | 'coordinates' | 'zip'>;

@Component({
	selector: 'app-checkout-screen',
	templateUrl: './checkout-screen.component.html',
	styleUrls: ['./checkout-screen.component.scss'],
})
export class CheckoutScreenComponent implements OnInit {
	@ViewChild('subscriptionRef', {static: true})
	subscriptionRef: TemplateRef<void>;

	stripe_customer_id: string;
	stripe_subscription_id: string;
	stripe_subscription_status: string;
	stripe_cancel_at_period_end: Boolean;
	stripe_canceled_at: Date;
	stripe_current_period_end: Date;
	stripe_subscription_can_subscribe: Boolean = false;

	readonly validators = {
		first_name: [Validators.required],
		last_name: [Validators.required],
		birthday: [
			Validators.required,
			(control: AbstractControl) => {
				const now = new Date();
				if (control.value > now) return {nofuture: {value: control.value}};
				else return null;
			},
		],
		email: [Validators.required, Validators.email],
		phone: [Validators.required],
		password: [
			(control: AbstractControl) => {
				if (control.value && control.value.length)
					return {
						...Validators.minLength(8)(control),
						...((control.value || '').match(/[A-Z]+/) ? null : {capital: {value: control.value}}),
						...((control.value || '').match(/[a-z]+/) ? null : {lower: {value: control.value}}),
						...((control.value || '').match(/[0-9]+/) ? null : {number: {value: control.value}}),
					};
				else return null;
			},
		],
		confirm_password: [(control: AbstractControl) => (control.value === this.fields.password ? null : {match: {value: control.value}})],
	};
	readonly errors = {
		first_name: {
			required: 'First Name is required.',
			minlength: 'First Name is too short.',
			pattern: 'First Name should include only latin letters.',
		},
		last_name: {
			required: 'First Name is required.',
			minlength: 'First Name is too short.',
			pattern: 'First Name should include only latin letters.',
		},
		partner_age: {
			required: 'Age is required',
		},
		birthday: {
			required: 'Birth Date is required.',
			nofuture: 'You cannot choose future date.',
		},
		email: {
			required: 'Email is required.',
			email: 'Email is not valid.',
		},
		phone: {
			required: 'Phone Number is required.',
			invalid: 'Phone Number is not valid.',
		},
		password: {
			minlength: 'Password should be at least 8 characters.',
			capital: 'Password should contain at least one capital letter.',
			lower: 'Password should contain at least one lowercase letter.',
			number: 'Password should contain at least one number.',
		},
		confirm_password: {
			match: "Password doesn't match.",
		},
	};
	readonly selectOptions = {
		identify: [
			{label: 'Straight', value: 'heterosexual'},
			{label: 'LGBTQ+', value: 'lgbt'},
		],
		relation: [
			{label: 'Single', value: 'single', icon: 'assets/relation/single.svg'},
			{label: 'Committed Relationship', value: 'committed_relationship', icon: 'assets/relation/committed_relationship.svg'},
			{label: 'Married', value: 'married', icon: 'assets/relation/married.svg'},
		],
	};

	loading = false;
	get showParentProfile() {
		return this.fields.relation === 'married' || this.fields.relation === 'committed_relationship';
	}

	currentStep: TemplateRef<void>;

	steps = [];

	annexe: Record<ProviderType, Annexe>;
	readonly fields: {
		birthday: Date;
		first_name: string;
		last_name: string;
		email: string;
		phone: string;
		identify: string;
		relation: string;
		password: string;
		confirm_password: string;
		partner_first_name: string;
		partner_last_name: string;
		partner_age: string;
		location: {
			country: string;
			state: string;
			city: string;
		};
		ivf_clinic: string;
	} = {
		birthday: null,
		first_name: null,
		last_name: null,
		email: null,
		phone: null,
		identify: null,
		relation: null,
		password: null,
		confirm_password: null,
		partner_first_name: null,
		partner_last_name: null,
		partner_age: null,
		location: null,
		ivf_clinic: null,
	};

	readonly availableDays = [
		{value: 'MON', label: 'Mon'},
		{value: 'TUE', label: 'Tue'},
		{value: 'WED', label: 'Wed'},
		{value: 'THU', label: 'Thu'},
		{value: 'FRI', label: 'Fri'},
		{value: 'SAT', label: 'Sat'},
		{value: 'SUN', label: 'Sun'},
	];

	readonly amountBeforeMeeting = [
		{value: 15, label: '15 minutes'},
		{value: 30, label: '30 minutes'},
		{value: 60, label: '1 hour'},
		{value: 2 * 60, label: '2 hours'},
		{value: 3 * 60, label: '3 hours'},
		{value: 4 * 60, label: '4 hours'},
		{value: 5 * 60, label: '5 hours'},
		{value: 6 * 60, label: '6 hours'},
		{value: 24 * 60, label: '1 day'},
		{value: 2 * 24 * 60, label: '2 days'},
		{value: 3 * 24 * 60, label: '3 days'},
	];

	availableTimeValues = (() => {
		const anchor = DateTime.fromSeconds(0).toUTC();

		return Array.from({length: 24 * 4}).map((_, i) => {
			const delta = 15 * i;
			return {label: anchor.plus({minute: delta}).toFormat('hh:mm a'), value: delta};
		});
	})();

	tzones: Option[];
	calendarConfig = {
		calendar_id: '',
		time_zone: '',
		availability: [],
		duration_of_meetings: [],
		amount_before_meeting: 0,
		subject_of_meeting_invitation: '',
		location_of_meeting: '',
		meeting_invitation_description: '',
	};

	get calendarLinks() {
		const group = `${location.origin}/concierge`;
		return {
			group,
			self: `${group}/${this.calendarConfig?.calendar_id}`,
		};
	}

	locationString = '';

	ivfFuse = new Fuse([] as string[]);
	ivfClinicValue$ = new Subject();
	clinics$ = this.ivfClinicValue$.pipe(
		debounceTime(100),
		distinctUntilChanged(),
		map((value) => this.ivfFuse.search(value)),
	);

	get fieldsAreValid() {
		const request = {...this.fields};
		if (request.password) {
			if (request.password !== request.confirm_password) {
				return false;
			}
			request.password = request.confirm_password;
		} else if (request.hasOwnProperty('password')) {
			delete request.password;
			delete request.confirm_password;
		}

		if (!this.showParentProfile) {
			delete request.partner_first_name;
			delete request.partner_last_name;
			delete request.partner_age;
		}

		return Object.values(request).every((item) => item !== null);
	}

	get fullAddress() {
		const filters = this.annexe?.ivf_clinic?.questionnaire;
		return filters ? [filters.zip, filters.city, filters.state, filters.country].filter(Boolean).join(', ') : '';
	}
	ivfLocation: IvfLocaiton;

	constructor(
		private store: AuthStoreService,
		private snackBar: MatSnackBar,
		private analytics: AnalyticsService,
		private cache: CacheService,
		private utils: UtilsService,
		private http: HttpClient,
		public router: Router,
		private checkoutService: CheckoutService,
	) {
		this.analytics.setDimension('My Account', 'User area');
	}
	ngOnInit() {
		this.store.getUser().subscribe((res) => {
			this.stripe_subscription_can_subscribe = res.stripe_subscription_can_subscribe;

			this.stripe_customer_id = res.stripe_customer_id;
			this.stripe_subscription_id = res.stripe_subscription_id;
			this.stripe_subscription_status = res.stripe_subscription_status;
			this.stripe_cancel_at_period_end = res.stripe_cancel_at_period_end;
			this.stripe_canceled_at = res.stripe_canceled_at;
			this.stripe_current_period_end = res.stripe_current_period_end;

			if (this.stripe_subscription_can_subscribe) {
				this.router.navigate(['/subscription']);
			} else {
				this.router.navigate(['/']);
			}

			this.annexe = res.annexe;
			this.ivfLocation = (this.annexe.ivf_clinic?.questionnaire.location as IvfLocaiton) || {
				city: '',
				coordinates: [0, 0],
				country: '',
				state: '',
				zip: '',
			};
			for (const key in this.fields) {
				this.fields[key] = res[key];
			}

			this.locationString = res.location
				? [res.location.city, res.location.state, res.location.country].filter(Boolean).join(', ')
				: '';
		});

		this.cache
			.select<any>('options')
			.pipe(
				skipWhile((res) => !res),
				skipWhile((config) => !config?.ivf?.length),
				first(),
			)
			.subscribe((config) => {
				this.tzones = this.utils.IANAToOptions(config.timezones || []);
				this.ivfFuse.setCollection(config.ivf);
			});

		this.currentStep = this.subscriptionRef;

		this.steps = [{label: 'Subscription', value: this.subscriptionRef}];
	}

	goToCheckout(step) {
		this.currentStep = step.value;
	}

	async copyToClipboard(text: string) {
		await navigator.clipboard?.writeText(text);
		this.snackBar.open('Link was copied to the clipboard');
	}

	onAddressDetails(location: Location) {
		this.fields.location = location;
	}

	removeAvailableRow(index: number) {
		this.calendarConfig.availability.splice(index, 1);
	}

	addAvailableRow() {
		this.calendarConfig.availability.push({
			days: [],
			start: 540,
			end: 1080,
		});
	}

	removeDurationRow(index: number) {
		this.calendarConfig.duration_of_meetings.splice(index, 1);
	}

	addDurationRow() {
		this.calendarConfig.duration_of_meetings.push(60);
	}

	onSubmit() {
		const request = {...this.fields};
		if (request.password) request.password = request.confirm_password;
		else if (request.hasOwnProperty('password')) {
			delete request.password;
			delete request.confirm_password;
		}

		if (!this.showParentProfile) {
			delete request.partner_first_name;
			delete request.partner_last_name;
			delete request.partner_age;
		}

		if (!this.fieldsAreValid) {
			return;
		}

		this.analytics.push({
			token: nanoid(12),
			category: 'User Area',
			action: 'Change personal data',
			label: `CTA - Submit`,
		});

		this.loading = true;
		this.store.updateUser({...request, ivfLocation: this.ivfLocation}).subscribe(
			() => {
				this.loading = false;
				this.snackBar.open('Profile successfully saved', null, {duration: 3000});
			},
			() => this.snackBar.open('Something went wrong', null, {duration: 5000}),
		);
	}

	saveCalendarConfig() {
		this.loading = true;
		this.store.updateCalenadrConfig(this.calendarConfig).subscribe({
			next: () => {
				this.loading = false;
				this.snackBar.open('Calendar Config successfully saved', null, {duration: 3000});
			},
			error: () => this.snackBar.open('Something went wrong', null, {duration: 5000}),
		});
	}

	connectCalendar = (event: MessageEvent) => {
		this.store.connectCalendar(event.data).subscribe(() => window.location.reload());
		window.removeEventListener('message', this.connectCalendar);
	};

	detachCalednar() {
		this.loading = true;
		this.store.detachCalednar().subscribe({
			next: () => location.reload(),
			error: () => this.snackBar.open('Something went wrong', null, {duration: 5000}),
		});
	}

	onMapCoordinates([lng, lat]: [number, number]) {
		this.ivfLocation.coordinates = [lng, lat];
	}

	onMapDetails({country = '', city = '', state = '', zip = ''}) {
		this.ivfLocation.country = country;
		this.ivfLocation.state = state;
		this.ivfLocation.city = city;
		this.ivfLocation.zip = state && zip ? `${state} ${zip}` : zip;

		if ([country, city, state, zip].every((item) => item === '')) {
			this.ivfLocation.coordinates = [0, 0];
		}
	}

	openCalendarUrl(type: string) {
		const modal = window.open('', '_blank');
		modal.document.write('Loading...');
		this.store.getCalendarAuthUrl(type).subscribe((authUrl) => {
			window.addEventListener('message', this.connectCalendar);
			modal.location.href = authUrl;
		});
	}

	cancelSubscription() {
		this.http
			.post('/api/cancel-subscription', {})
			.pipe(
				switchMap((session) => {
					//return null
					return this.router.navigate(['/account']);
				}),
			)
			.subscribe((result) => {
				// If `redirectToCheckout` fails due to a browser or network
				// error, you should display the localized error message to your
				// customer using `error.message`.
			});
	}
}
