<div class="container" [@openClose]="openClose">
	<ng-container *ngIf="!isAnswered; else answeredTemplate">
		<ng-container *ngIf="lastQuestion">
			<h2>{{ lastQuestion.question }}</h2>

			<div class="row" [class.vertical]="useVerticalLayout" [class.freetext]="showTextField">
				<ng-container *ngIf="showTextField">
					<mat-form-field appearance="outline" color="accent">
						<textarea matInput rows="10" [placeholder]="textFieldLabel" [(ngModel)]="textFieldValue"></textarea>
					</mat-form-field>

					<button mat-flat-button (click)="freeTextAction()">Submit</button>
				</ng-container>

				<ng-container *ngIf="!showTextField">
					<button mat-flat-button *ngFor="let item of lastQuestion.answers" (click)="item.action()">{{ item.answer }}</button>
				</ng-container>
			</div>
		</ng-container>

		<button mat-icon-button *ngIf="withClose" class="close" (click)="close()">
			<mat-icon>close</mat-icon>
		</button>
	</ng-container>
</div>

<ng-template #answeredTemplate>
	<p class="title">Thank you.</p>
	<p class="subtitle">We look forward to supporting you on your journey!</p>
</ng-template>
