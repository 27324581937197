import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CalendarModule} from '@/components/calendar/calendar.module';
import {FormFieldModule} from '@/components/form-field/form-field.module';
import {MaterialModule} from '@/material.module';

import {TimezoneDateModule} from '@/pipes/timezoneDate.module';
import {ConciergeCalendarComponent} from './concierge-calendar.component';

@NgModule({
	declarations: [ConciergeCalendarComponent],
	imports: [CommonModule, CalendarModule, MaterialModule, FormFieldModule, TimezoneDateModule],
})
export class ConciergeCalendarModule {}
