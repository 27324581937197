import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GetFileNamePipe } from './get-file-name.pipe';
import { IncludesPipe } from './incldues.pipe';

@NgModule({
	declarations: [GetFileNamePipe, IncludesPipe],
	imports: [CommonModule],
	exports: [GetFileNamePipe, IncludesPipe],
})
export class PipesModule {}
