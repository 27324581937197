import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { nanoid } from 'src/app/helpers/nanoid';
import { AnalyticsService } from 'src/app/services/analytics.service';

import { Appointment, CalendarViewState, ContactAgencyService } from 'src/app/services/contact-agency.service';
import { timezoneService } from 'src/app/services/timezone.service';
import { UtilsService } from 'src/app/services/utils.service';
import { LocationUpdateService } from '@/components/calendar/calendar-list/LocationUpdateService';
import { LocationBase } from '@/models/common.model';

export interface Contact {
	id: number;
	first_name: string;
	last_name: string;
	calendar_id: string;
	location?: LocationBase[];
}

export interface Location extends LocationBase {
	label: string;
	value: string;
}

export type ModifiedContact = Contact & { duration$: Observable<Array<{ label: string; value: number }>> };
export interface AppointmentEvent {
	time: DateTime;
	calendarId: string;
	location: LocationBase;
}

@Component({
	selector: 'app-calendar-list',
	templateUrl: './calendar-list.component.html',
	styleUrls: ['./calendar-list.component.scss'],
})
export class CalendarListComponent implements OnInit, OnDestroy {
	@Input()
	forceMobile = true;

	@Input()
	dealId: number;

	@Output()
	onSelectTime = new EventEmitter<Pick<AppointmentEvent, 'calendarId' | 'time'>>();
	@Output()
	createAppointment = new EventEmitter<AppointmentEvent>();
	@Output()
	beforeCreateAppointment = new EventEmitter<AppointmentEvent>();
	@Output()
	updateAppointment = new EventEmitter<AppointmentEvent>();
	@Output()
	beforeUpdateAppointment = new EventEmitter<AppointmentEvent>();
	@Output()
	deleteAppointment = new EventEmitter<AppointmentEvent>();

	oldAppointmentId: number;

	existingAppointments$: Observable<Record<number, Appointment>>;

	duration: number;

	get bookingTimeJS() {
		return this.bookingTime.toJSDate();
	}

	bookingTime: DateTime;
	bookingInProgress = false;
	bookingCalendarId: string;

	intersectionObserver: IntersectionObserver;
	showCalendar = false;

	viewState = CalendarViewState.SCHEDULE;
	CalendarViewState = CalendarViewState;

	id = nanoid(12);

	tzones = this.utilsService.tzones;
	tz = timezoneService.timezone;

	@Input()
	public get contacts(): Contact[] {
		return this._filteredContacts;
	}
	public set contacts(value: Contact[]) {
		if (value) {
			this._contacts = value.map((contact) => ({
				...contact,
				duration$: this.contactAgencyService.fetchConfigById(contact.calendar_id).pipe(
					filter((config) => !!config),
					map(({ duration_of_meetings }) => duration_of_meetings),
					map((duration) =>
						duration
							.map((item) => ({
								label: this.utilsService.durationToString(item),
								value: item,
							}))
							.sort((a, b) => b.value - a.value),
					),
					tap((duration) => this.duration || (this.duration = duration[0].value)),
				),
			}));
			this._filteredContacts = this.filterContactId
				? Array.isArray(this.filterContactId)
					? this._contacts.filter((item) => (this.filterContactId as number[]).includes(item.id))
					: this._contacts.filter((item) => item.id === this.filterContactId)
				: this._contacts;
			this.updateExistingAppointments();
			this.cleanup();
		}
	}
	_filteredContacts: ModifiedContact[];
	private _contacts: ModifiedContact[];

	@Input()
	public get eggDonorId(): number {
		return this._eggDonorId;
	}
	public set eggDonorId(value: number) {
		this._eggDonorId = value;
		this.updateExistingAppointments();
		this.cleanup();
	}
	private _eggDonorId: number;

	@Input()
	public get filterContactId(): number | number[] {
		return this._filterContactId;
	}
	public set filterContactId(value: number | number[]) {
		this._filterContactId = value;
		this.contacts = this._contacts;
		this.cleanup();
	}
	private _filterContactId: number | number[];

	@Input()
	public get locations(): Location[] {
		return this._locations;
	}
	public set locations(value: Location[]) {
		this._locations = value
			?.filter((item) => {
				const id = this.locationToLabel(item);
				return this._contacts.some(
					(contact) =>
						!contact.location || contact.location.some((contactLocation) => this.locationToLabel(contactLocation) === id),
				);
			})
			.map((item) => ({
				...item,
				label: `${item.city}, ${item.state}`,
				value: `${item.city}, ${item.state}`,
			}));

		this.selectLocation(this.locations?.[0]?.value);
	}
	private _locations: Location[];

	selectedLocation: string = null;

	constructor(
		private utilsService: UtilsService,
		private contactAgencyService: ContactAgencyService,
		private elementRef: ElementRef<HTMLElement>,
		private changeDetectorRef: ChangeDetectorRef,
		private analytics: AnalyticsService,
		private locationUpdateService: LocationUpdateService,
	) {}

	locationToLabel(location?: LocationBase) {
		return location && `${location.country}-${location.city}-${location.state}`;
	}

	onIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
		const el = entries[entries.length - 1];
		if (el.isIntersecting && !this.showCalendar) {
			this.showCalendar = true;
			this.changeDetectorRef.detectChanges();
		}
	}

	updateExistingAppointments() {
		this.existingAppointments$ = this.contactAgencyService.getAppointments().pipe(
			map((appointments) => appointments.filter((item) => this.contacts.find((contact) => contact.id === item.provider_contact_id))),
			map((appointments) =>
				appointments.reduce(
					(res, item) => ({
						...res,
						[item.provider_contact_id]: item,
					}),
					{} as Record<number, Appointment>,
				),
			),
			startWith({}),
		);
	}

	cleanup() {
		this.bookingTime = null;
		this.viewState = CalendarViewState.SCHEDULE;
	}

	changeTimezone(value: string) {
		timezoneService.timezone = value;
		this.tz = value;
	}

	selectLocation(value: string) {
		this.selectedLocation = value;
		const selected = this.locations?.find((item) => item.value === value);
		if (selected) {
			const id = this.locationToLabel(selected);
			const contacts = this._contacts.filter(
				(contact) => !contact.location || contact.location.some((contactLocation) => this.locationToLabel(contactLocation) === id),
			);
			this.filterContactId = contacts.map((item) => item.id);
			this.locationUpdateService.sendData(selected);
		}
	}

	onTimeSelect(time: DateTime, calendarId: string) {
		console.log(`[onTimeSelect] time => ${time}`);
		this.onSelectTime.emit({
			time,
			calendarId,
		});
		this.bookingTime = time;
		this.bookingCalendarId = calendarId;
		this.viewState = CalendarViewState.CONFIRM;
		this.analytics.push({
			token: this.id,
			category: 'appointment',
			action: 'appointment_scheduling',
			value: calendarId,
		});
	}

	onAppointmentConfirm(force = false) {
		if (!force && this.bookingInProgress) {
			return;
		}
		this.bookingInProgress = true;

		const selectedOption = this.locations?.find((item) => item.value === this.selectedLocation);
		const selectedLocation = selectedOption
			? {
					country: selectedOption.country,
					city: selectedOption.city,
					state: selectedOption.state,
			  }
			: null;

		if (!this.dealId) {
			if (this.oldAppointmentId) {
				this.beforeUpdateAppointment.emit({
					calendarId: this.bookingCalendarId,
					time: this.bookingTime,
					location: selectedLocation,
				});
			} else {
				this.beforeCreateAppointment.emit({
					calendarId: this.bookingCalendarId,
					time: this.bookingTime,
					location: selectedLocation,
				});
			}
			return;
		}

		this.contactAgencyService
			.createAppointment({
				calendarId: this.bookingCalendarId,
				appointmentTime: this.bookingTime.toISO(),
				duration: this.duration,
				dealId: this.dealId,
				oldAppointmentId: this.oldAppointmentId,
				eggDonorId: this.eggDonorId,
			})
			.subscribe(() => {
				this.viewState = CalendarViewState.SCHEDULE;
				this.bookingInProgress = false;
				if (this.oldAppointmentId) {
					this.updateAppointment.emit({ calendarId: this.bookingCalendarId, time: this.bookingTime, location: selectedLocation });
				} else {
					this.createAppointment.emit({ calendarId: this.bookingCalendarId, time: this.bookingTime, location: selectedLocation });
				}
				this.oldAppointmentId = null;
				this.changeDetectorRef.detectChanges();
			});
	}

	_updateAppointment({ date, id }: { date: DateTime; id: number }, calendarId: string) {
		this.onTimeSelect(date, calendarId);
		this.oldAppointmentId = id;
		this.onAppointmentConfirm();
	}

	_deleteAppointment(id: number, calendarId: string) {
		const selectedOption = this.locations?.find((item) => item.value === this.selectedLocation);
		const selectedLocation = selectedOption
			? {
					country: selectedOption.country,
					city: selectedOption.city,
					state: selectedOption.state,
			  }
			: null;

		this.contactAgencyService.cancelAppointment(id).subscribe(({ appointment_time }) =>
			this.deleteAppointment.emit({
				time: DateTime.fromISO(appointment_time as unknown as string),
				calendarId,
				location: selectedLocation,
			}),
		);
	}

	ngOnInit() {
		if (window.IntersectionObserver) {
			this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), {
				root: null,
				rootMargin: '0px',
				threshold: 0,
			});
			this.intersectionObserver.observe(this.elementRef.nativeElement);
		} else {
			this.showCalendar = true;
		}
	}

	ngOnDestroy() {
		this.intersectionObserver?.disconnect();
	}
}
