import {BreakpointObserver} from '@angular/cdk/layout';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {DateTime} from 'luxon';
import {from, of} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, switchMap, tap} from 'rxjs/operators';

import {timeZonesNames} from '@vvo/tzdb';

import defaults from '../screens/questionnaire/assets/egg';
import {CacheService} from './cache.service';
import {compact} from "lodash";
import {Weight} from "@/screens/questionnaire/egg-q/min-max/weight-min-max.component";

export enum ItemType {
	DONOR = 'donor',
	SURROGACY = 'surrogacy',
	SURROGATE = 'surrogate'
}

export enum Orientation {
	LANDSCAPE,
	PORTRAIT,
}

const extractRangeProperty = (rangeProperty: null | [any, any], rangeBounds: { min_value: number, max_value: number}) => {
	let prop = rangeProperty[0] && rangeProperty[1] ? rangeProperty : [];
	if (prop[0] === rangeBounds.min_value && prop[1] === rangeBounds.max_value) {
		prop = [];
	}
	return prop.length ? prop : null;
};

const extractAndNullifyRange = (rangeProperty: null | [any, any]) => {
	if (rangeProperty.length) {
		if (rangeProperty[0] === null && rangeProperty[1] === null) {
			return null;
		}
		return rangeProperty;
	}
	return null;
};

@Injectable({providedIn: 'root'})
export class UtilsService {
	static EmailValidator = Validators.pattern(
		/^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
	);
	public emailValidator = UtilsService.EmailValidator;
	public tinyScreenQuery = '420px';
	public smallScreenQuery = '812.99px';
	public mediumScreenQuery = '1280.99px';
	public isSmallScreenWidth = this.breakpointObserver.observe(`(max-width: ${this.smallScreenQuery})`).pipe(
		map((value) => value.matches),
		distinctUntilChanged(),
	);
	public isSmallScreen = this.breakpointObserver
		.observe(`(max-width: ${this.smallScreenQuery}), (max-height: ${this.tinyScreenQuery}) and (orientation: landscape)`)
		.pipe(
			map((value) => value.matches),
			distinctUntilChanged(),
			shareReplay(1),
		);
	public isMediumScreen = this.breakpointObserver.observe('(min-width: 813px) and (max-width: 1280.99px)').pipe(
		map((value) => value.matches),
		distinctUntilChanged(),
	);
	public orientation = this.breakpointObserver.observe('(orientation: landscape)').pipe(
		map((value) => (value.matches ? Orientation.LANDSCAPE : Orientation.PORTRAIT)),
		distinctUntilChanged(),
	);

	tzones = this.IANAToOptions(timeZonesNames);

	constructor(private http: HttpClient, private cache: CacheService, public breakpointObserver: BreakpointObserver) {}

	public mapFilters(filters, type: ItemType = ItemType.DONOR) {
		switch (type) {
			case ItemType.DONOR:
				return {
					age: extractRangeProperty(filters.age, defaults.age),
					height: extractRangeProperty(filters.height, defaults.height),
					weight: extractRangeProperty(filters.weight, defaults.weight),
					hair_color: filters.hair_color.size ? Array.from(filters.hair_color) : null,
					eye_color: filters.eye_color.size ? Array.from(filters.eye_color) : null,
					race: filters.race.size ? Array.from(filters.race) : null,
					religion: filters.religion.size ? Array.from(filters.religion) : null,
					previous_donor: filters.previous_donor || null,
					donor_type: filters.donor_type === 'all' ? null : filters.donor_type || null,
					donation_types: filters.donation_types.size ? Array.from(filters.donation_types) : null,
					prev_fertility: filters.prev_fertility || null,
					video_available: filters.video_available || null,
					country: filters.country || null,
					education_level: filters.education_level || null,
					state: filters.state || null,
					high_school_gpa: filters.high_school.length ? filters.high_school : null,
					act: filters.act.length ? filters.act : null,
					sat: filters.sat.length ? filters.sat : null,
					college_gpa: filters.college.length ? filters.college : null,
					blood_type: filters.blood.length ? filters.blood : null,
					ethnicity: filters.ethnicity || null,
					agency: filters.agency || null,
					units: filters.units || null,
				};

			case ItemType.SURROGACY:
				return {
					// search_by
					search_by: filters.search_by || null,
					location: filters.location || null,
					actualLocation: filters.actualLocation || null,
					// checkbox
					international_intended_parents: filters.international_intended_parents || null,
					full_services_agency: filters.full_services_agency || null,
					// other sorting
					carrier_screening: filters.carrier_screening?.size ? Array.from(filters.carrier_screening) : null,
					available_surrogacy_services: filters.available_surrogacy_services?.size ? Array.from(filters.available_surrogacy_services) : null,
				};
			case ItemType.SURROGATE:
				return {
					age: extractRangeProperty(filters.age, { min_value: 19, max_value: 40 }),
					bmi: extractAndNullifyRange(filters.bmi),
					compensation: extractAndNullifyRange(filters.compensation),
					total_cost: extractAndNullifyRange(filters.total_cost),
					relationship_status: filters.relationship_status.size ? Array.from(filters.relationship_status) : null,
					experienced_surrogate: filters.experienced_surrogate || null,
					abortions_allowed: filters.abortions_allowed || null,
					twins_allowed: filters.twins_allowed || null,
					max_c_sections: typeof filters.max_c_sections === 'number' ? filters.max_c_sections : null,
					max_miscarriages: typeof filters.max_miscarriages === 'number' ? filters.max_miscarriages : null,
					max_live_births: typeof filters.max_live_births === 'number' ? filters.max_live_births : null,
					covid_vaccinated: filters.covid_vaccinated || null,
					same_sex_couple_allowed: filters.same_sex_couple_allowed || null,
					video_available: filters.video_available || null,
					country: filters.country || null,
					state: filters.state || null,
					agency: filters.agency || null,
				};
		}
	}

	public parseCost(number: string | number | null) {
		if (typeof number !== 'number') {
			return number;
		}
		return '$' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	public toHumanReadable(s: any) {
		if (typeof s === 'boolean') {
			return s ? 'Yes' : 'No';
		} else if (typeof s === 'number') {
			return s;
		} else {
			return s || 'No';
		}
	}

	public toHumanReadableOrUnknown(s: any) {
		if (typeof s === 'undefined' || s === null) {
			return '-';
		} else if (typeof s === 'boolean') {
			return this.castBooleanOrUnknown(s);
		} else if (typeof s === 'number') {
			return this.castNumberOrUnknown(s);
		} else if (typeof s === 'string') {
			return s || '-';
		} else {
			return s;
		}
	}

	public castBooleanOrUnknown(s: boolean | undefined | null) {
		if (typeof s === 'boolean'){
			return s ? 'Yes' : 'No';
		}
		return '-';
	}

	public castNumberOrUnknown(s: number | undefined | null) {
		if (typeof s === 'number'){
			return s;
		}
		return '-';
	}

	public toCapitalizedCase(s: string) {
		if (s && typeof s === 'string') {
			return s[0].toUpperCase() + s.slice(1);
		} else {
			return s;
		}
	}

	public useHeight(cm: number, units: 'imperial' | 'metric'): string {
		if (!cm || typeof cm !== 'number') return cm as unknown as string;
		if (units === 'imperial') {
			const inches = Math.round(cm / 2.54);
			return `${Math.floor(inches / 12)}′${Math.round(inches % 12)}″` as any;
		} else return cm / 100 + ' m';
	}

	public calculateWeight(kg: number, units: 'imperial' | 'metric'): string {
		if (!kg) {
			return;
		}
		return new Weight(kg, 'metric').asFormattedUnits(units);
	}

	public useWeight(g: number, units: 'imperial' | 'metric'): string {
		if (!g || typeof g !== 'number') return g as unknown as string;
		return units === 'imperial' ? Math.round((g / 1000) * 2.205) + ` lbs` : Math.round(g / 1000) + ' kg';
	}

	public deepCopy(source: any): any {
		const result = {};
		for (const item in source) {
			if (typeof source[item] === 'object' && source[item] !== null) {
				if (Array.isArray(source[item])) {
					result[item] = source[item].slice();
				} else if (source[item] instanceof Set) {
					result[item] = new Set(source[item]);
				} else {
					result[item] = this.deepCopy(source[item]);
				}
			} else {
				result[item] = source[item];
			}
		}
		return result;
	}

	public deepEquals(a, b) {
		if (Object.values(a).length !== Object.values(b).length) {
			return false;
		}
		for (const key in a) {
			if (a[key] && b[key] && typeof a[key] === 'object') {
				if (Array.isArray(a[key])) {
					if (a[key].length === b[key].length) {
						for (let i = 0; i < a[key].length; i++) {
							const r = a[key][i] === b[key][i];
							if (!r) {
								return false;
							}
						}
					} else {
						return false;
					}
				} else if (a[key] instanceof Set && b[key] instanceof Set) {
					if (a[key].size === b[key].size) {
						for (const item of a[key]) {
							const r = b[key].has(item);
							if (!r) {
								return false;
							}
						}
					} else {
						return false;
					}
				} else {
					const r = this.deepEquals(a[key], b[key]);
					if (!r) {
						return false;
					}
				}
			} else {
				const r = a[key] === b[key];
				if (!r) {
					return false;
				}
			}
		}
		return true;
	}

	public getCountry(name: string) {
		return from(this.cache.get<{alpha2Code: string; flag: string}>(name)).pipe(
			switchMap((data) =>
				data
					? of(data)
					: this.http.get(`https://restcountries.com/v3/name/${name.toLowerCase()}`).pipe(
							map((res) =>
								res && res[0]
									? {
											alpha2Code: res[0].cca2,
											flag: `assets/flags/${res[0].cca2.toLowerCase()}.png`,
									  }
									: {
											alpha2Code: '',
											flag: '',
									  },
							),
							tap((res) => this.cache.upsert(name, res)),
					  ),
			),
		);
	}

	public getOptions() {
		return this.http.get<Record<string, any>>('/api/v2/options').pipe(tap((res) => this.cache.upsert('options', parseConfig(res))));
	}

	public omit(obj: Record<string, any>, keys: string[]) {
		const result = {...obj};
		for (const key of keys) {
			delete result[key];
		}
		return result;
	}

	public IANAToOptions(tzs: string[]) {
		const resultMap = {};
		for (const item of tzs) {
			const items = item.split('/');
			const label = items[0].replace(/_/g, ' ');
			const subLabel = (items.length === 1 ? items[0] : items.slice(1).join('/')).replace(/_/g, ' ');
			const offset = DateTime.local().setZone(item).offsetNameShort;
			if (resultMap[label]) resultMap[label].push({value: item, label: `${subLabel} (${offset})`});
			else resultMap[label] = [{value: item, label: `${subLabel} (${offset})`}];
		}
		const result = [];
		for (const key in resultMap) {
			result.push(resultMap[key].length === 1 ? {label: key, value: resultMap[key][0].value} : {label: key, values: resultMap[key]});
		}
		return result;
	}

	arrayToObj<T extends Record<string, any>>(arr: T[], key: keyof T, value?: keyof T) {
		const result = {} as Record<keyof [T], any>;
		for (const item of arr) {
			result[item[key]] = value ? item[value] : item;
		}
		return result;
	}

	mapFilterForm(form: Record<string, any>) {
		const remove = [
			'first_name',
			'last_name',
			'birthday',
			'email',
			'phone',
			'identify',
			'relation',
			'password',
			'address',
			'apartment',
			'country',
			'state',
			'city',
			'zip',
			'partner_first_name',
			'partner_last_name',
		];
		const pre_map = (key: string) =>
			({
				eye: 'eye_color',
				hair: 'hair_color',
				education: 'education_level',
			}[key] || key);
		remove.forEach((item) => item in form && delete form[item]);
		const filter = {};
		for (const key of Object.keys(form)) {
			if (key === 'height' || key === 'age') {
				filter[key] = form[key].value.value.length ? form[key].value.value : null;
				if (key === 'height') filter['units'] = form[key].value.feet ? 'imperial' : 'metric';
			} else {
				if (Array.isArray(form[key].value)) {
					filter[pre_map(key)] = form[key].value.length ? form[key].value : null;
				} else {
					filter[pre_map(key)] = form[key].value || null;
				}
			}
		}
		return filter;
	}

	sortAphabetically(a: string, b: string) {
		const minLength = Math.min(a.length, b.length);
		a = a.toLowerCase();
		b = b.toLowerCase();
		if (a === b) {
			return 0;
		}

		for (let i = 0; i < minLength; i++) {
			if (a[i] === b[i]) {
				continue;
			}

			return a.charCodeAt(i) - b.charCodeAt(i);
		}
	}

	durationToString(duration: number) {
		const hours = Math.floor(duration / 60);
		const minutes = duration % 60;
		return (hours ? `${hours} hour${hours > 1 ? 's' : ''}` : '') + (minutes ? ` ${minutes} minute${minutes > 1 ? 's' : ''}` : '');
	}

    joinToString(...args: string[]) {
        return compact(args).join(', ');
    }
}

const parseConfig = (res: Record<string, any>) => {
	const result = {};
	for (const key in res) {
		result[key] = res[key];
		if (typeof res[key] === 'object' && !Array.isArray(res[key])) {
			result[key + '__array'] = [];
			for (const subkey in res[key]) result[key + '__array'].push({label: res[key][subkey], value: subkey});
		}
	}
	return result;
};
