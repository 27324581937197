import {
	AfterViewInit,
	Component,
	ElementRef,
	NgZone,
	OnDestroy,
	OnInit,
	QueryList,
	Renderer2,
	TemplateRef,
	ViewChild,
	ViewChildren,
	ViewContainerRef
} from "@angular/core";
import {CurrencyPipe} from "@angular/common";
import {Observable, Subscription} from "rxjs";
import {DonorsStoreService} from "@/services/donors.service";
import {ISurrogate, SurrogatesStoreService} from "@/services/surrogates.service";
import {ProviderType, surrogacyServicesSelectors, surrogacyServicesSelectorsEdit} from "@/config";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {ActivatedRoute, Router} from "@angular/router";
import {CompaniesStoreService} from "@/services/company.service";
import {ParentStoreService} from "@/services/parent.service";
import {Cost, ProvidersStoreService} from "@/services/providers.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BreakpointObserver} from "@angular/cdk/layout";
import {AnalyticsService} from "@/services/analytics.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthStoreService} from "@/services/auth.service";
import {UtilsService} from "@/services/utils.service";
import {ChatService} from "@/services/chat.service";
import {ContactAgencyService} from "@/services/contact-agency.service";
import {first} from "rxjs/operators";
import {TemplatePortal} from "@angular/cdk/portal";
import {cloneDeep, uniq} from "lodash";

@Component({
	selector: 'app-surrogates-compare',
	templateUrl: './compare.component.html',
	styleUrls: ['./compare.component.scss'],
	providers: [CurrencyPipe],
})
export class SurrogatesCompareComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChildren('row')
	rows: QueryList<ElementRef<HTMLElement>>;
	@ViewChild('previous_donor_section')
	previous_donor_section: ElementRef<HTMLElement>;
	@ViewChild('expl')
	commentTemplate: TemplateRef<null>;

	rows$: Subscription;

	comparables: Array<Partial<ISurrogate> & { [key: string]: any }> = [];
	interested$: Observable<boolean>[];
	ids$: Subscription;
	favourites: { [key: number]: Observable<boolean> } = {};
	hasChat$: Observable<string>[] = [];
	suggestedComparables: Array<Partial<ISurrogate> & { [key: string]: any }>;
	type: ProviderType = ProviderType.SURROGATES_AGENCY;

	readonly ProviderType = ProviderType;

	small = false;
	isSmallScreen: boolean;
	selectedCommentToggle = '';
	selectedComment = '';
	display = 'none';
	donorLength: number;
	checked: Observable<boolean>;
	right: number;

	readonly sections = {
		[ProviderType.SURROGATES_AGENCY]: {
			internal: [
				{s: 'main', n: 'General'},
			],
			selectors: {
				main: [
					{s: 'age', n: 'Age', m: this.utils.toHumanReadable},
					{s: 'bmi', n: 'BMI', m: this.utils.toHumanReadable},
					{s: 'parsedLocation', n: 'Location'},
					{s: 'agreesToAbortion', n: 'Agrees to abortion/selective reduction', m: this.utils.toHumanReadable},
					{s: 'agreesToTwins', n: 'Agrees to twins', m: this.parseBoolean},
					{s: 'covidVaccinated', n: 'COVID Vaccinated', m: this.parseBoolean},
					{s: 'liveBirthsCount', n: 'Live births', m: this.utils.toHumanReadable},
					{s: 'miscarriagesCount', n: 'Miscarriages', m: this.utils.toHumanReadable},
					{s: 'cSectionsCount', n: 'C-Sections', m: this.utils.toHumanReadable},
					{s: 'relationshipStatus', n: 'Relationship status', m: this.utils.toCapitalizedCase },
					{s: 'sameSexCouple', n: 'Open to same-sex couple', m: this.parseBoolean},
				]
			},
			costs: {
				...surrogacyServicesSelectorsEdit,
				carrier: surrogacyServicesSelectors.carrier
			}
		},
	};

	expanded = new Set<string>(['services']);

	overlayRef: OverlayRef;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private donorsStore: DonorsStoreService,
		private surrogatesStore: SurrogatesStoreService,
		private companyStore: CompaniesStoreService,
		private parentStore: ParentStoreService,
		private providerStore: ProvidersStoreService,
		private dialog: MatDialog,
		private snack: MatSnackBar,
		private renderer: Renderer2,
		private zone: NgZone,
		private breakpointObserver: BreakpointObserver,
		private overlay: Overlay,
		private _viewContainerRef: ViewContainerRef,
		private analytics: AnalyticsService,
		private domSanitizer: DomSanitizer,
		private authService: AuthStoreService,
		public utils: UtilsService,
		private chatService: ChatService,
		private contactAgencyService: ContactAgencyService,
		private currencyPipe: CurrencyPipe,
	) {
	}

	parseBoolean(value: boolean) {
		return value ? 'Yes' : 'No';
	}

	parseObject(value: Record<string, string>) {
		let result = '';
		for (const item in value) {
			result += `<b style="display: block; margin-top: 9px; font-weight: 600;">${item}</b>`;
			result += '<span>' + value[item].split(', ').join('</span><span>') + '</span>';
		}
		return this.domSanitizer.bypassSecurityTrustHtml(result || 'No medical concerns');
	}

	parseIncudance(desired: string) {
		return (value: string[]) => (value?.includes(desired) ? 'Yes' : 'No');
	}

	onChange(event: string, id: number, _event?: MouseEvent, index?: number) {
		if (event === 'stared') {
			this.parentStore.toggleInFavourites(id, this.type);
			this.analytics.emit('surrogates', 'favourite-compare', id.toString());
		} else if (event === 'share') {
			if (_event && _event.ctrlKey) {
				const surrogate = this.comparables.find((item) => item.id === id);
				this.snack
					.open(surrogate.originUrl, 'GO', {duration: 3278, horizontalPosition: 'left'})
					.onAction()
					.subscribe(() => window.open(surrogate.originUrl, '_blank'));
			} else {
				const input = document.createElement('input');
				input.value = `${location.host}/surrogates/${id}`;
				document.body.appendChild(input);
				input.select();
				document.execCommand('copy');
				document.body.removeChild(input);
				this.snack.open('The link to the profile has been copied to the clipboard.', 'Close', {
					duration: 3278,
					horizontalPosition: 'left',
				});
				this.analytics.emit('surrogates', 'share-compare', id.toString());
			}
		} else if (event === 'remove') {
			this.parentStore.removeFromCompare(id, this.type);
		} else if (event === 'checked') {
			this.parentStore.toggleInCompare(id, this.type);
		} else {
			this.hasChat$[index].pipe(first()).subscribe((chatId) =>
				chatId
					? this.router.navigate(['/', 'chat', chatId])
					: this.contactAgencyService.openDialog(ProviderType.SURROGATES_AGENCY, {
						surrogateId: this.comparables[index].id,
						companyId: this.comparables[index]['Agency.provider_mapping.provider_id'],
						agencyName: this.comparables[index]['Agency.provider_mapping.provider.company_name'],
					}),
			);
			this.analytics.emit('surrogates', 'create-deal-compare', id.toString());
		}
		this.interestingPanelView();
	}

	onScroll = () => {
		const el = document.documentElement;
		if (el.scrollTop > 100 && !this.small) {
			this.zone.run(() => (this.small = true));
			el.scrollTop = 140;
			try {
				const scroll = document.querySelector('.table-scroll').scrollLeft;
				document.querySelector<HTMLElement>('.row.head.small').style.transform = this.isSmallScreen
					? `translateX(${-scroll}px)`
					: `translateX(${300 - scroll}px)`;
				window.removeEventListener('scroll', this.onScroll);
			} catch (e) {
			}
			setTimeout(() => {
				this.onScroll();
				window.addEventListener('scroll', this.onScroll);
			}, 1000);
		} else if (el.scrollTop < 30 && this.small) {
			this.zone.run(() => (this.small = false));
			el.scrollTop = 0;
			try {
				document.querySelector<HTMLElement>('.row.head').style.transform = `translateX(0)`;
				window.removeEventListener('scroll', this.onScroll);
			} catch (e) {
			}
			setTimeout(() => {
				this.onScroll();
				window.addEventListener('scroll', this.onScroll);
			}, 1000);
		}
	};

	onHorizontalScroll = () => {
		const small = document.querySelector('.row.head.small') as HTMLElement;
		const scrollElement = document.querySelector('.table-scroll');
		const scroll = scrollElement.scrollLeft;
		if (scroll > scrollElement.scrollWidth - window.innerWidth + 30) return;
		if (small) small.style.transform = this.isSmallScreen ? `translateX(${-scroll}px)` : `translateX(${300 - scroll}px)`;
		if (this.isSmallScreen) {
			const preCols = document.querySelectorAll('.pre-col') as NodeListOf<HTMLElement>;
			let length = preCols.length;
			// while for tiny perfomance speadup
			while (length) {
				preCols[(length -= 1)].style.transform = `translateX(${scroll}px)`;
			}
		}
	};

	onComment(field: Partial<Cost>, event: MouseEvent) {
		this.selectedComment = field.message;
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(event.target as HTMLElement)
			.withViewportMargin(10)
			.withPositions([
				{
					overlayX: 'center',
					overlayY: 'top',
					originX: 'center',
					originY: 'bottom',
					offsetY: 16,
				},
				{
					overlayX: 'center',
					overlayY: 'bottom',
					originX: 'center',
					originY: 'top',
					offsetY: -16,
				},
			]);
		const scrollStrategy = this.overlay.scrollStrategies.reposition({
			autoClose: true,
		});
		this.overlayRef = this.overlay.create({positionStrategy, hasBackdrop: true, scrollStrategy});
		this.overlayRef.backdropClick().subscribe(() => this.onCommentClose());
		const portal = new TemplatePortal(this.commentTemplate, this._viewContainerRef);
		this.overlayRef.attach(portal);
	}

	onCommentClose() {
		this.overlayRef.dispose();
		this.selectedCommentToggle = '';
		this.selectedComment = '';
	}

	trackBy(index: number, item: { id: any }) {
		return item?.id;
	}

	isNumber(value: any) {
		return typeof value === 'number';
	}

	isNotEmpty(value: Record<string, unknown> | string) {
		if (typeof value === 'object' && value !== null) return Object.values(value).some((item) => item);
		else return Boolean(value);
	}

	calcHeight() {
		for (const row of this.rows) {
			for (let i = 0; i < row.nativeElement.children.length; i += 2)
				this.renderer.setStyle(row.nativeElement.children[i], 'height', row.nativeElement.children[i + 1].clientHeight + 'px');
		}
	}

	interestingPanelView() {
		const donorElementRef = document.querySelector('div.table-scroll > div > div.row.head > div');
		if (!donorElementRef) return setTimeout(() => this.interestingPanelView(), 10);
		const donorElement = donorElementRef.clientWidth;
		const interestingDonor = document.querySelector('div.suggested');
		if (window.innerWidth > 812.99) {
			this.right = (window.innerWidth - (donorElement + 1) * this.donorLength - 300 - 200) / 2;
			if (this.right > 50) this.right = 50;
			else if (this.right < 10) {
				this.right = 0;
			}
			this.display = (donorElement + 1) * this.donorLength + 512 > window.innerWidth ? 'none' : 'block';
			interestingDonor.setAttribute('style', 'display:' + this.display + '; right :' + this.right + 'px');
		} else {
			this.display = 'none';
			if (this.donorLength === 1) {
				this.display = 'block';
				interestingDonor.setAttribute('style', 'display:' + this.display + '; left : 50vw');
			}
		}
	}

	calculateTotals(surrogates: any) {
		for (const surrogate of surrogates) {
			if (surrogate.costsCalculated) {
				continue;
			}
			surrogate.costsCalculated = true;
			if (surrogate.costs.carrier_compensation) {
				surrogate.costs.carrier_compensation = {
					...surrogate.costs.carrier_compensation,
					value: surrogate.compensation || surrogate.costs.carrier_compensation.value
				};
			};
			for (const item of this.sections[this.type].costs.internal) {
				if (!this.sections[this.type].costs[item.s]) continue;
				const total = (surrogate.costs[item.s] = {value: 0});
				for (const subItem of this.sections[this.type].costs[item.s])
					if (surrogate.costs[subItem.s] && !surrogate.costs[subItem.s].included && surrogate.costs[subItem.s].value)
						total.value += surrogate.costs[subItem.s].value;
			}
			const insurance = ['insured_carrier', 'aca_insurance', 'art_insurance', 'ifi_insurance'];
			if (insurance.some((item) => surrogate.costs[item])) {
				const values = insurance.map((item) => surrogate.costs[item].value);
				const sectionValue = this.sections[this.type].costs.insurance
					.map((item) => item.s)
					.filter((item) => !insurance.includes(item))
					.reduce((sum, key) => sum + surrogate.costs[key]?.value || 0, 0);
				const minValue = Math.min(...values) + sectionValue;
				const maxValue = Math.max(...values) + sectionValue;
				const min = this.currencyPipe.transform(minValue, 'USD', 'symbol', '1.0-0');
				const max = this.currencyPipe.transform(maxValue, 'USD', 'symbol', '1.0-0');

				surrogate.costs.insurance = {
					name: "Carrier's Health Insurance",
					special_value: min === max ? min : `${min} - ${max}`,
					message: min === max ? surrogate.costs[insurance[0]]?.message : '',
				};
			}
		}
	}

	ngOnInit() {
		this.right = 0;
		if (this.authService.getType() === 'parent') this.parentStore.emitCompareEvent(this.type).subscribe();
		this.sections[this.type].internal.forEach((item) => this.expanded.add(item.s));
		this.sections[this.type].costs.internal.forEach((item) => this.expanded.add('services_' + item.s));
		this.ids$ = this.parentStore.getCompare(this.type).subscribe((ids) => {
			this.donorLength = ids.length;
			this.surrogatesStore.fetchSurrogateById(ids).subscribe((surrogates) => {
				this.hasChat$ = ids.map((item) => this.chatService.donorHasChat$(item));
				this.interested$ = ids.map((item) => this.parentStore.isInterestedIn(item));
				const providerIds = uniq(surrogates.map((item) => item['Agency.provider_mapping.provider_id']));
				providerIds.map(providerId => {
					this.companyStore
						.fetchCompanyById(providerId, 'long')
						.pipe(first())
						.subscribe(([result]) => {
							this.providerStore.getFullCosts(providerId).subscribe((res) => {
								const providerSurrogates = surrogates.filter(s => providerId === s['Agency.provider_mapping.provider_id']);

								delete res[ProviderType.SURROGACY_AGENCY]['carrier_compensation_nonprefered_exp'];
								delete res[ProviderType.SURROGACY_AGENCY]['carrier_compensation_nonprefered_first'];
								delete res[ProviderType.SURROGACY_AGENCY]['carrier_compensation_prefered_exp'];
								delete res[ProviderType.SURROGACY_AGENCY]['carrier_compensation_prefered_first'];
								providerSurrogates.forEach(ps => {
									ps.costs = cloneDeep(res[ProviderType.SURROGACY_AGENCY]);
								});
								this.calculateTotals(providerSurrogates);
								this.comparables = [...this.comparables, ...providerSurrogates];
							});
						});
				});
			});
			this.surrogatesStore
				.fetchSurrogates(0, 9, null)
				.pipe(first())
				.subscribe((data) => {
					if (!data) return;
					this.suggestedComparables = data.data.filter((item) => !ids.includes(item.id));
					data.data.forEach((item) => (this.favourites[item.id] = this.parentStore.isInFavourites(item.id, this.type)));
					setTimeout(() => this.interestingPanelView(), 200);
				});
		});

	}

	ngAfterViewInit() {
		this.zone.runOutsideAngular(() => window.addEventListener('scroll', this.onScroll));
		this.rows$ = this.rows.changes.subscribe(() => this.calcHeight());
		this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 812.99px)');
		document.querySelector('.table-scroll').addEventListener('scroll', this.onHorizontalScroll);
	}

	ngOnDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		document.querySelector('.table-scroll').removeEventListener('scroll', this.onHorizontalScroll);
		this.ids$.unsubscribe();
		this.rows$.unsubscribe();
	}
}