<div class="login-container {{ screen }}" style="background-image: url('assets/register/background.png')">

	<div class="content-container">
		<img class='login-image' *ngIf="screen === 'login'" src="assets/login/login-image.png"/>
		<div class="form-container">
			<app-insvg class="sparks" *ngIf="successfulReset" link="assets/sparks.svg" name="sparks"></app-insvg>
			<app-insvg *ngIf="successfulReset" link="assets/baby_happy.svg" name="baby_happy"></app-insvg>
			<h3 class="mat-display-1">
				{{
					screen === 'login'
						? 'Login'
						: screen === 'reset'
						? successfulReset
							? 'New password successfull created!'
							: 'Reset your password'
						: successfulReset
						? 'Check your email'
						: 'Reset your password'
				}}
			</h3>
			<p *ngIf="screen === 'forgot'" class="subtitle">
				<ng-container *ngIf="!successfulReset; else elseT">
					Don't worry, just enter the email address you registered with and we will send you a link to reset your password.
				</ng-container>
				<ng-template #elseT>
					An email has been sent to your email address, <b>{{ email.value }} </b>.<br />Follow the directions in the email to reset your
					password and then sign in again.
				</ng-template>
			</p>
			<p *ngIf="screen === 'reset'" class="subtitle">
				<ng-container *ngIf="!successfulReset; else elseT"> Please enter your new password. </ng-container>
				<ng-template #elseT> You can now log in with the new password. </ng-template>
			</p>
			<form (ngSubmit)="onSubmit()">
				<mat-form-field appearance="outline" *ngIf="!successfulReset && screen !== 'reset'">
					<mat-label>Email</mat-label>
					<input matInput [formControl]="email" type="email" autocomplete="username" (input)="password.setErrors(null)" />
					<mat-error *ngIf="email.invalid">{{ getError().email }}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" *ngIf="screen !== 'forgot' && !successfulReset">
					<mat-label>{{ screen === 'reset' ? 'New Password' : 'Password' }}</mat-label>
					<input
						matInput
						[formControl]="password"
						name="password"
						(input)="revalidate()"
						[type]="hidePass ? 'text' : 'password'"
						[autocomplete]="screen === 'reset' ? 'new-password' : 'current-password'"
					/>
					<mat-error *ngIf="password.invalid">{{ getError().password }}</mat-error>
					<button
						matSuffix
						mat-icon-button
						type="button"
						[attr.aria-label]="'Hide password'"
						[attr.aria-pressed]="hidePass"
						(click)="hidePass = !hidePass"
					>
						<mat-icon fontSet="material-icons-outlined">{{ hidePass ? 'visibility' : 'visibility_off' }}</mat-icon>
					</button>
				</mat-form-field>
				<mat-form-field appearance="outline" *ngIf="screen === 'reset' && !successfulReset">
					<mat-label>Confirm New Password</mat-label>
					<input matInput [formControl]="confirmPassword" name="password" [type]="hidePass ? 'text' : 'password'" autocomplete="new-password" />
					<mat-error *ngIf="confirmPassword.invalid">{{ getError().confirm }}</mat-error>
					<button
						matSuffix
						mat-icon-button
						type="button"
						[attr.aria-label]="'Hide password'"
						[attr.aria-pressed]="hidePass"
						(click)="hidePass = !hidePass"
					>
						<mat-icon fontSet="material-icons-outlined">{{ hidePass ? 'visibility' : 'visibility_off' }}</mat-icon>
					</button>
				</mat-form-field>
				<div *ngIf="screen === 'reset' && !successfulReset" class="pass_check">
					<svg style="height: 0; pointer-events: none; position: absolute">
						<linearGradient id="linear-gradient" x1="-0.085" y1="-0.059" x2="0.642" y2="0.782" gradientUnits="objectBoundingBox">
							<stop offset="0" stop-color="#9bcb6c" />
							<stop offset="1" stop-color="#c4aedd" />
						</linearGradient>
					</svg>
					<div>
						<app-insvg
							[ngStyle]="{display: !password.hasError('minlength') && password.value ? 'none' : 'block'}"
							name="check_inactive"
							link="assets/checkbox_inactive.svg"
						></app-insvg>
						<app-insvg
							[ngStyle]="{display: !password.hasError('minlength') && password.value ? 'block' : 'none'}"
							name="check_active"
							link="assets/checkbox_active.svg"
						></app-insvg>
						<span>At least 8 characters</span>
					</div>
					<div>
						<app-insvg
							[ngStyle]="{display: !password.hasError('capital') && password.value ? 'none' : 'block'}"
							name="check_inactive"
							link="assets/checkbox_inactive.svg"
						></app-insvg>
						<app-insvg
							[ngStyle]="{display: !password.hasError('capital') && password.value ? 'block' : 'none'}"
							name="check_active"
							link="assets/checkbox_active.svg"
						></app-insvg>
						<span>At least 1 capital letter</span>
					</div>
					<div>
						<app-insvg
							[ngStyle]="{display: !password.hasError('lower') && password.value ? 'none' : 'block'}"
							name="check_inactive"
							link="assets/checkbox_inactive.svg"
						></app-insvg>
						<app-insvg
							[ngStyle]="{display: !password.hasError('lower') && password.value ? 'block' : 'none'}"
							name="check_active"
							link="assets/checkbox_active.svg"
						></app-insvg>
						<span>At least 1 lowercase letter</span>
					</div>
					<div>
						<app-insvg
							[ngStyle]="{display: !password.hasError('number') && password.value ? 'none' : 'block'}"
							name="check_inactive"
							link="assets/checkbox_inactive.svg"
						></app-insvg>
						<app-insvg
							[ngStyle]="{display: !password.hasError('number') && password.value ? 'block' : 'none'}"
							name="check_active"
							link="assets/checkbox_active.svg"
						></app-insvg>
						<span>At least 1 number</span>
					</div>
				</div>

				<div *ngIf="screen === 'login'" class="captcha">
					This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and
					<a href="https://policies.google.com/terms">Terms of Service</a> apply.
				</div>

				<div class="submit" *ngIf="(screen === 'reset' && successfulReset) || !successfulReset">
					<button [ngClass]="{disabled: loading}" mat-raised-button color="primary" type="submit">
						{{ loading ? '&#8203;' : screen === 'forgot' ? 'Reset' : screen === 'reset' && successfulReset ? 'Login' : 'Enter' }}
					</button>
					<mat-spinner [ngStyle]="{display: loading ? 'block' : 'none'}" class="white" diameter="24"></mat-spinner>
				</div>
			</form>
			<div *ngIf="screen === 'login'" className="extra-actions">
				<div>Forgot your password? <a routerLink="forgot">Reset Now</a></div>
				<div>Don't have an account? <a routerLink="/register">Join Us</a></div>
			</div>
		</div>
	</div>
</div>
