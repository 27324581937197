import { Component, OnInit } from '@angular/core';
import {CompaniesStoreService} from "@/services/company.service";
import {AuthStoreService} from "@/services/auth.service";

@Component({ template: '' })
export class AttachCalendarComponent implements OnInit {

	constructor(private readonly authStore: AuthStoreService, private readonly companyService: CompaniesStoreService) {}

	async ngOnInit() {
		const url = new URL(window.location.href);
		let code = '';
		const hash = url.hash.slice(1).split('&');
		for (const item of hash) {
			if (item.startsWith('code')) {
				code = item.slice(5);
				break;
			}
		}

		const message = {
            type: code ? 'outlook' : 'google',
            code: code || url.searchParams.get('code'),
        };

		this.authStore.getUser().subscribe(async (user) => {
			const isProvider = !!user.company_type;

			// this was added due to a bug in certain cases to attach calendar
			if (isProvider) {
				if (window.opener) {
					window.opener.close();
				}
				await this.companyService.connectCalendar(message).toPromise();
				window.location.href = '/providers/settings/calendar'
			} else {
				if (window.opener) {
					window.opener.postMessage(message, window.origin);
					window.close();
				}
			}
		})
	}
}
