<ng-container *ngIf="ivf$ | async as ivf">
	<div class="nameSection">
		<div class="image">
			<img decoding="auto" [src]="ivf.img" />
		</div>

		<div class="name">{{ ivf.actualName[0] }}</div>
		<div class="subname">{{ ivf.actualName[1] || '' }}</div>

		<div *ngIf="nearestLocation$ | async as nearestLocation" class="distance">
			<div class="actualDistance">{{ nearestLocation.distance | number: (nearestLocation.distance < 1 ? '1.0-2' : '1.0-0') }} Mi</div>
			<div class="nearest">&nbsp;Nearest to {{ nearestLocation.text }}</div>

			<ng-container *ngTemplateOutlet="ratingContainer"></ng-container>
		</div>
	</div>

	<div class="mobileRating">
		<ng-container *ngTemplateOutlet="ratingContainer"></ng-container>
	</div>

	<ng-container *ngIf="ivf.mainNumberOfBabiesBorn !== null">
		<div class="title">The Clinic’s Results</div>
		<div class="subtitle">Based on services for Your Journey</div>

		<div class="resultsValue">
			<app-numeric-group
				[label]="(isEggFreezing$ | async) ? 'Egg Freezing Cycles' : 'Babies Born'"
				[value]="ivf.mainNumberOfBabiesBorn"
			></app-numeric-group>
			<ng-container *ngIf="ivf.mainSuccessRate !== null && (isEggFreezing$ | async) === false">
				<div>{{ (ivf.mainSuccessRate || 0) * 100 | number: '1.0-2' }}% Clinic Success Rate</div>
				<mat-progress-bar [value]="(ivf.mainSuccessRate || 0) * 100"></mat-progress-bar>
				<mat-progress-bar [value]="getNationalNumber('mainSuccessRate') | async"></mat-progress-bar>
				<div>{{ getNationalNumber('mainSuccessRate') | async | number: '1.0-2' }}% National Success Rate</div>
			</ng-container>
		</div>

		<div class="title">More Clinic Results</div>
		<div class="subtitle">The results shown below include other types of journeys at this fertility clinic.</div>

		<div class="successRates">
			<div class="weirdNumber">
				<app-numeric-group label="" [value]="$any(ivf.profile.numberOfBabiesBorn_total)"></app-numeric-group>

				<div class="label">Babies Born</div>
				<div class="sublabel">Across All Methods</div>
			</div>

			<div class="weirdNumber">
				<app-numeric-group label="" [value]="ivf.cycles_avg"></app-numeric-group>

				<div class="label">Total IVF Cycles</div>
			</div>
		</div>

		<div class="colourHint">
			<div>
				<span class="colourDot colourDotPurple"></span>
				<span>Clinic Data</span>
			</div>
			<div>
				<span class="colourDot colourDotGreen"></span>
				<span>National Data</span>
			</div>
		</div>

		<div class="successRates">
			<div>
				<div class="chartTitle">Live Birth Using Donated Eggs</div>
				<div class="chartContainer resultsValue">
					<div>{{ (ivf.successDonorEggs_avg || 0) * 100 | number: '1.0-2' }}% Clinic Success Rate</div>
					<mat-progress-bar [value]="(ivf.successDonorEggs_avg || 0) * 100"></mat-progress-bar>
					<mat-progress-bar [value]="getNationalNumber('successDonorEggs_avg') | async"></mat-progress-bar>
					<div>{{ getNationalNumber('successDonorEggs_avg') | async | number: '1.0-2' }}% National Success Rate</div>

					<div class="numericValue">
						<span>{{ ivf.numberOfBabiesBornUsingEggDonor_avg }}</span>
						<span>Babies Born</span>
					</div>
				</div>
			</div>

			<div>
				<div class="chartTitle">Live Birth Using Donated Embryos</div>
				<div class="chartContainer resultsValue">
					<div>{{ (ivf.successDonatedEmbryos_avg || 0) * 100 | number: '1.0-2' }}% Clinic Success Rate</div>
					<mat-progress-bar [value]="(ivf.successDonatedEmbryos_avg || 0) * 100"></mat-progress-bar>
					<mat-progress-bar [value]="getNationalNumber('successDonatedEmbryos_avg') | async"></mat-progress-bar>
					<div>{{ getNationalNumber('successDonatedEmbryos_avg') | async | number: '1.0-2' }}% National Success Rate</div>

					<div class="numericValue">
						<span>{{ ivf.numberOfBabiesBornUsingDonatedEmbryos_avg }}</span>
						<span>Babies Born</span>
					</div>
				</div>
			</div>

			<div>
				<div class="chartTitle">
					<div class="actualTitle">Live Births Using Your Own Eggs</div>

					<div class="numericValue">
						<span>{{ ivf.numberOfBabiesBorn_avg }}</span>
						<span>Babies Born</span>
					</div>
				</div>
				<div class="chartContainer">
					<app-success-rates-chart type="multiage" key="successOwnEggs" [withLabels]="true" [data]="ivf"></app-success-rates-chart>
				</div>
			</div>

			<div>
				<div class="chartTitle">
					<div class="actualTitle">Live Birth Using a Gestational Carrier And Your Own Eggs</div>

					<div class="numericValue">
						<span>{{ ivf.surrogacyOwnEggsBabies_avg }}</span>
						<span>Babies Born</span>
					</div>
				</div>
				<div class="chartContainer">
					<app-success-rates-chart type="multiage" key="successSurrogacyOwnEggs" [withLabels]="true" [data]="ivf"></app-success-rates-chart>
				</div>
			</div>

			<div>
				<div class="chartTitle">Live Birth Using a Gestational Carrier And Donated Eggs</div>
				<div class="chartContainer resultsValue">
					<div>{{ (ivf.successSurrogacyDonatedEggs_avg || 0) * 100 | number: '1.0-2' }}% Clinic Success Rate</div>
					<mat-progress-bar [value]="(ivf.successSurrogacyDonatedEggs_avg || 0) * 100"></mat-progress-bar>
					<mat-progress-bar [value]="getNationalNumber('successSurrogacyDonatedEggs_avg') | async"></mat-progress-bar>
					<div>{{ getNationalNumber('successSurrogacyDonatedEggs_avg') | async | number: '1.0-2' }}% National Success Rate</div>

					<div class="numericValue">
						<span>{{ ivf.surrogacyDonatedEggs_avg }}</span>
						<span>Babies Born</span>
					</div>
				</div>
			</div>

			<div>
				<div class="chartTitle">Live Birth Using a Gestational Carrier And Donated Embryos</div>
				<div class="chartContainer resultsValue">
					<div>{{ (ivf.successSurrogacyDonatedEmbryos_avg || 0) * 100 | number: '1.0-2' }}% Clinic Success Rate</div>
					<mat-progress-bar [value]="(ivf.successSurrogacyDonatedEmbryos_avg || 0) * 100"></mat-progress-bar>
					<mat-progress-bar [value]="getNationalNumber('successSurrogacyDonatedEmbryos_avg') | async"></mat-progress-bar>
					<div>{{ getNationalNumber('successSurrogacyDonatedEmbryos_avg') | async | number: '1.0-2' }}% National Success Rate</div>

					<div class="numericValue">
						<span>{{ ivf.surrogacyDonatedEmryos_avg }}</span>
						<span>Babies Born</span>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="ivf.video || ivf.about">
		<div class="title">About The Clinic</div>
		<div class="subtitle"></div>

		<app-video [video]="ivf.video"></app-video>
		<p class="pre-wrap">{{ ivf.about }}</p>
	</ng-container>

	<ng-container *ngIf="ivf.whatMakesUsUnique">
		<div class="title">What Makes Us Unique</div>
		<div class="subtitle"></div>

		<p class="pre-wrap">{{ ivf.whatMakesUsUnique }}</p>
	</ng-container>

	<div *ngIf="ivf.provider?.providers_costs?.length" class="costs">
		<div class="title">Your Estimated Costs</div>
		<div class="subtitle">Based on services for Your Journey</div>

		<div class="programs">
			<app-ivf-program
				*ngFor="let program of ivf.provider.providers_costs | slice:0:3; let index = index"
				[program]="program"
				[nth]="index + 1"
			></app-ivf-program>
		</div>

		<div class="hint">Clinic’s fees and associated costs are estimates and may vary in some cases.</div>
	</div>

	<div class="title">The Clinic Team</div>

	<div class="team">
		<mat-selection-list #list [multiple]="false" [disableRipple]="true">
			<ng-container *ngFor="let member of ivf.team">
				<mat-list-option [value]="member" (click)="updateSelectedTeam(member)">
					<img mat-list-icon class="teamIcon" [src]="member.img" />

					<div mat-line class="teamName">{{ member.name }}</div>
					<div mat-line class="teamPosition">{{ member.position }}</div>

					<div class="chevron">
						<mat-icon>{{ member === selectedTeam ? 'expand_more' : 'chevron_right' }}</mat-icon>
					</div>
				</mat-list-option>

				<ng-container *ngIf="member === selectedTeam" [ngTemplateOutlet]="teamDetails"> </ng-container>
			</ng-container>
		</mat-selection-list>
	</div>

	<div class="title">Clinic Locations {{ (locations$ | async)?.length | i18nPlural: {'=0': '', '=1': '', other: '(# Locations)'} }}</div>
	<div class="subtitle"></div>

	<div class="locations" style.grid-template-rows="repeat({{ locationRows$ | async }}, 1fr)">
		<app-location-entry
			*ngFor="let location of locations$ | async; let index = index"
			[location]="location"
			[index]="index + 1"
		></app-location-entry>
	</div>

	<ng-container *ngIf="listOfWeirdThings$ | async as listOfWeirdThings">
		<ng-container *ngIf="listOfWeirdThings.length">
			<div class="title">Accreditations & Memberships</div>
			<div class="subtitle"></div>

			<div class="weirdThings">
				<ul>
					<li *ngFor="let item of listOfWeirdThings">{{ item }}</li>
				</ul>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="ivf.research">
		<div class="title">Clinic Research & Publications</div>
		<div class="subtitle"></div>

		<div class="research pre-wrap">{{ ivf.research }}</div>
	</ng-container>

	<div class="title titleReviews">
		<span>Reviews & Ratings</span>
		<a routerLink="./">Write A Review</a>
	</div>
	<div class="subtitle"></div>

	<div class="reviews">
		<div>
			<div class="review grid">
				<div class="reviewTitle">How satisfied were you with your fertility clinic?</div>
				<div class="stars">
					<app-rating></app-rating>
				</div>
				<div class="starsValue">0.0 (0)</div>
			</div>

			<div class="review grid">
				<div class="reviewTitle">Did you feel like a human or a number?</div>
				<div class="numberHumanValue">
					<div>0% (0)</div>
					|
					<div>0% (0)</div>
				</div>
				<div class="numberHuman">
					<div>Human</div>
					|
					<div>Number</div>
				</div>
			</div>

			<div class="review">
				<div class="reviewTitle">Would you recommend this IVF Clinic to others?</div>
				<div>
					<div class="reviewProgress">
						<span class="progressLabel">Yes</span>
						<mat-progress-bar [value]="0"></mat-progress-bar>
						<div class="progressLabel">0 (0)</div>
					</div>

					<div class="reviewProgress">
						<span class="progressLabel">No</span>
						<mat-progress-bar [value]="0"></mat-progress-bar>
						<div class="progressLabel">0 (0)</div>
					</div>
				</div>
			</div>
		</div>

		<div>
			<div class="review grid">
				<div class="reviewTitle">How was the overall quality of the nursing team?</div>
				<div class="stars">
					<app-rating></app-rating>
				</div>
				<div class="starsValue">0.0 (0)</div>
			</div>

			<div class="review">
				<div class="reviewTitle">How many cycles/attempts did you go through until you were successful?</div>
				<div>
					<div class="reviewProgress">
						<span class="progressLabel">0 To 3</span>
						<mat-progress-bar [value]="0"></mat-progress-bar>
						<div class="progressLabel">0 (0)</div>
					</div>

					<div class="reviewProgress">
						<span class="progressLabel">4 To 6</span>
						<mat-progress-bar [value]="0"></mat-progress-bar>
						<div class="progressLabel">0 (0)</div>
					</div>

					<div class="reviewProgress">
						<span class="progressLabel">7 To 9</span>
						<mat-progress-bar [value]="0"></mat-progress-bar>
						<div class="progressLabel">0 (0)</div>
					</div>

					<div class="reviewProgress">
						<span class="progressLabel">9+</span>
						<mat-progress-bar [value]="0"></mat-progress-bar>
						<div class="progressLabel">0 (0)</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #noCost>
	<div class="na">N/A</div>
</ng-template>

<ng-template #teamDetails>
	<div *ngIf="selectedTeam.about" class="section">
		<div class="label">About</div>
		<p class="content pre-wrap">{{ selectedTeam.about }}</p>
	</div>

	<div *ngIf="selectedTeam.languages?.length" class="section">
		<div class="label">Languages</div>
		<div class="content">
			<ul>
				<li *ngFor="let item of selectedTeam.languages">{{ item }}</li>
			</ul>
		</div>
	</div>

	<div *ngIf="selectedTeam.certifications?.length" class="section">
		<div class="label">Board Certifications</div>
		<div class="content">
			<ul>
				<li *ngFor="let item of selectedTeam.certifications">{{ item }}</li>
			</ul>
		</div>
	</div>

	<div *ngIf="selectedTeam.education?.length" class="section">
		<div class="label">Education</div>
		<div class="content">
			<ul>
				<li *ngFor="let item of selectedTeam.education">{{ item }}</li>
			</ul>
		</div>
	</div>

	<div *ngIf="selectedTeam.memberships?.length" class="section">
		<div class="label">Professional Memberships</div>
		<div class="content">
			<ul>
				<li *ngFor="let item of selectedTeam.memberships">{{ item }}</li>
			</ul>
		</div>
	</div>

	<div *ngIf="selectedTeam.research" class="section">
		<div class="label">Research</div>
		<p class="content pre-wrap">{{ selectedTeam.research }}</p>
	</div>

	<div *ngIf="selectedTeam.publications?.length" class="section">
		<div class="label">Awards & Publications</div>
		<div class="content">
			<ul>
				<li *ngFor="let item of selectedTeam.publications">{{ item }}</li>
			</ul>
		</div>
	</div>
</ng-template>

<ng-template #ratingContainer>
	<div *ngIf="ivf$ | async as ivf" class="ratingContainer">
		<div class="rating">
			<img src="assets/yellow-star.png" />
			<span> {{ ivf.rating }} ({{ ivf.numberOfReviews | i18nPlural: {'=0': 'No Reviews', '=1': 'One Review', other: '# Reviews'} }}) </span>
		</div>
		<div class="additions">
			<img
				src="assets/international_parents.png"
				matTooltip="We are {{ ivf.internationalIntendedParents ? '' : 'not ' }}accepting International Parents"
				[class.disabled]="!ivf.internationalIntendedParents"
			/>
			<img
				src="assets/bipoc.svg"
				matTooltip="We {{ (ivf.services | includes: 'have_bipoc_physicians') ? 'have' : 'do not have' }} BIPOC physicians"
				[class.disabled]="!(ivf.services | includes: 'have_bipoc_physicians')"
			/>
			<img
				src="assets/LGBT_friendly.svg"
				matTooltip="We {{ ivf.lgbtFriendly ? 'provide' : 'do not provide' }} LGBTQ+-specific services"
				[class.disabled]="!ivf.lgbtFriendly"
			/>
			<img
				src="assets/hiv.svg"
				matTooltip="We {{ (ivf.services | includes: 'hiv_patients') ? 'have' : 'do not have' }} HIV patients"
				[class.disabled]="!(ivf.services | includes: 'hiv_patients')"
			/>
		</div>
	</div>
</ng-template>
