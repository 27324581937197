import {BreakpointObserver} from '@angular/cdk/layout';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {CurrencyPipe} from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	NgZone,
	OnDestroy,
	OnInit,
	QueryList,
	Renderer2,
	TemplateRef,
	ViewChild,
	ViewChildren,
	ViewContainerRef,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {first, last} from 'rxjs/operators';

import {eggAgencyServicesSelectors, ProviderType, surrogacyServicesSelectorsEdit} from '@/config';
import {AnalyticsService} from '@/services/analytics.service';
import {AuthStoreService} from '@/services/auth.service';
import {ChatService} from '@/services/chat.service';
import {CompaniesStoreService} from '@/services/company.service';
import {ContactAgencyService} from '@/services/contact-agency.service';
import {DonorsStoreService, IDonor} from '@/services/donors.service';
import {ParentStoreService} from '@/services/parent.service';
import {Cost, ProvidersStoreService} from '@/services/providers.service';
import {UtilsService} from '@/services/utils.service';

@Component({
	selector: 'app-compare',
	templateUrl: './compare.component.html',
	styleUrls: ['./compare.component.scss'],
	providers: [CurrencyPipe],
})
export class CompareComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChildren('row')
	rows: QueryList<ElementRef<HTMLElement>>;
	@ViewChild('previous_donor_section')
	previous_donor_section: ElementRef<HTMLElement>;
	@ViewChild('expl')
	commentTemplate: TemplateRef<null>;

	rows$: Subscription;

	donors: Array<Partial<IDonor> & {[key: string]: any}>;
	interested$: Observable<boolean>[];
	ids$: Subscription;
	favourites: {[key: number]: Observable<boolean>} = {};
	hasChat$: Observable<string>[] = [];
	donorsList: any;
	type: ProviderType;

	readonly ProviderType = ProviderType;

	small = false;
	isSmallScreen: boolean;
	selectedCommentToggle = '';
	selectedComment = '';
	display = 'none';
	donorLength: number;
	checked: Observable<boolean>;
	right: number;

	readonly sections = {
		[ProviderType.EGG_AGENCY]: {
			internal: [
				{s: 'main', n: 'Info'},
			],
			selectors: {
				main: [
					{s: 'age', n: 'Age', m: (value) => value + ' Years Old'},
					{s: 'height', n: 'Height', m: (value) => this.utils.useHeight(value, this.parentStore.getUnits())},
					{s: 'weight', n: 'Weight', m: (value) => this.utils.useWeight(value, this.parentStore.getUnits())},
					{s: 'hair_color', n: 'Hair Color'},
					{s: 'eye_color', n: 'Eye Color'},
					{s: 'race', n: 'Race', m: (value) => (value || []).map(this.utils.toCapitalizedCase).join(', ')},
					{s: 'formattedLocation', n: 'Location'},
					{s: 'ethnicity', n: 'Ethnicity', m: (value) => (value || []).map(this.utils.toCapitalizedCase).join(', ')},
					{s: 'religion', n: 'Religion'},
					{s: 'occupation', n: 'Occupation'},
					{s: 'education_level', n: 'Education Level'},
					{s: 'formattedDonationTypes', n: 'Donation types', m: (value) => (value || []).join(', ')},
				],
			},
			costs: eggAgencyServicesSelectors,
		},
		[ProviderType.SURROGACY_AGENCY]: {
			internal: [
				{s: 'main', n: 'General'},
				{s: 'location', n: 'Location'},
				{s: 'surrogacy_services', n: 'Available Surrrogacy Services'},
				{s: 'carrier_screening', n: 'Carrier Screening Process'},
			],
			selectors: {
				main: [
					{s: 'year_founded', n: 'Year Founded'},
					{s: 'international_intended_parents', n: 'Work with International Intended Parents'},
					{s: 'lgbt_friendly', n: 'LGBTQ+ Friendly'},
					{s: 'matching_timeframe', n: 'Surrogate Matching Time (avg.)', m: (value) => value + ' Months'},
					{s: 'length_of_journey', n: 'Length of Journey (avg.)', m: (value) => value + ' Months'},
					{s: 'number_of_babies_born', n: 'Number of Babies Born'},
					{s: 'family_load', n: 'Number of Families per Coordinator'},
				],
				location: [
					{s: 'provider', n: 'Country', m: (value) => value.location[0]?.country},
					{s: 'provider', n: 'State', m: (value) => value.location[0]?.state},
					{s: 'provider', n: 'City', m: (value) => value.location[0]?.city},
				],
				surrogacy_services: [
					{s: 'available_surrogacy_services', n: 'Case Management', m: this.parseIncudance('case_management_and_education')},
					{s: 'available_surrogacy_services', n: 'Counseling & Support', m: this.parseIncudance('counseling_and_support')},
					{s: 'available_surrogacy_services', n: 'Billing Management', m: this.parseIncudance('medical_billing_management')},
					{s: 'available_surrogacy_services', n: 'Escrow Management', m: this.parseIncudance('escrow_management')},
					{s: 'available_surrogacy_services', n: 'In-House Attorney', m: this.parseIncudance('in_house_attorney')},
					{s: 'available_surrogacy_services', n: 'Establishing Parental Rights', m: this.parseIncudance('establishing_parental_rights')},
					{s: 'available_surrogacy_services', n: 'Fertility Loans', m: this.parseIncudance('fertility_loans')},
				],
				carrier_screening: [
					{s: 'carrier_screening_process', n: 'Criminal Background Check', m: this.parseIncudance('criminal_background_check')},
					{s: 'carrier_screening_process', n: 'Psychological Screening', m: this.parseIncudance('psychological_pre_screened_carriers')},
					{s: 'carrier_screening_process', n: 'Social Worker Screening', m: this.parseIncudance('social_worker_pre_screened_carriers')},
					{s: 'carrier_screening_process', n: 'Medical Screening', m: this.parseIncudance('medically_pre_screened_carriers')},
					{s: 'carrier_screening_process', n: 'Home Visits', m: this.parseIncudance('home_visits_pre_screening')},
					{s: 'carrier_screening_process', n: "Carrier's Insurance Review", m: this.parseIncudance('insurance_review')},
					{s: 'carrier_screening_process', n: 'Financial Review', m: this.parseIncudance('financial_review')},
					{s: 'carrier_screening_process', n: 'Medical Records Review', m: this.parseIncudance('medical_records_review')},
				],
			},
			costs: surrogacyServicesSelectorsEdit,
		},
	};
	relativesOrder = ['Mother', 'Father', 'Maternal Grandmother', 'Maternal Grandfather', 'Paternal Grandmother', 'Paternal Grandfather'];

	expanded = new Set<string>(['services']);

	overlayRef: OverlayRef;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private donorsStore: DonorsStoreService,
		private companyStore: CompaniesStoreService,
		private parentStore: ParentStoreService,
		private providerStore: ProvidersStoreService,
		private dialog: MatDialog,
		private snack: MatSnackBar,
		private renderer: Renderer2,
		private zone: NgZone,
		private breakpointObserver: BreakpointObserver,
		private overlay: Overlay,
		private _viewContainerRef: ViewContainerRef,
		private analytics: AnalyticsService,
		private domSanitizer: DomSanitizer,
		private authService: AuthStoreService,
		public utils: UtilsService,
		private chatService: ChatService,
		private contactAgencyService: ContactAgencyService,
		private currencyPipe: CurrencyPipe,
	) {}

	parseBoolean(value: boolean) {
		return value ? 'Yes' : 'No';
	}

	parseObject(value: Record<string, string>) {
		let result = '';
		for (const item in value) {
			result += `<b style="display: block; margin-top: 9px; font-weight: 600;">${item}</b>`;
			result += '<span>' + value[item].split(', ').join('</span><span>') + '</span>';
		}
		return this.domSanitizer.bypassSecurityTrustHtml(result || 'No medical concerns');
	}

	parseIncudance(desired: string) {
		return (value: string[]) => (value?.includes(desired) ? 'Yes' : 'No');
	}

	onChange(event: string, id: number, _event?: MouseEvent, index?: number) {
		if (event === 'stared') {
			this.parentStore.toggleInFavourites(id, this.type);
			this.analytics.emit('donors', 'favourite-compare', id.toString());
		} else if (event === 'share') {
			if (_event && _event.ctrlKey) {
				const donor = this.donors.find((item) => item.id === id);
				this.snack
					.open(donor.origin, 'GO', {duration: 3278, horizontalPosition: 'left'})
					.onAction()
					.subscribe(() => window.open(donor.origin, '_blank'));
			} else {
				const input = document.createElement('input');
				input.value = `${location.host}/${this.type === ProviderType.EGG_AGENCY ? 'egg-donor/donor' : 'surrogacy/agency'}/${id}`;
				document.body.appendChild(input);
				input.select();
				document.execCommand('copy');
				document.body.removeChild(input);
				this.snack.open('The link to the profile has been copied to the clipboard.', 'Close', {
					duration: 3278,
					horizontalPosition: 'left',
				});
				this.analytics.emit('donors', 'share-compare', id.toString());
			}
		} else if (event === 'remove') {
			this.parentStore.removeFromCompare(id, this.type);
		} else if (event === 'checked') {
			this.parentStore.toggleInCompare(id, this.type);
		} else {
			this.hasChat$[index].pipe(first()).subscribe((chatId) =>
				chatId
					? this.router.navigate(['/', 'chat', chatId])
					: this.contactAgencyService.openDialog(ProviderType.EGG_AGENCY, {
							donorId: this.donors[index].id,
							companyId: this.donors[index]['Agency.provider_mapping.provider_id'],
							agencyName: this.donors[index]['Agency.provider_mapping.provider.company_name'],
					  }),
			);
			this.analytics.emit('donors', 'create-deal-compare', id.toString());
		}
		if (this.type === ProviderType.EGG_AGENCY) this.interestingPanelView();
	}

	onScroll = () => {
		const el = document.documentElement;
		if (el.scrollTop > 100 && !this.small) {
			this.zone.run(() => (this.small = true));
			el.scrollTop = 140;
			try {
				const scroll = document.querySelector('.table-scroll').scrollLeft;
				document.querySelector<HTMLElement>('.row.head.small').style.transform = this.isSmallScreen
					? `translateX(${-scroll}px)`
					: `translateX(${300 - scroll}px)`;
				window.removeEventListener('scroll', this.onScroll);
			} catch (e) {}
			setTimeout(() => {
				this.onScroll();
				window.addEventListener('scroll', this.onScroll);
			}, 1000);
		} else if (el.scrollTop < 30 && this.small) {
			this.zone.run(() => (this.small = false));
			el.scrollTop = 0;
			try {
				document.querySelector<HTMLElement>('.row.head').style.transform = `translateX(0)`;
				window.removeEventListener('scroll', this.onScroll);
			} catch (e) {}
			setTimeout(() => {
				this.onScroll();
				window.addEventListener('scroll', this.onScroll);
			}, 1000);
		}
	};

	onHorizontalScroll = () => {
		const small = document.querySelector('.row.head.small') as HTMLElement;
		const scrollElement = document.querySelector('.table-scroll');
		const scroll = scrollElement.scrollLeft;
		if (scroll > scrollElement.scrollWidth - window.innerWidth + 30) return;
		if (small) small.style.transform = this.isSmallScreen ? `translateX(${-scroll}px)` : `translateX(${300 - scroll}px)`;
		if (this.isSmallScreen) {
			const preCols = document.querySelectorAll('.pre-col') as NodeListOf<HTMLElement>;
			let length = preCols.length;
			// while for tiny perfomance speadup
			while (length) {
				preCols[(length -= 1)].style.transform = `translateX(${scroll}px)`;
			}
		}
	};

	onComment(field: Partial<Cost>, event: MouseEvent) {
		this.selectedComment = field.message;
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(event.target as HTMLElement)
			.withViewportMargin(10)
			.withPositions([
				{
					overlayX: 'center',
					overlayY: 'top',
					originX: 'center',
					originY: 'bottom',
					offsetY: 16,
				},
				{
					overlayX: 'center',
					overlayY: 'bottom',
					originX: 'center',
					originY: 'top',
					offsetY: -16,
				},
			]);
		const scrollStrategy = this.overlay.scrollStrategies.reposition({
			autoClose: true,
		});
		this.overlayRef = this.overlay.create({positionStrategy, hasBackdrop: true, scrollStrategy});
		this.overlayRef.backdropClick().subscribe(() => this.onCommentClose());
		const portal = new TemplatePortal(this.commentTemplate, this._viewContainerRef);
		this.overlayRef.attach(portal);
	}

	onCommentClose() {
		this.overlayRef.dispose();
		this.selectedCommentToggle = '';
		this.selectedComment = '';
	}

	trackBy(index: number, item: IDonor) {
		if (!item) {
			return null;
		}
		return item.id;
	}

	isNumber(value: any) {
		return typeof value === 'number';
	}

	isNotEmpty(value: Record<string, unknown> | string) {
		if (typeof value === 'object' && value !== null) return Object.values(value).some((item) => item);
		else return Boolean(value);
	}

	calcHeight() {
		for (const row of this.rows) {
			for (let i = 0; i < row.nativeElement.children.length; i += 2)
				this.renderer.setStyle(row.nativeElement.children[i], 'height', row.nativeElement.children[i + 1].clientHeight + 'px');
		}
	}

	interestingPanelView() {
		const donorElementRef = document.querySelector('div.table-scroll > div > div.row.head > div');
		if (!donorElementRef) return setTimeout(() => this.interestingPanelView(), 10);
		const donorElement = donorElementRef.clientWidth;
		const interestingDonor = document.querySelector('div.interesting-donor');
		if (window.innerWidth > 812.99) {
			this.right = (window.innerWidth - (donorElement + 1) * this.donorLength - 300 - 200) / 2;
			if (this.right > 50) this.right = 50;
			else if (this.right < 10) {
				this.right = 0;
			}
			this.display = (donorElement + 1) * this.donorLength + 512 > window.innerWidth ? 'none' : 'block';
			interestingDonor.setAttribute('style', 'display:' + this.display + '; right :' + this.right + 'px');
		} else {
			this.display = 'none';
			if (this.donorLength === 1) {
				this.display = 'block';
				interestingDonor.setAttribute('style', 'display:' + this.display + '; left : 50vw');
			}
		}
	}

	calculateTotals() {
		for (const donor of this.donors) {
			if (donor.egg_donor_compensation)
				donor.costs.egg_donor_compensation = {
					...(donor.costs.egg_donor_compensation || {}),
					value: donor.egg_donor_compensation || donor.costs.egg_donor_compensation?.value,
				};
			for (const item of this.sections[this.type].costs.internal) {
				if (!this.sections[this.type].costs[item.s]) continue;
				const total = (donor.costs[item.s] = {value: 0});
				for (const subItem of this.sections[this.type].costs[item.s])
					if (donor.costs[subItem.s] && !donor.costs[subItem.s].included && donor.costs[subItem.s].value)
						total.value += donor.costs[subItem.s].value;
			}
			if (donor.costs.financial_options_offered_by_agency)
				donor.costs.financial_options_offered_by_agency.special_value = (
					donor.costs.financial_options_offered_by_agency.special_value || ''
				)
					.split(' ; ')
					.map((item) => item.replace(/http(s)?:\/\//, ''))
					.map((item) =>
						item
							? `<a target="_blank" style="display: block;" href="https://${item}">${item.substring(0, item.indexOf('/')) || item}</a>`
							: '-',
					)
					.join('\n');
		}
	}

	ngOnInit() {
		this.right = 0;
		this.type = this.router.routerState.snapshot.url.includes('egg-donor') ? ProviderType.EGG_AGENCY : ProviderType.SURROGACY_AGENCY;
		if (this.authService.getType() === 'parent') this.parentStore.emitCompareEvent(this.type).subscribe();
		this.sections[this.type].internal.forEach((item) => this.expanded.add(item.s));
		this.sections[this.type].costs.internal.forEach((item) => this.expanded.add('services_' + item.s));
		// cannot add pipe 'cause each one depends on the result of all previous
		switch (this.type) {
			case ProviderType.EGG_AGENCY: {
				this.ids$ = this.parentStore.getCompare(ProviderType.EGG_AGENCY).subscribe((ids) => {
					if (ids.length === 0) return this.router.navigate(['../'], {relativeTo: this.route});
					this.donorLength = ids.length;
					this.donorsStore.fetchDonorById(ids).subscribe((data) => {
						this.hasChat$ = ids.map((item) => this.chatService.donorHasChat$(item));
						this.interested$ = ids.map((item) => this.parentStore.isInterestedIn(item));
						this.donorsStore
							.getAgencyById(data.map((item) => item.agency_id))
							.pipe(first())
							.subscribe((agenciesRaw) => {
								const agencies = agenciesRaw.map((item) => {
									const r = this.utils.deepCopy(item);
									for (const key in r) {
										if (r[key] === null) delete r[key];
									}
									r.comments = r.comments || {};
									delete r.id;
									return r;
								});
								this.donors = data.map((item, index) => {
									this.favourites[item.id] = this.parentStore.isInFavourites(item.id, ProviderType.EGG_AGENCY);
									item.health_medical = {};
									for (const key of [
										{s: 'medical_problems_if_any', n: 'Medical Problems'},
										{s: 'mental_problems_if_any', n: 'Mental Problems'},
										{s: 'smoker', n: 'Smoker'},
										{s: 'tattoos', n: 'Tattoos'},
										{s: 'piercing', n: 'Piercing'},
										{s: 'alcohol', n: 'Alcohol'},
										{s: 'recreational_drugs', n: 'Recreational Drugs'},
										{s: 'any_transfusions', n: 'Transfusions'},
										{s: 'have_you_ever_been_refused_as_a_blood_donor', n: 'Refused as a Blood Donor'},
										{s: 'psychiatric_counseling', n: 'Psychiatric Counseling'},
										{s: 'psychiatric_hospitalization', n: 'Psychiatric Hospitalization'},
										{s: 'clinical_depression', n: 'Clinical Depression'},
										{s: 'clinical_diagnoses_with_add_or_hdhd', n: 'Clinically Diagnosed With ADD or ADHD?'},
										{s: 'recent_medications', n: 'Recent Medications'},
										{s: 'hiv_aids', n: 'HIV/AIDS'},
										{s: 'st_ds', n: 'STDs'},
									]) {
										if (item[key.s] && item[key.s] !== 'No' && item[key.s] !== '[1]')
											item.health_medical[key.n] = this.utils.toHumanReadable(item[key.s]);
									}
									if (item.number_of_miscarriages && item.number_of_miscarriages !== '[1]')
										item.health_medical['Number of Miscarriages'] = String(item.number_of_miscarriages);
									return {
										...agencies[index],
										...item,
									};
								});
								this.calculateTotals();
							});
					});
					this.donorsStore
						.fetchDonors(0, 9, null)
						.pipe(first())
						.subscribe((data) => {
							if (!data) return;
							this.donorsList = data.data.filter((item) => !ids.includes(item.id));
							data.data.forEach((item) => (this.favourites[item.id] = this.parentStore.isInFavourites(item.id, ProviderType.EGG_AGENCY)));
							setTimeout(() => this.interestingPanelView());
						});
				});
				break;
			}
			case ProviderType.SURROGACY_AGENCY: {
				this.ids$ = this.parentStore.getCompare(ProviderType.SURROGACY_AGENCY).subscribe((ids) => {
					if (ids.length === 0) return this.router.navigate(['../'], {relativeTo: this.route});
					this.donorLength = ids.length;
					this.companyStore.fetchCompanyById(ids).subscribe((data) => {
						combineLatest(data.map((item) => this.providerStore.getFullCosts(item.id)))
							.pipe(last())
							.subscribe((res) => {
								this.donors = data.map((item, index) => {
									item.costs = res[index][ProviderType.SURROGACY_AGENCY];
									this.favourites[item.id] = this.parentStore.isInFavourites(item.id, ProviderType.SURROGACY_AGENCY);
									return item;
								});
								this.calculateTotals();
							});
					});
				});
				break;
			}
			default:
				break;
		}
	}

	ngAfterViewInit() {
		this.zone.runOutsideAngular(() => window.addEventListener('scroll', this.onScroll));
		this.rows$ = this.rows.changes.subscribe(() => this.calcHeight());
		this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 812.99px)');
		document.querySelector('.table-scroll').addEventListener('scroll', this.onHorizontalScroll);
	}

	ngOnDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		document.querySelector('.table-scroll').removeEventListener('scroll', this.onHorizontalScroll);
		this.ids$.unsubscribe();
		this.rows$.unsubscribe();
	}
}
