import {Injectable} from '@angular/core';
import * as localForage from 'localforage';
import {from, Observable, Subject} from 'rxjs';
import {filter, mergeMap, startWith} from 'rxjs/operators';
import {storeVersion} from "../../main";

@Injectable({providedIn: 'root'})
export class CacheService {
	private instance: LocalForage;
	private readonly version = storeVersion;
	private last = new Subject<string | number>();

	constructor() {
		this.instance = localForage.createInstance({
			driver: localForage.LOCALSTORAGE,
			name: 'cache',
			version: this.version,
			storeName: 'keyvaluepairs',
		});

		this.init();
	}

	async init() {
		const currentVersion = await this.instance.getItem<number>('__version__');
		if (this.version !== currentVersion) {
			await this.instance.clear();
			await this.instance.setItem('__version__', this.version);
			location.reload();
		}
	}

	async upsert<T>(key: string | number, value: T) {
		const res = await this.instance.setItem(key.toString(), value);
		this.last.next(key);
		return res;
	}

	async get<T>(key: string | number) {
		return this.instance.getItem<T>(key.toString());
	}

	select<T>(key: string | number): Observable<T> {
		return this.last.pipe(
			startWith(key),
			filter((changed) => changed === key),
			mergeMap(() => from(this.get<T>(key))),
		);
	}
}
