import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';

import {combineLatest, of, Subscription} from 'rxjs';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';

import {LocationUpdateService} from '@/components/calendar/calendar-list/LocationUpdateService';
import {AppointmentEvent, CalendarListComponent} from '@/components/calendar/calendar-list/calendar-list.component';
import {MobileChatComponent} from '@/components/mobile-chat/mobile-chat.component';
import {ProviderType} from '@/config';
import {getCSSVar} from '@/helpers/getCssVar';
import {ServiceMessageType} from '@/models/chat.model';
import {LocationBase} from '@/models/common.model';
import {ProfileEditingBase} from '@/models/provider.model';
import {ChatService} from '@/services/chat.service';
import {ContactAgencyService} from '@/services/contact-agency.service';
import {ParentStoreService} from '@/services/parent.service';
import {UtilsService} from '@/services/utils.service';
import {IvfService} from '../ivf/ivf.service';
import {AnalyticsService} from "@/services/analytics.service";

@Component({
	selector: 'app-provider-profile',
	templateUrl: './provider-profile.component.html',
	styleUrls: ['./provider-profile.component.scss'],
})
export class ProviderProfileComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(CalendarListComponent)
	calendarList: CalendarListComponent;
	@ViewChild('actions')
	actionsRef: TemplateRef<void>;
	@ViewChild('backToSearch')
	backToSearchRef: TemplateRef<void>;

	@ViewChild('editingRef')
	editingRef: ProfileEditingBase;

	ProviderType = ProviderType;

	id$ = this.route.params.pipe(map(({id}) => +id));
	providerId$ = this.route.params.pipe(map(({providerId}) => +providerId));
	isEditing$ = this.route.url.pipe(map((segments) => !!segments.find((segment) => segment.path === 'edit')));
	type$ = this.route.data.pipe(map(({type}) => type as ProviderType));

	inFav$ = combineLatest([this.id$, this.type$]).pipe(switchMap(([id, type]) => this.parentService.isInFavourites(id, type)));
	inCompare$ = combineLatest([this.id$, this.type$]).pipe(switchMap(([id, type]) => this.parentService.isInCompare(id, type)));

	bookingMessageSuccess = false;
	bookingMessage = '';

	listLinks = {
		[ProviderType.SURROGACY_AGENCY]: '/surrogacy',
		[ProviderType.IVF_CLINIC]: '/ivf',
	};

	contacts$ = combineLatest([this.isEditing$, this.providerId$, this.type$]).pipe(
		switchMap(([isEditing, id, type]) => (isEditing ? of([]) : this.contactAgencyService.getContacts(id, type))),
		map((contacts) => contacts.filter((item) => item.calendar_id && item.show_calendar)),
		map((contacts) => (contacts.length ? contacts : null)),
	);
	locations$ = combineLatest([this.isEditing$, this.id$, this.type$]).pipe(
		switchMap(([isEditing, id, type]) => (!isEditing && type === ProviderType.IVF_CLINIC ? this.ivfService.getDistances(id) : of([]))),
	);
	ivf$ = combineLatest([this.isEditing$, this.id$, this.type$]).pipe(
		switchMap(([isEditing, id, type]) => (!isEditing && type === ProviderType.IVF_CLINIC ? this.ivfService.getOne(id) : of(null))),
		shareReplay(1),
	);

	isSmallScreen$ = this.utilService.isSmallScreen;

	locationUpdateSubscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		private contactAgencyService: ContactAgencyService,
		private parentService: ParentStoreService,
		private chatService: ChatService,
		private utilService: UtilsService,
		private analyticsService: AnalyticsService,
		private dialog: MatDialog,
		private ivfService: IvfService,
		private locationUpdateService: LocationUpdateService,
	) {}

	locationBase: LocationBase = null;
	updatedStatus(selectedLocation) {
		this.locationBase = selectedLocation;
		console.log(this.locationBase);
	}

	ngOnInit(): void {
		this.locationUpdateSubscription = this.locationUpdateService.data.subscribe((response) => {
			this.updatedStatus(response); // you will receive the data from sender component here.
		});
	}

	ngAfterViewInit() {}

	ngOnDestroy() {
		this.locationUpdateSubscription?.unsubscribe();
	}

	toggleInFav() {
		combineLatest([this.id$, this.type$])
			.pipe(first())
			.subscribe(([id, type]) => this.parentService.toggleInFavourites(id, type));
	}

	toggleInCompare() {
		combineLatest([this.id$, this.type$])
			.pipe(first())
			.subscribe(([id, type]) => this.parentService.toggleInCompare(id, type));
	}

	sendMessage() {
		const message = this.bookingMessage;
		this.bookingMessage = '';

		combineLatest([this.type$, this.providerId$])
			.pipe(
				first(),
				switchMap(([type, providerId]) => this.chatService.createChat2(type, providerId, message, this.locationBase)),
			)
			.subscribe(() => {
				this.analyticsService.emit('User Area', 'IVF Profile', 'CTA - Chat - Clinic - Success');
				this.bookingMessageSuccess = true
			});
	}

	onChatClick() {
		this.analyticsService.emit('User Area', 'IVF Profile', 'CTA - Chat - Clinic');
	}

	openMobileChat(forceChat: boolean) {
		const headerHeight = getCSSVar('--header-height');
		combineLatest([this.providerId$, this.type$, this.contacts$, this.locations$])
			.pipe(first())
			.subscribe(([providerId, type, contacts, locations]) =>
				this.dialog.open(MobileChatComponent, {
					height: `calc(100vh - ${headerHeight})`,
					width: '100vw',
					maxWidth: '100vw',
					position: {
						top: headerHeight,
					},
					data: {
						name: '',
						providerId,
						type,
						withCalendar: !forceChat && !!contacts,
						contacts: contacts || [],
						locations,
					},
					hasBackdrop: false,
					panelClass: 'dialog-no-padding',
					autoFocus: false,
					closeOnNavigation: true,
				}),
			);
	}

	onSelectTime({ time, calendarId }) {
		this.analyticsService.emit('User Area', 'IVF Profile', 'CTA - Schedule call - Clinic');
	}

	createAppointment({time, calendarId, location}: AppointmentEvent) {
		combineLatest([this.providerId$, this.type$])
			.pipe(
				first(),
				switchMap(([providerId, type]) =>
					this.chatService.createChat2(
						type,
						providerId,
						ContactAgencyService.buildServiceMessage(ServiceMessageType.CreatedAppointment, {
							time: time.toISO(),
							calendarId,
						}),
						location,
					),
				),
			)
			.subscribe(({chat}) => {
				this.analyticsService.emit('User Area', 'IVF Profile', 'CTA - Schedule call - Clinic - Success');
				this.calendarList.dealId = chat.deal_id;
				this.calendarList.onAppointmentConfirm(true);
			});
	}

	saveProfile() {
		this.editingRef.save();
	}
}
