import {ListRange} from '@angular/cdk/collections';

import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';

export class InfiniteSource<T> {
	public countFromSource = 0;
	public get count() {
		return this.dataSource.value.length;
	}

	public dataSource = new BehaviorSubject<T[][]>([]);

	private dataSubscription = new Subscription();

	private _columns = 1;
	public get columns() {
		return this._columns;
	}
	public set columns(value) {
		this._columns = value;
		this.refreshColumns();
	}

	private _refresh$ = new Subject<void>();
	public get refresh$() {
		return this._refresh$.asObservable();
	}

	private _isLoading = new BehaviorSubject<boolean>(true);
	public get isLoading$() {
		return this._isLoading.asObservable();
	}

	constructor(private fetch: (start: number, end: number) => Observable<{count: number; results: T[]}>) {
		this.dataSubscription.add(this.refresh$.subscribe(() => this._refresh()));
	}

	handleRange(range: ListRange) {
		// data is displayed in multiple columns
		const actualStart = range.start * this.columns;
		const actualEnd = range.end * this.columns;

		console.log(`[InfiniteSource] actualStart => ${actualStart}, actualEnd => ${actualEnd}`);
		const cacheOfSlice = this.dataSource.value.slice(range.start, range.end);

		if (cacheOfSlice.length && cacheOfSlice.every((item) => !!item)) {
			return;
		}

		this._isLoading.next(true);
		this.fetch(actualStart, actualEnd)?.subscribe(({count, results}) => {
			this._isLoading.next(false);

			this.countFromSource = count;

			const current = this.dataSource.value.slice();
			current.splice(range.start, range.end - range.start, ...this.splitIntoColumns(results));

			this.dataSource.next(current);
		});
	}

	destroy() {
		this.dataSubscription.unsubscribe();
		this._refresh$.complete();
	}

	refresh() {
		this._refresh$.next();
	}

	restore(another: InfiniteSource<T>) {
		this.countFromSource = another.countFromSource;
		this.columns = another.columns;
		this.dataSource.next(another.dataSource.value);
		this._isLoading.next(false);
	}

	private _refresh() {
		this.dataSource.next([]);
	}

	private refreshColumns() {
		const current = this.dataSource.value.flat();
		this.dataSource.next(this.splitIntoColumns(current));
	}

	private splitIntoColumns(data: T[]) {
		const r = [];
		for (let i = 0; i < data.length; i++) {
			if (i % this.columns === 0) {
				r.push([]);
			}
			r[r.length - 1].push(data[i]);
		}
		return r;
	}
}
