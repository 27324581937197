import {BreakpointObserver} from '@angular/cdk/layout';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Platform} from '@angular/cdk/platform';
import {DomPortalOutlet, TemplatePortal} from '@angular/cdk/portal';
import {CurrencyPipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {
	AfterViewInit,
	ApplicationRef,
	Component,
	ComponentFactoryResolver,
	ElementRef,
	HostListener,
	Injector,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabGroup} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {chunk, orderBy} from 'lodash-es';
import {Observable, Subscription} from 'rxjs';
import {first} from 'rxjs/operators';

import {LocationUpdateService} from '@/components/calendar/calendar-list/LocationUpdateService';
import {nanoid} from '@/helpers/nanoid';
import {AuthStoreService} from '@/services/auth.service';
import {eggAgencyServicesSelectors, ProviderType} from '../../config';
import {AnalyticsService} from '../../services/analytics.service';
import {ChatService} from '../../services/chat.service';
import {ContactAgencyService} from '../../services/contact-agency.service';
import {DonorsStoreService, IDonor} from '../../services/donors.service';
import {ParentStoreService} from '../../services/parent.service';
import {UtilsService} from '../../services/utils.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
	providers: [ContactAgencyService, CurrencyPipe],
	encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('carousel', {static: false})
	carousel: ElementRef<HTMLUListElement>;
	@ViewChild('video')
	videoRef: ElementRef<HTMLVideoElement>;

	@ViewChild('overlay', {static: false})
	overlayTemplate: TemplateRef<any>;
	@ViewChild('stop')
	stopRef: TemplateRef<any>;

	@ViewChild('info')
	infoTemplate: TemplateRef<any>;

	@ViewChild('actions') actionsTemplate: TemplateRef<any>;

	@ViewChild('summary', {static: false}) summary: TemplateRef<any>;
	@ViewChild('services', {static: false}) services: TemplateRef<any>;

	@ViewChild(MatTabGroup)
	tabGroup: MatTabGroup;

	@ViewChild('expl')
	commentTemplate: TemplateRef<null>;

	costsTutorialStep = 0;
	stripe_customer_id: string;
	stripe_subscription_id: string;
	stripe_subscription_status: string;
	stripe_subscription_can_subscribe = false;

	donor: any;
	images: string[] = [];
	agency$: Subscription;
	_subscription = new Subscription();
	checked$: Observable<boolean>;
	isInFavourite$: Observable<boolean>;

	loadingPDF = false;
	creatingDeal = false;

	overlayImage: number = null;
	overlayRef: OverlayRef;

	tabs: Array<{template: TemplateRef<any>; title: string; id: string}> = [];

	hasChat$: Observable<string>;
	comparing$: Observable<number[]>;

	occupationLong = false;
	forPDF = false;
	isSmallScreen: boolean;

	selectedIndex = 0;
	tabsOffset = [];

	selectedCommentToggle = '';
	selectedComment = '';
	selectedInfo = '';

	showContactAgencyAsStatic = false;

	interested$: Observable<boolean>;

	readonly servicesSelectors = eggAgencyServicesSelectors;

	readonly history = [
		{s: 'date', n: 'Date'},
		{s: 'eggs', n: 'Eggs'},
		{s: 'embryos', n: 'Embryos'},
		{s: 'result', n: 'Pregnancy Result'},
	];

	readonly favourite = [
		{s: 'favourite_book', n: 'Favorite Book'},
		{s: 'favourite_movie', n: 'Favorite Movie'},
		{s: 'favourite_sport', n: 'Favorite Sport'},
		{s: 'favourite_hobbies', n: 'Favorite Hobbies'},
	];

	relatives = [
		{s: 'mother', n: 'Mother'},
		{s: 'father', n: 'Father'},
		{s: 'maternal_grandmother', n: 'Maternal Grandmother'},
		{s: 'maternal_grandfather', n: 'Maternal Grandfather'},
		{s: 'paternal_grandmother', n: 'Paternal Grandmother'},
		{s: 'paternal_grandfather', n: 'Paternal Grandfather'},
	];
	relativesOrder = ['Mother', 'Father', 'Maternal Grandmother', 'Maternal Grandfather', 'Paternal Grandmother', 'Paternal Grandfather'];
	relativeFields = [
		{ss: 'age', s: 'age', n: 'Age:'},
		{ss: 'hair', s: 'hair_color', n: 'Hair Color:'},
		{ss: 'eye', s: 'eye_color', n: 'Eye Color:'},
		{ss: 'height', s: 'height', n: 'Height:', m: (value) => this.useHeight(value)},
		{ss: 'weight', s: 'weight', n: 'Weight:', m: (value) => this.useWeight(value)},
		{ss: 'occupation', s: 'occupation', n: 'Occupation:'},
		{s: 'health_status', n: 'Health Status:'},
	];
	readonly familysvg = {
		brother: '../assets/family/man1.svg',
		sister: '../assets/family/women1.svg',
		mother: '../assets/family/women2.svg',
		father: '../assets/family/man2.svg',
		maternal_grandmother: '../assets/family/gmother1.svg',
		maternal_grandfather: '../assets/family/gfather1.svg',
		paternal_grandmother: '../assets/family/gmother2.svg',
		paternal_grandfather: '../assets/family/gfather2.svg',
	};
	readonly servicesSummary = [
		{s: 'agency__fees', n: 'Agency Fees'},
		{s: 'donor__fees', n: 'Egg Donor Fees'},
		{s: 'legal__fees', n: 'Legal Fees'},
		{s: 'screening__fees', n: 'Egg Donor Screening'},
		{s: 'complication_insurance', n: 'Complications Insurance'},
		{s: 'escrow_account_service_fee', n: 'Escrow Account Service Fee'},
		{s: 'contingencies_fees', n: 'Contingencies Fees'},
		{s: 'refund_policy', n: 'Refund Policy'},
		{s: 'unused_funds_returned_policy', n: 'Unused Funds Returned Policy'},
		{s: 'financial_options_offered_by_agency', n: 'Financial Options Offered by Agency'},
		{s: 'discount', n: 'Discount'},
	];

	commentOverlayRef: OverlayRef;

	constructor(
		private authStore: AuthStoreService,
		private store: DonorsStoreService,
		private parentService: ParentStoreService,
		private route: ActivatedRoute,
		private overlay: Overlay,
		private snack: MatSnackBar,
		private _viewContainerRef: ViewContainerRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		private router: Router,
		private analytics: AnalyticsService,
		private breakpointObserver: BreakpointObserver,
		public platform: Platform,
		public utils: UtilsService,
		private chatService: ChatService,
		private contactAgencyService: ContactAgencyService,
		private currencyPipe: CurrencyPipe,
		private locationUpdateService: LocationUpdateService,
	) {
		this.analytics.setDimension('Donor Profile', 'Egg Donor');
	}

	@HostListener('window:scroll')
	onWindowScroll() {
		let scrolledIndex = 0;
		for (let i = 0; i < this.tabsOffset.length; i++) {
			if (this.tabsOffset[i] < window.scrollY) {
				scrolledIndex = i;
			}
		}
		if (this.selectedIndex !== scrolledIndex) {
			this.selectedIndex = scrolledIndex;
		}

		this.showContactAgencyAsStatic = this.isSmallScreen && window.scrollY > 360;
	}

	calculateTotals() {
		if (this.donor.donor_type === 'frozen_eggs') {
			this.donor.total_compensation = this.currencyPipe.transform(this.donor.total_compensation, 'USD', 'symbol', '1.0-0');
			return;
		}
		// !fixme: no idea what that's doing
		if (this.donor.egg_donor_compensation) {
			this.donor.costs.egg_donor_compensation = {
				...(this.donor.costs.egg_donor_compensation || {}),
				value: this.donor.costs.egg_donor_compensation?.value || this.donor.egg_donor_compensation,
			};
		}
		let minTotal = 0;
		let maxTotal = 0;
		const donorCompensation = ['egg_donor_compensation', 'egg_donor_compensation_max'].filter(
			(key) => !this.donor.costs[key]?.included,
		);
		for (const item of this.servicesSelectors.internal) {
			if (!this.servicesSelectors[item.s]) {
				continue;
			}
			let minSection = 0;
			let maxSection = 0;
			for (const subItem of this.servicesSelectors[item.s]) {
				if (subItem.s === 'egg_donor_compensation') {
					if (
						this.donor.costs.override_egg_donor_compensation?.special_value !== 'true' &&
						// profile_donor_compensation holds the actual parsed value from the profile, obviously a bodge but refactoring is comming
						(!('profile_donor_compensation' in this.donor) || this.donor.profile_donor_compensation)
					) {
						minSection += this.donor.egg_donor_compensation;
						maxSection += this.donor.egg_donor_compensation;
						this.donor.costs.egg_donor_compensation.value = this.donor.egg_donor_compensation;
					} else {
						const donorValues = donorCompensation.map((i) => this.donor.costs[i]?.value).filter(Boolean);
						if (donorValues.length) {
							const min = Math.min(...donorValues);
							const max = Math.max(...donorValues);
							const minValue = this.currencyPipe.transform(min, 'USD', 'symbol', '1.0-0');
							const maxValue = this.currencyPipe.transform(max, 'USD', 'symbol', '1.0-0');
							this.donor.costs.egg_donor_compensation.special_value =
								minValue === maxValue ? minValue : `${minValue} - ${maxValue}`;
							minSection += min;
							maxSection += max;
						}
					}
				} else if (this.donor.costs[subItem.s] && !this.donor.costs[subItem.s].included && this.donor.costs[subItem.s].value) {
					minSection += this.donor.costs[subItem.s].value;
					maxSection += this.donor.costs[subItem.s].value;
				}
			}
			minTotal += minSection;
			maxTotal += maxSection;
			const minSectionValue = this.currencyPipe.transform(minSection, 'USD', 'symbol', '1.0-0');
			const maxSectionValue = this.currencyPipe.transform(maxSection, 'USD', 'symbol', '1.0-0');
			this.donor.costs[item.s + '__fees'] = {
				special_value: minSectionValue === maxSectionValue ? minSectionValue : `${minSectionValue} - ${maxSectionValue}`,
			};
		}
		if (
			this.donor.costs.financial_options_offered_by_agency &&
			!this.donor.costs.financial_options_offered_by_agency.special_value.includes('<a ')
		)
			this.donor.costs.financial_options_offered_by_agency.special_value = (
				this.donor.costs.financial_options_offered_by_agency.special_value || ''
			)
				.split(' ; ')
				.map((item) => item.replace(/http(s)?:\/\//, ''))
				.map((item) => `<a target="_blank" href="https://${item}">${item.substring(0, item.indexOf('/')) || item}</a>`)
				.join('\n');
		this.donor.costs.agency__fees = this.donor.costs.agency_compensation;
		minTotal += this.donor.costs.agency_compensation.value;
		maxTotal += this.donor.costs.agency_compensation.value;
		const minTotalValue = this.currencyPipe.transform(minTotal, 'USD', 'symbol', '1.0-0');
		const maxTotalValue = this.currencyPipe.transform(maxTotal, 'USD', 'symbol', '1.0-0');
		console.log(this.donor.total_compensation);
		this.donor.total_compensation = minTotalValue === maxTotalValue ? minTotalValue : `${minTotalValue} - ${maxTotalValue}`;
		console.log(this.donor.total_compensation);
	}

	onChange(event: 'interested' | 'print' | 'share' | 'stared' | 'checked' | 'premium', _event?: MouseEvent) {
		if (event === 'premium') {
			if (this.stripe_subscription_can_subscribe) {
				this.analytics.push({
					token: nanoid(12),
					category: 'User Area',
					action: 'Egg Donor Profile',
					label: 'CTA - Go Premium',
				});
				this.router.navigate(['/', 'checkout']);
			} else {
				this.analytics.push({
					token: nanoid(12),
					category: 'User Area',
					action: 'Egg Donor Profile',
					label: 'CTA - Contact Agency',
				});
				this.hasChat$.pipe(first()).subscribe((chatId) =>
					chatId
						? this.router.navigate(['/', 'chat', chatId])
						: this.contactAgencyService.openDialog(ProviderType.EGG_AGENCY, {
								donorId: this.donor.id,
								companyId: this.donor['Agency.provider_mapping.provider_id'],
								agencyName: this.donor['Agency.provider_mapping.provider.company_name'],
						  }),
				);
				this.analytics.emit('donors', 'create-deal-profile', this.donor.id.toString());
			}
		} else if (event === 'stared') {
			this.parentService.toggleInFavourites(this.donor.id, ProviderType.EGG_AGENCY);
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Egg Donor Profile',
				label: 'CTA - Like',
			});
		} else if (event === 'interested') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Egg Donor Profile',
				label: 'CTA - Contact Agency',
			});
			this.hasChat$.pipe(first()).subscribe((chatId) =>
				chatId
					? this.router.navigate(['/', 'chat', chatId])
					: this.contactAgencyService.openDialog(ProviderType.EGG_AGENCY, {
							donorId: this.donor.id,
							companyId: this.donor['Agency.provider_mapping.provider_id'],
							agencyName: this.donor['Agency.provider_mapping.provider.company_name'],
					  }),
			);
			this.analytics.emit('donors', 'create-deal-profile', this.donor.id.toString());
		} else if (event === 'share') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Egg Donor Profile',
				label: 'CTA - Share',
			});
			if (_event && _event.ctrlKey) {
				this.snack
					.open(this.donor.origin, 'GO', {duration: 3278, horizontalPosition: 'left'})
					.onAction()
					.subscribe(() => window.open(this.donor.origin, '_blank'));
			} else {
				const input = document.createElement('input');
				input.value = `${location.host}/egg-donor/donor/${this.donor.id}`;
				document.body.appendChild(input);
				input.select();
				document.execCommand('copy');
				document.body.removeChild(input);
				this.snack.open('The link to the profile has been copied to the clipboard.', 'Close', {
					duration: 3278,
					horizontalPosition: 'left',
				});
			}
		} else if (event === 'print') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Egg Donor Profile',
				label: 'CTA - Save PDF',
			});
			if (this.donor.pdf) {
				const url = `/images/${this.donor.pdf}`;
				const blocked = window.open(url, this.donor.id.toString()) === null;
				if (blocked) {
					const link = document.createElement('a');
					link.href = url;
					link.target = '_blank';
					link.download = `GoStork - Donor #${this.donor.id}.pdf`;
					link.click();
				}
				return;
			}
			this.loadingPDF = true;
			this.store.getDonorPDF(this.donor.id).subscribe(
				(buffer) => {
					const dataURL = URL.createObjectURL(new Blob([buffer], {type: 'application/pdf'}));
					const blocked = window.open(dataURL, this.donor.id.toString()) === null;
					if (blocked) {
						const link = document.createElement('a');
						link.href = dataURL;
						link.target = '_blank';
						link.download = `GoStork - Donor #${this.donor.id}.pdf`;
						link.click();
					}
					setTimeout(() => URL.revokeObjectURL(dataURL), 100);
					this.loadingPDF = false;
				},
				(error) => {
					console.error(error);
					this.loadingPDF = false;
				},
			);
		} else if (event === 'checked') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Egg Donor Profile',
				label: 'CTA - Add to Compare',
			});
			this.parentService.toggleInCompare(this.donor.id, ProviderType.EGG_AGENCY);
		}
	}

	useHeight(n: number | string) {
		if (typeof n === 'string') n = Number(n);
		return this.utils.useHeight(n, this.parentService.getUnits());
	}

	useWeight(n: number | string) {
		if (typeof n === 'string') n = Number(n);
		return this.utils.useWeight(n, this.parentService.getUnits());
	}

	isNotEmpty(value: Record<string, unknown>) {
		return Object.values(value || {}).some((item) => item);
	}

	onScroll(direction: 'forward' | 'backward') {
		this.carousel.nativeElement.scrollBy({
			left: (document.documentElement.clientWidth / 2.2) * (direction === 'forward' ? 1 : -1),
			behavior: 'smooth',
		});
	}

	onComment(selected: string) {
		this.selectedComment = this.donor.costs[selected]?.message;
		if (!this.selectedComment) return;
		this.selectedCommentToggle = selected;
		const anchor = document.getElementById(this.selectedCommentToggle);
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(anchor)
			.withPositions([
				{
					overlayX: 'center',
					overlayY: 'top',
					originX: 'end',
					originY: 'bottom',
				},
				{
					overlayX: 'center',
					overlayY: 'bottom',
					originX: 'end',
					originY: 'top',
				},
			]);
		const scrollStrategy = this.overlay.scrollStrategies.reposition({autoClose: true});
		this.commentOverlayRef = this.overlay.create({
			positionStrategy,
			scrollStrategy,
			hasBackdrop: this.platform.IOS || this.platform.ANDROID,
		});
		this.commentOverlayRef.backdropClick().subscribe(() => this.onCommentClose());
		const portal = new TemplatePortal(this.commentTemplate, this._viewContainerRef);
		this.commentOverlayRef.attach(portal);
	}

	onCommentClose() {
		this.commentOverlayRef?.dispose();
		this.selectedCommentToggle = '';
		this.selectedComment = '';
	}

	onInfoClose() {
		this.overlayRef?.dispose();
		this.selectedInfo = '';
	}

	onInfo(event: Event, message: string) {
		event.stopPropagation();
		this.selectedInfo = message;
		const positionStrategy = this.isSmallScreen
			? this.overlay.position().global().centerHorizontally().centerVertically()
			: this.overlay
					.position()
					.flexibleConnectedTo(event.target as HTMLElement)
					.withPositions([
						{
							originX: 'end',
							originY: 'center',
							overlayX: 'start',
							overlayY: 'center',
						},
					])
					.withViewportMargin(60)
					.withDefaultOffsetX(30);
		this.overlayRef = this.overlay.create({positionStrategy, hasBackdrop: true});
		this.overlayRef.backdropClick().subscribe(() => this.onInfoClose());
		const portal = new TemplatePortal(this.infoTemplate, this._viewContainerRef);
		this.overlayRef.attach(portal);
	}

	trackBy(item: IDonor) {
		if (!item) {
			return null;
		}
		return item.id;
	}

	onImageOpen(index: number) {
		this.overlayImage = index;
		const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			disposeOnNavigation: true,
			positionStrategy,
		});
		const dialog = new TemplatePortal(this.overlayTemplate, this._viewContainerRef);
		this.overlayRef.backdropClick().subscribe(() => this.overlayRef.dispose());
		this.overlayRef.keydownEvents().subscribe(({key}) => {
			if (key === 'ArrowLeft') {
				this.onImageChange('backward');
			} else if (key === 'ArrowRight') {
				this.onImageChange('forward');
			}
		});
		this.overlayRef.attach(dialog);
	}

	onImageChange(direction: 'forward' | 'backward') {
		if (direction === 'forward' && this.overlayImage === this.images.length - 1) {
			this.overlayImage = this.donor.video ? -1 : 0;
		} else if (direction === 'backward' && this.overlayImage < 1) {
			this.overlayImage = this.donor.video && this.overlayImage === 0 ? -1 : this.images.length - 1;
		} else {
			this.overlayImage = this.overlayImage + (direction === 'forward' ? 1 : -1);
		}
	}

	onTabChange(index: number) {
		if (index === 1) {
			this.startTutorial();
		}
		if (this.selectedIndex === index) {
			return;
		}
		this.selectedIndex = index;
		const element = document.getElementById(this.tabs[index].id);
		if (element) {
			window.scrollTo({top: element.offsetTop - 140, behavior: 'smooth'});
		}
	}

	remoteFromCompare(id: number) {
		this.parentService.removeFromCompare(id, ProviderType.EGG_AGENCY);
	}

	startTutorial() {
		if (localStorage.getItem('__costs__tutorial') === 'true' || this.overlayRef) {
			return;
		}
		const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			disposeOnNavigation: true,
			positionStrategy,
		});
		const dialog = new TemplatePortal(this.stopRef, this._viewContainerRef);
		this.overlayRef.attach(dialog);
	}

	finishTutorial() {
		this.overlayRef.dispose();
		this.overlayRef = null;
		localStorage.setItem('__costs__tutorial', 'true');
	}

	ngOnInit() {
		this.locationUpdateService.sendData(true);
		this.authStore.getUser().subscribe((user) => {
			this.stripe_subscription_can_subscribe = user.stripe_subscription_can_subscribe;

			this.stripe_customer_id = user.stripe_customer_id;
			this.stripe_subscription_id = user.stripe_subscription_id;
			this.stripe_subscription_status = user.stripe_subscription_status;
			if (this.stripe_subscription_can_subscribe && this.stripe_subscription_status !== 'active') {
				this.router.navigate(['/subscription']);
			}
		});

		this.route.params.pipe(first()).subscribe((params) => {
			this.store
				.fetchDonorById(params.id, 'short')
				.pipe(first())
				.subscribe({
					next: ([data]) => {
						this.store.setWatched(data.id);
						this.interested$ = this.parentService.isInterestedIn(data.id);
						this.hasChat$ = this.chatService.donorHasChat$(data.id);
						this.agency$ = this.store.getAgencyById(data.agency_id).subscribe((agencies) => {
							const r: Record<string, any> = this.utils.deepCopy(agencies[0]);
							for (const key of Object.keys(data)) {
								if (!data[key] && r[key]) continue;
								r[key] = this.utils.toHumanReadable(data[key]);
							}
							r.location = r.state ? `${r.state}, ${r.country}` : r.country;
							if (typeof r.occupation === 'string' && r.occupation.split(' ').length > 2) {
								this.occupationLong = true;
							}
							if (!r.siblings || r.siblings === '[1]') r.siblings = [];
							r.ethnicity = r.ethnicity?.join(', ') || '';
							r.race = r.race?.join(', ') || '';
							r.health_medical = {};
							for (const key of [
								{s: 'medical_problems_if_any', n: 'Medical Problems'},
								{s: 'mental_problems_if_any', n: 'Mental Problems'},
								{s: 'genetic_testing_completed', n: 'Genetic Testing'},
								{s: 'smoker', n: 'Smoker'},
								{s: 'tattoos', n: 'Tattoos'},
								{s: 'piercing', n: 'Piercing'},
								{s: 'alcohol', n: 'Alcohol'},
								{s: 'recreational_drugs', n: 'Recreational Drugs'},
								{s: 'any_transfusions', n: 'Transfusions'},
								{s: 'have_you_ever_been_refused_as_a_blood_donor', n: 'Refused as a Blood Donor'},
								{s: 'psychiatric_counseling', n: 'Psychiatric Counseling'},
								{s: 'psychiatric_hospitalization', n: 'Psychiatric Hospitalization'},
								{s: 'clinical_depression', n: 'Clinical Depression'},
								{s: 'clinical_diagnoses_with_add_or_hdhd', n: 'Clinically Diagnosed With ADD or ADHD?'},
								{s: 'recent_medications', n: 'Recent Medications'},
								{s: 'hiv_aids', n: 'HIV/AIDS'},
								{s: 'st_ds', n: 'STDs'},
							]) {
								if (r[key.s] && r[key.s] !== 'No' && r[key.s] !== '[1]') r.health_medical[key.n] = r[key.s];
							}
							if (r.number_of_miscarriages && r.number_of_miscarriages !== '[1]')
								r.health_medical['Number of Miscarriages'] = r.number_of_miscarriages;
							const relativeFields = [];
							for (const field of this.relativeFields) {
								let empty = true;
								for (const relative of this.relatives) {
									if (r[`${relative.s}_${field.s}`] && r[`${relative.s}_${field.s}`] !== '[1]') {
										empty = false;
										break;
									}
								}
								for (const sibling of r.siblings) {
									if (sibling[field.ss]) {
										empty = false;
										break;
									}
								}
								if (!empty) relativeFields.push(field);
							}
							this.relativeFields = relativeFields;
							const relatives = [];
							for (const relative of this.relatives) {
								let empty = true;
								for (const field of this.relativeFields) {
									if (r[`${relative.s}_${field.s}`] && r[`${relative.s}_${field.s}`] !== '[1]') {
										empty = false;
										break;
									}
								}
								if (!empty) relatives.push(relative);
							}
							if (r.siblings)
								r.siblings = r.siblings.filter((sibling) => Object.values(sibling).filter((item) => item).length > 1);
							this.relatives = [...relatives];
							this.donor = r;
							this.calculateTotals();
						});
						if (params.pdf === 'true') {
							this.forPDF = true;
						}
						this.images = data.images;
						this.checked$ = this.parentService.isInCompare(data.id, ProviderType.EGG_AGENCY);
						this.isInFavourite$ = this.parentService.isInFavourites(data.id, ProviderType.EGG_AGENCY);
						setTimeout(() => {
							if (this.videoRef) {
								this.videoRef.nativeElement.muted = true;
							}
							if (this.isSmallScreen) return;
							const header = document.querySelector('mat-tab-header');
							if (!header) return;
							const portalHost = new DomPortalOutlet(header, this.componentFactoryResolver, this.appRef, this.injector);
							const portal = new TemplatePortal(this.actionsTemplate, this._viewContainerRef);
							portalHost.attach(portal);
						}, 100);
					},
					error: (error: HttpErrorResponse) =>
						error.status === 404 ? console.warn(`#${params.id} not found`) : console.error(error),
				});
		});
		this.comparing$ = this.parentService.getCompare(ProviderType.EGG_AGENCY);
		window['get_origin'] = () =>
			console.log(
				`GSID :  ${this.donor.id}\nID   :  ${this.donor.origin_id}\nDID  :  ${this.donor.theirsId}\nLink :  ${this.donor.origin}`,
			);
		this.router.navigate(['./'], {fragment: '', relativeTo: this.route, skipLocationChange: true});
	}

	ngAfterViewInit() {
		Promise.resolve().then(() => {
			this.tabs = [
				{template: this.summary, title: 'About', id: 'about'},
				{template: this.services, title: 'Costs', id: 'costs'},
			];
			this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 812.99px)');
		});
		const updateHeight = () =>
			setTimeout(() => {
				try {
					this.tabsOffset = this.tabs.map((item) => document.getElementById(item.id).offsetTop - 140);
				} catch (e) {
					updateHeight();
				}
			}, 1000);
		updateHeight();
	}

	ngOnDestroy() {
		this.locationUpdateService.sendData(null);
		delete window['get_origin'];
		this._subscription.unsubscribe();
		this.agency$?.unsubscribe();
	}

	public readonly chunk = chunk;
	public readonly Object = Object;
	public readonly Math = Math;

	keysForTable(section: Record<string, any>[]) {
		const allKeys = [];

		const addKey = (k: string) => {
			if (!allKeys.includes(k)) {
				allKeys.push(k);
			}
		};

		section.map((row) => {
			if (row?.type === 'kvp') {
				row.content.map((c) => addKey(c.question));
				return;
			}
			Object.keys(row?.content || row).forEach((k) => {
				addKey(k);
			});
		});

		return orderBy(
			allKeys,
			(k) => {
				const idx = ['child', 'year of delivery'].indexOf(k.toLowerCase());
				if (idx === -1) {
					return 999999;
				}
				return idx;
			},
			'asc',
		);
	}

	splitSection(section: Record<string, any>, chunks: number): Record<string, any>[] {
		const sectionKeys = Object.keys(section).filter((k) => k !== 'table');
		const chunkKeys = chunk(sectionKeys, chunks);
		const objects = chunkKeys.map((c) => {
			const obj = {};
			c.forEach((key) => (obj[key] = section[key]));
			return obj;
		});
		return objects;
	}

	getCell(row: any, key: any) {
		if (row?.type === 'kvp') {
			return row.content.find((c) => c.question === key)?.answer;
		}

		return row.content[key];
	}
}

@Component({
	selector: 'app-sent',
	templateUrl: './sent.component.html',
})
export class SentComponent {}
