import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'includes',
})
export class IncludesPipe implements PipeTransform {
	transform(value: string, sought: string): boolean;
	transform<T>(value: T[], sought: T): boolean;
	transform<T extends any[] | string>(value: T, sought: T extends string ? string : T[0]): boolean {
		return value?.includes(sought) || false;
	}
}
