import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';

import {cloneDeep, isEqual} from 'lodash-es';

import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NIvfFilters} from '@/models/ivf.model';
import {IFilters, IvfService} from '@/screens/ivf/ivf.service';

interface SortByOption {
	value: NIvfFilters.IvfSort;
	label: string;
	order: 'ASC' | 'DESC';
}

const sortByOptions: SortByOption[] = [
	{
		value: NIvfFilters.IvfSort.JourneyCost,
		label: 'Lowest Costs',
		order: 'ASC',
	},
	{
		value: NIvfFilters.IvfSort.Location,
		label: 'Nearest To {{city}}, {{state}}',
		order: 'ASC',
	},
	{
		value: NIvfFilters.IvfSort.Success,
		label: 'Clinic Success Rate',
		order: 'DESC',
	},
	{
		value: NIvfFilters.IvfSort.BabiesBorn,
		label: 'Clinic Experience',
		order: 'DESC',
	},
	{
		value: NIvfFilters.IvfSort.Rating,
		label: 'Best Patient Ratings',
		order: 'DESC',
	},
	{
		value: NIvfFilters.IvfSort.Name,
		label: "Clinic's Name (A to Z)",
		order: 'ASC',
	},
	{
		value: NIvfFilters.IvfSort.Name,
		label: "Clinic's Name (Z to A)",
		order: 'DESC',
	},
];

@Component({
	selector: 'app-ivf-filters',
	templateUrl: './ivf-filters.component.html',
	styleUrls: ['./ivf-filters.component.scss'],
})
export class IvfFiltersComponent implements OnInit, OnDestroy {
	@Input()
	@HostBinding('class.withTabs')
	withTabs = false;

	sortByOptions = cloneDeep(sortByOptions);

	get filters() {
		return this.ivfService.state.filters;
	}

	get sortValue() {
		// return this.sortByOptions.find(({value, order}) => value === this.filters.sort && order === this.filters.order);
		return this.sortByOptions.find(({value, order}) => value === this.filters.sort && order === this.filters.order);
	}

	updates: Array<{id: keyof IFilters; value: any}> = [];
	update$ = new Subject<void>();

	constructor(private ivfService: IvfService) {}

	onUpdate(id: keyof IFilters, value: any) {
		this.updates.push({id, value});
		this.update$.next();
	}

	updateLocationSortLabel(state: string, city: string) {
		const locationSort = this.sortByOptions.find(({value}) => value === NIvfFilters.IvfSort.Location);
		const originalLocationSort = sortByOptions.find(({value}) => value === NIvfFilters.IvfSort.Location);

		locationSort.label = originalLocationSort.label.replace('{{city}}', city || '').replace('{{state}}', state || '');

		this.sortByOptions = this.sortByOptions.slice();
	}

	onMapDetails({country = '', city = '', state = '', zip = ''}) {
		this.onUpdate('country', country);
		this.onUpdate('state', state);
		this.onUpdate('city', city);
		this.onUpdate('zip', state && zip ? `${state} ${zip}` : zip);

		if ([country, city, state, zip].every((item) => item === '')) {
			this.onUpdate('coordinates', []);
		}

		this.updateLocationSortLabel(state, city);
	}

	onMapCoordinates([lng, lat]: [number, number]) {
		this.onUpdate('coordinates', [lng, lat]);
	}

	onSortChange(actualValue: SortByOption) {
		this.onUpdate('sort', actualValue.value);
		this.onUpdate('order', actualValue.order || 'DESC');
	}

	ngOnInit() {
		this.update$.pipe(debounceTime(400)).subscribe(() => {
			const updatedFilters = this.updates.reduce(
				(filters, update) => ({
					...filters,
					[update.id]: update.value,
				}),
				this.filters,
			);
			this.updates.length = 0;

			if (!isEqual(this.filters, updatedFilters)) {
				this.ivfService.updateLocalFilters(updatedFilters);
			}
		});
		this.updateLocationSortLabel(this.filters.state, this.filters.city);
	}

	ngOnDestroy() {
		this.update$.complete();
	}
}
