<button mat-icon-button (click)="clear()"><mat-icon>close</mat-icon></button>
<span [class.with-search]="withSearch">{{selectedLabel | join}}&nbsp;</span>
<input *ngIf="withSearch && empty" #searchInput [formControl]="searchControl">

<ng-template #dropdownPortal>
	<div class="options" [class.with-value]="!empty && withSearch" [style.maxHeight.px]="height">
		<input *ngIf="withSearch && !empty" [formControl]="searchControl" placeholder="Search">
		<cdk-virtual-scroll-viewport itemSize="48" [style.height.px]="height">
			<button
				mat-button
				*cdkVirtualFor="let option of filteredOptions$ | async"
				(click)="onSelect(option)"
			>
				<app-checkbox [size]="16" [value]="_value.has(option.value)">
					<label>{{option.label}}</label>
				</app-checkbox>
			</button>
		</cdk-virtual-scroll-viewport>
	</div>
</ng-template>
