import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';

import {filter, startWith, switchMap, tap} from 'rxjs/operators';

import {AdminService, CreatUserRequets, Role, User} from '@/services/admin.service';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, AfterViewInit {
	@ViewChild(MatSort)
	sort: MatSort;
	@ViewChild('createUserRef')
	createUserRef: TemplateRef<void>;
	@ViewChild('userCreatedRef')
	userCreatedRef: TemplateRef<void>;

	data: User[];

	displayedColumns = ['id', 'name', 'email', 'role', 'created_at', 'destroy'];

	isLoading = true;

	roleOptions = Object.entries(Role).map(([label, value]) => ({label, value}));

	constructor(private adminService: AdminService, private snack: MatSnackBar, private dialog: MatDialog) {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		this.sort.sortChange
			.pipe(
				startWith({}),
				tap(() => (this.isLoading = true)),
				switchMap(() => this.adminService.getAdminUser(this.sort.active, this.sort.direction)),
			)
			.subscribe(({users}) => {
				this.isLoading = false;
				this.data = users;
			});
	}

	updateRole(id: number, role: Role) {
		this.adminService.updateRole(id, role).subscribe({
			next: () => {
				this.snack.open('Role successfully updated');
				this.sort.sortChange.next(this.sort);
			},
			error: () => this.snack.open('Role was not updated'),
		});
	}

	openCreateUserDialog(data = {} as CreatUserRequets) {
		const dialogRef = this.dialog.open(this.createUserRef, {
			data,
			width: '600px',
			maxWidth: '100vw',
		});

		dialogRef
			.afterClosed()
			.pipe(
				filter((result) => !!result),
				switchMap(() => this.adminService.createUser(data)),
			)
			.subscribe({
				next: (response) => {
					if (response?.password) {
						this.dialog.open(this.userCreatedRef, {
							data: response,
						});
					} else {
						this.snack.open(`User already existed. Their role was updated to ${data.role}`);
					}
					this.sort.sortChange.next(this.sort);
				},
				error: () => {
					this.snack.open('User was not created');
					this.openCreateUserDialog(data);
				},
			});
	}

	async copyPassword(password: string) {
		if (navigator.clipboard) {
			await navigator.clipboard.writeText(password);
			this.snack.open('Password was copied to the clipboard');
		}
	}

	deleteUser(id: number) {
		const user = this.data.find((item) => item.id === id);
		const sure = confirm(`You are going to remove ${user.first_name} ${user.last_name}`);
		if (!sure) {
			return;
		}
		this.adminService.deleteUser(id).subscribe({
			next: () => {
				this.snack.open(`${user.first_name} ${user.last_name} removed successfully`);
				this.sort.sortChange.next(this.sort);
			},
			error: () => this.snack.open('User was not deleted'),
		});
	}
}
