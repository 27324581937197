<div class="data">
    <div class="nameSection">
        <a class="image" [routerLink]="profileLink">
            <img decoding="auto" [src]="data.img" />
        </a>

        <div class="name">
            <a [routerLink]="profileLink">
				{{ data.actualName[0] }}
			</a>
            <span *ngIf="data.sponsorshipPriority > 0"
                  class="sponsored mat-typography"
                  matTooltip="Sponsored Results feature healthcare providers who paid GoStork to appear higher in search listings."
            >
                Sponsored
            </span>
        </div>
        <div class="subname">{{ data.actualName[1] || '' }}</div>

        <div class="distance">
            <ng-container *ngIf="nearestLocation$ | async as nearestLocation">
                <div class="actualDistance">{{ nearestLocation.distance | number: (nearestLocation.distance
                    < 1 ? '1.0-2' : '1.0-0') }} Mi</div>
                        <div class="nearest">&nbsp;Nearest to {{ nearestLocation.text }}</div>
            </ng-container>

            <ng-container *ngTemplateOutlet="ratingContainer"></ng-container>
            </div>

            <div class="viewProfile">
                <a [routerLink]="profileLink">View Profile</a>
            </div>

            <div *ngIf="(locations$ | async)?.length > 1" class="others" (click)="openLocationsDropdown($event)">
                {{ (locations$ | async).length - 1 }} Other Locations
            </div>
        </div>

        <div class="mobileRating">
            <ng-container *ngTemplateOutlet="ratingContainer"></ng-container>
        </div>

        <div class="leftSection">
            <div class="results">
                <div class="title">The Clinic’s Results</div>
                <div class="subtitle">Based on Your Journey</div>

                <div class="resultsValue">
                    <app-numeric-group [label]="(isEggFreezing$ | async) ? 'Egg Freezing Cycles' : 'Babies Born'" [value]="data.mainNumberOfBabiesBorn"></app-numeric-group>
                    <ng-container *ngIf="(isEggFreezing$ | async) === false">
                        <div>{{ clinicSuccess === null ? 'No Data' : (clinicSuccess | number: '1.0-2') + '% Clinic Success Rate' }}</div>
                        <mat-progress-bar [value]="clinicSuccess"></mat-progress-bar>
                        <mat-progress-bar [value]="naionalSuccess$ | async"></mat-progress-bar>
                        <div>{{ naionalSuccess$ | async | number: '1.0-2' }}% National Success Rate</div>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="data.cost.length" class="cost">
                <div class="title">Your Estimated Costs</div>
                <div class="subtitle">Based on Your Journey</div>

                <div class="value">
                    <div *ngIf="data.cost.length === 2; else singleCost" class="costValue">
                        {{ data.cost[0] | currency: 'USD':'symbol':'1.0-1' }} to {{ data.cost[1] | currency: 'USD':'symbol':'1.0-1' }}
                    </div>
                    <ng-template #singleCost>
                        <div class="costValue">{{ data.cost[0] | currency: 'USD':'symbol':'1.0-1' }}</div>
                    </ng-template>

                    <a mat-flat-button color="primary" [routerLink]="profileLink"> View Cost Details </a>
                </div>
            </div>
        </div>

        <div class="rightSection">
            <div class="title">About The Clinic</div>

            <app-video [video]="data.video"></app-video>

            <div class="distanceLocation">
                <div *ngIf="nearestLocation$ | async as nearestLocation" class="distance">
                    <div class="actualDistance">
                        {{ nearestLocation.distance | number: (nearestLocation.distance
                        < 1 ? '1.0-2' : '1.0-0') }} Mi Away </div>
                            <div class="nearest">{{ nearestLocation.text }}</div>
                    </div>

                    <div *ngIf="(locations$ | async)?.length > 1" class="othersContainer" (click)="openLocationsDropdown($event)">
                        <div class="others">{{ (locations$ | async).length - 1 }} Other Locations</div>
                        <div class="viewAll">View All</div>
                    </div>
                </div>

                <div *ngIf="data.cost.length" class="cost">
                    <div class="title">Your Estimated Costs</div>
                    <div *ngIf="data.cost.length === 2; else singleCost" class="costValue">
                        {{ data.cost[0] | currency: 'USD':'symbol':'1.0-1' }} to {{ data.cost[1] | currency: 'USD':'symbol':'1.0-1' }}
                    </div>
                    <ng-template #singleCost>
                        <div class="costValue">{{ data.cost[0] | currency: 'USD':'symbol':'1.0-1' }}</div>
                    </ng-template>
                    <div class="subtitle">Based on Your Journey</div>
                    <a color="primary" [routerLink]="profileLink"> View Cost Details </a>
                </div>

                <a mat-stroked-button color="primary" class="viewFullProfile" [routerLink]="profileLink">View Full Clinic Profile</a>
            </div>
        </div>

        <div class="calendar" matTooltip="Unavailable, because clinic restricts maximun age of patients" matTooltipPosition="before" [matTooltipDisabled]="data.fitsAgeLimit" [class.disabled]="!data.fitsAgeLimit">
            <ng-container *ngIf="(isSmallScreen$ | async) === false">
                <div class="name">{{ (withCalendar$ | async) ? 'Schedule A Consult Call' : 'Chat with the Clinic' }}</div>
                <ng-container *ngIf="withCalendar$ | async; else chatTemplateRef">
                    <div class="calendarValue">
                        <app-calendar-list [contacts]="providerContacts$ | async"
                                           [locations]="locations$ | async"
                                           (onSelectTime)="onSelectTime($event)"
                                           (beforeCreateAppointment)="createAppointment($event)"></app-calendar-list>
                    </div>
                </ng-container>

                <ng-template #chatTemplateRef>
                    <mat-form-field appearance="outline" [class.success]="messageSuccess">
                        <textarea matInput rows="15" placeholder="Start chatting with this clinic today!" [(ngModel)]="message" (click)="onChatClick()"></textarea>
                    </mat-form-field>

                    <button mat-raised-button color="primary" (click)="openChat()">Chat With Clinic</button>
                </ng-template>
            </ng-container>

            <div class="actions">
                <!-- <mat-checkbox color="primary" [ngModel]="inCompare$ | async" (ngModelChange)="toggleInCompare()">Compare to Others </mat-checkbox> -->
                <div></div>
                <!-- removing this by Eran's Ask
                <app-heart matTooltip="Add to Favourites" [value]="inFav$ | async" (valueChange)="toggleInFav()"></app-heart>
				-->
            </div>

            <button *ngIf="data.fitsAgeLimit" mat-flat-button [color]="(withCalendar$ | async) ? 'primary' : 'accent'" class="invite" (click)="openMobileChat()">
		<mat-icon>{{ (withCalendar$ | async) ? 'schedule' : 'chat_bubble_outline' }}</mat-icon>
		<span>{{ (withCalendar$ | async) ? 'Schedule call with Clinic ' : 'Chat with Clinic' }}</span>
	</button>
        </div>

        <ng-template #locationsRef>
            <div class="locations">
                <div class="title">
                    <img src="assets/location-pin.png" />
                    <span>Nearby Clinic Locations</span>
                </div>

                <div class="items">
                    <app-location-entry *ngFor="let location of locations$ | async | slice: 1:5; let index = index" [location]="location" [index]="index + 1"></app-location-entry>
                </div>

                <a mat-button [routerLink]="profileLink">Show More</a>
            </div>
        </ng-template>

        <ng-template #ratingContainer>
            <div class="ratingContainer">
                <div class="rating">
                    <img src="assets/yellow-star.png" />
                    <span>
				{{ data.rating }} ({{ data.numberOfReviews | i18nPlural: {'=0': 'No Reviews', '=1': 'One Review', other: '# Reviews'} }})
			</span>
                </div>
                <div class="additions">
                    <img src="assets/international_parents.png" matTooltip="We are {{ data.internationalIntendedParents ? '' : 'not ' }}accepting International Parents" [class.disabled]="!data.internationalIntendedParents" />
                    <img src="assets/bipoc.svg" matTooltip="We {{ (data.services | includes: 'have_bipoc_physicians') ? 'have' : 'do not have' }} BIPOC physicians" [class.disabled]="!(data.services | includes: 'have_bipoc_physicians')" />
                    <img src="assets/LGBT_friendly.svg" matTooltip="We {{ data.lgbtFriendly ? 'provide' : 'do not provide' }} LGBTQ+-specific services" [class.disabled]="!data.lgbtFriendly" />
                    <img src="assets/hiv.svg" matTooltip="We {{ (data.services | includes: 'hiv_patients') ? 'have' : 'do not have' }} HIV patients" [class.disabled]="!(data.services | includes: 'hiv_patients')" />
                </div>
            </div>
        </ng-template>
