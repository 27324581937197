<h1 mat-dialog-title>Account verification</h1>
<form (submit)="onSubmit($event)">
	<div mat-dialog-content>
		<div *ngIf="loading; else editTemplate" class="loader">
			<mat-progress-spinner color="primary" mode="indeterminate" diameter="46"></mat-progress-spinner>
		</div>
		<ng-template #editTemplate>
			<p>We sent a verification code to <b>{{data.email}}</b>. Please enter it below.</p>
			<div class="fields">
				<mat-form-field
					*ngFor="let field of fields.controls"
				>
					<input
						#control
						matInput
						type="text"
						inputmode="numeric"
						autocomplete="one-time-code"
						data-lpignore="true"
						[formControl]="field"
						(keypress)="onKeypress($event)"
						(keyup)="onKeyup($event)"
						(focus)="onFocus($event)"
						(paste)="onPaste($event)">
				</mat-form-field>
			</div>
			<mat-error>{{error || '&#8203;'}}</mat-error>
			<div class="resend">
				<div *ngIf="(timer$ | async) as value; else resendActions" class="resend__timer">
					<mat-progress-bar
						[value]="(100 * value) / 60"
					></mat-progress-bar>
				</div>
				<ng-template #resendActions>
					<p>Didn't get a code?</p>
					<div class="resend__actions">
						<button type="button" (click)="resendCode('email')">Resend code</button>
						<button type="button" (click)="resendCode('phone')">Send via SMS</button>
					</div>
				</ng-template>
			</div>
		</ng-template>
	</div>
	<div mat-dialog-actions>
		<button *ngIf="!loading" mat-flat-button color="primary" type="submit" [disabled]="code.length < codeLength">Submit</button>
	</div>
</form>
