import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';

import {RouterModule} from '@angular/router';

import {PipesModule} from '@/pipes/pipes.module';

import {IvfFiltersModule} from '../ivf-filters/ivf-filters.module';
import {HeaderComponent} from './header.component';

@NgModule({
	declarations: [HeaderComponent],
	imports: [
		CommonModule,
		RouterModule,
		// lib
		MatToolbarModule,
		MatProgressSpinnerModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		MatBadgeModule,
		MatButtonModule,
		MatSelectModule,
		// local
		PipesModule,
		IvfFiltersModule,
	],
	exports: [HeaderComponent],
})
export class HeaderModule {}
