import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	Directive,
	ElementRef,
	EventEmitter, HostListener,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewChildren,
} from '@angular/core';

import {sum} from 'lodash';
import {animationFrameScheduler, fromEvent, Subscription} from 'rxjs';

import {auditTime, startWith} from 'rxjs/operators';

import {InfiniteSource} from '@/services/infinite.datasource';
import {ScrollerService} from './scroller.service';

@Directive({
	selector: '[appScrollerItem]',
})
export class ScrollerItemDirective {
	constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
	selector: 'app-scroller',
	templateUrl: './scroller.component.html',
	styleUrls: ['./scroller.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollerComponent implements AfterViewInit, OnDestroy {
	@ContentChild(ScrollerItemDirective, {static: true})
	itemTemplate: ScrollerItemDirective;

	@ViewChild('listRef', {static: true})
	listRef: ElementRef<HTMLElement>;

	@ViewChildren('rowRef')
	rowRef: QueryList<ElementRef<HTMLElement>>;

	@Input()
	itemWidth: number;

	@Input()
	paddingRight = 10;

	@Input()
	dataSource: InfiniteSource<unknown>;

	@Input()
	id: string;

	@Output()
	changeFilters = new EventEmitter<void>();

	readonly margin = 20;

	emptyRow = [];

	subscription = new Subscription();

	heights: number[] = [];

	triggerHeight = 0;

	data: unknown[][] = [];
	displayData: unknown[][] = [];

	padding = 0;
	startIndex = 0;
	displaySize = 16;

	lastFetchStartIndex = -1;

	lastScrollTop = 0;
	locked = false;
	isRestoring = false;

	resizeObserver = window.ResizeObserver
		? new ResizeObserver((entries) => {
				for (const entry of entries) {
					this.heights[this.startIndex + +(entry.target as HTMLElement).dataset.index] =
						(entry.target as HTMLElement).offsetHeight + this.margin;
				}
		  })
		: null;

	@Input()
	public set columns(value: number) {
		if (this.dataSource) {
			this.dataSource.columns = value;
		}
		this._columns = value;
		this.emptyRow = Array.from({length: value}).fill(null);
	}
	public get columns() {
		return this.dataSource.columns;
	}
	private _columns: number;

	constructor(
		private elementRef: ElementRef<HTMLElement>,
		private ngZone: NgZone,
		private changeDetectorRef: ChangeDetectorRef,
		private scrollerService: ScrollerService,
	) {}

	log(val?) { console.log(val); }

	@HostListener('window:resize')
	onResize() {
		this.locked = true;

		if (this.itemWidth) {
			this.columns = Math.floor(this.elementRef.nativeElement.offsetWidth / this.itemWidth);
		} else if (this._columns) {
			this.dataSource.columns = this._columns;
		}
		this.locked = false;
	}

	onScroll() {
		const direction = window.scrollY - this.lastScrollTop;
		this.lastScrollTop = window.scrollY;

		if (this.locked) {
			return;
		}

		const scrollTop = () => window.scrollY - this.padding;
		const someCountAvailable = () => this.dataSource.countFromSource > this.startIndex + this.displaySize + 1;

		const shouldRemoveAbove = () =>
			scrollTop() > this.triggerHeight && someCountAvailable() && direction > 0 && this.displayData.length > 1;
		const shouldRemoveBelow = () => this.startIndex && scrollTop() < this.triggerHeight && direction < 0;

		if (shouldRemoveAbove()) {
			this.locked = true;

			while (shouldRemoveAbove()) {
				const firstElement = this.rowRef.first?.nativeElement;
				if (firstElement) {
					this.resizeObserver?.unobserve(firstElement);
				}

				const delta = (this.heights[this.startIndex++] = firstElement.offsetHeight + this.margin);
				this.setDisplayData();

				if (firstElement) {
					firstElement.style.display = 'none';
				}

				this.padding += delta;
				this.setPadding();

				console.log('[ScrollerComponent] removing one above');
				this.unlock();
			}
			this.unlock();

		} else if (shouldRemoveBelow()) {
			this.locked = true;

			while (shouldRemoveBelow()) {
				const lastElement = this.rowRef.last?.nativeElement;
				if (lastElement) {
					this.resizeObserver?.unobserve(lastElement);
				}

				const delta = this.heights[--this.startIndex];
				this.setDisplayData();

				if (lastElement) {
					lastElement.style.display = 'none';
				}
				this.padding -= delta;
				this.setPadding();

				console.log('[ScrollerComponent] adding one above');
				this.unlock();
			}
			this.unlock();

		}

		const page = Math.round(this.startIndex / this.displaySize);
		const fetchStartIndex = page * this.displaySize;

		if (fetchStartIndex > this.lastFetchStartIndex) {
			this.dataSource.handleRange({
				start: fetchStartIndex,
				end: fetchStartIndex + this.displaySize,
			});
			this.lastFetchStartIndex = fetchStartIndex;
		}

		this.scrollerService.saveScrollPosition(this.id, {
			heights: this.heights,
			padding: this.padding,
			position: this.lastScrollTop,
			startIndex: this.startIndex,
		});
	}

	setDisplayData() {
		this.displayData = this.data.slice(this.startIndex, this.startIndex + this.displaySize + 1);
	}

	setPadding() {
		this.listRef.nativeElement.style.paddingTop = `${this.padding}px`;
	}

	unlock() {
		this.ngZone.run(() => {
			this.changeDetectorRef.detectChanges();
			setTimeout(() => (this.locked = false));
		});
	}

	ngAfterViewInit() {
		this.onResize();

		const exist = this.scrollerService.getScrollPosition(this.id);
		if (exist) {
			// this entire restoration procedure is bat$hit crazy, unfortunately due to time constraints, it had to be patched this way
			// rather than fixing all the other bugs which might exist with the scroller & datasource implementation.
			// I am sorry for the developer who will need to maintain this...
			this.isRestoring = true;
			this.locked = true;
			this.heights = exist.heights;
			this.padding = exist.padding;
			this.startIndex = exist.startIndex;
			this.lastScrollTop = exist.position;
			setTimeout(() => {
				this.dataSource.restore(this.scrollerService.getDataSource(this.id));
				this.data = this.dataSource.dataSource.value;
				// this is incase datasource is corrupted for some reason, so we fallback to maximum datasource length
				if (this.startIndex > this.data.length - 1) {
					this.startIndex = this.data.length - 1;
				}
				this.setDisplayData();
				this.setPadding();

				window.scrollTo({top: exist.position});
			});

			setTimeout(() => {
                this.unlock();
				window.scrollTo({top: exist.position});
				this.onScroll();
				this.isRestoring = false;
			}, 150)

			this.triggerHeight = sum([0, 1, 2].map((index) => this.rowRef.get(index)?.nativeElement.offsetHeight || 0 + this.margin));
			this.rowRef.forEach((item) => this.resizeObserver?.observe(item.nativeElement));
		} else {
			this.scrollerService.registerScroller(this.id, this.dataSource);
		}

		this.subscription.add(
			this.dataSource.refresh$.subscribe(() => {
				if (this.isRestoring) {
					return;
				}
				this.lastFetchStartIndex = -1;
				this.lastScrollTop = 0;
				window.scroll({top: 0});
				this.startIndex = 0;
				this.padding = 0;
				this.setPadding();
				this.setDisplayData();
				this.onScroll();
				this.changeDetectorRef.detectChanges();
			}),
		);

		this.subscription.add(
			this.dataSource.dataSource.subscribe((data) => {
				if (this.isRestoring) {
					return;
				}
				this.data = data;
				this.setDisplayData();
				this.onScroll();
				this.changeDetectorRef.detectChanges();
			}),
		);

		this.ngZone.runOutsideAngular(() =>
			Promise.resolve().then(() => {
				this.subscription.add(
					fromEvent(window, 'scroll')
						.pipe(startWith(null as Event), auditTime(0, animationFrameScheduler))
						.subscribe(() => this.onScroll()),
				);
			}),
		);

		this.subscription.add(
			this.rowRef.changes.subscribe(() => {
				this.triggerHeight = sum([0, 1, 2].map((index) => this.rowRef.get(index)?.nativeElement.offsetHeight || 0 + this.margin));

				this.rowRef.forEach((item) => this.resizeObserver?.observe(item.nativeElement));
			}),
		);

	}

	ngOnDestroy() {
		this.resizeObserver?.disconnect();
		this.subscription.unsubscribe();

		this.scrollerService.saveScrollPosition(this.id, {
			heights: this.heights,
			padding: this.padding,
			position: this.lastScrollTop,
			startIndex: this.startIndex,
		});

		this.scrollerService.setDataSource(this.id, this.dataSource);
	}
}
