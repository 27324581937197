<app-calendar-header #header [numberOfDaysToShow]="numberOfDays"></app-calendar-header>

<div class="rows">
	<div class="row" *ngFor="let row of _calendar; let i = index" [style.height.px]="buttonHeight * buttonsPerColumn - 14">
		<ng-container *ngIf="!row; else rowAvailable" class="empty-column">
			<div *ngFor="let _ of emptyList">
				<button mat-button disabled class="disabled" [class.loading]="isCalendarLoading">—</button>
			</div>
		</ng-container>
		<ng-template #rowAvailable>
			<div *ngIf="row.length === 0" class="ghost"></div>
			<div *ngFor="let cell of row">
				<button
					mat-button
					[title]="$any(cell) | timezoneDate: 'FULL':timezone"
					[disabled]="!cell"
					[class.disabled]="!cell"
					(click)="isRescheduling ? updateAppointment.next({date: cell, id: existingAppointment.id}) : cellClick.emit(cell)"
				>
					{{ cell ? (cell | timezoneDate: 'h:mm a': timezone) : '—' }}
				</button>
			</div>
			<button mat-button class="more-button" *ngIf="(row | trueLength) > buttonsPerColumn" [class.more]="isMoreActive" (click)="expand()">
				more
			</button>
		</ng-template>
	</div>
</div>

<div *ngIf="disableCalendar" class="exists">
	<div class="date">
		<div class="date__month">{{ appointmentTimeJS | timezoneDate: 'MMMM':timezone }}</div>
		<div class="date__day">{{ appointmentTimeJS | timezoneDate: 'd':timezone }}</div>
	</div>
	<div class="details__time">{{ appointmentTimeJS | timezoneDate: "MMMM d, yyyy 'at' h:mm a":timezone }}</div>
	<div class="details__location">
		<a rel="noopener" target="_blank" [href]="meetingLocation">{{ meetingLocation }}</a>
	</div>
	<div class="actions">
		<button mat-stroked-button color="accent" class="actions__reschedule" (click)="isRescheduling = true">
			<app-insvg name="reschedule" link="assets/reschedule.svg"></app-insvg>
			<span>Reschedule</span>
		</button>
		<button
			mat-icon-button
			color="warn"
			class="actions__cancel"
			(click)="deleteAppointment.next(existingAppointment.id); existingAppointment = null"
		>
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>
