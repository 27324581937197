<span [class.with-search]="withSearch">{{ selectedLabel }}&nbsp;</span>
<input *ngIf="withSearch && empty" #searchInput [formControl]="searchControl" />

<ng-template #dropdownPortal>
	<div class="options" [class.with-value]="!empty && withSearch" [style.maxHeight.px]="height">
		<input *ngIf="withSearch && !empty" [formControl]="searchControl" placeholder="Search" />
		<cdk-virtual-scroll-viewport itemSize="48" [style.height.px]="height">
			<ng-container *cdkVirtualFor="let option of filteredOptions$ | async">
				<div *ngIf="option.isGroup; else optionRef" class="options__group__label">{{ option.label }}</div>

				<ng-template #optionRef>
					<button
						mat-button
						[class.selected]="option.value === value"
						[class.options__group__item]="option.isChild"
						(click)="onSelect(option)"
					>
						<app-insvg *ngIf="option.icon" [name]="option.icon" [link]="option.icon"></app-insvg>
						<label [style.color]="option.color">{{ option.label }}</label>
					</button>
				</ng-template>
			</ng-container>
		</cdk-virtual-scroll-viewport>
	</div>
</ng-template>
