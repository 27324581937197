import {Component, ContentChild, HostBinding, Input, TemplateRef} from '@angular/core';
import {LazyContentDirective} from './lazy-content.directive';

@Component({
	selector: 'app-skeleton',
	templateUrl: './skeleton.component.html',
	styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
	@HostBinding('attr.show')
	@Input()
	public get show() {
		return this._show;
	}
	public set show(value) {
		this._show = value;
		this.render();
	}
	private _show = false;

	@HostBinding('style.width.px')
	@Input()
	public get width(): number {
		return this.show ? null : this._width;
	}
	public set width(value: number) {
		this._width = value;
	}
	private _width: number;

	@HostBinding('style.height.px')
	@Input()
	public get height(): number {
		return this.show ? null : this._height;
	}
	public set height(value: number) {
		this._height = value;
	}
	private _height: number;

	@ContentChild(LazyContentDirective, {read: TemplateRef, static: true})
	template: TemplateRef<any>;

	displayTemplate: TemplateRef<any>;

	constructor() {}

	render() {
		if (this.template && this.show) {
			if (!this.displayTemplate) {
				this.displayTemplate = this.template;
			}
		} else {
			this.displayTemplate = null;
		}
	}
}
