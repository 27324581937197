import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@/material.module';
import {WhenToStartComponent} from './when-to-start.component';

@NgModule({
	declarations: [WhenToStartComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
	exports: [WhenToStartComponent],
})
export class WhenToStartModule {}
