import {CheckoutModule} from '@/components/checkout/checkout.module';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {FormFieldModule} from '../../components/form-field/form-field.module';
import {MaterialModule} from '../../material.module';
import {UsersModule} from '../users/users.module';
import {ProfileParentComponent} from './profile-parent.component';

@NgModule({
	declarations: [ProfileParentComponent],
	imports: [CommonModule, FormsModule, MaterialModule, UsersModule, FormFieldModule, CheckoutModule],
})
export class ProfileParentModule {}
