import { Component } from '@angular/core';

@Component({
	selector: 'app-notfound',
	template: `
		<div class="w">
			<div class="h">404</div>
			<div class="s">Not Found</div>
		</div>
	`,
	styles: [`
		.w {
			color: #663898;
			display: flex;
			font-weight: 900;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			position: relative;
			text-align: center;
		}
		.h {
			font-size: 12em;
    		line-height: 1.3em;
		}
		.s {
			font-size: 32px;
			line-height: 32px;
			opacity: .8;
		}
	`]
})
export class NotFoundComponent {
	constructor() { }
}
