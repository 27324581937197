<ng-container *ngIf="form" [formGroup]="form">
	<div *ngIf="ivf$ | async as ivf" class="nameSection">
		<div class="image">
			<img decoding="auto" [src]="form.value.img" />
			<button mat-mini-fab class="uploadButton" (click)="fileInput.click()">
				<mat-icon>photo_camera</mat-icon>
			</button>
			<input #fileInput type="file" accept="image/jpeg,image/jpg,image/png" (change)="uploadImage(fileInput.files[0])" />
		</div>

		<div class="name">{{ ivf.actualName[0] }}</div>
		<div class="subname">{{ ivf.actualName[1] || '' }}</div>
	</div>

	<div class="title">About The Clinic</div>

	<section class="video">
		<app-video [video]="form.value.video"></app-video>

		<div class="actions">
			<button mat-stroked-button color="primary" class="uploadButton" (click)="openVideoDialog()">
				<mat-icon>ondemand_video</mat-icon>
				<span> Use Video From YouTube </span>
			</button>
			<button mat-stroked-button color="primary" class="uploadButton" (click)="videoFileInput.click()">
				<mat-icon>videocam</mat-icon>
				<span> Upload From File </span>
				<mat-progress-bar
					*ngIf="videoUploadingProgress"
					[value]="videoUploadingProgress"
					[mode]="videoUploadingProgress === 100 ? 'indeterminate' : 'determinate'"
				></mat-progress-bar>
			</button>
			<input #videoFileInput type="file" accept="video/mp4" (change)="uploadVideo(videoFileInput.files[0])" />

			<button mat-stroked-button *ngIf="form.value.video" color="warn" class="uploadButton" (click)="removeVideo()">Remove Video</button>
		</div>
	</section>

	<section>
		<mat-form-field appearance="outline">
			<textarea matInput formControlName="about" rows="5"></textarea>
		</mat-form-field>
	</section>

	<div class="title">What Makes Us Unique</div>
	<section>
		<mat-form-field appearance="outline">
			<textarea matInput formControlName="whatMakesUsUnique" rows="5"></textarea>
		</mat-form-field>
	</section>

	<div class="title">The Clinic Team</div>
	<div *ngIf="ivf$ | async as ivf" class="team">
		<mat-list [disableRipple]="true">
			<ng-container *ngFor="let member of teamForm.controls; let index = index">
				<mat-list-item (click)="updateSelectedTeam(index)">
					<img mat-list-icon class="teamIcon" [src]="member.value.img" />

					<div mat-line class="teamName">{{ member.value.name }}</div>
					<div mat-line class="teamPosition">{{ member.value.position }}</div>

					<div class="chevron">
						<mat-icon>{{ index === selectedTeamIndex ? 'expand_more' : 'chevron_right' }}</mat-icon>
					</div>
				</mat-list-item>

				<ng-container *ngIf="index === selectedTeamIndex" [ngTemplateOutlet]="teamDetails"></ng-container>
			</ng-container>
		</mat-list>

		<mat-divider></mat-divider>

		<button mat-button class="addMember" (click)="addTeamMemeber()">
			<mat-icon>add</mat-icon>
			<span>Add New Team Member</span>
		</button>
	</div>

	<div class="title">Available Clinic Services</div>
	<section>
		<div class="services">
			<ng-container formGroupName="services">
				<mat-checkbox *ngFor="let service of ivfServices" [formControlName]="service.value">
					{{ service.label }}
				</mat-checkbox>
			</ng-container>

			<mat-checkbox formControlName="lgbtFriendly">LGBTQ+ Patients</mat-checkbox>
			<mat-checkbox formControlName="internationalIntendedParents">International Patients</mat-checkbox>
		</div>

		<mat-divider></mat-divider>

		<div class="subtitle">Accepting Patient (Own Biology) With The Following Ages</div>

		<div class="ageGroup">
			<div class="label">Max Age for Female Patient Using Her Own Eggs</div>
			<mat-form-field>
				<input matInput type="number" inputmode="numeric" formControlName="maxAgeFemale" />
			</mat-form-field>

			<div class="label">Max Age for Male Patient Using His Own Sperm</div>
			<mat-form-field>
				<input matInput type="number" inputmode="numeric" formControlName="maxAgeMale" />
			</mat-form-field>

			<div class="label">Couple’s Combined Age</div>
			<mat-form-field>
				<input matInput type="number" inputmode="numeric" formControlName="combinedAge" />
			</mat-form-field>
		</div>

		<div class="subtitle">Accepting Patient (Using Donor Egg) With The Following Ages</div>

		<div class="ageGroup">
			<div class="label">Max Age for Female Patient Using Donor Egg</div>
			<mat-form-field>
				<input matInput type="number" inputmode="numeric" formControlName="maxAgeFemaleED" />
			</mat-form-field>

			<div class="label">Max Age for Male Patient Using Donor Sperm</div>
			<mat-form-field>
				<input matInput type="number" inputmode="numeric" formControlName="maxAgeMaleED" />
			</mat-form-field>

			<div class="label">Couple’s Combined Age</div>
			<mat-form-field>
				<input matInput type="number" inputmode="numeric" formControlName="combinedAgeED" />
			</mat-form-field>
		</div>
	</section>

	<div class="title">Accreditations & Memberships</div>
	<section>
		<mat-form-field>
			<mat-chip-list #weirdThingsList>
				<mat-chip *ngFor="let item of form.get('memberships').value" (removed)="removeCoreChip('memberships', item)">
					{{ item }}
					<button matChipRemove>
						<mat-icon>cancel</mat-icon>
					</button>
				</mat-chip>
				<input
					[matChipInputFor]="weirdThingsList"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					[matChipInputAddOnBlur]="true"
					(matChipInputTokenEnd)="addCoreChip('memberships', $event)"
				/>
			</mat-chip-list>
		</mat-form-field>
	</section>

	<div class="title">Research</div>
	<section>
		<mat-form-field appearance="outline">
			<textarea matInput formControlName="research" rows="5"></textarea>
		</mat-form-field>
	</section>
</ng-container>

<ng-template #teamDetails>
	<ng-container [formGroup]="selectedTeamForm">
		<div class="section">
			<div class="label">Photo</div>
			<button mat-stroked-button color="primary" class="uploadButton" (click)="teamFileInput.click()">
				<mat-icon>photo_camera</mat-icon>
				<span> Select Photo </span>
			</button>
			<input
				#teamFileInput
				type="file"
				class="fileInput"
				accept="image/jpeg,image/jpg,image/png"
				(change)="uploadImage(teamFileInput.files[0], true)"
			/>
		</div>

		<div class="section">
			<div class="label">Name</div>
			<mat-form-field>
				<input matInput formControlName="name" />
			</mat-form-field>
		</div>

		<div class="section">
			<div class="label">About</div>
			<mat-form-field>
				<textarea matInput formControlName="about" rows="5"></textarea>
			</mat-form-field>
		</div>

		<div class="section">
			<div class="label">Position</div>
			<mat-form-field>
				<input matInput formControlName="position" />
			</mat-form-field>
		</div>

		<div class="section">
			<div class="label">Languages</div>
			<app-form-field type="select" [multiple]="true" controlName="languages" [options]="languages$ | async"></app-form-field>
		</div>

		<div class="section">
			<div class="label">Board Certifications</div>
			<mat-form-field>
				<mat-chip-list #certificationsList>
					<mat-chip *ngFor="let item of selectedTeamForm.get('certifications').value" (removed)="removeTeamChip('certifications', item)">
						{{ item }}
						<button matChipRemove>
							<mat-icon>cancel</mat-icon>
						</button>
					</mat-chip>
					<input
						[matChipInputFor]="certificationsList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						[matChipInputAddOnBlur]="true"
						(matChipInputTokenEnd)="addTeamChip('certifications', $event)"
					/>
				</mat-chip-list>
			</mat-form-field>
		</div>

		<div class="section">
			<div class="label">Education</div>
			<mat-form-field>
				<mat-chip-list #educationList>
					<mat-chip *ngFor="let item of selectedTeamForm.get('education').value" (removed)="removeTeamChip('education', item)">
						{{ item }}
						<button matChipRemove>
							<mat-icon>cancel</mat-icon>
						</button>
					</mat-chip>
					<input
						[matChipInputFor]="educationList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						[matChipInputAddOnBlur]="true"
						(matChipInputTokenEnd)="addTeamChip('education', $event)"
					/>
				</mat-chip-list>
			</mat-form-field>
		</div>

		<div class="section">
			<div class="label">Professional Memberships</div>
			<mat-form-field>
				<mat-chip-list #membershipsList>
					<mat-chip *ngFor="let item of selectedTeamForm.get('memberships').value" (removed)="removeTeamChip('memberships', item)">
						{{ item }}
						<button matChipRemove>
							<mat-icon>cancel</mat-icon>
						</button>
					</mat-chip>
					<input
						[matChipInputFor]="membershipsList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						[matChipInputAddOnBlur]="true"
						(matChipInputTokenEnd)="addTeamChip('memberships', $event)"
					/>
				</mat-chip-list>
			</mat-form-field>
		</div>

		<div class="section">
			<div class="label">Research</div>
			<mat-form-field>
				<input matInput formControlName="research" />
			</mat-form-field>
		</div>

		<div class="section">
			<div class="label">Awards & Publications</div>
			<mat-form-field>
				<mat-chip-list #publicationsList>
					<mat-chip *ngFor="let item of selectedTeamForm.get('publications').value" (removed)="removeTeamChip('publications', item)">
						{{ item }}
						<button matChipRemove>
							<mat-icon>cancel</mat-icon>
						</button>
					</mat-chip>
					<input
						[matChipInputFor]="publicationsList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						[matChipInputAddOnBlur]="true"
						(matChipInputTokenEnd)="addTeamChip('publications', $event)"
					/>
				</mat-chip-list>
			</mat-form-field>
		</div>

		<div class="section">
			<button mat-stroked-button color="warn" class="removeMember" (click)="removeTeamMember(selectedTeamIndex)">Remove Team Member</button>
		</div>
	</ng-container>
</ng-template>

<ng-template #selectYoutubeVideoRef>
	<h2 mat-dialog-title>Use Video From YouTube</h2>

	<div mat-dialog-content class="selectYoutubeContent">
		<mat-form-field>
			<mat-label>Video Link</mat-label>
			<input #videoInput matInput type="url" placeholder="https://www.youtube.com/watch?v=XXXXXXXXXXX" />
		</mat-form-field>
	</div>

	<div mat-dialog-actions>
		<button mat-button [matDialogClose]="videoInput.value">Select</button>
	</div>
</ng-template>
