const DOMEvents = {
	UIEvent: 'abort DOMActivate error load resize scroll select unload',
	ProgressEvent: 'abort error load loadend loadstart progress progress timeout',
	Event: 'abort afterprint beforeprint cached canplay canplaythrough change chargingchange chargingtimechange checking close dischargingtimechange DOMContentLoaded downloading durationchange emptied ended ended error error error error fullscreenchange fullscreenerror input invalid languagechange levelchange loadeddata loadedmetadata noupdate obsolete offline online open open orientationchange pause pointerlockchange pointerlockerror play playing ratechange readystatechange reset seeked seeking stalled submit success suspend timeupdate updateready visibilitychange volumechange waiting',
	AnimationEvent: 'animationend animationiteration animationstart',
	AudioProcessingEvent: 'audioprocess',
	BeforeUnloadEvent: 'beforeunload',
	TimeEvent: 'beginEvent endEvent repeatEvent',
	OtherEvent: 'blocked complete upgradeneeded versionchange',
	FocusEvent: 'blur DOMFocusIn  Unimplemented DOMFocusOut  Unimplemented focus focusin focusout',
	MouseEvent: 'click contextmenu dblclick mousedown mouseenter mouseleave mousemove mouseout mouseover mouseup show',
	SensorEvent: 'compassneedscalibration Unimplemented userproximity',
	OfflineAudioCompletionEvent: 'complete',
	CompositionEvent: 'compositionend compositionstart compositionupdate',
	ClipboardEvent: 'copy cut paste',
	DeviceLightEvent: 'devicelight',
	DeviceMotionEvent: 'devicemotion',
	DeviceOrientationEvent: 'deviceorientation',
	DeviceProximityEvent: 'deviceproximity',
	MutationNameEvent: 'DOMAttributeNameChanged DOMElementNameChanged',
	MutationEvent: 'DOMAttrModified DOMCharacterDataModified DOMNodeInserted DOMNodeInsertedIntoDocument DOMNodeRemoved DOMNodeRemovedFromDocument DOMSubtreeModified',
	DragEvent: 'drag dragend dragenter dragleave dragover dragstart drop',
	GamepadEvent: 'gamepadconnected gamepaddisconnected',
	HashChangeEvent: 'hashchange',
	KeyboardEvent: 'keydown keypress keyup',
	MessageEvent: 'message message message message',
	PageTransitionEvent: 'pagehide pageshow',
	PopStateEvent: 'popstate',
	StorageEvent: 'storage',
	SVGEvent: 'SVGAbort SVGError SVGLoad SVGResize SVGScroll SVGUnload',
	SVGZoomEvent: 'SVGZoom',
	TouchEvent: 'touchcancel touchend touchenter touchleave touchmove touchstart',
	TransitionEvent: 'transitionend',
	WheelEvent: 'wheel'
};

const recentlyLogged = {};

let attached = false;

window['enable_events_logging'] = (value: boolean) => localStorage.setItem('enable_events_logging', value.toString());

export default function () {
	if (localStorage.getItem('enable_events_logging') !== 'true')
		return;
	if (attached)
		return;
	attached = true;

	let buffer = [];
	let db: IDBDatabase = null;

	for (const DOMEvent in DOMEvents) {
		const types = DOMEvents[DOMEvent].split(' ');
		for (const type of types) {
			const category = `${DOMEvent}::${type}`;
			document.addEventListener(type, (event) => {
				if (recentlyLogged[category])
					return;
				recentlyLogged[category] = true;
				setTimeout(() => recentlyLogged[category] = false);
				const record = {
					name: category,
					target: event.target instanceof HTMLElement
						? event.target.dataset.event
							|| event.target.id
							|| event.target.className
							|| event.target.nodeName
						: null,
					active: document.activeElement instanceof HTMLElement
						? document.activeElement.dataset.event
							|| document.activeElement.id
							|| document.activeElement.className
							|| document.activeElement.nodeName
						: null,
					isActive: event.target === document.activeElement
				};
				if (db) {
					const store = db.transaction(['events'], 'readwrite').objectStore('events');
					const count = store.count();
					count.onsuccess = () => {
						if (count.result > 100) {
							const keys = store.getAllKeys();
							keys.onsuccess = () => keys.result
								.slice(0, keys.result.length - 100)
								.forEach((key) => store.delete(key));
						}
						store
							.add(record)
							.onerror = console.error;
					}
				}
				else
					buffer.push(record)
			}, true);
		}
	}

	const openRequest = indexedDB.open('events', 3);
	openRequest.onerror = (error) => console.error(error);

	openRequest.onsuccess = () => {
		db = openRequest.result;

		window['list_events'] = () => {
			const request = db.transaction(['events'], 'readonly').objectStore('events').getAll()
			request.onsuccess = () => console.log(request.result);
		};

		window['clear_events'] = () => db.transaction(['events'], 'readwrite').objectStore('events').clear();

		buffer.forEach((item) => db.transaction(['events'], 'readwrite').objectStore('events').add(item).onerror = console.error);
		buffer = null;
	}

	openRequest.onerror = () => buffer = null;

	openRequest.onupgradeneeded = (event) => {
		const dbUpgrade = event.target['result'] as IDBDatabase;
		if (dbUpgrade.objectStoreNames.contains('events'))
			dbUpgrade.deleteObjectStore('events');
		const store = dbUpgrade.createObjectStore('events', { keyPath: 'id', autoIncrement: true });

		store.createIndex('name', 'name', { unique: false });
		store.createIndex('target', 'target', { unique: false });
		store.createIndex('active', 'active', { unique: false });
		store.createIndex('isActive', 'isActive', { unique: false });
	}
}
