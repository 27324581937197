<button mat-button class="back" (click)="closeDialog()">
	<mat-icon>chevron_left</mat-icon>
	<span>Back</span>
</button>

<div class="title">
	{{ data.withCalendar ? 'Schedule a Consultation Call' : 'Chat' }} <br *ngIf="data.withCalendar" />
	with {{ data.name }}
</div>

<app-calendar-list
	*ngIf="data.withCalendar; else createChatRef"
	#calendarListRef
	[contacts]="data.contacts"
	[locations]="data.locations"
	(beforeCreateAppointment)="createAppointment($event)"
></app-calendar-list>

<ng-template #createChatRef>
	<mat-form-field appearance="outline" [class.success]="messageSuccess">
		<textarea matInput rows="10" placeholder="Start chatting with this clinic today!" [(ngModel)]="message"></textarea>
	</mat-form-field>

	<button mat-raised-button color="primary" class="send" (click)="openChat()">Send Message</button>
</ng-template>

<div class="hint">{{ hintText }}</div>

<div class="help">Need Help?</div>

<div class="bg">
	<img src="assets/ivf/trees.svg" />
</div>
