<div class="ftu-dialog-mount"></div>
<div id="ftu-dialog-mount" [ngClass]="{ 'is-shown': isShown }">

    <div class="cdk-overlay-container">
        <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-backdrop-blur cdk-overlay-backdrop-showing"></div>

        <div class="cdk-global-overlay-wrapper">
    <div class="cdk-ftu-popup cdk-overlay-pane">
    <div class="concierge-ftu-content-container">
        <div class="dialog">
            <div class="concierge-ftu-content-cell">
                <div [ngSwitch]="viewState">
                    <div *ngSwitchCase="CalendarViewState.COMPLETE">
                        <h1 class="complete">Thank you for scheduling your free Concierge call!</h1>
                        <p>Following your call, you will have full access to our platform.</p>
                    </div>
                    <div *ngSwitchCase="CalendarViewState.CANCELLED">
                        <h1>Your call has been cancelled.</h1>
                        <p>Please reschedule a free concierge call - we are here to support you on your journey. Following that call, you will have full access to our database.</p>
                    </div>
                    <ng-container *ngSwitchDefault>
                        <h1>Let’s Book Your Free Concierge Call</h1>
                        <p>To start using our diverse database, get your questions answered and learn about how we can save you thousands of dollars, please select a time to speak with a GoStork Concierge.</p>
                    </ng-container>
                </div>

            </div>
            <div class="concierge-ftu-content-cell">
                <div [ngSwitch]="viewState" class="calendar-container">
                    <ng-container *ngSwitchCase="CalendarViewState.SCHEDULE" [ngTemplateOutlet]="schedule"></ng-container>
                    <ng-container *ngSwitchCase="CalendarViewState.CANCELLED" [ngTemplateOutlet]="schedule"></ng-container>

                    <ng-container *ngSwitchCase="CalendarViewState.CONFIRM">
                        <h2>Book Your Free Call with GoStork</h2>
                        <div *ngIf="bookingCalendar$ | async as bookingCalendar" class="name">
                            <b> {{ bookingCalendar.first_name }} {{ bookingCalendar.last_name }} </b>
                        </div>

                        <mat-form-field appearance="outline" class="area" (click)="viewState = CalendarViewState.SCHEDULE">
                            <mat-label>Date & Time</mat-label>
                            <input matInput [value]="bookingTime.toJSDate() | timezoneDate: 'EEEE, MMMM d \'at\' h:mm a': timezone" required />
                            <span matSuffix>Edit</span>
                        </mat-form-field>
                        <button mat-flat-button color="primary" class="confirm" [disabled]="loading" (click)="confirmAppointment()">{{ loading ? "Scheduling..." : "Confirm"}}</button>
                    </ng-container>

                    <div *ngSwitchCase="CalendarViewState.COMPLETE" >
                        <div class="exists">
                            <div class="date">
                                <div class="date__month">{{ appointmentTimeJS | timezoneDate: 'MMMM':timezone }}</div>
                                <div class="date__day">{{ appointmentTimeJS | timezoneDate: 'd':timezone }}</div>
                            </div>
                            <div>
                                <strong>Here are your call details:</strong>
                                <ng-container *ngIf="bookingCalendar$ | async as bookingCalendar">
                                    <div >
                                        Concierge: {{ bookingCalendar.first_name }} {{ bookingCalendar.last_name }}
                                    </div>
                                    <div class="details__time">{{ appointmentTimeJS | timezoneDate: "MMMM d, yyyy 'at' h:mm a ZZZZ":timezone }}</div>
                                    <div class="details__location">
                                        <a rel="noopener" target="_blank" [href]="bookingCalendar.location_of_meeting">{{ bookingCalendar.location_of_meeting }}</a>
                                    </div>
                                </ng-container>
                                <div class="actions">
                                    <button mat-stroked-button color="accent" class="actions__reschedule" (click)="reschedule()">
                                        <app-insvg name="reschedule" link="assets/reschedule.svg"></app-insvg>
                                        <span>Reschedule</span>
                                    </button>

                                    <button mat-stroked-button color="warn" class="actions__cancel" (click)="cancelAppointment()">
                                        <mat-icon>close</mat-icon>

                                        <span>Cancel</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>

<ng-template #calendarRef>
    <div class="concierge-ftu-content-container">
        <div class="concierge-ftu-content-cell">
            <div [ngSwitch]="viewState">
                <div *ngSwitchCase="CalendarViewState.COMPLETE">
                    <h1 class="complete">Thank you for scheduling your free Concierge call!</h1>
                    <p>Following your call, you will have full access to our platform.</p>
                </div>
                <div *ngSwitchCase="CalendarViewState.CANCELLED">
                    <h1>Your call has been cancelled.</h1>
                    <p>Please reschedule a free concierge call - we are here to support you on your journey. Following that call, you will have full access to our database.</p>
                </div>
                <ng-container *ngSwitchDefault>
                    <h1>Let’s Book Your Free Concierge Call</h1>
                    <p>To start using our diverse database, get your questions answered and learn about how we can save you thousands of dollars, please select a time to speak with a GoStork Concierge.</p>
                </ng-container>
            </div>

        </div>
        <div class="concierge-ftu-content-cell">
            <div [ngSwitch]="viewState" class="calendar-container">
                <ng-container *ngSwitchCase="CalendarViewState.SCHEDULE" [ngTemplateOutlet]="schedule"></ng-container>
                <ng-container *ngSwitchCase="CalendarViewState.CANCELLED" [ngTemplateOutlet]="schedule"></ng-container>

                <ng-container *ngSwitchCase="CalendarViewState.CONFIRM">
                    <h2>Book Your Free Call with GoStork</h2>
                    <div *ngIf="bookingCalendar$ | async as bookingCalendar" class="name">
                        <b> {{ bookingCalendar.first_name }} {{ bookingCalendar.last_name }} </b>
                    </div>

                    <mat-form-field appearance="outline" class="area" (click)="viewState = CalendarViewState.SCHEDULE">
                        <mat-label>Date & Time</mat-label>
                        <input matInput [value]="bookingTime.toJSDate() | timezoneDate: 'EEEE, MMMM d \'at\' h:mm a': timezone" required/>
                        <span matSuffix>Edit</span>
                    </mat-form-field>
                    <button mat-flat-button color="primary" class="confirm" [disabled]="loading" (click)="confirmAppointment()">{{ loading ? "Scheduling..." : "Confirm" }}</button>
                </ng-container>


                <div *ngSwitchCase="CalendarViewState.COMPLETE" >
                    <div class="exists">
                        <div class="date">
                            <div class="date__month">{{ appointmentTimeJS | timezoneDate: 'MMMM':timezone }}</div>
                            <div class="date__day">{{ appointmentTimeJS | timezoneDate: 'd':timezone }}</div>
                        </div>
                        <div>
                            <strong>Here are your call details:</strong>
                            <ng-container *ngIf="bookingCalendar$ | async as bookingCalendar">
                                <div >
                                    Concierge: {{ bookingCalendar.first_name }} {{ bookingCalendar.last_name }}
                                </div>
                                <div class="details__time">{{ appointmentTimeJS | timezoneDate: "MMMM d, yyyy 'at' h:mm a ZZZZ":timezone }}</div>
                                <div class="details__location">
                                    <a rel="noopener" target="_blank" [href]="bookingCalendar.location_of_meeting">{{ bookingCalendar.location_of_meeting }}</a>
                                </div>
                            </ng-container>
                            <div class="actions">
                                <button mat-stroked-button color="accent" class="actions__reschedule" (click)="reschedule()">
                                    <app-insvg name="reschedule" link="assets/reschedule.svg"></app-insvg>
                                    <span>Reschedule</span>
                                </button>

                                <button mat-stroked-button color="warn" class="actions__cancel" (click)="cancelAppointment()">
                                    <mat-icon>close</mat-icon>

                                    <span>Cancel</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #schedule>
    <h2>Book Your Free Call with GoStork</h2>
    <div *ngFor="let config of calendarConfigs$ | async; let index = index">
        <div class="name">
            <b> {{ config.first_name }} {{ config.last_name }} </b>
        </div>

        <div *ngIf="config.duration_of_meetings.length > 1" class="duration">
            <div>
                <b>How long do you need?</b>
            </div>
            <mat-button-toggle-group [value]="duration" (change)="duration = $event.value">
                <mat-button-toggle *ngFor="let d of durations[index]" [value]="d.value">
                    {{ d.label }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <app-calendar forceMobile [adminConfig]="config" [duration]="duration" [calendarId]="config.calendar_id" (cellClick)="onTimeSelect($event, config.calendar_id)" (timezoneChange)='onChangeTimezone($event)'></app-calendar>
    </div>
</ng-template>
