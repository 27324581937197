import {Overlay} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {DateTime} from 'luxon';
import {BehaviorSubject, combineLatest, Subject, Subscription} from 'rxjs';
import {filter, first, map, shareReplay, startWith, switchMap, tap} from 'rxjs/operators';

import {AppointmentEvent, CalendarListComponent} from '@/components/calendar/calendar-list/calendar-list.component';
import {MobileChatComponent} from '@/components/mobile-chat/mobile-chat.component';
import {ProviderType} from '@/config';
import {getCSSVar} from '@/helpers/getCssVar';
import {ServiceMessageType} from '@/models/chat.model';
import {NIvfFilters} from '@/models/ivf.model';
import {ChatService} from '@/services/chat.service';
import {ContactAgencyService} from '@/services/contact-agency.service';
import {ParentStoreService} from '@/services/parent.service';
import {UtilsService} from '@/services/utils.service';
import {IvfService} from '../ivf.service';
import {AnalyticsService} from "@/services/analytics.service";

@Component({
	selector: 'app-ivf-card',
	templateUrl: './ivf-card.component.html',
	styleUrls: ['./ivf-card.component.scss'],
})
export class IvfCardComponent implements OnChanges, OnDestroy {
	@ViewChild(CalendarListComponent)
	calendarList: CalendarListComponent;

	@ViewChild('locationsRef')
	locationsRef: TemplateRef<void>;

	@Input()
	public set data(value: Record<string, any>) {
		value = this.ivfService.prepareImage(value);
		this._data = value;
		this.profileLink = value && this.ivfService.createProfileLink(value);
		this.ivfService.prepareName(value);
		this.ivfService.prepareCost(value);
	}
	public get data(): Record<string, any> {
		return this._data;
	}

	@Input()
	public set coordinates(value: [number, number]) {
		this._coordinates.next(value);
	}
	public get coordinates(): [number, number] {
		return this._coordinates.value;
	}

	@Output()
	expand = new EventEmitter<boolean>();

	profileLink = '';
	messageSuccess = false;

	refresh = new Subject<void>();
	refresh$ = this.refresh.pipe(startWith(null));

	inCompare$ = this.refresh$.pipe(switchMap(() => this.parentService.isInCompare(this.data.id, ProviderType.IVF_CLINIC)));
	inFav$ = this.refresh$.pipe(switchMap(() => this.parentService.isInFavourites(this.data.id, ProviderType.IVF_CLINIC)));
	locations$ = this.refresh$.pipe(
		switchMap(() => this._coordinates),
		switchMap((coordinates) => this.ivfService.getDistances(this.data.id, coordinates)),
		map((location) => [...location].sort((a, b) => a.distance - b.distance)),
		tap((location) => (this.data.location = location)),
		map(
			(locations) =>
				locations.map((item) => ({
					...item,
					text: [item.city, item.state].filter(Boolean).join(', '),
					distance: item.distance / 1609,
				})) || [],
		),
		shareReplay(1),
	);
	nearestLocation$ = this.locations$.pipe(
		map((locations) => (locations.length ? locations[0] : null)),
		shareReplay(1),
	);

	isEggFreezing$ = this.ivfService.state$.pipe(
		map((state) => state.filters?.questionnaire?.buildingFamily === NIvfFilters.BuildingFamily.Freezing),
		shareReplay(1),
	);

	filtersSubscription: Subscription;

	get clinicSuccess() {
		return this.data?.mainSuccessRate === null ? null : (this.data?.mainSuccessRate || 0) * 100;
	}

	get naionalSuccess$() {
		return this.nationalData$.pipe(map((data) => (data?.mainSuccessRate || 0) * 100));
	}

	providerContacts$ = this.refresh$.pipe(
		filter(() => !!this.data.provider?.id),
		map(() => this.data.provider.provider_contacts),
		map((contacts) => contacts.filter((item) => item.calendar_id && item.show_calendar)),
	);
	withCalendar$ = this.providerContacts$.pipe(
		map((contacts) => !!contacts.length),
		shareReplay(1),
	);

	nationalData$ = this.ivfService.getNational();

	isSmallScreen$ = this.utils.isSmallScreen;

	message = '';

	private _coordinates = new BehaviorSubject<[number, number]>(null);
	private _data: Record<string, any>;

	constructor(
		private utils: UtilsService,
		private parentService: ParentStoreService,
		private ivfService: IvfService,
		private router: Router,
		private chatService: ChatService,
		private analyticsService: AnalyticsService,
		private overlay: Overlay,
		private viewContainerRef: ViewContainerRef,
		private dialog: MatDialog,
	) {}

	openMobileChat() {
		if (this.data.chat?.id) {
			this.router.navigate(['/', 'chat', this.data.chat.id]);
		} else {
			const headerHeight = getCSSVar('--header-height');
			combineLatest([this.withCalendar$, this.providerContacts$, this.locations$])
				.pipe(first())
				.subscribe(([withCalendar, contacts, locations]) =>
					this.dialog.open(MobileChatComponent, {
						height: `calc(100vh - ${headerHeight})`,
						width: '100vw',
						maxWidth: '100vw',
						position: {
							top: headerHeight,
						},
						data: {
							name: this.data.name,
							providerId: this.data.provider.id,
							type: ProviderType.IVF_CLINIC,
							withCalendar,
							contacts,
							locations,
						},
						hasBackdrop: false,
						panelClass: 'dialog-no-padding',
						autoFocus: false,
						closeOnNavigation: true,
					}),
				);
		}
	}

	openChat() {
		if (this.data.chat?.id) {
			this.router.navigate(['/', 'chat', this.data.chat.id]);
		} else {
			this.chatService.createChat2(ProviderType.IVF_CLINIC, this.data.provider.id, this.message).subscribe(() => {
                this.analyticsService.emit('User Area', 'IVF Search', 'CTA - Chat - Clinic - Success');
                this.messageSuccess = true;
			});
			this.message = '';
		}
	}

	createAppointment({time, calendarId, location}: AppointmentEvent) {
		this.chatService
			.createChat2(
				ProviderType.IVF_CLINIC,
				this.data.provider.id,
				ContactAgencyService.buildServiceMessage(ServiceMessageType.CreatedAppointment, {
					time: time.toISO(),
					calendarId,
				}),
				location,
			)
			.subscribe(({chat}) => {
				this.analyticsService.emit('User Area', 'IVF Search', 'CTA - Schedule call - Clinic - Success');

				this.calendarList.dealId = chat.deal_id;
				this.calendarList.onAppointmentConfirm(true);
			});
	}

	toggleInCompare() {
		this.parentService.toggleInCompare(this.data.id, ProviderType.IVF_CLINIC);
	}

	toggleInFav() {
		this.parentService.toggleInFavourites(this.data.id, ProviderType.IVF_CLINIC);
	}

	onSelectTime({ time, calendarId }) {
		this.analyticsService.emit('User Area', 'IVF Search', 'CTA - Schedule call - Clinic');
	}

	openLocationsDropdown(event: MouseEvent) {
		const target = event.target as HTMLElement;

		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(target)
			.withDefaultOffsetY(20)
			.withPositions([
				{
					originX: 'start',
					overlayX: 'start',
					originY: 'bottom',
					overlayY: 'top',
				},
			]);
		const overlayRef = this.overlay.create({
			positionStrategy,
			backdropClass: 'cdk-overlay-greenish-backdrop',
			hasBackdrop: true,
			disposeOnNavigation: true,
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
		});
		overlayRef.attach(new TemplatePortal(this.locationsRef, this.viewContainerRef));

		overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
	}

	onChatClick() {
		this.analyticsService.emit('User Area', 'IVF Search', 'CTA - Chat - Clinic');
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('data' in changes) {
			this.refresh.next();
		}
	}

	ngOnDestroy() {
		this.filtersSubscription?.unsubscribe();
	}
}
