import {HttpClient} from '@angular/common/http';
import {Injectable, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BehaviorSubject, Observable, of, Subscription, timer} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {WebSocketSubject} from 'rxjs/webSocket';
import {LocationBase} from '@/models/common.model';
import {SurrogatesStoreService} from '@/services/surrogates.service';
import {ProviderType} from '../config';

import {ServiceMessageType} from '../models/chat.model';
import {AuthStoreService} from './auth.service';
import {ContactAgencyService, ProviderContactRole} from './contact-agency.service';
import {Deal, DealsService} from './deals.service';

import {DonorsStoreService} from './donors.service';

const ServerMessageTypeReversed = Object.entries(ServiceMessageType).reduce(
	(obj, [key, value]) => ((obj[value] = key), obj),
	{} as Record<ServiceMessageType, string>,
);

const keepAliveIntervalMs = 30_000;

export enum Author {
	USER = 'user',
	PROVIDER = 'provider',
	SYSTEM = 'system',
	SUPPORT = 'support',
}

export interface ChatContact {
	id: number;
	first_name: string;
	last_name: string;
	calendar_id: string;
	show_calendar: boolean;
	role: ProviderContactRole[];
	location: LocationBase[];
}

export interface Chat {
	id: string;
	user_id: number;
	provider_id: number;
	deal_id: number;
	egg_donor_id: number | null;
	surrogate_id: number | null;
	lastMessage?: Observable<Message>;
	created_at: Date;
	user: {
		first_name: string;
		last_name: string;
	};
	provider_contact: {
		first_name: string;
		last_name: string;
	};
	provider: {
		company_name: string;
		provider_contacts?: ChatContact[];
		surrogacy?: {
			img: string;
		};
		ivf?: {
			id: number;
			img: string;
			name: string;
		};
	};
	deal: Deal;
	egg_donor?: {
		img: string;
	};
	surrogate?: {
		img: string;
	};
	messages: [Message];

	title?: string;
	subtitle?: string;
	img?: string;
	isSupportChat: boolean;
	location: LocationBase;
}

export interface Message {
	id: string;
	chat_id: Chat['id'];
	text: string;
	attachments: string | null;
	attachmentsType: AttachmentType | null;
	read: boolean;
	notified: boolean;
	reaction: boolean;
	created_at: string | Date;
	author: Author;
	type: ServiceMessageType | null;
	typeData: Record<string, any>;
	parsed?: boolean;
	status?: string;
	last?: boolean;
}

export enum ServerMessageType {
	Message,
	Notification,
	File,
	Read,
	UnreadCount,
	DealStatus,
	React,
}

export enum ClientMessageType {
	Subscribe,
	Send,
	Read,
	React,
}

export interface SocketEvent {
	type: ServerMessageType | ClientMessageType;
	data: any;
}

export interface ServerFile {
	path: string;
	chatId: string;
	filename: string;
	mimetype: string;
	error: string;
}

export enum AttachmentType {
	IMAGE,
	VIDEO,
	DOC,
}

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	public unreadMessages$ = new BehaviorSubject<number>(0);
	public lastOpened: Chat['id'] = null;

	public socketState = new BehaviorSubject(false);

	file$ = new BehaviorSubject<ServerFile>(null);

	_chats = new BehaviorSubject<Chat[]>([]);
	chats$ = this._chats.pipe(
		map((chats) =>
			chats.map((chat) => ({
				...chat,
				provider: {
					...chat.provider,
					original_contacts: chat.provider?.provider_contacts || [],
					provider_contacts: chat.isSupportChat
						? chat.provider?.provider_contacts
						: chat.provider?.provider_contacts?.filter(
								(contact) =>
									contact.role.includes(this.dealService.dealTypeToContacyRole(chat.deal.type)) ||
									contact.role.includes(ProviderContactRole.OWNER),
						  ),
				},
				lastMessage: this._messages.get(chat.id).pipe(map((messages) => messages[messages.length - 1])),
			})),
		),
		map((chats) =>
			chats.sort((a, b) =>
				a.messages && b.messages ? new Date(b.messages[0].created_at).getTime() - new Date(a.messages[0].created_at).getTime() : 0,
			),
		),
	);

	private _messages = new Map<Chat['id'], BehaviorSubject<Message[]>>();
	private _loadingState: Record<
		Chat['id'],
		{
			finished: boolean;
			pagesInProgress: Record<string, Observable<{count: number; messages: Message[]}>>;
		}
	> = {};

	private _socketSub: Subscription;
	private keepAlive: Subscription;
	get socketSub() {
		return this._socketSub;
	}
	set socketSub(sub: Subscription) {
		if (this._socketSub && !this._socketSub.closed) {
			this._socketSub.unsubscribe();
		}
		this._socketSub = sub;
	}
	private _socket: WebSocketSubject<SocketEvent>;
	get socket() {
		return this._socket;
	}
	set socket(value: WebSocketSubject<SocketEvent>) {
		this._disconnect();
		this._socket = value;
	}

	constructor(
		private http: HttpClient,
		private auth: AuthStoreService,
		private dealService: DealsService,
		private donorsStore: DonorsStoreService,
		private surrogatesStore: SurrogatesStoreService,
		private domSanitizer: DomSanitizer,
	) {
		this.auth.token.subscribe((token) => (token ? this._connect(token) : this._disconnect()));
	}

	loadChats() {
		return this.http.get<{chats: Chat[]; unreadCount: number}>('/api/m/chat').pipe(
			tap((res) => {
				for (const chat of res.chats) {
					let messages = this._messages.get(chat.id);
					if (!messages) {
						messages = new BehaviorSubject([]);
					}
					const parsedMessages = chat.messages.map((message) => this._parseMessage(message, res.chats));
					messages.next(parsedMessages);
					chat.messages = parsedMessages as [Message];
					this._messages.set(chat.id, messages);

					chat.isSupportChat = !(chat.provider_id && chat.user_id);
				}
				this._chats.next(res.chats);
				this.unreadMessages$.next(res.unreadCount);
			}),
		);
	}

	loadMessages(chatId: string, update = false, count = 20) {
		const state = this._getLoadingState(chatId);

		const savedMessages = this._messages.get(chatId);
		if (state.finished) {
			return of({messages: []});
		}

		const lastMessage = (update ? savedMessages.value[0] : savedMessages.value[savedMessages.value.length - 1]) || '';
		const cursor =
			lastMessage && btoa(lastMessage.created_at instanceof Date ? lastMessage.created_at.toISOString() : lastMessage.created_at);
		const stateKey = `${cursor}-${count}`;
		if (!state.pagesInProgress[stateKey]) {
			state.pagesInProgress[stateKey] = this.http
				.get<{count: number; messages: Message[]}>(`/api/m/chat/${chatId}`, {
					params: {
						cursor,
						limit: count.toString(),
					},
				})
				.pipe(
					map(({messages}) => {
						state.finished = !messages.length;
						const parsed = messages.map((item) => this._parseMessage(item));
						const completeList = [...savedMessages.value, ...parsed];
						const filteredList = completeList.filter(
							(item, index) => index === completeList.findIndex((value) => item.id === value.id),
						);
						filteredList.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
						savedMessages.next(filteredList);
						return {
							count,
							messages: filteredList,
						};
					}),
				);
		}
		return state.pagesInProgress[stateKey];
	}

	loadProviderContacts(chatId: string) {
		const chat = this._chats.value.find((item) => item.id === chatId);
		if (!chat.provider || Array.isArray(chat.provider.provider_contacts)) {
			return of();
		}
		return this.http.get<{contacts: ChatContact[]}>(`/api/m/chat/${chatId}/contacts`).pipe(
			tap(({contacts}) => {
				const index = this._chats.value.findIndex((item) => item.id === chatId);
				const chats = this._chats.value.slice();
				chats[index].provider.provider_contacts = contacts;
				this._chats.next(chats);

				const messages = this._messages.get(chatId);
				if (messages) {
					messages.next(messages.value.map((item) => this._parseMessage(item)));
				}
			}),
		);
	}

	createChat(
		providerId: number,
		messageText: string,
		props: {eggDonorId: number | null; surrogateId: number | null} = {eggDonorId: null, surrogateId: null},
	) {
		const {eggDonorId, surrogateId} = props;
		return this.http
			.post<{chat: Chat; message: Message}>('/api/m/chat', {
				providerId,
				eggDonorId,
				surrogateId,
				message: messageText,
				dealType: eggDonorId ? ProviderType.EGG_AGENCY : ProviderType.SURROGACY_AGENCY,
			})
			.pipe(
				tap(({chat, message}) => {
					const existsIndex = this._chats.value.findIndex((item) => item.id === chat.id);
					chat.isSupportChat = !(chat.provider_id && chat.user_id);
					if (existsIndex !== -1) {
						const chats = this._chats.value.slice();
						chats.splice(existsIndex, 1, chat);
						this._chats.next(chats);
						this.handleMessage(message);
					} else {
						this._messages.set(chat.id, new BehaviorSubject([this._parseMessage(message)]));
						this._chats.next([chat, ...this._chats.value]);
					}
				}),
			);
	}

	createChat2(type: ProviderType, providerId: number, messageText: string, location?: LocationBase) {
		return this.http
			.post<{chat: Chat; message: Message}>('/api/m/chat', {
				providerId,
				eggDonorId: null,
				message: messageText,
				dealType: type,
				location: location || null,
			})
			.pipe(
				tap(({chat, message}) => {
					const existsIndex = this._chats.value.findIndex((item) => item.id === chat.id);
					chat.isSupportChat = !(chat.provider_id && chat.user_id);
					if (existsIndex !== -1) {
						const chats = this._chats.value.slice();
						chats.splice(existsIndex, 1, chat);
						this._chats.next(chats);
						this.handleMessage(message);
					} else {
						this._messages.set(chat.id, new BehaviorSubject([this._parseMessage(message)]));
						this._chats.next([chat, ...this._chats.value]);
					}
				}),
			);
	}

	createSupportChat(to: Author, receiverId: number, messageText: string) {
		return this.http
			.post<{chat: Chat; message: Message}>('/api/v2/admin/createChat', {
				to,
				receiverId,
				message: messageText,
			})
			.pipe(
				tap(({chat, message}) => {
					const existsIndex = this._chats.value.findIndex((item) => item.id === chat.id);
					chat.isSupportChat = true;
					if (existsIndex !== -1) {
						const chats = this._chats.value.slice();
						chats.splice(existsIndex, 1, chat);
						this._chats.next(chats);
						this.handleMessage(message);
					} else {
						this._messages.set(chat.id, new BehaviorSubject([this._parseMessage(message)]));
						this._chats.next([chat, ...this._chats.value]);
					}
				}),
			);
	}

	getMessages$(chat_id: string) {
		return this._messages.get(chat_id).pipe(
			map((messages) =>
				messages.map((message) => {
					message.attachmentsType = this._parseAttachment(message.attachments);
					return message;
				}),
			),
		);
	}

	addMessage(chatId: string, text: string, attachments: string = null) {
		this.file$.next(null);

		const messages = this._messages.get(chatId);
		const message: Message = {
			notified: false,
			id: null,
			text,
			author: null,
			chat_id: chatId,
			attachments: null,
			created_at: new Date().toISOString(),
			read: false,
			reaction: null,
			attachmentsType: null,
			type: null,
			typeData: {},
		};
		this.updateLastChatMessage(message);
		messages.next([...messages.value, this._parseMessage(message)]);
		this._reorder();

		this.socket.next({
			type: ClientMessageType.Send,
			data: {
				text,
				chatId,
				attachments,
			},
		});
	}

	markAsRead(messageId: string) {
		this.socket.next({
			type: ClientMessageType.Read,
			data: {
				messageId,
			},
		});
	}

	uploadFile(chatId: string, file: File) {
		this.file$.next(null);
		const form = new FormData();
		form.append('file', file, file.name);
		return this.http.post('/api/m/attachments', form, {reportProgress: true, observe: 'events', params: {chatId}});
	}

	donorHasChat$(eggDonorId: number) {
		return this._chats.pipe(map((chats) => chats.find((chat) => chat.egg_donor_id === eggDonorId)?.id));
	}

	surrogateHasChat$(surrogateId: number) {
		return this._chats.pipe(map((chats) => chats.find((chat) => chat.surrogate_id === surrogateId)?.id));
	}

	surrogacyHasChat$(providerId: number) {
		return this._chats.pipe(map((chats) => chats.find((chat) => !chat.egg_donor_id && chat.provider_id === providerId)?.id));
	}

	suggestDonors(userIds: number[], donorIds: number[]) {
		return this.http
			.post<Array<{chat: Chat; message: Message}>>('/api/m/suggest-donors', {
				userIds,
				donorIds,
			})
			.pipe(tap((createdChats) => this.handleCreatedChats(createdChats)));
	}

	suggestSurrogates(userIds: number[], surrogateIds: number[]) {
		return this.http
			.post<Array<{chat: Chat; message: Message}>>('/api/m/suggest-surrogates', {
				userIds,
				surrogateIds,
			})
			.pipe(tap((createdChats) => this.handleCreatedChats(createdChats)));
	}

	handleCreatedChats(createdChats: {chat: Chat; message: Message}[]) {
		for (const {chat, message} of createdChats) {
			const existsIndex = this._chats.value.findIndex((item) => item.id === chat.id);
			chat.isSupportChat = !(chat.provider_id && chat.user_id);
			if (existsIndex !== -1) {
				const chats = this._chats.value.slice();
				chats.splice(existsIndex, 1, chat);
				this._chats.next(chats);
				this.handleMessage(this._parseMessage(message));
			} else {
				this._messages.set(chat.id, new BehaviorSubject([this._parseMessage(message)]));
				this._chats.next([chat, ...this._chats.value]);
			}
		}
	}

	reactToMessage(messageId: string, reaction: boolean) {
		this.socket.next({
			type: ClientMessageType.React,
			data: {
				messageId,
				reaction,
			},
		});
	}

	private _getLoadingState(chatId: string) {
		if (this._loadingState[chatId]) {
			return this._loadingState[chatId];
		} else {
			return (this._loadingState[chatId] = {
				finished: false,
				pagesInProgress: {},
			});
		}
	}

	private extractMessageType(message: string): ServiceMessageType {
		const match = message.match(/\[\[SQRTM1\]\] (\w+)/);
		return match && ServiceMessageType[ServerMessageTypeReversed[match[1]]];
	}

	private _parseMessage(message: Message, chats?: Chat[]) {
		if (message.parsed) {
			return message;
		}

		const text = message.text;
		const type = this.extractMessageType(text);
		message.parsed = true;
		message.typeData = {};
		if (type) {
			const data = ContactAgencyService.unwrapServiceMessage(text);
			switch (type) {
				case ServiceMessageType.CreatedAppointment:
				case ServiceMessageType.UpdateAppointment:
				case ServiceMessageType.CancelAppointment: {
					const {calendarId, time} = data;
					const chat = (chats || this._chats.value).find((item) => item.id === message.chat_id);
					console.log(text, chat);
					if (!chat) {
						break;
					}
					const contact = chat.provider?.provider_contacts?.find((item) => item.calendar_id === calendarId);
					if (!contact) {
						message.parsed = false;
						break;
					}
					message.typeData = {
						contactId: contact.id,
						calendarId,
						name: `${contact.first_name} ${contact.last_name}`.trim() || chat.provider?.company_name,
						time,
					};
					break;
				}
				case ServiceMessageType.InviteForAppointment: {
					const {calendarId} = data;
					const chat = (chats || this._chats.value).find((item) => item.id === message.chat_id);
					if (!chat) {
						break;
					}
					const contact = chat.provider?.provider_contacts?.find((item) => item.calendar_id === calendarId);
					if (!contact) {
						message.parsed = false;
						break;
					}
					message.typeData = {
						contactId: contact.id,
						calendarId,
						name: `${contact.first_name} ${contact.last_name}`.trim() || chat.provider?.company_name,
					};
					break;
				}
				case ServiceMessageType.SuggestDonor: {
					const donorId = +data.donorId;
					message.typeData = {
						donorId,
						data$: this.donorsStore.getDonorById(donorId).pipe(map(([donor]) => donor)),
					};
					break;
				}
				case ServiceMessageType.SuggestSurrogate: {
					const surrogateId = +data.surrogateId;
					message.typeData = {
						surrogateId,
						data$: this.surrogatesStore.getSurrogateById(surrogateId).pipe(map(([surrogate]) => surrogate)),
					};
					break;
				}
				case ServiceMessageType.MessageReaction: {
					const reaction = data.reaction === 'true';
					message.typeData = {
						reaction,
					};
					break;
				}
				case ServiceMessageType.NoPhonesUser: {
					const {calendarId} = data;
					const chat = (chats || this._chats.value).find((item) => item.id === message.chat_id);
					if (!chat) {
						break;
					}
					const contact =
						chat.provider?.provider_contacts?.find((item) => item.calendar_id === calendarId) ||
						chat.provider?.provider_contacts?.find((item) => item.calendar_id && item.show_calendar);
					if (!contact) {
						message.parsed = false;
						break;
					}
					message.typeData = {
						contactId: contact.id,
						calendarId: contact.calendar_id,
						name: `${contact.first_name} ${contact.last_name}`.trim() || chat.provider?.company_name,
					};
				}
			}
			console.log(`[_parseMessage] Serice Message, type => ${type}, text => ${text}, typeData =>`, message.typeData);
		} else {
			message.text = this.domSanitizer.sanitize(SecurityContext.HTML, text);
			message.text = message.text.includes('target="_blank"')
				? message.text
				: message.text
						.replace('&#10;', '\n')
						.replace(/(?:https?:\/\/)?([\w-]+(?:\.\w{2,})+(?:\/[^\s,."]*)*)/g, '<a href="https://$1" target="_blank">$1</a>')
						.replace('\n', '&#10;');
		}
		message.type = type;
		return message;
	}

	private updateLastChatMessage(message: Message) {
		const chatIndex = this._chats.value.findIndex((item) => item.id === message.chat_id);
		if (chatIndex !== -1) {
			const updatedChats = this._chats.value.slice();
			updatedChats[chatIndex] = {
				...updatedChats[chatIndex],
				messages: [message],
			};
			this._chats.next(updatedChats);
		}
	}

	private handleMessage(message: Message) {
		if (!message?.text) {
			return;
		}
		const messages = this._messages.get(message.chat_id);
		this.updateLastChatMessage(message);
		if (messages) {
			const last = messages.value[messages.value.length - 1];
			if (last?.id === null && last?.text === message.text) {
				messages.value[messages.value.length - 1] = message;
				messages.next([...messages.value]);
			} else {
				messages.next([...messages.value, message]);
			}
			this._reorder();
		} else {
			this.loadChats().subscribe();
		}
	}

	private _parseAttachment(link: string) {
		if (!link) {
			return null;
		}
		switch (link.slice(link.lastIndexOf('.')).toLowerCase()) {
			case '.jpg':
			case '.jpeg':
			case '.png':
			case '.webp':
				return AttachmentType.IMAGE;
			case '.mp4':
			case '.webm':
			case '.mov':
				return AttachmentType.VIDEO;
			default:
				return AttachmentType.DOC;
		}
	}

	private _reorder() {
		this._chats.next(this._chats.value);
	}

	private _connect(token: string) {
		const protocol = window.location.protocol.replace('http', 'ws');
		const host = window.location.host;
		this.socket = new WebSocketSubject({
			url: `${protocol}//${host}/api/m/socket/?token=${token}`,
			deserializer: (event) => JSON.parse(event.data),
			openObserver: {
				next: () => {
					const stream = this.socketState.next(true);

					// Start the keep-alive pings
					this.keepAlive = timer(0, keepAliveIntervalMs)
						.pipe(
							switchMap(() => {
								// @ts-ignore
								this.socket.next({type: 'ping'});
								return of(null); // Return an Observable
							}),
						)
						.subscribe();

					return stream;
				},
			},
			closeObserver: {
				next: () => {
					const stream = this.socketState.next(false);

					if (this.keepAlive) {
						this.keepAlive.unsubscribe();
					}

					return stream;
				},
			},
		});
		this.socketSub = this.socket
			.pipe(
				catchError((error) => {
					console.warn('[_connect] [catchError]', error);
					setTimeout(() => {
						console.log('[_connect] trying to reconnect');
						this._disconnect();
						this._connect(token);
					}, 5000);
					return of({type: null} as SocketEvent);
				}),
			)
			.subscribe((event) => {
				switch (event.type) {
					case ServerMessageType.Message: {
						this.handleMessage(this._parseMessage(event.data));
						break;
					}
					case ServerMessageType.File: {
						const data = event.data as ServerFile;
						if (data.error) {
							console.error(data.error);
						}
						this.file$.next(data);
						break;
					}
					case ServerMessageType.Read: {
						const {messageId, chatId} = event.data;
						const messages = this._messages.get(chatId);
						if (messages) {
							const messageIndex = messages.value.findIndex((item) => item.id === messageId);
							if (messageIndex !== -1) {
								const updatedMessages = messages.value.slice();
								updatedMessages[messageIndex] = {
									...updatedMessages[messageIndex],
									read: true,
								};
								messages.next(updatedMessages);
								this.updateLastChatMessage(updatedMessages[messageIndex]);
							}
						}
						break;
					}
					case ServerMessageType.UnreadCount: {
						const {unreadCount} = event.data;
						this.unreadMessages$.next(unreadCount);
						break;
					}
					case ServerMessageType.DealStatus: {
						const {dealId, oldStatus, newStatus} = event.data;
						console.log(`[_connect] [ServerMessageType.DealStatus] oldStatus => ${oldStatus}, newStatus => ${newStatus}`);
						// duplicate and change, all at once
						this._chats.next(
							this._chats.value.map((chat) => {
								if (chat.deal_id === dealId) {
									chat.deal.status = newStatus;
									return chat;
								} else {
									return chat;
								}
							}),
						);
						break;
					}
					case ServerMessageType.React: {
						const {messageId, chatId, reaction} = event.data;
						const messages = this._messages.get(chatId);
						if (messages) {
							messages.next(
								messages.value.map((message) => {
									if (message.id === messageId) {
										message.reaction = reaction;
									}
									return message;
								}),
							);
						}
						break;
					}
					default:
						console.warn('[_connect] Unsupported event =>', event);
						break;
				}
			});
	}

	private _disconnect() {
		this.socketState.next(false);
		try {
			this.socketSub?.unsubscribe();
		} catch (error) {
			console.warn('[_disconnect]', error);
		}
	}
}
