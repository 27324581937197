import {HttpClient, HttpXhrBackend} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {delayWhen, skipWhile, switchMap, tap} from 'rxjs/operators';

import {environment as env} from '../../environments/environment';

export interface Location {
	country: string;
	state: string;
	city: string;
	address: string;
	coordinates: [number, number];
}

@Injectable({
	providedIn: 'root',
	deps: [HttpClient],
})
export class LocationService {
	static isLoaded = new BehaviorSubject(false);
	static isLoading = false;

	static geocoder: google.maps.Geocoder;
	private http = new HttpClient(new HttpXhrBackend({build: () => new XMLHttpRequest()}));

	constructor() {
		if (LocationService.isLoaded.value || LocationService.isLoading) {
			return;
		}
		const placesNode = document.getElementById('places-script');
		if (placesNode) {
			placesNode.remove();
			delete window.google;
		}
		LocationService.isLoading = true;
		const script = document.createElement('script');
		script.id = 'places-script';
		script.type = 'text/javascript';
		script.src = `https://maps.googleapis.com/maps/api/js?key=${env.google_places_token}&libraries=places&language=en`;
		script.onload = () => {
			LocationService.isLoading = false;
			LocationService.geocoder = new google.maps.Geocoder();
			LocationService.isLoaded.next(true);
		};
		document.getElementsByTagName('head')[0].appendChild(script);
	}

	requestLocationByIp() {
		return this.http
			.post<{location: {lng: number; lat: number}}>(`https://www.googleapis.com/geolocation/v1/geolocate?key=${env.google_places_token}`, {
				considerIp: 'true',
			})
			.pipe(
				tap(({location}) => console.log(`location =>`, location)),
				delayWhen(() => LocationService.isLoaded.pipe(skipWhile((isLoaded) => !isLoaded))),
				switchMap(({location}) => this.findLocation(location.lat, location.lng)),
				tap((location) => console.log(`location =>`, location)),
			);
	}

	findLocation(lat: number, lng: number) {
		const location = new google.maps.LatLng(lat, lng);
		return new Observable<Location>((subscriber) => {
			LocationService.geocoder.geocode({location}, (res, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					const country = res[0].address_components.find((item) => item.types.includes('country'));
					const state = res[0].address_components.find((item) => item.types.includes('administrative_area_level_1'));
					const city =
						res[0].address_components.find((item) => item.types.includes('locality')) ||
						res[0].address_components.find((item) => item.types.includes('administrative_area_level_2'));
					const result = {
						country: country ? country.long_name : '',
						state: state ? state.short_name : '',
						city: city ? city.short_name : '',
					};
					const address = Object.values(result)
						.filter((item) => item)
						.reverse()
						.join(', ');
					const coordinates: [number, number] = [lng, lat];
					subscriber.next({
						...result,
						address,
						coordinates,
					});
					subscriber.complete();
				}
			});
		});
	}
}
