import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-numeric-group',
	templateUrl: './numeric-group.component.html',
	styleUrls: ['./numeric-group.component.scss'],
})
export class NumericGroupComponent {
	@Input()
	value: number;

	@Input()
	label = 'Babies Born';
}
