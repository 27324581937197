import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@/material.module';
import { NumericGroupComponent } from '@/screens/ivf/numeric-group/numeric-group.component';

@NgModule({
	declarations: [NumericGroupComponent],
	imports: [MaterialModule, CommonModule],
	exports: [NumericGroupComponent],
})
export class NumericGroupModule {}
