export enum ServiceMessageType {
	CreatedAppointment = 'CRT_AP',
	InviteForAppointment = 'INVT_AP',
	UpdateAppointment = 'UPDT_AP',
	CancelAppointment = 'CNCL_AP',
	SuggestDonor = 'SGST_DNR',
	SuggestSurrogate = 'SGST_SUR',
	MessageReaction = 'MSG_RCT',
	NoPhonesUser = 'N_PH_U',
	NoPhonesProvider = 'N_PH_P',
	Date = 'DT',
	DealWithOtherAgencySigned = 'OTHR_DL_SGND',
}
