import {HttpInterceptor, HttpRequest, HttpHandler} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {LocationService} from './location.service';
import {timezoneService} from './timezone.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	ipLocation = '';

	constructor(locationService: LocationService) {
		locationService
			.requestLocationByIp()
			.pipe(
				map(
					(location) => `${location.country}||${location.state}||${location.city}||${location.coordinates[0]}|${location.coordinates[1]}`,
				),
			)
			.subscribe((location) => (this.ipLocation = location));
	}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		if (!request.url.startsWith('http')) {
			request = request.clone({
				setHeaders: {
					'X-Timezone': timezoneService.timezone,
					'X-Host': window.location.origin,
				},
				url: (localStorage.getItem('[SQRTM1] (serverUrl)') || '') + request.url,
			});
		}

		return request.url.startsWith('http') || !this.ipLocation
			? next.handle(request)
			: next.handle(request.clone({setHeaders: {'X-IP-Location': encodeURIComponent(this.ipLocation)}}));
	}
}
