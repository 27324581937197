import {Component, Input} from '@angular/core';
import {ivfServicesSelectors} from '@/config';
import {Cost} from '@/services/providers.service';

@Component({
	selector: 'app-ivf-program',
	templateUrl: './ivf-program.component.html',
	styleUrls: ['./ivf-program.component.scss'],
})
export class IvfProgramComponent {
	private static COLOURS = ['#9bcb6c', '#663898', '#f2c94c', '#df2e85'];

	private _program: Cost;

	@Input()
	public set program(value: Cost) {
		this._program = value;

		if (value && !value.used_as) {
			const service = ivfServicesSelectors.internal.find(({s}) => s === value.name);
			if (service) {
				this._program.used_as = this._program.name;
				this._program.name = service.n;
			}
		}
	}
	public get program(): Cost {
		return this._program;
	}
	@Input()
	nth: number;

	get colour() {
		return IvfProgramComponent.COLOURS[this.nth];
	}

	constructor() {}
}
