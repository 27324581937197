<div *ngIf="showBar" @openClose class="bar">
    <div class="title">Would you like a little help?</div>
    <div class="subtitle">Schedule a 100% FREE call with a GoStork Concierge to support you.</div>

    <button mat-flat-button class="book" (click)="book()">Book a Free Consultation</button>
</div>

<div *ngIf="showBarIVF" @openClose class="ivf-bar">
    <div class="title">Want to talk about your options?</div>
    <div class="subtitle">GoStork Offers A Free Concierge Call With An Expert Who Has Experienced Fertility Treatment.</div>

    <button mat-flat-button class="book" (click)="book()">Book a Free Consultation</button>
</div>

<ng-template #calendarRef>
    <div [ngSwitch]="viewState" class="calendar-container">
        <button mat-icon-button class="close" (click)="close()">
			<mat-icon>close</mat-icon>
		</button>

        <h2>Book Your Concierge Call with GoStork</h2>

        <ng-container *ngSwitchCase="CalendarViewState.SCHEDULE">
            <app-form-field type="select" [withSearch]="true" [options]="tzones" [value]="timezone" (valueChange)="changeTimezone($event)">
                <div matPrefix>Timezone:</div>
            </app-form-field>

            <div *ngFor="let config of calendarConfigs$ | async; let index = index">
                <div class="name">
                    <b> {{ config.first_name }} {{ config.last_name }} </b>
                </div>

                <div *ngIf="config.duration_of_meetings.length > 1" class="duration">
                    <div>
                        <b>How long do you need?</b>
                    </div>
                    <mat-button-toggle-group [value]="duration" (change)="duration = $event.value">
                        <mat-button-toggle *ngFor="let d of durations[index]" [value]="d.value">
                            {{ d.label }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>

                <app-calendar [timezone]="timezone" forceMobile [adminConfig]="config" [duration]="duration" [calendarId]="config.calendar_id" (cellClick)="onTimeSelect($event, config.calendar_id)"></app-calendar>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarViewState.CONFIRM">
            <div *ngIf="bookingCalendar$ | async as bookingCalendar" class="name">
                <b> {{ bookingCalendar.first_name }} {{ bookingCalendar.last_name }} </b>
            </div>

            <mat-form-field appearance="outline" class="area" (click)="viewState = CalendarViewState.SCHEDULE">
                <mat-label>Date & Time</mat-label>
                <input matInput [value]="bookingTime.toJSDate() | timezoneDate: 'EEEE, MMMM d \'at\' h:mm a': timezone" required />
                <span matSuffix>Edit</span>
            </mat-form-field>
            <button mat-flat-button color="primary" class="confirm" [disabled]="loading" (click)="confirmAppointment()">Confirm</button>
        </ng-container>

        <div *ngSwitchCase="CalendarViewState.COMPLETE" class="complete">Thank you for scheduling a concierge call</div>
    </div>
</ng-template>
