<app-header></app-header>

<div class="content" role="main">
	<router-outlet></router-outlet>
</div>

<app-when-to-start *ngIf="!isPopupOpened" questionType="appointment"></app-when-to-start>

<footer>{{ version }}</footer>

<ng-container *ngTemplateOutlet="showCookieBanner ? cookie_banner : null"></ng-container>

<div *ngIf="iddqd" class="iddqd"></div>

<app-when-to-start *ngIf="!isPopupOpened" questionType="heardFrom"></app-when-to-start>

<ng-template #cookie_banner>
	<div class="cookie-banner">
		<span>
			This website uses cookies to improve your user experience and for analytical purposes. To learn more about how we use cookies, please
			see our <a routerLink="/policies/cookie-policy" target="_blank">Cookie Policy</a>
		</span>
		<button mat-flat-button color="primary" (click)="onCookieAccept()">Accept and Close</button>
	</div>
</ng-template>
