import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MaterialModule} from '@/material.module';

import {VideoComponent} from './video.component';

@NgModule({
	declarations: [VideoComponent],
	imports: [CommonModule, MaterialModule],
	exports: [VideoComponent],
})
export class VideoModule {}
