// breadcrumb.component.ts
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
	breadcrumbs: Array<{ label: string; url: string }> = [];

	constructor(private router: Router, private route: ActivatedRoute) {
		this.breadcrumbs = this.buildBreadcrumbs(this.route.root);
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.breadcrumbs = this.buildBreadcrumbs(this.route.root);
		});
	}

	ngOnInit(): void {}

	buildBreadcrumbs(
		route: ActivatedRoute,
		url: string = '',
		breadcrumbs: Array<{ label: string; url: string }> = [],
	): Array<{ label: string; url: string }> {
		const children: ActivatedRoute[] = route.children;

		if (breadcrumbs.length === 0) {
			const rootLabel = route.snapshot.data['breadcrumb'] || 'Home';
			breadcrumbs.push({ label: rootLabel, url: '/dashboard' });
		}

		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
			if (routeURL !== '') {
				url += `/${routeURL}`;
			}

			const label = child.snapshot.data['breadcrumb'];
			if (label) {
				breadcrumbs.push({ label, url });
			}

			return this.buildBreadcrumbs(child, url, breadcrumbs);
		}

		return breadcrumbs;
	}
}
