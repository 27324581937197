import {Component, Input} from '@angular/core';

interface Location {
	text: string;
	distance: number;
}

@Component({
	selector: 'app-location-entry',
	templateUrl: './location-entry.component.html',
	styleUrls: ['./location-entry.component.scss'],
})
export class LocationEntryComponent {
	@Input()
	index = 0;

	@Input()
	location: Location;
}
