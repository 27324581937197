<mat-form-field appearance="outline">
	<mat-label>{{label}}</mat-label>
	<input
		matInput
		#input
		autocomplete="none"
		[formControl]="control"
		[matAutocomplete]="autocomplete"
		(blur)="onBlur()"
		spellcheck="false">
	<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="getTitle" (optionSelected)="onSelect($event.option.value)">
		<mat-option *ngFor="let option of responses" [value]="option" >
			{{option.title}}
		</mat-option>
		<mat-option *ngIf="revision !== 0 && responses.length === 0 && control.value && control.value.length > 4" class="empty">No Matches Found for Given Query</mat-option>
		<img src="assets/powered_by_google_on_white.png" alt="Powered by Google">
	</mat-autocomplete>
	<button
		matSuffix
		*ngIf="autofill"
		mat-icon-button
		matTooltip="Use my location"
		(click)="identifyLocation()">
		<mat-icon>near_me</mat-icon>
	</button>
	<mat-error>{{getError()}}</mat-error>
</mat-form-field>
