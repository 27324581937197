import {QuestionType} from './interface';

export const education =  ['High School', 'Some College', 'Associate Degree', 'Bachelor Degree', 'Post Graduate'];
export const ethnicity = [
	'African American', 'Albanian', 'American Indian', 'Argentinean', 'Armenian', 'Austrian', 'Bahamian', 'Bajan', 'Bangladeshi',
	'Belgian', 'Bolivian', 'Brazilian', 'Bulgarian', 'Cajun', 'Cambodian', 'Canadian', 'Canadian Indian', 'Carribean', 'Chilean', 'Chinese', 'Colombian',
	'Croatian', 'Cuban', 'Czech', 'Danish', 'Dominican', 'Dutch', 'Ecuadorian', 'Egyptian', 'English', 'Eritrean', 'Estonian', 'Ethiopian',
	'Filipino', 'Finnish', 'Flemish', 'French', 'French-Canadian', 'German', 'Ghanian', 'Greek', 'Guatemalan', 'Guinean', 'Haitian',
	'Hmong', 'Honduran', 'Hungerian', 'Icelandic', 'Indian', 'Indonesian', 'Iranian', 'Iraqi', 'Irish', 'Israeli', 'Italian', 'Jamaican',
	'Japanese', 'Khmer', 'Korean', 'Lao', 'Latvian', 'Lebanese', 'Liberian', 'Luthuenian', 'Malyasian', 'Mauritian', 'Mexican', 'Morocco',
	'Nigerian', 'Norwegian', 'Pacific islander', 'Pakistani', 'Palestinian', 'Panamanian', 'Persian', 'Peruvian', 'Polish', 'Portuguese',
	'Puerto Rican', 'Romanian', 'Russian', 'Rwandan', 'Salvadorian', 'Saudi', 'Scottish', 'Sicilian', 'Slovakian', 'Spanish', 'Sudanese',
	'Swedish', 'Swissh', 'Thai', 'Trinidadian', 'Tripoli', 'Turkish', 'Ukranian', 'Venezuelan', 'Vietnamese', 'Welsh', 'Yemenite', 'Yugoslavian'
];

const questions = {
	eye: {
		subtitle: 'Let’s get some information about your preferred egg donor so we can match you with the best one!',
		title: 'Choose her eye color',
		type: QuestionType.MultiOption,
		options: ['Brown', 'Blue', 'Green', 'Hazel', 'Gray'],
		disclaimer: 'Some intended parents like to choose a donor whose physical characteristics are similar to theirs.  If it’s important to you to select a donor who bears some resemblance to you or has the same hair color, eye color, and/or height, this questionnaire will help you narrow down our large pool of egg donors.'
	},
	hair: {
		title: 'Choose her hair color',
		type: QuestionType.MultiOption,
		options: ['Brown', 'Blonde', 'Black', 'Red'],
		disclaimer: 'Some intended parents like to choose a donor whose physical characteristics are similar to theirs.  If it’s important to you to select a donor who bears some resemblance to you or has the same hair color, eye color, and/or height, this questionnaire will help you narrow down our large pool of egg donors.',
		cloud: 'Way to go on your first<br>choice! You’ve completed<br>the first step… Keep going!'
	},
	height: {
		title: 'Choose her height range',
		type: QuestionType.Range,
		min_value: 135,
		max_value: 211,
		disclaimer: 'Some intended parents like to choose a donor whose physical characteristics are similar to theirs.  If it’s important to you to select a donor who bears some resemblance to you or has the same hair color, eye color, and/or height, this questionnaire will help you narrow down our large pool of egg donors.',
		cloud: 'Super! We can’t wait<br>for you to see the egg donors.<br>You’ve got 3 more <br> questions to go… '
	},
	weight: {
		type: QuestionType.Range,
		min_value: 40,
		max_value: 100,
	},
	age: {
		title: 'Choose her age range',
		type: QuestionType.Range,
		min_value: 19,
		max_value: 36,
		disclaimer: 'Age is one of the most important factors affecting a woman’s fertility. The American Society for Reproductive Medicine (ASRM) recommends that egg donors be between the ages of 21-34',
		cloud: 'Great job!<br>Your preferences will help<br>us match you with the<br>right egg donors'
	},
	race: {
		title: 'Choose your egg donor’s race',
		type: QuestionType.MultiOption,
		options: ['Black or African American', 'American Indian', 'Asian', 'White', 'Pacific Islander', 'Latin'],
		disclaimer: 'If it’s important to you to select a donor who has the same race, this questionnaire will also help you narrow down our large pool of egg donors based on their race.',
		cloud: 'Let’s keep going!<br>You have 1<br>more step left'
	},
	religion: {
		title: 'Choose your egg donor’s religion',
		type: QuestionType.MultiOption,
		options: ['Muslim', 'Christian', 'Hindu', 'Atheist', 'Buddhist', 'Jewish'],
		disclaimer: 'Religious background is another factor that may influence your decision, if you’re looking for common ground with the donor.',
		cloud: 'Super! This is the last<br>step. Next, we’re<br>going to create your<br>GoStork account!'
	},
	name: {
		title: 'Last but not least, let’s create your GoStork account',
		bottomsubtitle: 'Get access to thousands of donors and connect with their agencies at no cost to you!',
		type: QuestionType.Text,
		required: true,
		label: ['First Name', 'Last Name', 'Date of Birth', 'Phone Number'],
		cloud: 'Let’s create<br>your account to save your<br>preferences and access it<br>later '
	},
	address: {
		title: 'Your address',
		type: QuestionType.Text,
		required: true,
		label: ['Street Address, City, Country', 'Apt'],
		cloud: 'Nice to meet you<br>{firstName}!<br>Let’s finish setting up your<br>account!'
	},
	identify: {
		title: 'How do you identify?',
		type: QuestionType.Option,
		required: true,
		options: ['Straight', 'LGBTQ+'],
		disclaimer: 'Services offered by egg donor agencies may vary based on your identification and relationship status',
		cloud: 'You’re almost done!<br>You have 2 steps left.'
	},
	relation: {
		title: 'Your current relationship status',
		type: QuestionType.Option,
		required: true,
		options: ['Married', 'Committed Relationship', 'Single'],
		disclaimer: 'Services offered by egg donor agencies may vary based on your identification and relationship status',
		cloud: 'We’re so excited for you<br>to see all of our egg donors!'
	},
	partner: {
		title: 'Let’s get your partner’s information',
		type: QuestionType.Text,
		required: true,
		label: ['First Name', 'Last Name'],
		cloud: 'We want to meet<br>the entire family!'
	},
	password: {
		title: 'Save your answers in your profile',
		type: QuestionType.Text,
		required: true,
		content_type: ['email', 'password'],
		label: ['Email', 'Password']
	},
};

export default questions;
