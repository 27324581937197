import { Component, Input, HostListener, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
	selector: 'app-rating',
	templateUrl: './rating.component.html',
	styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements AfterViewInit {
	@Input()
	rating = 0;
	@Input()
	mode: 'select' | 'show' = 'show';
	@Output()
	changed = new EventEmitter<number>();

	rand = 'fill_' + Math.random().toString().slice(2);
	_rating = 0;

	svg: HTMLElement = null;

	constructor(
		private elementRef: ElementRef<HTMLElement>
	) { }

	@HostListener('click')
	onClick() {
		if (this.mode === 'show')
			return;
		this.rating = this._rating;
		this.changed.emit(this.rating);
	}

	@HostListener('mousemove', ['$event'])
	onMouseMove(event: MouseEvent) {
		if (this.mode === 'show')
			return;
		this._rating = Math.min(Math.ceil(event.offsetX / this.svg.clientWidth * 5), 5);
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		if (this.mode === 'show')
			return;
		this._rating = 0;
	}

	ngAfterViewInit() {
		this.svg = this.elementRef.nativeElement.firstChild as HTMLElement;
	}
}
