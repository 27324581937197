<div class="list" #listRef>
    <div *ngFor="let row of displayData; let index = index" #rowRef class="row" style.grid-template-columns="repeat({{ emptyRow.length }}, 1fr)" [attr.data-index]="index" [style.paddingRight.px]="paddingRight">
        <ng-container *ngFor="let item of row; let index2 = index">
            <ng-container *ngTemplateOutlet="item && itemTemplate?.templateRef; context: {$implicit: item, rowIndex:index,colIndex:index2};"></ng-container>
        </ng-container>
    </div>
</div>

<app-loader *ngIf="(dataSource.isLoading$ | async) === true"></app-loader>

<div class="no-info" *ngIf="(dataSource.isLoading$ | async) === false && dataSource.count === 0">
    <div class="svg-backgroung">
        <img class="bird" src="assets/bg-bird.svg" />
        <img class="bird" src="assets/bg-bird.svg" />
        <img class="bird" src="assets/bg-bird.svg" />
        <img class="bird" src="assets/bg-bird.svg" />
        <img class="bird" src="assets/bg-bird.svg" />
        <img class="bird" src="assets/bg-bird.svg" />
        <img class="bird" src="assets/bg-bird.svg" />
        <img class="bird" src="assets/bg-bird.svg" />
        <img class="bird" src="assets/bg-bird.svg" />
    </div>
    <div class="image">
        <img class="none" src="assets/none.svg" />
    </div>
    <div class="text">
        <p>Oops! It seems like we couldn't find results that match your filters. Please change your filters.</p>
    </div>
    <button mat-raised-button color="accent" (click)="changeFilters.emit()" class="change-filters">Change Filters</button>
</div>