import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RatingComponent } from './rating.component';

import { MaterialModule } from '../../material.module';

@NgModule({
	imports: [CommonModule, MaterialModule],
	declarations: [RatingComponent],
	exports: [RatingComponent]
})
export class RatingModule { };
