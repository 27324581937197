import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {nanoid} from '../helpers/nanoid';
import {AuthStoreService} from './auth.service';
import GoogleAnalytics4 from "react-ga4";


declare let ga: (...args: any) => void;

@Injectable({providedIn: 'root'})
export class AnalyticsService {
	constructor(private http: HttpClient, private authService: AuthStoreService) {
	}

	public pageView(urlAfterRedirects?: string) {
		if (urlAfterRedirects) {
			ga('set', 'page', urlAfterRedirects);
			GoogleAnalytics4._gaCommandSet('page', urlAfterRedirects);
		}
		ga('send', 'pageview');
		GoogleAnalytics4._gaCommandSendPageview(null, null);
	}

	public emit(category: string, action: string, label: string = null, value: string | number = null) {
		GoogleAnalytics4.event({
			category,
			action,
			label,
			value: value as any
		})
		ga('send', 'event', {
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
		});
	}

	public timing(category: string, variable: string, value: number, label?: string) {
		GoogleAnalytics4._gaCommandSendTiming(category, variable, value, label);
		ga('send', 'timing', category, variable, value, label);
	}

	public updateUserDimension() {
		ga('set', 'dimension1', this.authService.value?.id);
		GoogleAnalytics4._gaCommandSet('dimension1', this.authService.value?.id);

	}

	public setDimension(...dmns: [string, string]) {
		const user = this.authService.value;
		dmns.push(user.id?.toString() || '', user.id ? 'logged' : 'new');
		dmns.forEach((value, index) => {
			const dimName = `cd${index + 1}`;
			GoogleAnalytics4._gaCommandSet(dimName, value);
			ga('set', dimName, value)
		});
	}

	public push(data: {token: string; category: string; action: string; label?: string; value?: string}) {
		if ('label' in data && !data.label) {
			delete data.label;
		}
		if ('value' in data && !data.value) {
			delete data.value;
		}
		this.emit(data.category, data.action, data.label, data.value)
		this.http
			.post('/api/v2/event/push', data)
			.pipe(catchError(() => of(null)))
			.subscribe();
	}

	public attachGlobalListener() {
		document.addEventListener('click', (event) => {
			let node = event.target as HTMLElement;
			while (node) {
				if (node.dataset['data-an-action']) {
					break;
				}
				node = node.parentElement;
			}

			if (node) {
				this.push({
					token: nanoid(12),
					category: node.dataset['data-an-category'],
					action: node.dataset['data-an-action'],
					label: node.dataset['data-an-label'],
					value: node.dataset['data-an-value'],
				});
			}
		});
	}
}
