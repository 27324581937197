import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';

@Pipe({
	name: 'timezoneDate',
})
export class TimezoneDatePipe implements PipeTransform {
	transform(value: any, format: string, timezone: string): string {
		if (!value) return '—';
		try {
			let date: DateTime;
			if (value.constructor?.name === 'Date') {
				date = DateTime.fromJSDate(value).setZone(timezone);
			} else {
				date = DateTime.fromISO(value).setZone(timezone);
			}
			return date.toFormat(format);
		} catch (e) {
			console.error('Invalid date or timezone', e);
			return '—';
		}
	}
}
