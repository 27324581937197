<div class="suggested" [ngStyle]="{'display': display}">
	<p class="header-text">You might also be interested in </p>
	<div class="container" *ngFor="let suggestion of suggestedComparables;">
		<mat-card>
			<a [routerLink]="'/surrogates/' + suggestion.id" class="image">
				<img mat-card-image loading="lazy" [src]="'/images/' + suggestion.images[0]">
			</a>
			<div class="info-container" >
				<span>
					<a class="title" [routerLink]="'/surrogates/' + suggestion.id">Surrogate #{{('0000' + suggestion.id).slice(-6)}}</a>
				</span>
				<div *ngFor="let item of sections[type].selectors.main.slice(0,2)">
					<span>{{item.n}} : </span><ng-container *ngTemplateOutlet="probablyNone; context: { content: (item.m ? item.m(suggestion[item.s]) : suggestion[item.s] ? suggestion[item.s] : suggestion['origin_' + item.s]) }"></ng-container>
				</div>
				<div *ngFor="let item of sections[type].selectors.main.slice(6,7)">
					<span>{{item.n}} : </span><ng-container *ngTemplateOutlet="probablyNone; context: { content: (item.m ? item.m(suggestion[item.s]) : suggestion[item.s] ? suggestion[item.s] : suggestion['origin_' + item.s]) }"></ng-container>
				</div>
			</div>
			<div class="actions">
				<app-checkbox [value]="checked | async" (valueChange)="onChange('checked', suggestion.id)" [size]="20">{{(checked | async) ? 'Remove from Compare' : 'Add to Compare'}}</app-checkbox>
				<app-heart [value]="favourites[suggestion.id] | async" (valueChange)="onChange('stared', suggestion.id)" matTooltip="Add to Favorites"></app-heart>
			</div>
		</mat-card>
	</div>
</div>

<div class="table-wrapper">
	<div class="td pre-col head" [class.hide]="small">
		<a routerLink="../"><mat-icon>arrow_back</mat-icon>Back to the search</a>
	</div>
	<div class="table-scroll">
		<div class="table">
			<div class="row fake-head" [ngClass]="{'show': small}">
				<div class="td col"></div>
			</div>
			<div class="row head" [ngClass]="{'small': small}">
				<div class="td col" *ngFor="let comparable of comparables; let i = index; trackBy: trackBy">
					<button mat-icon-button class="remove" (click)="onChange('remove', comparable.id)"><mat-icon [inline]="true">close</mat-icon></button>
					<a [routerLink]="'/surrogates/' + comparable.id" class="image">
						<img mat-card-image loading="lazy" [src]="'/images/' + comparable.images[0]">
					</a>
					<a class="title" [routerLink]="'/surrogates/' + comparable.id">Surrogate #{{('0000' + comparable.id).slice(-6)}}</a>
					<div class="actions">
						<app-heart [value]="favourites[comparable.id] | async" (valueChange)="onChange('stared', comparable.id)" matTooltip="Add to Favorites"></app-heart>
						<button class="share" matTooltip="Share" (click)="onChange('share', comparable.id, $event)" mat-icon-button><app-insvg name="export" link="assets/export.svg"></app-insvg></button>
						<button
							mat-raised-button
							data-an-category="user"
							data-an-action="contact_agency"
							data-an-label="surrogate"
							data-an-value="compare"
							color="accent"
							(click)="onChange('interested', comparable.id, $event, i)"
						>
							{{(hasChat$[i] | async) ? 'Continue Messaging' : 'Contact Agency'}}
						</button>
					</div>
				</div>
			</div>

			<ng-container *ngFor="let section of sections[type].internal">
				<div class="row section">
					<div class="td pre-col section" (click)="expanded.has(section.s) ? expanded.delete(section.s) : expanded.add(section.s)  ">
						<span>{{section.n}}</span>
						<mat-icon [inline]="true">{{expanded.has(section.s) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
					</div>
					<div class="td col" *ngFor="let comparable of comparables; trackBy: trackBy">
						<span>&nbsp;</span>
					</div>
				</div>
				<div #row class="exp-section" [ngClass]="{'sub-show': expanded.has(section.s)}">
					<ng-container *ngFor="let item of sections[type].selectors[section.s]">
						<div class="td pre-col">
							<span>{{item.n}}</span>
						</div>
						<div class="row sub">
							<div class="td col" *ngFor="let comparable of comparables; trackBy: trackBy">
								<ng-container *ngTemplateOutlet="probablyNone; context: { content: (item.m ? item.m(comparable[item.s]) : comparable[item.s] ? comparable[item.s] : comparable['origin_' + item.s]), field: item.s }"></ng-container>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>

			<div class="row section">
				<div class="td pre-col section" (click)="expanded.has('services') ? expanded.delete('services') : expanded.add('services')">
					<span>Costs</span>
					<mat-icon [inline]="true">{{expanded.has('services') ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
				</div>
				<div class="td col" *ngFor="let comparable of comparables; trackBy: trackBy">
					<ng-container *ngTemplateOutlet="viewRow; context: { field: { value: comparable.totalCost }}"></ng-container>
				</div>
			</div>
			<div class="exp-section services" [ngClass]="{'sub-show': expanded.has('services')}">
				<div class="row">
					<div class="td pre-col">
						<span>{{sections[type].costs.internal[0].n}}</span>
					</div>
					<div class="td col" *ngFor="let comparable of comparables; trackBy: trackBy">
						<ng-container *ngTemplateOutlet="viewRow; context: { field: comparable.costs[sections[type].costs.internal[0].s] }"></ng-container>
					</div>
				</div>
				
				<ng-container *ngFor="let subSection of sections[type].costs.internal | slice:1">
					<div class="row sub-section">
						<div class="td pre-col sub-section" (click)="expanded.has('services_' + subSection.s) ? expanded.delete('services_' + subSection.s) : expanded.add('services_' + subSection.s)  ">
							<span>{{subSection.n}}</span>
							<mat-icon [inline]="true">{{expanded.has('services_' + subSection.s) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
						</div>
						<div class="td col" *ngFor="let comparable of comparables; trackBy: trackBy">
							<ng-container *ngTemplateOutlet="viewRow; context: { field: comparable.costs[subSection.s] }"></ng-container>
						</div>
					</div>
					<div class="exp-section" [ngClass]="{'sub-show': expanded.has('services_' + subSection.s)}">
						<ng-container *ngFor="let item of sections[type].costs[subSection.s]">
							<div class="row sub">
								<div class="td pre-col sub">
									<span>{{item.sn || item.n}}</span>
								</div>
								<div class="td col" *ngFor="let comparable of comparables; trackBy: trackBy" [class]="item.s">
									<ng-container *ngTemplateOutlet="viewRow; context: { field: comparable.costs[item.s] }"></ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<ng-template #probablyNone let-content="content" let-field="field">
	<ng-container [ngSwitch]="field">
		<app-insvg
			*ngSwitchCase="'international_intended_parents'"
			class="content-icon"
			name="international_parents"
			link="assets/international_parents.svg"
			matTooltip="Agency is {{content ? '' : 'not '}}accepting International Parents"
			[class.disabled]="!content"
		></app-insvg>
		<app-insvg
			*ngSwitchCase="'lgbt_friendly'"
			class="content-icon"
			name="LGBT_friendly"
			link="assets/LGBT_friendly.svg"
			matTooltip="Agency {{content ? 'provides' : 'does not provide'}} LGBTQ+-specific services"
			[class.disabled]="!content"
		></app-insvg>
		<span
			*ngSwitchDefault
			matTooltip="Information was not provided by agency"
			[matTooltipDisabled]="content && content !== '[1]'"
			[ngClass]="{'none': !content || content === '[1]'}"
			[innerHTML]="isNumber(content) ? content : (utils.toCapitalizedCase(content) || 'No')"
		></span>
	</ng-container>
</ng-template>

<ng-template #expl>
	<div class="card">
		<div class="header">Additional information by agency:</div>
		<div class="content">{{selectedComment}}</div>
		<span class="arrow-top"></span>
		<button mat-icon-button (click)="onCommentClose()"><mat-icon>close</mat-icon></button>
	</div>
</ng-template>

<ng-template #viewRow let-field="field">
	<span
		matTooltip="Included in agency compensation"
		[matTooltipDisabled]="!field?.included"
		[innerHTML]="field
			? (field.included
				? 'Included'
				: field.value
					? (field.value | currency:'USD':'symbol':'1.0-0')
					: (field.special_value || '-'))
			: '-'"
	></span>
	<div
		*ngIf="field?.message"
		class="comment"
		[class.selected]="selectedCommentToggle === field"
		(click)="onComment(field, $event)">
		<app-insvg name="dialog-bubble" link="assets/dialog-bubble.svg"></app-insvg>
	</div>
</ng-template>
