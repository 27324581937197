import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {of, Subscription} from 'rxjs';
import {catchError, delay, filter} from 'rxjs/operators';
import {ProviderType} from '@/config';
import {ParentStoreService} from '@/services/parent.service';

interface Question {
	question: string;
	answers: Array<{
		answer: string;
		action: () => void;
	}>;
}

@Component({
	selector: 'app-when-to-start',
	templateUrl: './when-to-start.component.html',
	styleUrls: ['./when-to-start.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'true',
				style({
					transform: 'translate(-50%, 0)',
				}),
			),
			state(
				'false',
				style({
					transform: 'translate(-50%, 100%)',
				}),
			),
			transition('true <=> false', [animate('.4s ease')]),
		]),
	],
})
export class WhenToStartComponent implements OnInit, OnDestroy {
	@Input()
	type: 'egg' | 'surrogacy';

	@Input()
	questionType: 'when' | 'appointment' | 'heardFrom';

	isAnswered = false;
	_isLoaded = false;

	useVerticalLayout = false;
	showTextField = false;
	textFieldLabel = '';
	textFieldValue = '';

	freeTextAction: () => void;

	selectedOption = '';

	withClose = false;

	@HostBinding('class.visible')
	get openClose() {
		return this._isLoaded && this.questions.length;
	}

	subscription = new Subscription();
	questions: Question[] = [];

	get lastQuestion() {
		return this.questions[this.questions.length - 1] || null;
	}

	constructor(private userService: ParentStoreService, private snackbar: MatSnackBar) {}

	updateWhenQuestion(answer: string) {
		this.isAnswered = true;
		this.userService.updateWhenQuestion(this.type === 'egg' ? ProviderType.EGG_AGENCY : ProviderType.SURROGACY_AGENCY, answer).subscribe();
		setTimeout(() => this.close(), 2000);
	}

	updateAppointmentAnswer(appointmentId: number, answer: number) {
		this.isAnswered = true;
		this.userService.updateAppointmentRating(appointmentId, answer).subscribe();
		setTimeout(() => this.close(), 2000);
	}

	updateHeardFrom(answer: string, details?: string) {
		this.isAnswered = true;
		this.userService
			.updateHeardFrom(details ? `${answer} - ${details}` : answer)
			.pipe(
				catchError((error) => {
					this.snackbar.open(error.message || 'Something went wrong');
					return of(error);
				}),
			)
			.subscribe();
		setTimeout(() => this.close(), 2000);
	}

	showFreeText(selectedOption: string, label: string) {
		this.textFieldLabel = label;
		this.showTextField = true;
		this.selectedOption = selectedOption;
	}

	close() {
		this.isAnswered = false;
		this.questions.pop();
	}

	ngOnInit() {
		switch (this.questionType) {
			case 'when':
				this.subscription.add(
					this.userService.answeredWhenQuestion$
						.pipe(
							filter((data) => !!data),
							delay(2000),
						)
						.subscribe((data) => {
							this._isLoaded = true;
							if (!data[this.type === 'egg' ? ProviderType.EGG_AGENCY : ProviderType.SURROGACY_AGENCY]) {
								this.questions = [
									{
										question: `When do you plan to start your ${this.type === 'egg' ? 'egg donation' : 'surrogacy'} journey?`,
										answers: [
											{
												answer: 'I hope to start ASAP!',
												action: () => this.updateWhenQuestion('asap'),
											},
											{
												answer: '1-3 months',
												action: () => this.updateWhenQuestion('1-3'),
											},
											{
												answer: '3-6 months',
												action: () => this.updateWhenQuestion('3-6'),
											},
											{
												answer: 'Just researching',
												action: () => this.updateWhenQuestion('researching'),
											},
										],
									},
								];
							}
						}),
				);
				break;
			case 'appointment':
				this.subscription.add(
					this.userService.pendingAppointmentsAnswers$
						.pipe(
							filter((data) => !!data),
							delay(2000),
						)
						.subscribe((data) => {
							this._isLoaded = true;
							this.questions = data.map((item) => ({
								question: `Did you have your consultation call with ${item.providerName}`,
								answers: [
									{
										answer: 'Yes',
										action: () => this.updateAppointmentAnswer(item.appointmentId, 5),
									},
									{
										answer: 'No',
										action: () => this.updateAppointmentAnswer(item.appointmentId, null),
									},
								],
							}));
						}),
				);
				break;
			case 'heardFrom':
				this.useVerticalLayout = true;
				this.withClose = false;

				this.freeTextAction = () => this.updateHeardFrom(this.selectedOption, this.textFieldValue);

				this.subscription.add(
					this.userService.heardFrom$
						.pipe(
							filter((data) => data !== undefined && data !== null),
							delay(30000),
						)
						.subscribe((data) => {
							this._isLoaded = true;
							if (!data) {
								this.questions = [
									{
										question: 'How did you hear about GoStork?',
										answers: [
											...['Google/Web Search', 'Facebook', 'Twitter', 'Pinterest', 'Friend or Family', 'Webinar'].map((value) => ({
												answer: value,
												action: () => this.updateHeardFrom(value),
											})),
											{
												answer: 'Instagram',
												action: () => this.showFreeText('Instagram', 'Is there a specific person or account you can share?'),
											},
											{
												answer: 'My Doctor or Clinic',
												action: () => this.showFreeText('My Doctor or Clinic', "Please share your doctor or clinic's name (optional)"),
											},
											{
												answer: 'Other',
												action: () =>
													this.showFreeText(
														'Other',
														'Please share details, such as the social media account, other website/community, nanprofit, fertility professional, etc.',
													),
											},
										],
									},
								];
							}
						}),
				);
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
