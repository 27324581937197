<div class="menu">
</div>

<ng-template #subscriptionRef>
    <form class="card" (ngSubmit)="(null)">
        <app-checkout></app-checkout>
    </form>
</ng-template>

<ng-container *ngTemplateOutlet="currentStep"></ng-container>

<div class="spec-bg">
    <img src="assets/profile_background.svg" alt="" />
</div>