import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SkeletonComponent} from './skeleton.component';
import {LazyContentDirective} from './lazy-content.directive';

@NgModule({
	declarations: [SkeletonComponent, LazyContentDirective],
	imports: [CommonModule],
	exports: [SkeletonComponent, LazyContentDirective],
})
export class SkeletonModule {}
