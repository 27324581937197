import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';
import {Router} from '@angular/router';
import {StoreConfig, EntityState, EntityStore, QueryEntity} from '@datorama/akita';
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, first, map, mergeMap, startWith, tap, switchMap, delay, catchError, filter, skip} from 'rxjs/operators';
import {DateTime, Interval} from 'luxon';

import {UtilsService} from './utils.service';
import {Cost} from './providers.service';

export enum AvailableServices {
	CARRIER_MATCHING = 'carrier_matching',
	CARRIER_SCREENING = 'carrier_screening',
	IN_HOUSE_ATTORNEY = 'in_house_attorney',
	ESCROW_MANAGEMENT = 'escrow_management',
	CASE_MANAGEMENT_AND_EDUCATION = 'case_management_and_education',
	COUNSELING_AND_SUPPORT = 'counseling_and_support',
	MEDICAL_BILLING_MANAGEMENT = 'medical_billing_management',
	ESTABLISHING_PARENTAL_RIGHTS = 'establishing_parental_rights',
	FERTILITY_LOANS = 'fertility_loans',
}

export enum CarrierScreening {
	CRIMINAL_BACKGROUND_CHECK = 'criminal_background_check',
	PSYCHOLOGICAL_PRE_SCREENED_CARRIERS = 'psychological_pre_screened_carriers',
	MEDICALLY_PRE_SCREENED_CARRIERS = 'medically_pre_screened_carriers',
	INSURANCE_REVIEW = 'insurance_review',
	SOCIAL_WORKER_PRE_SCREENED_CARRIERS = 'social_worker_pre_screened_carriers',
	HOME_VISITS_PRE_SCREENING = 'home_visits_pre_screening',
	FINANCIAL_REVIEW = 'financial_review',
	MEDICAL_RECORDS_REVIEW = 'medical_records_review',
}

export type ICompany = {
	id: number;
	img: string;
	video: string;
	rating: number;
	year_founded: number;
	number_of_babies_born: number;
	matching_timeframe: number;
	length_of_journey: number;
	family_load: number;
	lgbt_friendly: boolean;
	international_intended_parents: boolean;
	total_compensation: number;
	agency_compensation: number;

	about: string;
	what_makes_us_unique: string;
	available_surrogacy_services: AvailableServices[];
	carrier_screening_process: CarrierScreening[];

	provider: {
		company_name: string;
		location: [
			{
				zip: number;
				city: string;
				unit: string;
				state: string;
				address: string;
				country: string;
			},
		];
		provider_contacts: {
			id: number;
			calendar_id: string | null;
			show_calendar: boolean;
			first_name: string;
			last_name: string;
		}[];
		sponsorship_priority: number;
	};
	surrogacy_teams: {
		about: string;
		name: string;
		img: string;
		position: string;
		location: string[];
		professional_memberships: string[];
		languages: string[];
	}[];

	surrogacy_reviews: {
		id: number;
		match_time: number;
		child_months: number;
		number_or_human: boolean;
		rating_effective: number;
		rating_responsive: number;
		rating_screening_and_matching: number;
		rating_international_parents: number;
		satisfaction: number;
		overal_rating: number;
		message: string;
		created_at: Date;
		surrogacy_reviews_replies: {
			id: number;
			content: string;
			status: 'posted';
			deleted_at: null;
		}[];
	}[];

	actual_me: {
		calendar_id: string;
		role: string[];
	};
	number_of_reviews: number;

	costs: Record<string, Partial<Cost>>;
} & {[key: string]: any};

export interface CompanyState extends EntityState<ICompany, number> {
	count: number;
	backup: ICompany[];
	configs: Config[];
	busyCache: Record<string, {timestamp: DateTime; events: Interval[]; next_available: DateTime}>;
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'company', idKey: 'id'})
export class CompanyStore extends EntityStore<CompanyState, ICompany> {
	constructor() {
		super({count: 0, backup: [], configs: [], busyCache: {}});
	}
}

@Injectable({providedIn: 'root'})
export class CompaniesQuery extends QueryEntity<CompanyState> {
	constructor(protected store: CompanyStore) {
		super(store);
	}
}

@Injectable({providedIn: 'root'})
export class CompaniesStoreService {
	constructor(
		private store: CompanyStore,
		private query: CompaniesQuery,
		private http: HttpClient,
		private router: Router,
		private utils: UtilsService,
	) {}

	get count() {
		return this.query.getValue().count;
	}

	get loading() {
		return this.query.selectLoading();
	}

	backup(data: ICompany[]) {
		this.store.update({backup: data});
	}

	restore() {
		const backup = this.store.getValue().backup;
		return backup ? backup.slice() : null;
	}

	countSubscription() {
		return this.query.select('count');
	}

	clear() {
		this.store.remove();
		this.store.update({count: 0});
	}

	fetchCompanies(filters: Record<string, any>, offset: number, count: number, favourites: boolean, sortBy: string, order: string) {
		return this.http
			.put('/api/users/filters', filters, {
				params: {
					type: 'surrogacy',
					offset: offset.toString(),
					count: count.toString(),
					favourites: favourites.toString(),
					sort: sortBy,
					order,
					// rev: (revision || 0).toString(),
				},
			})
			.pipe(
				tap(
					(res: {data: ICompany[]; count: number; revision?: number; countInLocation: number}) => {
						this.store.upsertMany(res.data);
						this.store.update({count: res.count});
						this.store.setLoading(false);
					},
					(error) => {
						if (error.status === 401) {
							this.router.navigateByUrl('/login');
						}
					},
				),
			);
	}

	getCompanyById(ids: number | number[]): Observable<ICompany[]> {
		if (Array.isArray(ids)) {
			const requests$: Observable<ICompany>[] = [];
			const existing: ICompany[] = [];
			for (const id of ids) {
				if (this.query.hasEntity(id)) existing.push(this.query.getEntity(id));
				else requests$.push(this.getGeneralProfileById(id));
			}
			return requests$.length ? forkJoin(requests$).pipe(map((res) => res.concat(existing))) : of(existing);
		} else
			return this.query.hasEntity(ids)
				? this.query.selectMany([ids]).pipe(first())
				: this.getGeneralProfileById(ids).pipe(map((res) => [res]));
	}

	getGeneralProfileById(id: number) {
		return this.http.get<ICompany>(`/api/v2/surrogacy/${id}?general=true`);
	}

	fetchCompanyById(ids: number | number[], type: 'short' | 'long' = 'long'): Observable<ICompany[]> {
		const result: Observable<ICompany>[] = [];
		(Array.isArray(ids) ? ids : [ids]).forEach((id) => {
			result.push(this.http.get<ICompany>(`/api/v2/surrogacy/${id}?long=${type === 'long'}`));
		});
		return forkJoin(result);
	}

	fetchCurrentCompany(): Observable<ICompany> {
		return this.http.get<ICompany>('/api/v2/surrogacy/me').pipe(
			map((res) => {
				res.available_surrogacy_services = res.available_surrogacy_services || [];
				res.carrier_screening_process = res.carrier_screening_process || [];
				return res;
			}),
		);
	}

	fetchConfigs() {
		return this.http.get<Config[]>('/api/v2/providers/config').pipe(
			delay(2000),
			map((res) => (res || []).map((item) => ((item.id = item['provider_contact.provider_id']), item))),
			tap((configs) => this.store.update({configs})),
		);
	}

	getConfig(provider_id: number) {
		return this.query.select((state) => state.configs.find((item) => item.id === provider_id));
	}

	fetchConfigById(calendar_id: string) {
		return this.http.get<Config>('/api/v2/providers/config/' + calendar_id);
	}

	getBusy(provider_id: number | string, start: string, count: number): Observable<{events: Interval[]; next_available: DateTime}> {
		return this.query.select('busyCache').pipe(
			first(),
			switchMap((data) =>
				data[provider_id + start] && data[provider_id + start].timestamp > DateTime.local().minus({minutes: 2})
					? of(data[provider_id + start])
					: this.http
							.get<{events: RawBusy[]; next_available: string}>('/api/v2/providers/calendar', {
								params: new HttpParams({
									fromObject: {
										provider_id: provider_id.toString(),
										start,
										days: count.toString(),
									},
									encoder: new UrlParameterEncodingCodec(),
								}),
							})
							.pipe(
								map((res) => ({
									events: res.events.map((item) => Interval.fromISO(`${item.start}/${item.end}`)),
									next_available: res.next_available
										? DateTime.fromISO(res.next_available)
										: DateTime.fromISO(start).plus({week: 1}).minus({day: 1}),
								})),
								tap((res) =>
									this.store.update({
										busyCache: {...data, [provider_id + start]: {timestamp: DateTime.local(), ...res}},
									}),
								),
								catchError((error) => {
									console.error(error);
									return of({events: null, next_available: null});
								}),
							),
			),
		);
	}

	updateSection(type: string, content: Record<string, any>) {
		return this.http.put('/api/v2/surrogacy', content, {params: {type}});
	}

	replyToReview(review_id: number, content: string) {
		return this.http.post<ICompany['surrogacy_reviews'][0]['surrogacy_reviews_replies'][0]>('/api/v2/surrogacy/review/reply', {
			review_id,
			content,
		});
	}

	removeReviewReply(id: number) {
		return this.http.delete('/api/v2/surrogacy/review/reply/' + id);
	}

	updateReviewReply(id: number, content: string) {
		return this.http.put<ICompany['surrogacy_reviews'][0]['surrogacy_reviews_replies'][0]>('/api/v2/surrogacy/review/reply', {
			id,
			content,
		});
	}

	getCalendarAuthUrl(type: string) {
		return this.http.get<{url: string}>('/api/v2/providers/calendar/get-auth-url', {params: {type}}).pipe(map((res) => res.url));
	}

	connectCalendar(data: {code: string; type: string}) {
		return this.http
			.put<{calendar_id: string}>('/api/v2/providers/calendar/save-token', {}, {params: data})
			.pipe(map((res) => res.calendar_id));
	}

	disconnectCalendar() {
		return this.http.delete('/api/v2/providers/calendar/detach');
	}

	getEvents(): Observable<Event[]> {
		return this.http
			.get<{events: {summary: string; start: {dateTime: string}}[]}>('/api/v2/surrogacy/events')
			.pipe(
				map((res) =>
					res.events
						.filter((item) => item.start)
						.map((item) => ({summary: item.summary, start_time: DateTime.fromISO(item.start.dateTime)})),
				),
			);
	}

	testExchange(email: string, password: string, host: string) {
		return this.http
			.post<{calendar_id: string}>('/api/v2/providers/calendar/testExchangeConnection', {
				email,
				password,
				host,
			})
			.pipe(map((res) => res.calendar_id));
	}
}

class UrlParameterEncodingCodec extends HttpUrlEncodingCodec {
	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
}

export interface SurrogacyLocation {
	lat?: number;
	lng?: number;
	country?: string;
	state?: string;
	city?: string;
}

export interface IFiltersSurrogacy {
	search_by: string;
}

export interface RawBusy {
	/**
	 * ISO datetime string
	 */
	start: string;
	/**
	 * ISO datetime string
	 */
	end: string;
}

export interface Busy {
	start: DateTime;
	end: DateTime;
}

export type Day = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';

export interface Availability {
	days: Day[];
	/**
	 * In minutes
	 */
	start: number;
	/**
	 * In minutes
	 */
	end: number;
}

export interface Config {
	/**
	 * Provider ID
	 */
	id: number;
	/**
	 * In minutes
	 */
	duration_of_meetings: number | number[];
	/**
	 * In minutes
	 */
	amount_before_meeting: number;
	/**
	 * IANA TZ
	 */
	time_zone: string;
	availability: Availability[];
	location_of_meeting: string;
	meeting_invitation_description: string;
	subject_of_meeting_invitation: string;
}

export type Calendar = (null | DateTime[])[];

export interface AppointmentForm {
	surrogacy_id: number;
	/**
	 * ISO datetime string
	 */
	appointment_time: string;
	how_soon_parent_need_a_surrogate: '0-3' | '3-6' | '6+' | '<1';
	ivf_clinic_name: string;
	message_to_agency: string;
}

export interface Event {
	summary: string;
	start_time: DateTime;
}
