import {CheckoutModule} from '@/components/checkout/checkout.module';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {FormFieldModule} from '../../components/form-field/form-field.module';
import {MaterialModule} from '../../material.module';
import {UsersModule} from '../users/users.module';
import {CheckoutScreenComponent} from './checkout-screen.component';

@NgModule({
	declarations: [CheckoutScreenComponent],
	imports: [CommonModule, FormsModule, MaterialModule, UsersModule, FormFieldModule, CheckoutModule],
})
export class CheckoutScreenModule {}
