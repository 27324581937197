import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Observable} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {LabelValueIcon} from '@/models/common.model';
import {IVF_ACCREDITATIONS, IVF_MEMBERSHIPS, IVF_SERVICES, NIvfFilters} from '@/models/ivf.model';
import {TeamMemberBase} from '@/models/provider.model';
import {IvfService} from '../ivf.service';

@Component({
	selector: 'app-ivf-profile',
	templateUrl: './ivf-profile.component.html',
	styleUrls: ['./ivf-profile.component.scss'],
})
export class IvfProfileComponent implements OnInit {
	id$ = this.route.params.pipe(map(({id}) => id));
	ivf$: Observable<Record<string, any>> = this.id$.pipe(
		switchMap((id) => this.ivfService.getOne(id, true)),
		map((ivf) => this.ivfService.prepareImage(ivf)),
		map((ivf) => this.ivfService.prepareName(ivf)),
		shareReplay(1),
	);

	isEggFreezing$ = this.ivfService.state$.pipe(
		map((state) => state.filters?.questionnaire?.buildingFamily === NIvfFilters.BuildingFamily.Freezing),
		shareReplay(1),
	);
	locations$ = this.ivf$.pipe(
		switchMap((ivf) =>
			this.ivfService.getDistances(ivf.id).pipe(
				map((location) => [...location].sort((a, b) => a.distance - b.distance)),
				tap((location) => (ivf.location = location)),
				map(
					(locations) =>
						locations.map((item) => ({
							...item,
							text: [item.city, item.state].filter(Boolean).join(', '),
							distance: item.distance / 1609,
						})) || [],
				),
			),
		),
		shareReplay(1),
	);
	nearestLocation$ = this.locations$.pipe(
		map((locations) => (locations.length ? locations[0] : null)),
		shareReplay(1),
	);
	locationRows$ = this.locations$.pipe(map((locations) => Math.ceil((locations?.length || 0) / 2)));

	nationalData$ = this.ivfService.getNational();

	listOfWeirdThings$ = this.ivf$.pipe(map((ivf) => [...(ivf.memberships || []), ...(ivf.accreditation || [])]));

	selectedTeam: TeamMemberBase & Record<string, any>;

	constructor(
		private route: ActivatedRoute,
		private ivfService: IvfService,
		@Inject(IVF_SERVICES) public ivfServices: LabelValueIcon[],
		@Inject(IVF_ACCREDITATIONS) public ivfAccreditations: LabelValueIcon[],
		@Inject(IVF_MEMBERSHIPS) public ivfMemberships: LabelValueIcon[],
	) {}

	openVideo() {}

	getNationalNumber(key: string) {
		return this.nationalData$.pipe(map((data) => ((data[key] as number) || 0) * 100));
	}

	updateSelectedTeam(member: TeamMemberBase) {
		this.selectedTeam = this.selectedTeam === member ? null : member;
	}

	ngOnInit() {}
}
