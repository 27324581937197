import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimezoneDatePipe } from '@/pipes/timezoneDate.pipe';

@NgModule({
	declarations: [TimezoneDatePipe],
	imports: [CommonModule],
	exports: [TimezoneDatePipe],
})
export class TimezoneDateModule {}
