import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { GoStorkProducts, PrettyProducts } from '@/consts/config';
import { nanoid } from '@/helpers/nanoid';
import { parseConfigs } from '@/helpers/schedules';
import { StaticScrollBlockStrategy } from '@/helpers/static-block';
import { AdminService } from '@/services/admin.service';
import { AnalyticsService } from '@/services/analytics.service';
import { CalendarViewState } from '@/services/contact-agency.service';
import { timezoneService } from '@/services/timezone.service';
import { UtilsService } from '@/services/utils.service';

@Component({
	selector: 'app-concierge',
	templateUrl: './concierge.component.html',
	styleUrls: ['./concierge.component.scss'],
	animations: [
		trigger('openClose', [
			transition(':leave', [
				animate(
					'200ms',
					style({
						transform: 'translateY(-100%)',
						opacity: 0,
					}),
				),
			]),
		]),
	],
})
export class ConciergeComponent implements OnInit, OnDestroy {
	static buttonToken = nanoid();
	static bookToken = nanoid();

	@Input()
	type: GoStorkProducts;

	@ViewChild('calendarRef')
	calendarRef: TemplateRef<void>;

	showBar = false;
	showBarIVF = false;
	showCalendar = false;

	overlayRef: MatDialogRef<void, any>;

	calendarConfigs$ = this.adminService.getCalendars().pipe(
		tap((configs) => {
			const { durations, defaultDuration } = parseConfigs(this.utilsService, configs);

			this.durations = durations;
			this.duration = defaultDuration;
		}),
		shareReplay(1),
	);

	durations = [];
	duration = 60;

	loading = false;

	CalendarViewState = CalendarViewState;
	viewState = CalendarViewState.SCHEDULE;
	tzones = this.utilsService.tzones;
	timezone = timezoneService.timezone;

	bookingTime: DateTime;
	bookingCalendarId: string;
	private root: HTMLDivElement;

	bookingCalendar$ = this.calendarConfigs$.pipe(
		map((configs) => configs.find(({ calendar_id }) => calendar_id === this.bookingCalendarId)),
	);

	constructor(
		private dialog: MatDialog,
		private adminService: AdminService,
		private utilsService: UtilsService,
		private changeDetectorRef: ChangeDetectorRef,
		private analytics: AnalyticsService,
	) {}

	@HostBinding('class.ivf')
	get promoted() {
		return this.showBarIVF;
	}

	book() {
		this.showCalendar = true;

		this.overlayRef = this.dialog.open(this.calendarRef, {
			closeOnNavigation: true,
			hasBackdrop: true,
			panelClass: 'no-top-borders',
			backdropClass: 'cdk-overlay-blueish-backdrop',
			maxWidth: 636,
			position: {
				top: '0',
			},
			scrollStrategy: new StaticScrollBlockStrategy(),
			width: '100%',
		});
		this.overlayRef.backdropClick().subscribe(() => this.overlayRef.close());

		const action = `${PrettyProducts[this.type] || this.type} Search`;

		this.analytics.push({
			token: ConciergeComponent.buttonToken,
			category: 'User Area',
			action,
			label: 'CTA - Book a Free Consultation',
		});

		//this.close(true);
	}

	close(soft = false) {
		//this.showBar = false;
		if (!soft && this.overlayRef) {
			this.overlayRef.close();
			this.overlayRef = null;
		}
	}

	onTimeSelect(time: DateTime, calendarId: string) {
		this.bookingTime = time;
		this.bookingCalendarId = calendarId;
		this.viewState = CalendarViewState.CONFIRM;
	}

	confirmAppointment() {
		this.loading = true;

		const action = `${PrettyProducts[this.type] || this.type} Search`;

		this.analytics.push({
			token: ConciergeComponent.buttonToken,
			category: 'User Area',
			action,
			label: 'CTA - Schedule a Call Concierge',
		});

		const originalTimezone = timezoneService.timezone;
		timezoneService.timezone = this.timezone;
		this.adminService.scheduleAppointment(this.bookingCalendarId, this.bookingTime.toISO(), this.duration).subscribe(() => {
			this.loading = false;
			this.viewState = CalendarViewState.COMPLETE;
			timezoneService.timezone = originalTimezone;
			setTimeout(() => this.close(), 3000);
		});
	}

	ngOnInit() {
		this.calendarConfigs$.pipe(first()).subscribe((configs) => {
			if (this.type === 'ivf') {
				this.showBarIVF = !!configs.length;
			} else {
				this.showBar = !!configs.length;
			}
			this.changeDetectorRef.markForCheck();
		});
	}

	ngOnDestroy() {
		if (this.overlayRef) {
			/*
			TODO: commented line, if we uncomment this line, dialog doesn't open.
			There is an issue, after we close the dialog, the scroll goes to the second concierge bar
			position
			*/
			//this.overlayRef.close();
		}
	}

	changeTimezone($event: string) {
		this.timezone = $event;
	}
}
