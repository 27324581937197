import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MetaService {
	readonly sharingTags = ['og:title', 'og:description', 'og:url']

	constructor(
		private meta: Meta,
		private title: Title,
		@Inject(DOCUMENT) private dom: Document
	) { }

	setSharing({ title, description, url }) {
		this.meta.updateTag({ name: 'og:title', content: title });
		this.meta.updateTag({ name: 'og:description', content: description });
		this.meta.updateTag({ name: 'og:url', content: url });

		const exists = this.dom.querySelector<HTMLLinkElement>('link[rel="canonical"]');
		const link = exists || this.dom.createElement('link');
		link.setAttribute('rel', 'canonical');
		if (!exists)
			this.dom.head.appendChild(link);
		link.setAttribute('href', url);
	}

	setTitle(title: string) {
		this.title.setTitle(title);
	}

	setDescription(description: string) {
		this.meta.updateTag({ name: 'description', content: description });
	}
}
